<template>
  <div class="index">
    <el-row>
      <!-- 头部 -->
      <el-col :span="24">
        <Head />
      </el-col>
    </el-row>
    <div class="topdiv">
      <div style="background-color: #FFFFFF;">
        <el-row>
          <!-- 单位logo -->
          <el-col :span="4" style="border-right: solid #CCCCCC 1px;border-radius:0px;">
            <el-image style="width: 14rem; height: 14rem;margin-top: 0.4rem;"
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" fit="fill">
            </el-image>
          </el-col>
          <!-- 单位名称 -->
          <el-col :span="20" style="padding: 3rem;text-align: left;">
            <div style="font-size: 2rem;color: #0066FF;">{{fromData.orgName}}</div>
            <div style="margin-top: 1rem;font-size: 1.4rem;">
              <span v-if="fromData.industry == 0">养老行业</span>
              <span v-if="fromData.industry == 1">家政行业</span>
              |
              <!-- 融资阶段（0：不限，1：未融资，2：天使轮，3：A轮，4：B轮，5:C轮，6:D轮及以上，7:已上市，8:不需要融资） -->
              <span v-if="fromData.financing == 0">不限</span>
              <span v-if="fromData.financing == 1">未融资</span>
              <span v-if="fromData.financing == 2">天使轮</span>
              <span v-if="fromData.financing == 3">A轮</span>
              <span v-if="fromData.financing == 4">B轮</span>
              <span v-if="fromData.financing == 5">C轮</span>
              <span v-if="fromData.financing == 6">D轮及以上</span>
              <span v-if="fromData.financing == 7">已上市</span>
              <span v-if="fromData.financing == 8">不需要融资</span>
              |
              <span v-if="fromData.scale == 0">不限</span>
              <span v-if="fromData.scale == 1">0-20人</span>
              <span v-if="fromData.scale == 2">20-99人</span>
              <span v-if="fromData.scale == 3">100-499人</span>
              <span v-if="fromData.scale == 4">500-999人</span>
              <span v-if="fromData.scale == 5">1000-9999人</span>
              <span v-if="fromData.scale == 6">10000人以上</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="bottomdiv">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公司简介" name="first">
          <div style="display: flex;">
            <!-- 简介地址 -->
            <div style="width: 70%;border-right: solid #CCCCCC 1px;margin-right: 1rem;">
              <p style="font-size: 1.2rem;text-align: left;">
                <i style="color: #0066FF;font-size: 1.4rem;">||</i>
                {{fromData.orgName}}简介
              </p>
              <p style="font-size: 1.2rem;text-align: left;padding-left: 1rem;">
                {{fromData.orgIntroduce}}123
              </p>
              <p style="font-size: 1.2rem;text-align: left;">
                <i style="color: #0066FF;font-size: 1.4rem;">||</i>
                公司地址
              </p>
              <p style="text-align: left;padding-left: 1rem;">
                <i class="el-icon-location"></i>
                {{fromData.address}}
              </p>
            </div>
            <!-- 基本信息 -->
            <div>
              <p style="font-size: 1.2rem;text-align: left;">
                <i style="color: #0066FF;font-size: 1.4rem;">||</i>
                {{fromData.orgName}}基本信息
              </p>
              <div style="padding-left: 1rem;">
                <p style="text-align: left;">
                  <i class="el-icon-menu" style="margin-right: 0.5rem;"></i>
                  <span v-if="fromData.industry == 0">养老行业</span>
                  <span v-if="fromData.industry == 1">家政行业</span>
                </p>
                <p style="text-align: left;">
                  <i class="el-icon-data-line" style="margin-right: 0.8rem;"></i>
                  <!-- 融资阶段（0：不限，1：未融资，2：天使轮，3：A轮，4：B轮，5:C轮，6:D轮及以上，7:已上市，8:不需要融资） -->
                  <span v-if="fromData.financing == 0">不限</span>
                  <span v-if="fromData.financing == 1">未融资</span>
                  <span v-if="fromData.financing == 2">天使轮</span>
                  <span v-if="fromData.financing == 3">A轮</span>
                  <span v-if="fromData.financing == 4">B轮</span>
                  <span v-if="fromData.financing == 5">C轮</span>
                  <span v-if="fromData.financing == 6">D轮及以上</span>
                  <span v-if="fromData.financing == 7">已上市</span>
                  <span v-if="fromData.financing == 8">不需要融资</span>
                </p>
                <p style="text-align: left;">
                  <i class="el-icon-coordinate" style="margin-right: 0.8rem;"></i>
                  <!-- 公司规模（0：不限，1：0-20人，2：20-99人，3：100-499人，4：500-999人，5:1000-9999人，6:10000人以上） -->
                  <span v-if="fromData.scale == 0">不限</span>
                  <span v-if="fromData.scale == 1">0-20人</span>
                  <span v-if="fromData.scale == 2">20-99人</span>
                  <span v-if="fromData.scale == 3">100-499人</span>
                  <span v-if="fromData.scale == 4">500-999人</span>
                  <span v-if="fromData.scale == 5">1000-9999人</span>
                  <span v-if="fromData.scale == 6">10000人以上</span>
                </p>
                <p style="text-align: left;">
                  <i class="el-icon-location-information" style="margin-right: 0.5rem;"></i>
                  {{fromData.address}}
                </p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="招聘职位" name="second">
          <div style="display: flex;">
            <!-- 招聘职位 -->
            <div style="width: 70%;border-right: solid #CCCCCC 1px;margin-right: 1rem;">
              招聘职位
            </div>
            <!-- 基本信息 -->
            <div>
              <p style="font-size: 1.2rem;text-align: left;">
                <i style="color: #0066FF;font-size: 1.4rem;">||</i>
                {{fromData.orgName}}基本信息
              </p>
              <div style="padding-left: 1rem;">
                <p style="text-align: left;">
                  <i class="el-icon-menu"></i>
                  人工智能，IT技术服务｜咨询
                </p>
                <p style="text-align: left;">
                  <i class="el-icon-data-line"></i>
                  上市公司
                </p>
                <p style="text-align: left;">
                  <i class="el-icon-coordinate"></i>
                  2000人以上
                </p>
                <p style="text-align: left;">
                  <i class="el-icon-location-information"></i>
                  北京
                </p>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-row style="margin-top: 2rem;z-index: -999;">
      <!-- 底部版权 -->
      <el-col :span="24">
        <Copyright />
      </el-col>
    </el-row>
  </div>
</template>


<script>
  // 底部版权
  import Copyright from '@/components/Copyright.vue'
  import Head from '@/components/Head.vue'

  export default {
    name: 'app',
    components: {
      Head,
      Copyright
    },
    data: function() {
      return {
        activeName: 'first',
        orgId: localStorage.getItem('xggorgId'), //取出缓存
        fromData: {}
      }
    },
    // 页面加载完毕触发
    mounted() {
      localStorage.setItem('activeIndex', "6");
      this.getOrgInfo();
    },
    methods: {
      // 获取招聘单位详情接口
      getOrgInfo() {
        let _this = this;
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/pubilc/getOrgInfo/' + _this.orgId,
          params: "",
        }).then((res) => {
          _this.fromData = res.data.data
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }
    }
  }
</script>

<style src="@/style/main.css" scoped></style>
<style>
  .el-row {
    &:last-child {
      margin-bottom: 0;
    }

    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .topdiv {
    margin-top: 4rem;
    background-color: #CCCCCC;
    height: 18rem;
    padding: 3rem 8rem 0rem 8rem;
  }

  .bottomdiv {
    padding: 0rem 8rem;
  }
</style>
