<template>
  <!-- 没有父页面直接进去详情 -->
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="hallmaxdiv">
          <el-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
            <el-col :span="24" style="padding-right: 10px !important;margin-top: 0.5rem;background-color: #FFFFFF;">
              <!-- 面包屑 -->
              <div class="breadcrumb">
                <el-breadcrumb separator="/">
                  <el-breadcrumb-item>人才互聘大厅</el-breadcrumb-item>
                  <el-breadcrumb-item>招聘详情</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <div class="topdivnr">
                <p style="text-align: left;padding-left: 2rem;margin-top: 0px;">
                  {{formdata.recruitStatus==1 ? '招聘中' : '停止招聘'}}
                </p>
                <!-- 头部标题 -->
                <div class="toptitle">
                  {{formdata.recruitTitle}}
                  <span style="color: red;font-weight: 500;" v-if="formdata.jobSalary==1">3k以下</span>
                  <span style="color: red;font-weight: 500;" v-if="formdata.jobSalary==2">3-5k</span>
                  <span style="color: red;font-weight: 500;" v-if="formdata.jobSalary==3">5-10k</span>
                  <span style="color: red;font-weight: 500;" v-if="formdata.jobSalary==4">10-15k</span>
                  <span style="color: red;font-weight: 500;" v-if="formdata.jobSalary==5">15-20k</span>
                  <span style="color: red;font-weight: 500;" v-if="formdata.jobSalary==6">20-30k</span>
                  <span style="color: red;font-weight: 500;" v-if="formdata.jobSalary==7">30-50k</span>
                  <span style="color: red;font-weight: 500;" v-if="formdata.jobSalary==8">50k以上</span>
                </div>
                <div class="information">
                  <div>
                    <!-- 经验 -->
                    <span style="" v-if="formdata.experience==0"> 不限 </span>
                    <span style="" v-if="formdata.experience==1"> 在校生 </span>
                    <span style="" v-if="formdata.experience==2"> 应届生 </span>
                    <span style="" v-if="formdata.experience==3"> 1年以内 </span>
                    <span style="" v-if="formdata.experience==4"> 1-3年 </span>
                    <span style="" v-if="formdata.experience==5"> 3-5年 </span>
                    <span style="" v-if="formdata.experience==6"> 5-10年 </span>
                    <span style="" v-if="formdata.experience==7"> 10年以上 </span>
                    /
                    <span style="" v-if="formdata.education==0"> 不限 </span>
                    <span style="" v-if="formdata.education==1"> 初中及以下 </span>
                    <span style="" v-if="formdata.education==2"> 中专/技校 </span>
                    <span style="" v-if="formdata.education==3"> 高中 </span>
                    <span style="" v-if="formdata.education==4"> 大专 </span>
                    <span style="" v-if="formdata.education==5"> 本科 </span>
                    <span style="" v-if="formdata.education==6"> 硕士 </span>
                    <span style="" v-if="formdata.education==7"> 博士 </span>
                    /
                    <span>{{formdata.jobAddress}}</span>
                    /
                    <span style="" v-if="formdata.nature==0"> 不限 </span>
                    <span style="" v-if="formdata.nature==1"> 全职 </span>
                    <span style="" v-if="formdata.nature==2"> 兼职 </span>
                    <span style="" v-if="formdata.nature==3"> 实习 </span>

                  </div>
                </div>
                <div class="shocang">
                  <el-button v-if="formdata.isApply==0" type="primary" @click="purchase()"
                    style="margin-right: 2rem;width: 12rem;">
                    投简历
                  </el-button>
                  <el-button v-if="formdata.isApply==1" type="primary" style="margin-right: 2rem;width: 12rem;"
                    @click="watchclick(formdata.fileList,3)">已投递
                  </el-button>
                  <i v-if="formdata.isCollect==0" @click="CancelCollect()" class="el-icon-star-on"
                    style="cursor:pointer;color: #0066FF;">已收藏</i>
                  <i v-if="formdata.isCollect==1" class="el-icon-star-off" @click="Collection()"
                    style="cursor:pointer;">收藏</i>
                  <div style="float: right;padding-top: 20px;">
                    发布时间：{{formdata.updateTime}}
                    |
                    浏览：{{formdata.browseNum}}次
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="18" style="padding-right: 10px !important;margin-top: 0.5rem;">
              <!-- 左边下内容 -->
              <div class="grid-content bg-purple">
                <div class="leftdivtop">
                  <div class="leftdivtoptitle">招聘简介</div>
                  <el-divider></el-divider>
                </div>
                <el-row>
                  <el-col :span="24">
                    <!-- 招聘简介 -->
                    <div class="zuoxianrjj" style="min-height: 20rem;">
                      <span>{{formdata.recruitContent}}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">

                  </el-col>
                </el-row>
              </div>
            </el-col>

            <!-- 右边内容 -->
            <el-col :span="6" style="padding-left: 10px !important;margin-top: 0.5rem;">
              <el-row :gutter="20" style="background-color: #FFFFFF;border-radius: 4px;">
                <div class="leftdiv">
                  <!-- 头部 -->
                  <div class="leftdivtop">
                    <div class="leftdivtoptitle">集团级别信息</div>
                    <el-divider></el-divider>
                  </div>
                  <!-- 内容 -->
                  <div class="leftdivtopnrdiv" v-for="item in tuijlist">
                    <div class="leftdivtopnr">
                      <el-link @click="Clicktoview(item.resId)">{{item.resTitle}}</el-link>&#12288;
                      <span>{{item.createTime}}</span>
                    </div>
                  </div>
                </div>
              </el-row>
            </el-col>
          </el-row>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script src="../../assets/js/PDFObject.js"></script>
<script>
  export default {
    // 接收父组件的值
    props: ['searchThing'],
    data: function() {
      return {
        // 发布招聘用户ID
        resourcesid: '',
        tuijlist: [],
        userId: localStorage.getItem("userId"),
        loading: true,
        // 招聘详情信息
        formdata: {},
        recruitId: '',
      }
    },
    // 页面加载完毕触发
    mounted() {
      this.recruitId = this.$route.params.recruitId;
      // 获取招聘详情
      this.getdata();
    },
    methods: {
      // 获取招聘详情
      getdata() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/recruit/recruitInfo/' + this.recruitId + '??loginUserId=' + this.userId,
          data: '',
        }).then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            this.formdata = res.data.data;
          } else {
            this.$message({
              offset: 100,
              message: res.data.code + '请从招聘列表进入！',
              type: 'error'
            });
            this.$router.push({
              name: 'Hirehall'
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 收藏
      Collection() {
        let params = {
          "resId": this.searchThing,
          "userId": this.userId
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resCollectRecord',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$alert('恭喜你，收藏成功！', '收藏成功', {
              confirmButtonText: '确定'
            });
            // 获取招聘详情
            this.getdata();
          } else {
            this.$alert(res.data.msg, '收藏失败', {
              confirmButtonText: '确定'
            });
            // 获取招聘详情
            this.getdata();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 取消收藏
      CancelCollect() {
        let params = {
          "resId": this.searchThing,
          "userId": this.userId
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resCollectRecord/cancelCollect',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$alert('取消收藏成功！', '取消收藏', {
              confirmButtonText: '确定'
            });
            // 获取招聘详情
            this.getdata();
          } else {
            this.$alert(res.data.msg, '取消收藏失败', {
              confirmButtonText: '确定'
            });
            // 获取招聘详情
            this.getdata();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
  }
</script>

<style src="@/style/Team/RecruitmentDetails.css" scoped></style>
<style>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  .el-form-item__label {
    width: 15% !important;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .msgbox {
    margin-top: 5rem;
    width: 80% !important;
  }

  .msgbox iframe {
    height: 30rem;
    width: 100% !important;
  }

  .iconstyle {
    width: 2.7rem;
    height: 2.7rem;
    float: left;
    margin-right: 1rem;
  }
</style>
