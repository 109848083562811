<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>人才互聘管理</el-breadcrumb-item>
            <el-breadcrumb-item>职位管理</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="ruleForm" label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="职位名称">
                <template>
                  <el-input v-model="tableDataval.recruitTitle" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态:">
                <template>
                  <el-select v-model="tableDataval.recruitStatus" style="width: 100%;" placeholder="请选择" clearable
                    @change="onSubmit">
                    <!-- （0草稿，1报名中，2合作中，3评审中，4已结项） -->
                    <el-option label="草稿" :value="0"></el-option>
                    <el-option label="招聘中" :value="1"></el-option>
                    <el-option label="已停止" :value="2"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button type="primary" size="medium" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="recruitTitle" label="职位名称">
            </el-table-column>
            <el-table-column label="职位分类">
              <template slot-scope="scope">
                <span v-if="scope.row.industry == 0">养老</span>
                <span v-if="scope.row.industry == 1">家政</span>
              </template>
            </el-table-column>
            <el-table-column prop="projectTypeName" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.recruitStatus == 0">草稿</span>
                <span v-if="scope.row.recruitStatus == 1">招聘中</span>
                <span v-if="scope.row.recruitStatus == 2">已停止</span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间">
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <!-- 草稿 -->
                <el-button v-if="scope.row.recruitStatus == 0" size="small" @click="editclick(scope.row)" type="text">
                  编辑
                </el-button>
                <el-button v-if="scope.row.recruitStatus == 0" size="small" @click="releaseclick(scope.row)"
                  type="text">
                  发布
                </el-button>
                <el-button v-if="scope.row.recruitStatus == 0" size="small" @click="deleteclick(scope.row)" type="text">
                  删除
                </el-button>
                <!-- 招聘中&停止招聘 -->
                <el-button style="color: green;" v-if="scope.row.recruitStatus == 1 || scope.row.recruitStatus == 2"
                  size="small" @click="seeresume(scope.row)" type="text">
                  查看简历
                </el-button>
                <el-button v-if="scope.row.recruitStatus == 2" size="small" @click="startAdnstopclick(scope.row)"
                  type="text">
                  开启招聘
                </el-button>
                <el-button style="color: red;" v-if="scope.row.recruitStatus == 1" size="small"
                  @click="startAdnstopclick(scope.row)" type="text">
                  停止招聘
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 为用户ID
          'userId': localStorage.getItem("userId"),
          // 职位名称
          'recruitTitle': "",
          // 项目状态
          'recruitStatus': "",
        },
        // 总数
        total: 0,
        tableData: []
      }
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/recruit/recruitInfo/list?pageNum=' + this.tableDataval
            .pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.tableData = res.data.rows;
            this.total = res.data.total;
          } else {
            this.tableData = [];
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 查看简历
      seeresume(e) {
        let params = {
          "recruitId": e.recruitId
        }
        // 跳转到项目详情页面弹窗
        this.$router.push({
          name: 'Position2',
          params: params
        });
      },
      // 开启&停止招聘
      startAdnstopclick(e) {
        let params = {
          "recruitId": e.recruitId,
          "recruitStatus": e.recruitStatus == 1 ? 2 : 1
        }
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/recruit/recruitInfo',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              showClose: true,
              message: res.data.msg,
              type: 'success'
            });
            this.onSubmit();
          } else {
            this.onSubmit();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 发布
      releaseclick(e) {
        let params = {
          "recruitId": e.recruitId,
          "recruitStatus": 1
        }
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/recruit/recruitInfo',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              showClose: true,
              message: res.data.msg,
              type: 'success'
            });
            this.onSubmit();
          } else {
            this.onSubmit();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 删除
      deleteclick(e) {
        this.$confirm('此操作将永久删除该职位, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/recruit/recruitInfo/' + e.recruitId,
            data: {},
            //请求成功的回调函数
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                showClose: true,
                message: res.data.msg,
                type: 'success'
              });
              this.onSubmit();
            } else {
              this.onSubmit();
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 编辑
      editclick(e) {
        let params = {
          "recruitId": e.recruitId
        }
        // 跳转到项目详情页面弹窗
        this.$router.push({
          name: 'Releaseposition',
          params: params
        });
      }
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit();
    }
  }
</script>

<style src="@/style/Team/ContentmanagementCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
