<template>
  <div class="MyresourcesCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>资源共享管理</el-breadcrumb-item>
            <el-breadcrumb-item>我的资源</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="tableDataval" label-width="120px !important">
            <el-col :span="8">
              <el-form-item label="资源标题:">
                <template>
                  <el-input v-model="tableDataval.resTitle" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="状态:">
                <template>
                  <el-select v-model="tableDataval.status" placeholder="请选择" style="width: 100%;" clearable
                    @change="onSubmit">
                    <el-option label="草稿" value="0"></el-option>
                    <el-option label="待审核" value="1"></el-option>
                    <el-option label="已上架" value="2"></el-option>
                    <el-option label="退回" value="3"></el-option>
                    <el-option label="已下架" value="4"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <!-- <el-button>重置</el-button> -->
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%;height: 70.5vh;overflow-y: auto;" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50">
            </el-table-column>
            <el-table-column prop="" label="资源标题">
              <template slot-scope="scope">
                <!-- {{scope.row.resTitle}} -->
                <el-link @click="Clicktoview(scope.row.resId)" target="_blank">{{scope.row.resTitle}}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="产生联盟积分">
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == '0'">草稿</span>
                <span v-if="scope.row.status == '1'">待审核</span>
                <span v-if="scope.row.status == '2'">上架</span>
                <span v-if="scope.row.status == '3'">退回</span>
                <span v-if="scope.row.status == '4'">下架</span>
              </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="上传时间">
            </el-table-column>
            <el-table-column prop="" label="审核原因">
            </el-table-column>
            <el-table-column prop="" label="操作" width="160" fixed="right">
              <template slot-scope="scope">
                <el-button style="color: #F56C6C;" type="text" size="small" @click="deleteclick(scope.row.resId)">删除
                </el-button>
                <el-button v-if="scope.row.status=='3'" style="color: #E6A23C;" type="text" size="small"
                  @click="Returnreason(scope.row.resId)">查看退回原因
                </el-button>
                <el-button v-if="scope.row.status=='2'||scope.row.status=='4'" style="color: #E6A23C;" type="text"
                  size="small" @click="examine(scope.row)">
                  {{scope.row.status=='2'?"下架":"上架"}}
                </el-button>
                <el-button v-if="scope.row.status=='0'||scope.row.status=='3'||scope.row.status=='4'"
                  style="color: #909399;" type="text" size="small" @click="editclick(scope.row.resId)">编辑
                </el-button>
                <el-button v-if="scope.row.status=='0'||scope.row.status=='3'" style="color: #67C23A;" type="text"
                  size="small" @click="release(scope.row)">发布
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    data: function() {
      return {
        value1: "",
        // 查询条件
        ruleForm: {
          name: '',
          value: ''
        },
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          "userId": this.thisuserId,
          "resTitle": "",
          "status": ''
        },
        // 总数
        total: 0,
        tableData: [],
        thisuserId: '',
        resIds: '',

      }
    },
    methods: {
      // 点击标题跳转到详情页面
      Clicktoview(e) {
        this.$router.push({
          name: 'ResourceDetails',
          params: {
            resId: e
          }
        });
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      onSubmit() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resInfo/list?pageNum=' + this.tableDataval.pageNum + "&pageSize=" +
            this.tableDataval.pageSize,
          data: this.tableDataval,
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.total = res.data.total;
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 发布点击事件
      release(e) {
        let row = {
          // 资源ID
          resId: e.resId,
          // 待审核
          status: '1'
        }
        this.$confirm('此操作将发布资源, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/resouse/resInfo/changeStatus',
            data: row,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                type: 'success',
                message: '发布资源成功!'
              });
              this.onSubmit();
            } else {
              this.$message.error(res.data.msg);
              this.onSubmit();
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {});
      },
      // 上架&下架
      examine(e) {
        let row = {
          // 资源ID
          resId: e.resId,
          // 状态（0草稿，1待审核，2上架，3退回，4下架）
          status: e.status == '2' ? '4' : '2'
        }
        this.$confirm(e.status == "2" ? '此操作将下架资源, 是否继续?' : '此操作将上架资源, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/resouse/resInfo/changeStatus',
            data: row,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                type: 'success',
                message: '上架资源成功!'
              });
              this.onSubmit();
            } else {
              this.$message.error(res.data.msg);
              this.onSubmit();
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {});
      },
      //编辑
      editclick(e) {
        // 跳转到上传文件页面
        this.$router.push({
          name: 'Upload',
          params: {
            resId: e,
          }
        });
      },
      // 查看退回原因
      Returnreason(e) {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/resouse/resAuditRecord/list?resId=' + e,
          params: '',
        }).then((res) => {
          this.$alert(res.data.rows[0].reason, '退回原因', {
            confirmButtonText: '确定',
            callback: action => {}
          });
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 删除事件
      deleteclick(e) {
        this.resIds ? this.resIds : this.resIds = e;
        this.$confirm('此操作将永久删除该文件, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/resouse/resInfo/' + this.resIds,
            params: '',
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                type: 'success',
                message: '删除成功!'
              });
              this.onSubmit();
            } else {
              this.$message.error(res.data.msg);
              this.onSubmit();
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 序号多选
      handleSelectionChange(val) {
        this.resIds = '';
        for (var key in val) {
          this.resIds += val[key].resId + ','
        }
      }
    },
    // 页面加载完毕事件
    mounted() {
      this.tableDataval.userId = localStorage.getItem("userId");
      //ajax请求
      this.onSubmit();
    }
  }
</script>

<style src="@/style/Resources/MyresourcesCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
