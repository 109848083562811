<template>
  <div class="index">
    <el-row>
      <!-- 头部 -->
      <el-col :span="24">
        <Head />
      </el-col>
    </el-row>
    <el-row :gutter="20" class="subject">
      <!-- 导航栏 -->
      <el-col :span="4" class="bar">
        <Bar :key="componentKey" />
      </el-col>
      <!-- 内容 -->
      <el-col :span="20">
        <trainingApplicationexamine :resId="resId" />
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem;z-index: -999;">
      <!-- 底部版权 -->
      <el-col :span="24">
        <Copyright />
      </el-col>
    </el-row>
  </div>
</template>


<script>
  // 底部版权
  import Copyright from '@/components/Copyright.vue'
  import Head from '@/components/Head.vue'
  import Bar from '@/components/Bar.vue'
  import trainingApplicationexamine from '@/components/Hire/trainingApplicationexamine.vue'


  export default {
    name: 'app',
    components: {
      Copyright,
      Head,
      Bar,
      trainingApplicationexamine
    },

    data: function() {
      return {
        Notice: 0,
        // 资源id
        resId: '',
        componentKey: 0,
      }
    },
    // 实例已经创建完成之后被调用。
    created() {
      // 获取资源resId
      this.resId = this.$route.params.resId ? this.$route.params.resId : '';
    },
    // 页面加载完毕触发
    mounted() {
      // 把指向vue对象保存给_this
      let _this = this;
      // 定义当前头部导航栏
      localStorage.setItem('activeIndex', "");
      // 指定左侧导航栏
      localStorage.setItem('BaractiveIndex', "/trainingApplicationexamine");
      // 指定左侧导航栏
      _this.forceRerender();
    },
    methods: {
      forceRerender() {
        this.componentKey += 1;
      }
    },
  }
</script>


<style src="@/style/Resources/Upload.css" scoped></style>
<style src="@/style/main.css" scoped></style>
<style>
  .bar {
    padding-right: 0px;
    padding-left: 10px;
    padding-right: 0px !important;
    height: 100%;
  }
</style>
