<template>
  <div class="RegisterCom">
    <div class="title">请选择机构类型</div>
    <el-row :gutter="20" style="padding: 0rem 5rem;">
      <el-col :span="8" style="padding-left: 20px;" class="divlist">
        <!-- 带参数跳转 -->
        <router-link :to="{path:'Inside',query:{setid:123456}}">
          <div class="annlist">
            <div class="annlisttop">
              <div class="tubiao">
                <img src="../../assets/u61.svg" />
              </div>
              <div class="nrlistdiv">高校及事业机构</div>
            </div>
            <div class="nrlisttext">机构类型：本科院校、专门学院、高职高专学校、科研机构</div>
          </div>
        </router-link>
      </el-col>
      <el-col :span="8" class="divlist">
        <!-- 带参数跳转 -->
        <router-link :to="{path:'Inside',query:{setid:123456}}">
          <div class="annlist">
            <div class="annlisttop">
              <div class="tubiao">
                <img src="../../assets/u61.svg" />
              </div>
              <div class="nrlistdiv">企业机构</div>
            </div>
            <div class="nrlisttext">机构类型：企业法人、企业非法人、个人独资、个体户 或具有盈利性质的组织机构</div>
          </div>
        </router-link>
      </el-col>
      <el-col :span="8" class="divlist">
        <!-- 带参数跳转 -->
        <router-link :to="{path:'Inside',query:{setid:123456}}">
          <div class="annlist">
            <div class="annlisttop">
              <div class="tubiao">
                <img src="../../assets/u61.svg" />
              </div>
              <div class="nrlistdiv">政府机关</div>
            </div>
            <div class="nrlisttext">机构类型：机关法人、机关非法人</div>
          </div>
        </router-link>
      </el-col>
    </el-row>
    <div class="dibutext">
      机构入链注册，请先下载
      <el-link type="primary" @click="downloadclick">《机构注册委托证明》</el-link>
      ，签字盖章后上传平台
    </div>
  </div>
</template>

<script>
  export default {
   data: function () {
      return {
        usertype: '',
      }
    },
    // 页面加载完毕触发
    mounted() {
      // 把指向vue对象保存给_this
      let _this = this;
      // 获取跳转过来的参数
      _this.usertype = _this.$route.query.type;
    },
    methods: {
      // 下载《机构注册委托证明》
      downloadclick() {
        window.open("http://119.3.109.199:9091/profile/common/image/2021/12/09/32e265820e8847ac94097a515cbe7ba7.doc");
      },
    }
  }
</script>

<style src="@/style/register/RegisterCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }
  .el-form-item__label {
   text-align: right;
  }
</style>
