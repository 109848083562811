<template>
  <div class="MechanismCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="tableDataval" label-width="100px !important">
            <el-col :span="6">
              <el-form-item label="姓名:">
                <template>
                  <el-input v-model="tableDataval.nickName" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="userType == 0">
              <el-form-item label="所属机构:">
                <template>
                  <!--                  <el-select filterable v-model="tableDataval.inputOrg" placeholder="请选择" clearable>
                    <el-option v-for="item in orglistdata" :key="item.orgId" :label="item.orgName"
                      :value="item.orgName">
                    </el-option>
                  </el-select> -->
                  <el-input v-model="tableDataval.inputOrg" placeholder="请输入所属机构" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态:">
                <template>
                  <el-select v-model="tableDataval.status" placeholder="请选择" clearable>
                    <el-option label="待审核" value="1"></el-option>
                    <el-option label="有效" value="2"></el-option>
                    <el-option label="退回" value="3"></el-option>
                    <el-option label="失效" value="4"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="申请日期:">
                <template>
                  <el-date-picker v-model="tableDataval.time" type="daterange" align="right" unlink-panels
                    range-separator="至" value-format="yyyy-MM-dd" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                  </el-date-picker>
                </template>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item style="text-align: end;">
                <el-button @click="chongzhi">重置</el-button>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-button @click="all()" :type="primary1">全部</el-button>
        <!--        <el-badge :value="caibanvalue" class="item" style="margin-left: 1rem;">
          <el-button @click="daiban()" :type="primary2">待办</el-button>
        </el-badge> -->
        <el-button @click="daochu" style="margin-left: 1rem;" type="primary" icon="el-icon-download">导出</el-button>
      </el-col>
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%;height: 61.4vh;overflow-y: auto;">
            <el-table-column prop="nickName" label="姓名" width="140">
            </el-table-column>
            <el-table-column prop="userName" label="账号" width="140">
            </el-table-column>
            <el-table-column prop="inputOrg" label="所属机构">
            </el-table-column>
            <el-table-column prop="deptName" label="所属部门" width="180">
            </el-table-column>
            <el-table-column prop="job" label="所属部门职务">
            </el-table-column>
            <el-table-column prop="unionJob" label="联盟职务">
              <template slot-scope="scope">
                <span v-if="scope.row.unionJob == 0">
                  常务理事长
                </span>
                <span v-if="scope.row.unionJob == 1">
                  常务理事
                </span>
                <span v-if="scope.row.unionJob == 2">
                  理事
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="phonenumber" label="手机号" width="120">
            </el-table-column>
            <el-table-column prop="" label="状态" width="80">
              <template slot-scope="scope">
                <!-- <el-link :underline="false"target="_blank" v-if="scope.row.status == 0">新建</el-link> -->
                <el-link :underline="false" type="danger" v-if="scope.row.status == 1">待审核</el-link>
                <el-link :underline="false" type="primary" v-if="scope.row.status == 2">启用</el-link>
                <el-link :underline="false" target="_blank" v-if="scope.row.status == 3">退回</el-link>
                <el-link :underline="false" type="danger" v-if="scope.row.status == 4">禁用</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160">
            </el-table-column>
            <el-table-column prop="" label="操作" fixed="right" width="150">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="see(scope.row.userId)" style="color: #909399;">
                  查看
                </el-button>
                <el-button v-if="scope.row.status == 1" type="text" size="small" @click="examine(scope.row.userId)"
                  style="color: #E6A23C;">
                  审核
                </el-button>
                <el-button v-if="scope.row.status != 1" type="text" size="small" @click="edit(scope.row.userId)">
                  编辑
                </el-button>
                <el-button type="text" size="small" @click="Reset(scope.row.userId)">
                  重置密码
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>

    <!-- 用户修改&&用户查看详情弹窗 -->
    <el-dialog :title="dialogtitle" :visible.sync="dialogVisible" width="80%">
      <el-row>
        <el-col :span="24">
          <!-- 个人信息 -->
          <div class="Form1">
            <el-row>
              <el-col :span="24">
                <div style="padding: 1rem;">
                  <el-form :model="DetailTable" label-width="100px" class="demo-ruleForm">
                    <!-- 头部文字 -->
                    <div class="toptext">
                      <p>个人信息</p>
                    </div>
                    <el-row>
                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="姓名:">
                          <span
                            v-if="dialogVisiblestate == 0 || dialogVisiblestate == 2">{{DetailTable.nickName}}</span>
                          <el-input v-else v-model="DetailTable.nickName" style="width:80%;"></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="性别:">
                          <span>
                            <span v-if="DetailTable.sex == 0">男</span>
                            <span v-if="DetailTable.sex == 1">女</span>
                          </span>
                          <!-- <el-select v-else v-model="DetailTable.sex" value="DetailTable.sex" placeholder="请选择">
                            <el-option label="男" value="0"></el-option>
                            <el-option label="女" value="1"></el-option>
                          </el-select> -->
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="手机号码:">
                          <span
                            v-if="dialogVisiblestate == 0 || dialogVisiblestate == 2">{{DetailTable.phonenumber}}</span>
                          <el-input v-else v-model="DetailTable.phonenumber" style="width:80%;"></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="部门:">
                          <span
                            v-if="dialogVisiblestate == 0 || dialogVisiblestate == 2">{{DetailTable.deptName}}</span>
                          <el-input v-else v-model="DetailTable.deptName" style="width:80%;"></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="职务:">
                          <span v-if="dialogVisiblestate == 0 || dialogVisiblestate == 2">{{DetailTable.job}}</span>
                          <el-input v-else v-model="DetailTable.job" style="width:80%;"></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="证件类型:">
                          <span v-if="dialogVisiblestate == 0 || dialogVisiblestate == 2">
                            身份证
                          </span>
                          <el-select v-else v-model="DetailTable.certType" value="DetailTable.certType"
                            placeholder="请选择">
                            <el-option label="身份证" value="0"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="证件号码:">
                          <span v-if="dialogVisiblestate == 0 || dialogVisiblestate == 2">{{DetailTable.certNo}}</span>
                          <el-input v-else v-model="DetailTable.certNo" style="width:80%;"></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="所属机构:">
                          <span
                            v-if="dialogVisiblestate == 0 || dialogVisiblestate == 2">{{DetailTable.inputOrg}}</span>
                          <el-input v-else v-model="DetailTable.inputOrg" style="width:80%;"></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="24" style="text-align: left;">
                        <el-form-item label="身份证正反签字扫描件:">
                          <div style="display: inline-block;width: 100%" v-if="ifedit == 0">
                            <el-upload class="imgupload" :action="$baseUrl.test+'/common/upload'" name="file"
                              list-type="picture-card" :on-preview="cert" :on-remove="certUrlRemove"
                              :on-success="certUrlsuccess" :limit="2">
                              <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-form-item label="原身份证:">
                              <div class="demo-image__preview">
                                <el-image v-if="imgenlargecertUrl1" fit="contain"
                                  @click="imgenlarge(imgenlargecertUrl1)" style="width: 100px; height: 100px"
                                  :src="imgenlargecertUrl1">
                                </el-image>
                                <el-image v-if="imgenlargecertUrl2" fit="contain"
                                  @click="imgenlarge(imgenlargecertUrl2)" style="width: 100px; height: 100px"
                                  :src="imgenlargecertUrl2">
                                </el-image>
                              </div>
                            </el-form-item>
                          </div>

                          <div class="demo-image__preview" v-if="ifedit == 1 && imgenlargecertUrl1"
                            @click="imgenlarge(imgenlargecertUrl1)">
                            <el-image fit="contain" style="width: 100px; height: 100px" :src="imgenlargecertUrl1"
                              :preview-src-list="imgenlargecertUrl1">
                            </el-image>
                          </div>
                          <div class="demo-image__preview" v-if="ifedit == 1 && imgenlargecertUrl2"
                            @click="imgenlarge(imgenlargecertUrl2)">
                            <el-image fit="contain" style="width: 100px; height: 100px" :src="imgenlargecertUrl2"
                              :preview-src-list="imgenlargecertUrl2">
                            </el-image>
                          </div>
                        </el-form-item>
                      </el-col>

                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p>联盟职务</p>
                      </div>
                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="联盟职务:">
                          <span v-if="dialogVisiblestate == 0">
                            <span v-if="DetailTable.unionJob == 0">
                              常务理事长
                            </span>
                            <span v-if="DetailTable.unionJob == 1">
                              常务理事
                            </span>
                            <span v-if="DetailTable.unionJob == 2">
                              理事
                            </span>
                          </span>
                          <template v-else>
                            <el-select v-model="DetailTable.unionJob" placeholder="请选择" style="width: 50%;" clearable>
                              <el-option v-for="item in union_job" :key="item.dictValue" :label="item.dictLabel"
                                :value="item.dictValue">
                              </el-option>
                            </el-select>
                            <p style="color: red;font-size: 0.8rem;text-align: center;">
                              由审核人填写
                            </p>
                          </template>
                        </el-form-item>
                      </el-col>

                      <!-- <div class="toptext">
                        <p>账号状态</p>
                      </div>
                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="账号:">
                          <span
                            v-if="dialogVisiblestate == 0 || dialogVisiblestate == 2">{{DetailTable.userName}}</span>
                          <el-input v-else v-model="DetailTable.userName" style="width:80%;"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="状态:">
                          <el-switch v-model="DetailTable.status" @change="changeStatus()" active-value="2"
                            inactive-value="4" active-text="启用" inactive-text="禁用" :disabled="shenhe">
                          </el-switch>
                        </el-form-item>
                      </el-col> -->
                    </el-row>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>

        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refuse()" type="primary" v-if="dialogVisiblestate == 2">审 核</el-button>
        <el-button @click="Submitclick()" type="primary" v-if="dialogVisiblestate == 1">保 存</el-button>
        <el-button @click="dialogVisible = false">返 回</el-button>
      </span>

      <!-- 二级弹窗 -->
      <el-dialog width="60%" title="审核" style="text-align: center;" :visible.sync="innerVisible" append-to-body>
        <div style="font-weight:bold;text-align: left;margin: 1rem 0rem;">审核:</div>
        <div style="text-align: left">
          <el-radio v-model="DetailTable.status" label="2" border>通过</el-radio>
          <el-radio v-model="DetailTable.status" label="4" border>未通过</el-radio>
        </div>
        <div style="font-weight:bold;text-align: left;margin: 1rem 0rem;">理由:</div>
        <el-input type="textarea" :rows="2" placeholder="请输入审核理由" v-model="auditReason">
        </el-input>
        <div style="text-align: center;margin-top: 2rem;">
          <el-button type="primary" @click="Toexamine()">确 定</el-button>
          <el-button @click="innerVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <!-- 身份证放大弹窗 -->
      <el-dialog width="60%" title="审核" style="text-align: center;" :visible.sync="imgenlargedialog" append-to-body>
        <el-image fit="contain" :src="imgenlargecertUrl">
        </el-image>
        <div style="text-align: center;margin-top: 2rem;">
          <el-button @click="imgenlargedialog = false">取 消</el-button>
        </div>
      </el-dialog>


    </el-dialog>

  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        imgenlargecertUrl: '',
        imgenlargecertUrl1: '',
        imgenlargecertUrl2: '',
        thiscertid: [],
        imgenlargedialog: false,
        // 获取用户权限，用户类型（0平台管理员-秘书长  1机构管理员 2普通用户 ）
        userType: localStorage.getItem("userType"),
        // 机构列表
        orglistdata: [],
        shenhe: true,
        ruleForm: {
          name: '',
          value: ''
        },
        primary1: 'primary',
        primary2: '',
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 机构ID
          'inputOrg': '',
          'nickName': '',
          'time': []
        },
        total: 0,
        tableData: [],
        DetailTable: {
          'nickName': "",
          'sex': "",
          'phonenumber': "",
          'deptName': "",
          'job': "",
          'certType': "",
          'certNo': "",
          'certUrl': "",
          'unionJob': "",
          'userName': "",
          'certid': [],
          'inputOrg': ''
        },
        dialogtitle: '查看用户信息',
        dialogVisible: false,
        dialogVisiblestate: 0,
        // 当前用户id
        userId: '',
        // 待办
        caibanvalue: '',
        // 二级弹窗
        innerVisible: false,
        // 审核理由
        auditReason: "",
        // 联盟职务
        union_job: [],
        // 判断是否为编辑 0=是，1=不是
        ifedit: '0'
      }
    },
    methods: {
      // 身份证点击放大
      imgenlarge(e) {
        this.imgenlargecertUrl = e;
        this.imgenlargedialog = true;
      },
      // 身份证正反签字扫描件路径
      certUrlsuccess(response, file, fileList) {
        this.thiscertid.push(response.data.fileId);
        // 如果长度等于2
        if (this.thiscertid.length > 2) {
          this.thiscertid.splice(0, 1);
        }
      },
      // 身份证正反签字扫描件路径删除
      certUrlRemove(file, fileList) {
        // this.thiscertid.push(file.response.data.fileId);
        for (let i in this.thiscertid) {
          if (this.thiscertid[i] == file.response.data.fileId) {
            this.thiscertid.splice(i, 1);
          }
        }
      },
      // 导出
      daochu() {
        let data = {
          ...this.tableDataval
        }
        data.beginTime = this.tableDataval.time[0]
        data.endTime = this.tableDataval.time[1]
        delete data.time
        let url = this.$baseUrl.test + '/api/user/exportExcel?pageNum=' + data.pageNum + '&pageSize=' +
          data.pageSize + '&inputOrg=' + (data.inputOrg ? data.inputOrg : '') + '&beginTime=' + (data.beginTime ? data
            .beginTime : '') +
          '&endTime=' + (data.endTime ? data.endTime : '')
        var link = document.createElement('a');
        link.href = url;
        link.download = '用户数据123.xlsx';
        link.click();
      },
      // 全部
      all() {
        this.onSubmit();
        this.primary1 = 'primary';
        this.primary2 = '';
      },
      // 待办
      daiban() {
        this.primary1 = '';
        this.primary2 = 'primary';
        let tableDataval = {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 状态
          'status': 1,
          // 机构ID
          'inputOrg': this.userType == 0 ? '' : localStorage.getItem("inputOrg"),
        };
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/list',
          params: tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      chongzhi() {
        this.tableDataval = {
          'pageNum': 1,
          'pageSize': 10,
          'inputOrg': '',
          'nickName': '',
          'time': []
        }
        this.onSubmit();
      },
      onSubmit() {
        let data = {
          ...this.tableDataval
        }
        data.beginTime = this.tableDataval.time[0]
        data.endTime = this.tableDataval.time[1]
        delete data.time
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/user/userList?pageNum=' + this.tableDataval.pageNum + '&pageSize=' + this
            .tableDataval.pageSize,
          data: data,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
        let tableDataval = {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': '',
          // 状态
          'status': 1,
          // 机构ID
          'inputOrg': this.userType == 0 ? '' : localStorage.getItem("inputOrg"),
        };
        // 获取待办总数
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/list',
          params: tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          this.caibanvalue = res.data.rows.length;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      getUserdata() {
        // 每次赋值之前清空
        this.imgenlargecertUrl2 = "";
        this.imgenlargecertUrl1 = "";
        this.DetailTable = {};
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/getUserInfo/' + this.userId,
          params: '',
        }).then((res) => {
          this.DetailTable = res.data.data;
          // 获取当前身份证正反签字扫描件ID用于反显
          this.thiscertid = (this.DetailTable && this.DetailTable.certUrl) ? this.DetailTable.certUrl.split(",") : []
          // 如果长度大于1
          if (this.DetailTable.certUrl && this.DetailTable.certUrl.split(",").length > 1) {
            this.$axios({ // 获取当前身份证正反签字扫描件路径
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + this.DetailTable.certUrl.split(",")[0],
              params: '',
            }).then((res) => {
              this.imgenlargecertUrl1 = res.data.data.fileUrl;
            }).catch(error => {
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });

            this.$axios({ // 获取当前身份证正反签字扫描件路径
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + this.DetailTable.certUrl.split(",")[1],
              params: '',
            }).then((res) => {
              this.imgenlargecertUrl2 = res.data.data.fileUrl;
            }).catch(error => {
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });

          } else if (this.DetailTable.certUrl && this.DetailTable.certUrl.split(",").length > 0) {
            this.thiscertid.push(this.DetailTable.certUrl);
            this.$axios({ // 获取当前身份证正反签字扫描件路径
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + this.DetailTable.certUrl,
              params: '',
            }).then((res) => {
              this.imgenlargecertUrl1 = res.data.data.fileUrl;
            }).catch(error => {
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
        }).catch(error => {
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 重置密码
      Reset(item) {
        this.$confirm('此操作将重置密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/api/user/resetDefaultPwd',
            data: {
              "userId": item
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                type: 'success',
                message: '操作成功！（ 新密码为：a123456 ）'
              });
            } else {
              this.$message({
                offset: 100,
                type: 'error',
                message: res.data.msg
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消重置密码'
          });
        });

      },
      // 查看
      see(e) {
        this.userId = e;
        this.dialogtitle = '查看用户信息';
        this.dialogVisiblestate = 0;
        this.dialogVisible = true;
        this.getUserdata();
        // 判断是否为编辑 0=是，1=不是
        this.ifedit = '1';
      },
      // 编辑
      edit(e) {
        this.shenhe = false;
        this.userId = e;
        this.dialogtitle = '编辑用户信息';
        this.dialogVisiblestate = 1;
        this.dialogVisible = true;
        this.getUserdata();
        // 判断是否为编辑 0=是，1=不是
        this.ifedit = '0';
      },
      // 审核
      examine(e) {
        this.userId = e;
        this.shenhe = true;
        this.dialogtitle = '审核用户信息';
        this.dialogVisiblestate = 2;
        this.dialogVisible = true;
        this.getUserdata();
        // 判断是否为编辑 0=是，1=不是
        this.ifedit = '1';
      },
      // 修改用户状态事件
      changeStatus() {
        this.$confirm('是否确定修改当前用户状态?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/api/user/changeStatus',
            data: {
              "userId": this.userId,
              "status": this.DetailTable.status
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                type: 'success',
                message: '操作成功!'
              });
              this.innerVisible = false;
              this.dialogVisible = false;
              this.onSubmit();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            type: 'info',
            message: '已取消～'
          });
        });
      },
      // 修改用户提交事件
      Submitclick() {
        // if (this.thiscertid.length < 1) {
        //   this.$message({
        //     offset: 100,
        //     type: 'error',
        //     message: '请上传身份证正反照！'
        //   });
        // } else {
        let params = {
          "userId": this.userId,
          "userName": this.DetailTable.userName,
          "nickName": this.DetailTable.nickName,
          "phonenumber": this.DetailTable.phonenumber,
          "password": this.DetailTable.password,
          "inputOrg": this.tableDataval.inputOrg,
          "deptName": this.DetailTable.deptName,
          "job": this.DetailTable.job,
          "certType": this.DetailTable.certType,
          "certNo": this.DetailTable.certNo,
          "certUrl": this.thiscertid.join(),
          'unionJob': this.DetailTable.unionJob,
          'inputOrg': this.DetailTable.inputOrg,
        }
        // if (!this.DetailTable.unionJob) {
        //   this.$message({
        //     offset: 100,
        //     type: 'error',
        //     message: '请选择联盟职务！'
        //   });
        // } else {

        // }
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/api/user/',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              type: 'success',
              message: '操作成功!'
            });
            this.dialogVisible = false;
            location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
        // }
      },
      // 一级弹窗审核按钮点击事件
      refuse(e) {
        // if (!this.DetailTable.unionJob) {
        //   this.$message({
        //     offset: 100,
        //     type: 'error',
        //     message: '请选择联盟职务！'
        //   });
        // } else {

        // }
        this.innerVisible = true;
      },
      // 二级弹窗审核按钮点击事件
      Toexamine() {
        if (this.DetailTable.status == "1") {
          this.$message({
            offset: 100,
            type: 'info',
            message: '请选择通过或者不通过！'
          });
        } else if (this.DetailTable.status == "4" && this.auditReason == "") {
          this.$message({
            offset: 100,
            type: 'error',
            message: '请填写未通过理由!'
          });
        } else {
          this.$confirm('是否确定审核当前用户?', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/user/',
              data: {
                'unionJob': this.DetailTable.unionJob,
                'userId': this.userId
              },
            }).then((res) => {
              console.log(res);
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/user/changeStatus',
              data: {
                "userId": this.userId,
                "status": this.DetailTable.status,
                "auditReason": this.auditReason,
              },
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  offset: 100,
                  type: 'success',
                  message: '操作成功!'
                });
                this.dialogVisible = false;
                this.innerVisible = false;
                this.onSubmit();
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }).catch(() => {
            this.$message({
              offset: 100,
              type: 'info',
              message: '已取消～'
            });
          });
        }
      }
    },
    // 页面加载完毕事件
    mounted() {
      // 获取机构列表
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/pubilc/orglist?status=2',
        data: '',
        //请求成功的回调函数
      }).then((res) => {
        this.orglistdata = res.data.rows;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      let dictType = ['union_job']
      // 获取联盟职务数据
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/pubilc/getDictLists',
        data: dictType
        //请求成功的回调函数
      }).then((res) => {
        this.union_job = res.data.data.union_job
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      this.onSubmit();
    }
  }
</script>

<style src="@/style/Systemmanagement/UserCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }

  .el-col-6 {
    width: 22%;
  }
  /deep/ .demo-ruleForm{
    margin-top: 0vh;
    height: 58vh;
  }
</style>
