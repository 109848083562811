<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <!-- 面包屑 -->
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>工作台</el-breadcrumb-item>
          <el-breadcrumb-item>团队交流管理</el-breadcrumb-item>
          <el-breadcrumb-item>我的关注</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#ffffff" text-color="#8D8D8D" active-text-color="#008FE0" style="font-weight: bold;">
          <!-- <el-menu-item index="1">全部({{alltotal}})</el-menu-item> -->
          <el-menu-item index="1">文章({{getlisttotal}})</el-menu-item>
          <el-menu-item index="2">提问({{cytotal}})</el-menu-item>
          <el-menu-item index="3">回答({{wctotal}})</el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
    <!-- 我的关注文章 -->
    <div class="tableData1">
      <div class="tableData1div" v-for="item in tableData1">
        <div style="margin-right: 1rem;" v-if="item.articleCoverUrl">
          <el-image style="width: 200px; height: 100px" :src="item.articleCoverUrl" fit="contain">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div
          style="width: 70%;display: flex;flex-direction: column;justify-content: space-around;align-items: flex-start;">
          <div v-if="activeIndex == 1">
            <el-link :underline="false" @click="Clicktoview2(item.articleId)"
              style="line-height: 2rem;font-size: 1.3rem;">
              {{item.articleTitle}}
            </el-link>
          </div>
          <div v-if="activeIndex == 2">
            <el-link :underline="false" @click="Clicktoview2(item.questionId)"
              style="line-height: 2rem;font-size: 1.3rem;">
              {{item.questionTitle}}
            </el-link>
          </div>
          <div v-if="activeIndex == 3">
            <el-link :underline="false" @click="Clicktoview2(item.answerId)"
              style="line-height: 2rem;font-size: 1.3rem;">
              {{item.answerContent}}
            </el-link>
          </div>
          <div>
            <el-link v-if="activeIndex == 1" :underline="false" @click="Clicktoview2(item.articleId)"
              style="line-height: 2rem;">
              预览（{{item.browseNum}}）
            </el-link>
            <el-link v-if="activeIndex == 1" :underline="false" @click="Clicktoview2(item.articleId)"
              style="line-height: 2rem;">
              关注（{{item.followNum}}）
            </el-link>
            <el-link v-if="activeIndex == 1" :underline="false" @click="Clicktoview2(item.articleId)"
              style="line-height: 2rem;">
              回复（{{item.commentNum}}）
            </el-link>
            <el-link v-if="activeIndex == 2" :underline="false" @click="Clicktoview2(item.articleId)"
              style="line-height: 2rem;">
              回复（{{item.answerNum}}）
            </el-link>
            <el-link :underline="false" @click="Clicktoview2(item.articleId)" style="line-height: 2rem;">
              点赞（{{item.fabulousNum}}）
            </el-link>
          </div>
        </div>
        <div style="text-align: end;padding-top: 1.2rem;">
          <div>{{item.updateTime}}</div>
          <div>
            <el-button type="text" @click="deleteClick(item.articleId)">删除</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        // 当前的类型
        activeIndex: '1',
        value1: "",
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 为用户ID
          'userId': localStorage.getItem("userId"),
          // 项目名称
          'projectName': "",
          // 项目状态
          'projectStatus': "",
        },
        alltotal: 0,
        // 我关注的专栏
        total: 0,
        // 我关注的问题
        cytotal: 0,
        // 我关注的文章
        getlisttotal: 0,
        // 完成的项目
        wctotal: 0,
        tableData1: [],
      }
    },
    methods: {
      // 顶栏点击事件
      handleSelect(key, keyPath) {
        this.tableDataval.pageNum = "1";
        this.tableDataval.pageSize = "10";
        this.tableDataval.projectName = "";
        this.tableDataval.projectStatus = "";
        this.activeIndex = key;
        switch (key) {
          case "1":
            // 获取文章列表
            this.getlist();
            break;
          case "2":
            // 获取问题列表
            this.getJoinList();
            break;
          case "3":
            // 获取回答列表
            this.getComAnswerList();
            break;
          default:
            this.getlist();
        }
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
      },
      // 标题点击事件
      Clicktoview2(e) {
        // 跳转到文章详情页面
        this.$router.push({
          name: 'Writeanarticlexiangqing'
        });
        localStorage.setItem('articleId', e);
        localStorage.setItem('wzAdnwt', this.activeIndex)
      },

      // 删除文章
      deleteClick(e) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //  文章
          if (this.activeIndex == 1) {
            this.$axios({ // 文章
              method: 'delete',
              url: this.$baseUrl.test + '/communication/comArticle/' + e,
              data: '',
            }).then((res) => {
              this.handleSelect(this.activeIndex)
              this.$message({
                type: 'success',
                message: '操作成功!',
                offset: 100
              });
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
          // 问题
          else if (this.activeIndex == 2) {
            this.$axios({ // 问题
              method: 'delete',
              url: this.$baseUrl.test + '/communication/comArticle/' + e,
              data: '',
            }).then((res) => {
              this.handleSelect(this.activeIndex)
              this.$message({
                type: 'success',
                message: '操作成功!',
                offset: 100
              });
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }

        }).catch(() => {

        });
      },

      // 获取专栏列表
      getzhuanlan() {
        let userId = {
          "userId": localStorage.getItem("userId")
        };
        let data = []
        this.$axios({ // 文章
          method: 'post',
          url: this.$baseUrl.test + '/communication/comArticle/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: userId,
          //请求成功的回调函数
        }).then((res) => {
          data = res.data.rows;
          this.getlisttotal = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });

        this.$axios({ // 问题
          method: 'post',
          url: this.$baseUrl.test + '/communication/comQuestion/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: userId,
          //请求成功的回调函数
        }).then((res) => {
          data = data.concat(res.data.rows)
          this.cytotal = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });

        this.$axios({ // 回答
          method: 'get',
          url: this.$baseUrl.test + '/communication/comAnswer/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          params: '',
        }).then((res) => {
          data = data.concat(res.data.rows)
          this.wctotal = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
        this.tableData1 = data
        this.alltotal = Number(this.getlisttotal + this.cytotal + this.wctotal) // 全部
        this.total = this.alltotal // 总计
      },
      // 获取文章列表
      getlist() {
        let userId = {
          "userId": localStorage.getItem("userId")
        };
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comArticle/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: userId,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData1 = res.data.rows;
          this.getlisttotal = res.data.total;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 获取问题列表
      getJoinList() {
        let userId = {
          "userId": localStorage.getItem("userId")
        };
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comQuestion/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: userId,
          //请求成功的回调函数
        }).then((res) => {
          this.cytotal = res.data.total;
          this.tableData1 = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 获取回答列表
      getComAnswerList() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/communication/comAnswer/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          params: '',
        }).then((res) => {
          this.wctotal = res.data.total;
          this.tableData1 = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.getlist();
    }
  }
</script>

<style src="@/style/Team/MyattentionCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }

  .tableData {
    margin-top: 0.3rem;
  }

  .tableData1 {
    margin-top: 1rem;
  }

  .tableData1div {
    background-color: #FFFFFF;
    padding: 1rem;
    display: flex;
    border-bottom: #CCCCCC solid 0.0625rem;
    min-height: 6rem;
  }

  .tableData2 {
    margin-top: 1rem;
  }

  .el-image {
    background-color: #cccccc;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
  }

  .kap {
    background-color: #FFFFFF;
    padding: 1rem 0.8rem 0.8rem 0.8rem;
    border-radius: 0.5rem;
  }

  .specialTitle {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }

  .specialContent {
    min-height: 4rem;
    color: #CCCCCC;
  }

  .xiadiv {
    margin-bottom: 1rem;
    padding: 0rem 0.8rem 0.8rem 0rem;
    color: #CCCCCC;
  }

  .buttondiv {
    display: flex;
    justify-content: space-around;
  }
</style>
