<template>
  <div class="MechanismCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 5.33625rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>资源共享管理</el-breadcrumb-item>
            <el-breadcrumb-item>上传资源</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="24">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
          style="padding-left: 10%;">
          <el-form-item label="资源标题" prop="resTitle" label-width="150px !important">
            <el-input v-model="ruleForm.resTitle" placeholder="请输入5-30个字符"></el-input>
          </el-form-item>
          <el-form-item label="资源简介" prop="resContent" label-width="150px !important">
            <el-input type="textarea" v-model="ruleForm.resContent" placeholder="请输入500字以内的简介"></el-input>
          </el-form-item>
          <el-form-item label="资源类型" prop="resType" label-width="150px !important">
            <el-select v-model="ruleForm.resType" placeholder="请选择资源类型">
              <el-option v-for="item in resTypelist" :key="item.dictValue" :label="item.dictLabel"
                :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="专业类别" prop="subjectType" label-width="150px !important">
            <el-select v-model="ruleForm.subjectType" placeholder="请选择专业类别">
              <el-option v-for="item in subjectTypelist" :key="item.dictValue" :label="item.dictLabel"
                :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="联盟积分" prop="price" label-width="150px !important">
            <!-- <el-input v-model="ruleForm.price" placeholder="给资源定价"></el-input> -->
            <el-input-number style="width: 50%;float: left;" :min="0" v-model="ruleForm.price" placeholder="给资源定价">
            </el-input-number>
          </el-form-item>
          <!-- <el-form-item style="width: 50%;" label="" prop="price" label-width="150px !important">
            <el-progress v-show="percentageshow" :percentage="percentage" color="#409eff"></el-progress>
          </el-form-item> -->
          <el-form-item style="margin-bottom: 0rem;" label="" required label-width="150px !important">
            <div style="width: 60%;">
              <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-success="uploadSuccess"
                :before-upload="beforeupload" :on-progress="shangchuanshi" :on-error="errorUpload" ref="upload"
                :file-list="fileListsss">
                <el-button size="small" type="primary" style="float: left;" @click="addupload()">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg .mp4
                  <span style="color: red;"> (单文件限制1024MB)</span>
                </div>
              </el-upload>
            </div>
            <div style="height: 1rem;"></div>
          </el-form-item>
          <el-form-item label="上传文件" required label-width="150px !important">
            <div class="elupload" v-loading="loading" element-loading-text="正在上传,请勿离开页面!"
              element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
              <div style="height: 1rem;"></div>
              <el-table :data="fileData" style="width: 100%" :border="true">
                <el-table-column type="index" label="序号" width="50">
                </el-table-column>

                <el-table-column prop="resDetailTitle" label="章节/目录">
                  <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.resDetailTitle" placement="top">
                      <el-input v-model="scope.row.resDetailTitle" placeholder="请输入章节/目录"></el-input>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column prop="chapterName" label="文件名称">
                  <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.chapterName" placement="top">
                      <el-input v-model="scope.row.chapterName" placeholder="请输入文件名称"></el-input>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column prop="fileName" label="附件">
                  <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.fileName" placement="top">
                      <span>{{scope.row.fileName}}</span>
                    </el-tooltip>
                    <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-success="uploadSuccess"
                      :before-upload="beforeupload" multiple :on-error="errorUpload" :show-file-list="false">
                      <el-button size="small" type="text" @click="Reupload(scope.row)">重新上传</el-button>
                    </el-upload>
                  </template>
                </el-table-column>

                <el-table-column prop="price" label="是否收费">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.price" active-text="收费" inactive-text="免费" active-value="1"
                      inactive-value="0">
                    </el-switch>
                  </template>
                </el-table-column>

                <el-table-column prop="" label="操作" scope="scope" width="90">
                  <template slot-scope="scope">
                    <el-button type="danger" icon="el-icon-delete" circle size="small" @click="deleteclick(scope.row)">
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button type="info" @click="submitForm('ruleForm',0)">存为草稿</el-button>
            <el-button type="primary" @click="submitForm('ruleForm',1)">发布</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    name: '',
    components: {
      // commonComponents
    },
    props: ['resId'],
    data: function() {
      return {
        fileListsss: [],
        loading: false,
        percentageshow: false,
        percentage: 0,
        // 当前重新上传的文件ID
        uploadSuccessID: '',
        ruleForm: {
          resTitle: '',
          resContent: '',
          resType: '',
          subjectType: [],
          price: '',
          userId: '',
          resource: '',
          fileList: []
        },
        // 二级资源信息
        fileData: [],
        // 资源类型
        resTypelist: [],
        // 专业类别
        // subjectTypelist: [],
        rules: {
          resTitle: [{
            required: true,
            message: '请输入资源标题',
            trigger: 'blur'
          }],
          resContent: [{
            required: true,
            message: '请输入简介',
            trigger: 'blur'
          }],
          resType: [{
            required: true,
            message: '请选择资源类型',
            trigger: 'change'
          }],
          // subjectType: [{
          //   required: true,
          //   message: '请选择专业类别',
          //   trigger: 'blur'
          // }],
          price: [{
            required: true,
            message: '请给资源定价',
            trigger: 'blur'
          }]
        }
      }
    },
    methods: {
      // 上传点击事件
      addupload() {
        this.uploadSuccessID = "";
      },
      // 重新上传点击事件
      Reupload(e) {
        this.uploadSuccessID = e.fileId;
      },
      // 删除点击事件
      deleteclick(e) {
        console.log(e.fileId);
        // 循环二级附件
        for (let i = 0; i < this.fileData.length; i++) {
          for (var key in this.fileData[i]) {
            // 用新ID替换旧ID
            if (this.fileData[i][key] == e.fileId) {
              this.fileData.splice(i, 1);
            }
          }
        }
      },
      // 上传文件之前
      beforeupload(response) {
        let isLtSize = response.size / 1024 / 1024 < 200;
        if (!isLtSize) {
          this.$message.error('上传图片大小不能超过 1024MB!');
        } else {
          let _this = this;
          _this.loading = true;
          _this.percentageshow = true;
          _this.percentage = 1;
          _this.timer = setInterval(() => {
            // 要执行的函数
            _this.percentage = _this.percentage + 1;
            if (_this.percentage == 99) {
              clearInterval(_this.timer);
            }
          }, 1000)
        }
      },
      // 文件上传成功
      uploadSuccess(response, file, fileList) {
        let _this = this;

        setTimeout(() => {
          _this.percentage = 100;
          clearInterval(_this.timer)
        }, 1000)

        setTimeout(() => {
          _this.loading = false;
          _this.percentageshow = false;
        }, 2000)
        // 判断是否上传成功
        if (response.code == 200) {
          // 上传成功之后清空已经上传列表（用作显示上传进度）
          _this.$refs.upload.clearFiles()
          // 判断是否为重新上传
          if (_this.uploadSuccessID != "") {
            // 循环二级附件
            for (let i = 0; i < _this.fileData.length; i++) {
              for (var key in _this.fileData[i]) {
                // 用新ID替换旧ID
                if (_this.fileData[i][key] == _this.uploadSuccessID) {
                  _this.fileData[i].fileId = response.data.fileId
                  _this.fileData[i].fileName = response.data.fileName
                }
              }
            }
            // 清空ID
            _this.uploadSuccessID == ""
          } else {
            // 获取二级资源信息
            _this.fileData.push({
              // 文件ID
              fileId: response.data.fileId,
              // 章节
              fileExtension: response.data.fileExtension,
              // 文件明细标题
              resDetailTitle: response.data.resDetailTitle,
              chapterName: response.data.chapterName,
              // 文件名称
              fileName: response.data.fileName,
              // 是否收费
              price: response.data.price,
              // 文件地址
              fileUrl: response.data.fileUrl,
            })
          }
        } else {
          this.$message({
            offset: 100,
            showClose: true,
            message: response.msg,
            type: 'error'
          });
        }
      },
      // 文件上传失败
      errorUpload(response, file, fileList) {
        console.log(response.data.fileId);
      },
      // 提交事件
      submitForm(formName, sun) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let formdata = {};
            if (this.resId) {
              formdata = { //修改
                // 用户ID
                "userId": this.ruleForm.userId,
                // 请输入资源标题
                "resTitle": this.ruleForm.resTitle,
                // 请输入简介
                "resContent": this.ruleForm.resContent,
                // 请选择资源类型
                "resType": this.ruleForm.resType,
                // 请选择专业类别
                // "subjectType": this.ruleForm.subjectType,
                // 请给资源定价
                "price": this.ruleForm.price,
                // 二级资源信息
                "fileList": this.fileData,
                "status": sun == 0 ? 0 : 1,
                "resId": this.resId
              }
            } else { // 新增
              formdata = {
                // 用户ID
                "userId": this.ruleForm.userId,
                // 请输入资源标题
                "resTitle": this.ruleForm.resTitle,
                // 请输入简介
                "resContent": this.ruleForm.resContent,
                // 请选择资源类型
                "resType": this.ruleForm.resType,
                // 请选择专业类别
                // "subjectType": this.ruleForm.subjectType,
                // 请给资源定价
                "price": this.ruleForm.price,
                // 二级资源信息
                "fileList": this.fileData,
                "status": sun == 0 ? 0 : 1,
              }
            }
            if (this.fileData.length > 0) { // 判断文件是否为空
              let sum = 0;
              for (var i = 0; i < this.fileData.length; i++) {
                if (!this.fileData[i].resDetailTitle || !this.fileData[i].chapterName) {
                  sum = 1;
                }
              }
              if (sum == 1) { // 判断章节目录是否为空
                this.$message({
                  offset: 100,
                  message: "章节目录、文件名称不能为空！",
                  type: 'error'
                });
                return;
              }
              this.$axios({
                method: this.resId ? 'put' : 'post',
                url: this.$baseUrl.test + '/resouse/resInfo/',
                data: formdata
              }).then((res) => {
                if (res.data.code == 200) {
                  if (sun == 0) { // 存为草稿
                    this.$alert(
                      '<h4>您的资源《' + this.ruleForm.resTitle +
                      '》已保存为草稿</h4><br><h5>您的资源已经成功保存为草稿，请在工作台“我的资源”中再次编辑</h5>',
                      '', {
                        type: 'success',
                        center: true,
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: '确定',
                        callback: action => {
                          this.$message({
                            type: 'info',
                            message: `action: ${ action }`
                          });
                        }
                      });
                    // 清空所有内容
                    this.$refs[formName].resetFields();
                    this.fileData = [];
                  } else {
                    this.$alert(
                      '<h4>您上传的资源《' + this.ruleForm.resTitle +
                      '》已提交联盟专家审核</h4><br><h5>恭喜，资源提交成功，我们将会在3个工作日内审核完成，审核通过后将直接发布至资源共享大厅，请您及时关注平台消息提醒</h5>',
                      '', {
                        type: 'success',
                        center: true,
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: '确定',
                        callback: action => {
                          this.$message({
                            type: 'info',
                            message: `action: ${ action }`
                          });
                        }
                      });
                    // 清空所有内容
                    this.$refs[formName].resetFields();
                    this.fileData = [];
                  }
                } else {
                  this.$message({
                    offset: 100,
                    message: res.data.msg,
                    type: 'error'
                  });
                }
              });
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                message: "文件不能为空！",
                type: 'error'
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 清空事件
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.fileData = [];
      },
    },
    created() {
      this.setTime() // 延时1.5秒调用方法，等待父组件的传参返回
    },
    // 页面加载完毕事件
    mounted() {
      this.ruleForm.userId = localStorage.userId;
      // 获取资源类型，专业类别
      let dictType = ['res_type', 'subject_type']
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/pubilc/getDictLists',
        data: dictType
      }).then((res) => {
        this.resTypelist = res.data.data.res_type
        this.subjectTypelist = res.data.data.subject_type
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 如果跳转过来有资源ID
      if (this.resId) {
        let params = {
          "resId": this.resId,
          "loginUserId": localStorage.userId
        }
        // 根据传过来的资源ID获取资源详情
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resInfo/getResInfo',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            // 资源标题
            this.ruleForm.resTitle = res.data.data.resTitle;
            // 资源简介
            this.ruleForm.resContent = res.data.data.resContent;
            // 资源类型
            this.ruleForm.resType = res.data.data.resType;
            // 专业类别
            // this.ruleForm.subjectType = res.data.data.subjectType;
            // 联盟积分
            this.ruleForm.price = res.data.data.price;
            // 获取资源的文件信息
            for (let i in res.data.data.fileList) {
              this.fileData = res.data.data.fileList
            }
          } else {
            this.$message({
              offset: 100,
              message: res.data.code + '请求异常！',
              type: 'error'
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }
    }
  }
</script>

<style src="@/style/Resources/UploadCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    width: 30% !important;
    text-align: right;
  }

  .el-form-item__error {
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }

  .upload-demo {
    border-radius: 6px;
  }

  .el-upload {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    outline: 0;
    float: left !important;
  }

  .el-upload__tip[data-v-71abf336] {
    font-size: 12px;
    color: #606266;
    margin-top: 0px;
    width: 70%;
  }

  ::-webkit-scrollbar {
    display: none
  }

  h4 {
    margin: 0px;
  }

  h5 {
    margin: 0px;
    color: lightgrey;
  }

  .demo-ruleForm {
    margin-top: 0px;
    height: auto;
    overflow-y: auto;
  }
</style>
