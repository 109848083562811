<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>培训详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-col :span="2" style="color: #00000000;">1</el-col>
        <el-col :span="20">
          <div style="text-align: right;padding-right: 2rem;position: fixed;top: 10rem;right: 2rem;z-index: 999;">
            <el-button type="primary" @click="$router.back()">返回</el-button>
            <!-- 报名 -->
            <el-button :disabled="djcyannstyle" v-if="userType == 2" style="width: auto;" type="primary"
              @click="application(ruleForm)">
              {{djcyannstyletext}}
            </el-button>
            <!-- 进入培训 -->
            <el-button style="width: auto;" type="success" @click="see(ruleForm)">进入培训</el-button>
          </div>
          <!-- 基本信息 -->
          <div class="jbxxdiv" v-loading="loading" element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <!-- 培训名称 -->
            <h2 style="text-align: center;">{{ruleForm.projectName}}</h2>
            <p
              style="font-family: 'PingFang SC ', 'PingFang SC';font-weight: 400;font-style: normal;font-size: 16px;color: rgba(0, 0, 0, 0.447058823529412);text-align: center;line-height: 22px;">
              {{ruleForm.completeTime}}
            </p>
            <el-descriptions class="jbxxdivdescriptions" title="培训发起人信息" :column="1">
              <el-descriptions-item label="培训发起人">
                {{ruleForm.nickName}}
              </el-descriptions-item>
              <el-descriptions-item label="单位名称">{{ruleForm.orgName}}
              </el-descriptions-item>
              <el-descriptions-item label="联系号码">{{ruleForm.phonenumber}}</el-descriptions-item>
              <el-descriptions-item label="邮箱">{{ruleForm.email}}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="jbxxdivdescriptions" title="申报信息" :column="1">
              <el-descriptions-item label="培训名称">
                {{ruleForm.projectName}}
              </el-descriptions-item>
              <el-descriptions-item label="培训编号">
                {{ruleForm.projectNo}}
              </el-descriptions-item>
              <!--              <el-descriptions-item label="培训合作人数" v-if="userType != 2">{{ruleForm.cooperationNum}}
              </el-descriptions-item> -->
              <el-descriptions-item label="允许参与报名人数" v-if="userType != 2">
                {{ruleForm.allowSignupNum}}
                &emsp;&emsp;&emsp;&emsp;
                <span style="color: red;">
                  已报名人数：{{ruleForm.signupNum}}
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="结项时间">{{ruleForm.completeTime}}</el-descriptions-item>
              <el-descriptions-item label="培训经费(元)">{{ruleForm.projectFunds}}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions class="jbxxdivdescriptions" title="培训介绍" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
                {{ruleForm.projectContent}}
              </el-descriptions-item>
            </el-descriptions>

            <el-descriptions v-if="userType != 2" class="jbxxdivdescriptions" title="参与人员名单" :colon="false">
            </el-descriptions>
            <el-table v-if="userType != 2" :data="trainProjectSignup" border style="width: 100%">
              <el-table-column type="index" width="50" label="序号">
              </el-table-column>
              <el-table-column prop="nickName" label="用户姓名">
              </el-table-column>
              <el-table-column prop="orgName" label="所属单位">
              </el-table-column>
              <el-table-column prop="signupTime" label="报名日期" width="160">
              </el-table-column>
              <el-table-column prop="signupTime" label="报名状态" width="160">
                <template slot-scope="scope">
                  <span v-if="scope.row.signupStatus == 0">草稿</span>
                  <span v-if="scope.row.signupStatus == 1">待审核</span>
                  <span v-if="scope.row.signupStatus == 2">报名成功</span>
                  <span v-if="scope.row.signupStatus == 3">撤销报名</span>
                  <span v-if="scope.row.signupStatus == 4">报名失败</span>
                </template>
              </el-table-column>
              <el-table-column prop="" label="发放证书" scope="scope" width="200">
                <template slot-scope="scope">
                  <el-button v-if="!scope.row.certFilesUrl" size="small" :disabled="userType == 2" type="primary"
                    @click="ffzsdialogVisible = true,form.projectSignupId = scope.row.projectSignupId">
                    点击上传</el-button>
                  <el-image v-else style="width: 100px; height: 100px" :src="scope.row.certFilesUrl" fit="fill">
                  </el-image>
                </template>
              </el-table-column>

              <el-table-column prop="" label="操作" scope="scope" width="200">
                <template slot-scope="scope">
                  <el-switch :disabled="userType == 2 || ruleForm.projectStatus == 3"
                    @change="signupStatusswitch(scope.row)" v-model="scope.row.signupStatusswitch" active-text="报名成功"
                    inactive-text="待审核" />
                </template>
              </el-table-column>
            </el-table>

            <el-descriptions class="jbxxdivdescriptions" title="培训附件" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
              </el-descriptions-item>
            </el-descriptions>
            <template>
              <el-table :data="fileData" border style="width: 100%">
                <el-table-column type="index" width="50" label="序号">
                </el-table-column>
                <el-table-column prop="chapterName" label="附件名称">
                </el-table-column>
                <el-table-column prop="nftId" label="区块链地址">
                </el-table-column>
                <el-table-column prop="createTime" label="上传日期">
                </el-table-column>
                <el-table-column prop="" label="操作" scope="scope" width="90">
                  <template slot-scope="scope">
                    <span style="color: red;margin-right: 10px;" v-if="userType != 2"
                      @click="shanchufuj(scope.row)">删除</span>
                    <span style="color: #0066FF;" @click="chakan(scope.row)">下载</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>

            <el-descriptions class="jbxxdivdescriptions" title="培训课程" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
              </el-descriptions-item>
            </el-descriptions>
            <template>
              <el-table :data="fileData2" border style="width: 100%">
                <el-table-column type="index" width="50" label="序号" />
                <el-table-column prop="courseTitle" label="课程名称" />
                <el-table-column prop="courseContent" label="课程介绍" :show-overflow-tooltip="true" />
                <el-table-column prop="teacherName" label="课程主讲" />
                <el-table-column prop="adminName" label="课程管理员" />
                <el-table-column prop="createTime" label="课程时间" />
                <el-table-column prop="" label="操作" scope="scope" width="90">
                  <template slot-scope="scope">
                    <p style="color: #0066FF;" :underline="false" @click="cklick(scope.row)">查看</p>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>

        </el-col>
        <el-col :span="2"></el-col>
      </el-col>
    </el-row>
    <el-dialog title="审核" :visible.sync="dialogVisible" width="30%" :before-close="beforeClose">
      <!-- 基地 -->
      <div style="text-align: left;" v-if="userType == 0">
        <el-radio v-model="projectStatus" label="2">通过</el-radio>
        <el-radio v-model="projectStatus" label="3">驳回</el-radio>
      </div>
      <!-- 主管部门 -->
      <div style="text-align: left;" v-if="userType == 3">
        <el-radio v-model="projectStatus" label="4">通过</el-radio>
        <el-radio v-model="projectStatus" label="5">驳回</el-radio>
      </div>
      <div style="margin-top: 20px;">
        <el-input v-if="userType == 0" type="textarea" :rows="5" placeholder="请输入原因" v-model="auditRemarkJd" />
        <el-input v-if="userType == 3" type="textarea" :rows="5" placeholder="请输入原因" v-model="auditRemarkZg" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="examine()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="发放证书" :visible.sync="ffzsdialogVisible" width="30%" :before-close="handleClose">
      <div>
        <div style="display: flex;margin-bottom: 10px;">得分：<el-input-number style="width: 30%;" v-model="form.score"
            :precision="2" :step="0.1" :max="100"></el-input-number>
        </div>
        <div style="display: flex;">评价：<el-input style="width: 90%;margin-top: 10px;" v-model="form.studentComment"
            type="textarea" :rows="2" placeholder="请输入评价"></el-input>
        </div>
        <div style="display: flex;margin-top:20px;">证书：
          <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :on-success="uploadSuccess"
            ref="fmupload" :on-remove="handleRemove" :on-exceed="onexceed" limit="1" style="display: flex;">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ffzsdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="qdffzs()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    name: '',
    props: ['params'],
    data: function() {
      return {
        ffzsdialogVisible: false,
        form: {
          "score": undefined,
          "studentComment": undefined,
          "certStatus": "3", // 证书状态（0未申请、1申请中、2已发放、3已接受）
          "certFiles": undefined, // 文件上传后返回的文件ID，英文逗号隔开，如：1,2,3
          "projectSignupId": undefined,
        },
        switchDisabled: true,
        textarea: '',
        projectId: "",
        djcyannstyle: true,
        djcyannstyletext: '我要报名',
        dialogVisible: false,
        auditRemarkJd: "",
        auditRemarkZg: "",
        userType: '',
        ruleForm: {
          // 合作培训ID
          projectId: "",
          // 单位名称
          orgName: "",
          // 发起人用户ID
          userId: "",
          // 发起人姓名
          nickName: "",
          // 发起人手机号码
          phonenumber: "",
          // 培训名称
          projectName: "",
          // 培训编号
          projectNo: "",
          // 培训合作人数
          cooperationNum: "",
          // 允许参与报名人数
          allowSignupNum: "",
          // 邀请人
          inviteMembers: [],
          // 已报名人数
          signupNum: "",
          // 结项时间
          completeTime: "",
          // 培训经费(元)
          projectFunds: "",
          // 已使用经费(元)
          usedFunds: "",
          // 剩余经费(元)
          surplusFunds: "",
          // 培训介绍
          projectContent: "",
          // 培训附件
          projectFiles: "",
          // 结项申请时间
          closeApplyTime: ""
        },
        // 资源列表
        fileData: [],
        // 课程
        fileData2: [],
        // 参与名单
        trainProjectSignup: [],
        loading: false,
      }
    },
    methods: {
      // 删除附件
      shanchufuj(row) {
        // 当前项目ID
        let projectFiles = this.ruleForm.projectFiles.split(",")
        for (var i = 0; i < projectFiles.length; i++) {
          if (projectFiles[i] == row.fileId) {
            // 删除当前附件ID
            projectFiles.splice(i, 1)
          }
        }
        let data = {
          'projectId': this.projectId,
          'projectFiles': projectFiles.join(',')
        }
        this.$confirm('此操作将永久删除该附件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/train/trainProject',
            data: data,
            //请求成功的回调函数
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'success',
                message: '操作成功!'
              });
              this.onSubmit();
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'error',
                message: res.data.msg
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      chakan(row) {
        this.getBlob(row.fileUrl).then(blob => {
          this.DownloadItem(blob, row.fileName);
        });
      },
      getBlob(url) {
        return new Promise(resolve => {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.responseType = 'blob';
          xhr.onload = () => {
            if (xhr.status === 200) {
              resolve(xhr.response);
            }
          };
          xhr.send();
        });
      },
      DownloadItem(blob, fileName) {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      },
      qdffzs() {
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/train/trainProjectSignup',
          data: this.form,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "操作成功！",
              type: 'success',
              offset: 100
            });
            this.ffzsdialogVisible = false;
            this.form = {
              "score": undefined,
              "studentComment": undefined,
              "certStatus": "3", // 证书状态（0未申请、1申请中、2已发放、3已接受）
              "certFiles": undefined, // 文件上传后返回的文件ID，英文逗号隔开，如：1,2,3
              "projectSignupId": undefined
            }
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              offset: 100
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 证书上传成功
      uploadSuccess(response, file, fileList) {
        this.form.certFiles = response.data.fileId + '';
      },
      // 证书删除
      handleRemove(response, file, fileList) {
        // 清空ID
        this.form.certFiles = undefined;
      },
      // 文件超出个数限制时的钩子
      onexceed() {
        this.$message({
          message: "证书限制上传一张！",
          type: 'warning',
          offset: 100
        });
      },
      cklick(e) {
        let query = {
          "trainCourseId": e.trainCourseId,
          "projectId": this.projectId
        }
        this.$router.push({
          path: 'coursePortaldetails',
          query: query
        })
      },
      beforeClose() {
        this.dialogVisible = false
      },
      examine() { // 审核
        let params = {
          "trainReportId": this.projectId, //"这里是培训申报ID",
          "projectStatus": this.projectStatus, // "培训状态（0草稿，1待审核，2基地审核通过，3基地审核不通过，4主管部门审核通过，5主管部门审核不通过）",
          "auditRemarkJd": this.auditRemarkJd,
          "auditRemarkZg": this.auditRemarkZg,
          "auditUserId": localStorage.getItem("userId")
        }
        if (!this.projectStatus) {
          this.$message({
            message: "请选择通过或驳回！",
            type: 'error',
            offset: 100
          });
        } else if ((this.projectStatus == 3 && !this.auditRemarkJd) || (this.projectStatus == 5 && !this
            .auditRemarkZg)) {
          this.$message({
            message: "请输入驳回原因！",
            type: 'error',
            offset: 100
          });
        } else {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/train/trainReport',
            data: params,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功！",
                type: 'success',
                offset: 100
              });
              this.dialogVisible = false;
              this.projectStatus = '';
              this.auditRemarkJd = '';
              this.auditRemarkZg = '';
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: 100
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      // 获取参与人员列表
      getProjectSignup() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + "/train/trainProjectSignup/getProjectSignup",
          data: {
            'projectId': this.projectId,
            'signupUserId': localStorage.getItem("userId")
          },
        }).then((res) => {
          if (res.data.data) {
            if (res.data.data.signupStatus == 1) {
              this.djcyannstyle = true
              this.djcyannstyletext = "待审核"
            } else if (res.data.data.signupStatus == 2) {
              this.djcyannstyle = true
              this.djcyannstyletext = "已经报名"
            }
          } else {
            this.djcyannstyletext = "我要报名"
            this.djcyannstyle = false
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      getTrainprojectSignuplist() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/train/trainProjectSignup/list?projectId=' + this.projectId,
          params: '',
        }).then((res) => {
          for (var i = 0; i < res.data.rows.length; i++) {
            if (res.data.rows[i].signupStatus == 2) {
              res.data.rows[i].signupStatusswitch = true
            } else {
              res.data.rows[i].signupStatusswitch = false
            }
          }
          this.trainProjectSignup = res.data.rows
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      signupStatusswitch(item) { // 报名状态审核
        let params = {
          "projectSignupId": item.projectSignupId,
          "signupStatus": item.signupStatusswitch ? '2' : '1',
        }
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/train/trainProjectSignup/changeStatus',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "操作成功！",
              type: 'success',
              offset: 100
            });
            this.getTrainprojectSignuplist()
            this.getProjectSignup()
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              offset: 100
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      application(e) { // 培训报名
        let params = {
          "signupStatus": '1',
          "projectId": this.projectId,
          "signupUserId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainProjectSignup/',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "恭喜你，报名成功！",
              type: 'success',
              offset: 100
            });
            this.djcyannstyle = false
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              offset: 100
            });
          }
          this.getTrainprojectSignuplist()
          this.getProjectSignup()
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 进入培训
      see(e) {
        let params = {
          "projectId": e.projectId,
        }
        // 跳转到培训详情页面弹窗
        this.$router.push({
          path: 'trainingDetails',
          query: params
        })
      },
      // 下载文件
      downloadclick(e) {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/files/' + e.fileId,
          params: '',
        }).then((res) => {
          let url = res.data.data.fileUrl;
          // 生成一个a标签
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          // 生成时间戳
          let timestamp = new Date().getTime()
          link.download = timestamp + '.txt'
          document.body.appendChild(link)
          link.click()

          // 记录下载(整个资源做记录)
          let params = {
            "resId": this.searchThing,
            "userId": localStorage.getItem("userId")
          }
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/resouse/resDownRecord/',
            data: params,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg + "已添加至下载记录！",
                type: 'success',
                offset: 100
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: 100
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        });
      },
      // 获取课程列表
      getTrainCourselist() {
        let params = {
          "projectId": this.projectId,
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainCourse/list',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.fileData2 = res.data.rows
          } else {

          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      onSubmit() {
        let _this = this;
        _this.loading = true
        // 获取培训基本信息
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/train/trainProject/' + _this.projectId,
          data: '',
        }).then((res) => {
          _this.ruleForm = res.data.data;
          // 获取附件列表
          if (res.data.data.projectFiles) {
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/getFileList/' + res.data.data.projectFiles,
              params: '',
            }).then((res) => {
              _this.fileData = []
              for (let i in res.data.data) {
                // 获取二级资源信息
                _this.fileData.push({
                  // 文件ID
                  fileId: res.data.data[i].fileId,
                  // 创建时间
                  createTime: res.data.data[i].createTime,
                  // 附件名称
                  chapterName: res.data.data[i].fileName.split(".")[0],
                  fileName: res.data.data[i].fileName,
                  // 文件地址
                  fileUrl: res.data.data[i].fileUrl,
                  nftId: res.data.data[i].nftId,
                })
              }
              _this.loading = false
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }

        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }
    },
    // 页面加载完毕事件
    mounted() {
      let _this = this;
      _this.userType = localStorage.getItem('userType') ? Number(localStorage.getItem('userType')) : ''
      // 获取跳转过来的培训ID
      _this.projectId = _this.$route.query.projectId ? _this.$route.query.projectId : "";
      _this.onSubmit()
      _this.getTrainprojectSignuplist()
      _this.getProjectSignup()
      _this.getTrainCourselist()
    }
  }
</script>

<style src="@/style/Cooperation/ProjectCom.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }

  .el-descriptions__header {
    margin-bottom: 0px !important;
  }

  .jbxxdiv {
    padding-bottom: 2rem;
  }
</style>
