<template>
  <div class="WriteanarticlexiangqingCom">
    <el-row :gutter="2">
      <!-- 文章详情 -->
      <el-col :span="24" class="maxdiv" v-if="wzAdnwt == 1">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb>
            <el-breadcrumb-item>团队交流大厅</el-breadcrumb-item>
            <el-breadcrumb-item>文章详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 标题 -->
        <h2 style="text-align: left;padding-left: 2rem;margin-bottom: 0rem;">{{comArticle.articleTitle}}</h2>
        <!-- 用户信息 -->
        <div class="userint" style="margin-left: 2rem;margin-top: 1rem;margin-bottom: 1rem;">
          <div style="width: 3rem;">
            <img v-if="comArticle.unionLevel == 1" src="../../assets/lv1.jpg" />
            <img v-if="comArticle.unionLevel == 2" src="../../assets/lv2.jpg" />
            <img v-if="comArticle.unionLevel == 3" src="../../assets/lv3.jpg" />
            <img v-if="comArticle.unionLevel == 4" src="../../assets/lv4.jpg" />
            <img v-if="comArticle.unionLevel == 5" src="../../assets/lv5.jpg" />
            <img v-if="comArticle.unionLevel == 6" src="../../assets/lv6.jpg" />
          </div>
          <div class="userintdiv">
            <p>{{comArticle.nickName}}</p>
            <p>{{comArticle.createTime}}</p>
          </div>
        </div>
        <!-- 正文 -->
        <div class="ql-editor" style="padding: 0rem 2rem;" v-html="comArticle.articleContent"></div>
        <!-- 附件 -->
        <div style="display: flex;flex-direction: column;align-items: flex-start;padding-left: 30px;margin-top: 50px;">
          <el-link :underline="false" v-for="item in comArticle.fileList" :href="item.fileUrl">{{item.fileName}}
          </el-link>
        </div>
        <!-- 评论、关注、点赞 -->
        <div class="dibutext">
          <div style="line-height: 2.5rem;"><i class="el-icon-chat-dot-round"></i>评论：({{total}})</div>
          <div class="shozhi" @click="comArticleFollow" v-if="comArticle.isFollow == 1">
            <i class="el-icon-star-on"></i>已关注：({{comArticle.followNum}})
          </div>
          <div class="shozhi" @click="comArticleFollow" v-else>
            <i class="el-icon-star-off"></i>关注：({{comArticle.followNum}})
          </div>
          <div class="shozhi" @click="wzdz()" v-if="comArticle.isFabulous == 1"><i
              class="el-icon-thumb"></i>已点赞：({{comArticle.fabulousNum}})</div>
          <div class="shozhi" @click="wzdz()" v-else><i class="el-icon-thumb"></i>点赞：({{comArticle.fabulousNum}})
          </div>
        </div>
      </el-col>

      <!-- 问题详情 -->
      <el-col :span="24" class="maxdiv" v-if="wzAdnwt == 2">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/Communication' }">
              团队交流大厅</el-breadcrumb-item>
            <el-breadcrumb-item>问题详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 标题 -->
        <h2 style="text-align: left;padding-left: 2rem;margin-bottom: 0rem;">{{comArticle2.questionTitle}}</h2>
        <!-- 用户信息 -->
        <div class="userint" style="margin-left: 2rem;">
          <div>
            <div style="width: 3rem;">
              <img v-if="comArticle2.unionLevel == 1" src="../../assets/lv1.jpg" />
              <img v-if="comArticle2.unionLevel == 2" src="../../assets/lv2.jpg" />
              <img v-if="comArticle2.unionLevel == 3" src="../../assets/lv3.jpg" />
              <img v-if="comArticle2.unionLevel == 4" src="../../assets/lv4.jpg" />
              <img v-if="comArticle2.unionLevel == 5" src="../../assets/lv5.jpg" />
              <img v-if="comArticle2.unionLevel == 6" src="../../assets/lv6.jpg" />
            </div>
          </div>
          <div class="userintdiv">
            <p>{{comArticle2.nickName}}</p>
            <p>{{comArticle2.createTime}}</p>
          </div>
        </div>
        <!-- 正文 -->
        <div style="padding: 0rem 2rem;" v-html="comArticle2.questionContent"></div>
        <!-- 奖励联盟积分、回答问题、关注、点赞 -->
        <div class="dibutext2">
          <p style="width: 20rem;text-align: left;">
            奖励联盟积分：{{comArticle2.rewardIntegral}}
            <el-tooltip class="item" effect="dark" content="被提问者评为最佳回答，将获得联盟积分奖励" placement="top">
              <i class="el-icon-question" style="margin-left: 2rem;"></i>
            </el-tooltip>
          </p>
          <el-button type="primary" @click="dialogVisible=true">回答问题</el-button>
          <div class="shozhi" @click="comQuestionFollow" v-if="comArticle2.isFollow == 1">
            <i class="el-icon-star-on"></i>已关注：({{comArticle2.followNum}})
          </div>
          <div class="shozhi" @click="comQuestionFollow" v-else>
            <i class="el-icon-star-off"></i>关注：({{comArticle2.followNum}})
          </div>
          <div class="shozhi" @click="wtdz()" v-if="comArticle2.isFabulous == 1">
            <i class="el-icon-thumb"></i>已点赞：({{comArticle2.fabulousNum}})
          </div>
          <div class="shozhi" @click="wtdz()" v-else>
            <i class="el-icon-thumb"></i>点赞：({{comArticle2.fabulousNum}})
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 评论栏 -->
    <el-row :gutter="2">
      <el-col :span="24" class="maxdiv" style="margin-top: 1rem;">

        <h3 style="text-align: left;padding-left: 2rem;">{{total}}{{wzAdnwt == 2 ? '个回答' : '个评论'}}</h3>

        <el-divider></el-divider>
        <!-- 请写下你的评论 -->
        <div class="halltopss" v-if="wzAdnwt == 1">
          <el-input placeholder="请写下您的评论" v-model="comComment.commentContent" class="input-with-select halltopssinput"
            clearable>
            <el-button slot="append" class="halltopssann" @click="searchclick()">发 布</el-button>
          </el-input>
        </div>

        <!-- 文章评论列表 -->
        <div class="pllistmax" v-for="item in CommentData" v-if="wzAdnwt==1">
          <div class="pllist">
            <!-- 用户信息 -->
            <div class="userint">
              <div>
                <img class="userintimg" v-if="item.unionLevel == 1" src="../../assets/lv1.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 2" src="../../assets/lv2.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 3" src="../../assets/lv3.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 4" src="../../assets/lv4.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 5" src="../../assets/lv5.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 6" src="../../assets/lv6.jpg" />
              </div>
              <div class="userintdiv">
                <p>{{item.nickName}}</p>
                <p>{{item.commentTime}}</p>
              </div>
            </div>
            <!-- 评论正文 -->
            <div class="pllistzw">
              {{item.commentContent}}
            </div>
            <!-- 评论数&点赞数 -->
            <div class="pllistpldz">
              <div class="pllistpldz1 shozhi" @click="zhuijiapl(item)"><i
                  class="el-icon-chat-dot-round"></i>评论：({{item.replyNum}})</div>
              <div class="shozhi" @click="pldz()"><i class="el-icon-thumb"></i>点赞：({{item.fabulousNum}})</div>
            </div>
          </div>
        </div>

        <!-- 问题评论列表 -->
        <div class="pllistmax" v-for="item in CommentData2" v-if="wzAdnwt==2">
          <div class="pllist">
            <!-- 用户信息 -->
            <div class="userint">
              <div>
                <img class="userintimg" v-if="item.unionLevel == 1" src="../../assets/lv1.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 2" src="../../assets/lv2.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 3" src="../../assets/lv3.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 4" src="../../assets/lv4.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 5" src="../../assets/lv5.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 6" src="../../assets/lv6.jpg" />
              </div>
              <div class="userintdiv">
                <p>{{item.nickName}}</p>
                <p>{{item.commentTime}}</p>
              </div>
            </div>
            <!-- 回答问题正文 -->
            <div class="pllistzw">
              {{item.answerContent}}
            </div>
            <!-- 评论数&点赞数 -->
            <div class="pllistpldz">
              <div class="pllistpldz1 shozhi" @click="zhuijiapl(item)"><i
                  class="el-icon-chat-dot-round"></i>评论：({{item.replyNum}})</div>
              <div class="shozhi" @click="pldz()"><i class="el-icon-thumb"></i>点赞：({{item.fabulousNum}})</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 评论的评论弹出窗 -->
    <el-dialog :title='wzAdnwt==1?"追加评论":"回答问题"' :visible.sync="dialogVisible" style="text-align: left;" width="30%"
      :before-close="handleClose">
      <span style="color: #CCCCCC;">{{wzAdnwt==1?"原评论：":"问题："}}{{zjpl.commentContent}}</span>
      <el-input style="margin-top: 1rem;" type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tijiaozjpl" v-if="wzAdnwt==1">确 定</el-button>
        <el-button type="primary" @click="tijiaozjpl2" v-else>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import $ from 'jquery';
  import "quill/dist/quill.snow.css";
  export default {
    name: '',
    components: {},
    data: function() {
      return {
        zjpl: {},
        textarea: "",
        dialogVisible: false,
        articleId: localStorage.getItem("articleId"),
        // 默认是文章
        wzAdnwt: localStorage.getItem("wzAdnwt") ? localStorage.getItem("wzAdnwt") : 1,
        comArticle: {},
        comArticle2: {},
        comComment: {
          "articleId": localStorage.getItem("articleId"),
          "commentContent": "",
          "commentUserId": localStorage.getItem("userId"),
          // 这里是评论父ID
          "parentId": ""
        },
        CommentData: {},
        CommentData2: {},
        total: 0,
        zdyesAndno: true,
        yesAndno: true,
      }
    },

    computed: {},
    methods: {
      zhuijiapl(itme) {
        this.dialogVisible = true;
        this.zjpl = itme;
      },
      // 文章点赞
      wzdz() {
        let params = {
          "articleId": this.articleId,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comArticleFabulous',
          data: params,
        }).then((res) => {
          this.$message({
            offset: 100,
            message: '操作成功！',
            type: 'success'
          });
          this.getData();
          this.getcomComment();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      wtdz(item) {
        let params = {
          "questionId": this.articleId,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + "/communication/comQuestionFabulous",
          data: params,
        }).then((res) => {
          this.$message({
            offset: 100,
            message: '操作成功！',
            type: 'success'
          });
          this.getData();
          this.getcomComment();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 评论点赞
      pldz() {

      },
      // 提交追加评论
      tijiaozjpl() {
        let _this = this;
        let params = {
          "articleId": _this.articleId,
          "commentContent": _this.textarea,
          "commentUserId": localStorage.getItem("userId"),
          "parentId": _this.zjpl.commentId ? _this.zjpl.commentId : ""
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comComment',
          data: params,
        }).then((res) => {
          this.$message({
            offset: 100,
            message: '恭喜你，评论成功！',
            type: 'success'
          });
          _this.textarea = "";
          _this.dialogVisible = false;
          _this.CommentData = res.data.data;
          _this.comComment.commentContent = "";
          _this.getcomComment();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      tijiaozjpl2() {
        let _this = this;
        let params = {
          "questionId": _this.articleId,
          "answerContent": _this.textarea,
          "answerUserId": localStorage.getItem("userId"),
          "parentId": _this.zjpl.commentId ? _this.zjpl.commentId : ""
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comAnswer',
          data: params,
        }).then((res) => {
          this.$message({
            offset: 100,
            message: '恭喜你，评论成功！',
            type: 'success'
          });
          _this.textarea = "";
          _this.dialogVisible = false;
          _this.CommentData = res.data.data;
          _this.comComment.commentContent = "";
          _this.getcomComment();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      getData() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let params = {
          loginUserId: localStorage.getItem("userId")
        }
        // 文章详情
        if (this.wzAdnwt == 1) {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + "/communication/comArticle/" + this.articleId,
            params: params,
          }).then((res) => {
            loading.close();
            this.comArticle = res.data.data
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
        //问题详情
        else {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + "/communication/comQuestion/" + this.articleId,
            params: params,
          }).then((res) => {
            loading.close();
            this.comArticle2 = res.data.data
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      // 根据文章ID查询文章评论列表
      getcomComment() {
        if (this.wzAdnwt == 1) {
          let params = {
            pageNum: 1,
            pageSize: 10,
            articleId: this.articleId,
          }
          this.$axios({
            method: "post",
            url: this.$baseUrl.test + "/communication/comComment/list",
            data: params,
          }).then((res) => {
            // 获取文章评论列表
            this.CommentData = res.data.rows;
            this.total = res.data.total;
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        } else {
          let params = {
            pageNum: 1,
            pageSize: 10,
            questionId: this.articleId,
          }

          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/communication/comAnswer/list?questionId=' + this.articleId,
            params: '',
          }).then((res) => {
            // 获取问题回答列表
            this.CommentData2 = res.data.rows;
            this.total = res.data.total;
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }

      },
      // 发布评论
      searchclick() {
        let _this = this;
        let url = _this.wzAdnwt == 1 ? '/communication/comComment' : "/communication/comAnswer"
        // 如果是问题评论就用这个
        let params = {
          "questionId": _this.articleId,
          "answerContent": _this.comComment.commentContent,
          "answerUserId": localStorage.getItem("userId"),
          "parentId": _this.zjpl.commentId ? _this.zjpl.commentId : ""
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + url,
          data: _this.wzAdnwt == 1 ? _this.comComment : params,
        }).then((res) => {
          this.$message({
            offset: 100,
            message: '恭喜你，评论成功！',
            type: 'success'
          });
          _this.CommentData = res.data.data;
          _this.comComment.commentContent = "";
          _this.getcomComment();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 文章关注
      comArticleFollow() {
        let _this = this;
        let params = {
          "articleId": this.articleId,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comArticleFollow',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              message: '操作成功！',
              type: 'success'
            });
            this.getData();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      /* 问题关注*/
      comQuestionFollow() {
        let _this = this;
        let params = {
          "questionId": this.articleId,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comQuestionFollow',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              message: '操作成功！',
              type: 'success'
            });
            this.getData();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

    },
    // 页面加载完毕事件
    mounted() {
      this.getData();
      this.getcomComment();
      this.comArticleFollowlist();
    }
  }
</script>

<style src="@/style/Team/WriteanarticlexiangqingCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    text-align: right;
  }

  img {
    max-width: 100%;
  }

  .userintimg {
    width: 3rem;
    height: 3rem;
  }
</style>
