<template>
  <div class="index">
    <el-row :gutter="24" style="background-color: #FFFFFF; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>资源共享管理</el-breadcrumb-item>
            <el-breadcrumb-item>下载历史</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column prop="" label="资源标题">
              <template slot-scope="scope">
                <el-link @click="Clicktoview(scope.row.resId)" target="_blank">{{scope.row.resTitle}}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="联盟积分" width="150">
            </el-table-column>
            <el-table-column prop="nickName" label="分享者" width="150">
            </el-table-column>
            <el-table-column prop="downTime" label="下载时间" width="200">
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    data: function() {
      return {
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          "userId": localStorage.getItem("userId"),
        },
        // 总数
        total: 0,
        tableData: [],
        resIds: ''
      }
    },
    methods: {
      // 点击标题跳转到详情页面
      Clicktoview(e) {
        this.$router.push({
          name: 'ResourceDetails',
          params: {
            resId: e
          }
        });
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      onSubmit() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/resouse/resDownRecord/list',
          params: this.tableDataval,
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.total = res.data.total;
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      //ajax请求
      this.onSubmit();
    }
  }
</script>

<style src="@/style/Resources/DownloadrecordCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
