<template>
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="hallmaxdiv">
          <el-row>
            <el-col :span="24">
              <div class="halltop">
                <div class="halltoptitle">资源共享大厅</div>
                <div class="halltopss">
                  <el-input placeholder="请输入内容" v-model="tableDataval.resTitle" class="input-with-select halltopssinput"
                    clearable>
                    <el-button slot="append" class="halltopssann" @click="searchclick()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="18" style="padding-right: 10px !important;">
              <!-- 左边上内容 -->
              <div class="grid-content bg-purple" style="min-height: 13rem;margin-bottom: 0.5rem;">
                <!-- 第一排 -->
                <el-row class="diyipai" style="padding: 2rem 2rem 0rem 2rem !important;">
                  <el-col :span="4">
                    <div class="titlenrdiv">
                      <div class="nrtitle">资源类型:&#12288; </div>
                      <el-radio-group v-model="tableDataval.resType" @change="agreeChange1">
                        <el-radio fill="#000" size="mini" label="" border>不限</el-radio>
                      </el-radio-group>
                    </div>
                  </el-col>
                  <el-col :span="20" style="text-align: left;">
                    <el-radio-group v-model="tableDataval.resType" @change="agreeChange1">
                      <div class="nrlist">
                        <div v-for="item in resTypelist" :key="item.dictValue">
                          <el-radio fill="#000" size="mini" :label="item.dictValue" border>{{item.dictLabel}}
                          </el-radio>
                        </div>
                      </div>
                    </el-radio-group>
                  </el-col>
                </el-row>
                <!-- 第二排 -->
                <el-row class="diyipai" style="padding: 1rem 2rem 0rem 2rem !important;">
                  <el-col :span="4">
                    <div class="titlenrdiv">
                      <div class="nrtitle">资源来源:&#12288; </div>
                      <el-radio-group v-model="tableDataval.orgId" @change="agreeChange2">
                        <el-radio fill="#000" size="mini" label="" border>不限</el-radio>
                      </el-radio-group>
                    </div>
                  </el-col>
                  <el-col :span="20" style="margin-left: 5px;width: 75%;">
                    <div class="nrlist2">
                      <template>
                        <el-select size="mini" v-model="tableDataval.orgId" filterable allow-create default-first-option
                          placeholder="请输入或选择联盟单位名称" @change="options1change" clearable>
                          <el-option v-for="item in options1" :key="item.orgId" :label="item.orgName"
                            :value="item.orgId">
                          </el-option>
                        </el-select>
                      </template>
                      <template>
                        <el-select size="mini" v-model="tableDataval.userId" filterable allow-create
                          default-first-option placeholder="请输入或选择成员姓名" @change="options2change" clearable>
                          <el-option v-for="item in options2" :key="item.userId" :label="item.nickName"
                            :value="item.userId">
                          </el-option>
                        </el-select>
                      </template>
                    </div>
                  </el-col>
                </el-row>

                <!-- 第三排 -->
                <!-- <el-row class="diyipai" style="padding: 1rem 2rem 0rem 2rem !important;">
                  <el-col :span="4">
                    <div class="titlenrdiv">
                      <div class="nrtitle">专业类别:&#12288; </div>
                      <el-radio-group v-model="tableDataval.subjectType" @change="agreeChange3">
                        <el-radio fill="#000" size="mini" label="" border>不限
                        </el-radio>
                      </el-radio-group>
                    </div>
                  </el-col>
                  <el-col :span="20">
                    <el-radio-group v-model="tableDataval.subjectType" @change="agreeChange3" style="width: 100%;">
                      <div class="nrlist">
                        <div v-for="item in subjectTypelist">
                          <el-radio fill="#000" size="mini" :label="item.dictValue" border>{{item.dictLabel}}
                          </el-radio>
                        </div>
                      </div>
                    </el-radio-group>
                  </el-col>
                </el-row> -->

                <!-- 第四排 -->
                <el-row class="diyipai" style="padding: 1rem 2rem 2rem 2rem!important">
                  <el-col :span="4">
                    <div class="titlenrdiv">
                      <div class="nrtitle">资源状态:&#12288; </div>
                      <el-radio-group v-model="tableDataval.orderBy" @change="agreeChange4">
                        <el-radio fill="#000" size="mini" label="" border>不限</el-radio>
                      </el-radio-group>
                    </div>
                  </el-col>
                  <el-col :span="20">
                    <el-radio-group v-model="tableDataval.orderBy" @change="agreeChange4" style="width: 100%;">
                      <div class="nrlist">
                        <div>
                          <el-radio fill="#000" size="mini" label="1" border>下载最多
                          </el-radio>
                        </div>
                        <div>
                          <el-radio fill="#000" size="mini" label="2" border>最新分享
                          </el-radio>
                        </div>
                        <div>
                          <el-radio fill="#000" size="mini" label="3" border>人气优先
                          </el-radio>
                        </div>
                      </div>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </div>

              <!-- 左边下内容 -->
              <div class="grid-content bg-purple" style="max-height: 50vh;overflow-y: auto;">
                <div class="zuoxianr">找到 {{total}} 条资源</div>
                <!-- 第一条数据 -->
                <el-row v-for="item in formdata" style="border-bottom: 1px solid #f2f2f2;margin-bottom: 10px;">
                  <el-col :span="20" style="border-right: 1px solid #f2f2f2;">
                    <div class="grid-content bg-purple zuoxianrtitle">
                      <div style="white-space: nowrap;">
                        <!-- 资源标题 -->
                        <img v-if="item.resType == 0" src="../assets/icon/mp4.png" class="iconstyle"
                          style="width: 1.7rem;height: 1.7rem;" />
                        <img v-else src="../assets/icon/wendang.png" class="iconstyle"
                          style="width: 1.7rem;height: 1.7rem;" />
                        <!-- 标题 -->
                        <div style="line-height: 2rem;">{{item.resTitle}}</div>
                      </div>
                      <!-- 等级 -->
                      <div class="Grade">资源等级<span style="color: red;">{{item.resGrade}}</span>星</div>
                    </div>
                    <div class="zuoxianrjj">
                      <span style="margin-right: 0rem;">简介：</span>
                      <span>{{item.resContent}}</span>
                    </div>
                    <div class="dibutext">
                      <div>类型：{{item.resTypeName?item.resTypeName:item.resType}}</div><i class="el-icon-minus"></i>
                      <div>{{item.createTime}}</div><i class="el-icon-minus"></i>
                      <div>分享者：{{item.userNickName}}</div><i class="el-icon-minus"></i>
                      <div>分享机构：{{item.userOrgName}}</div><i class="el-icon-minus"></i>
                      <div>下载：{{item.downCount}}</div>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="homdeann">
                      <i v-if="item.isCollect==1" class="el-icon-star-on" style="cursor:pointer;color: #0066FF;"
                        @click="CancelCollect(item.resId)">已收藏</i>
                      <i v-else class="el-icon-star-off" style="cursor:pointer" @click="Collection(item.resId)">收藏</i>
                      <div>联盟积分：{{item.price}}</div>
                      <el-button type="primary" @click="Clicktoview(item.resId)">点击观看</el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!-- 分页 -->
              <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pagination.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
              </div>
            </el-col>

            <!-- 右边内容 -->
            <el-col :span="6" style="padding-left: 10px !important;">
              <el-row :gutter="20"
                style="background-color: #FFFFFF;border-radius: 4px;margin-bottom: 10px;height: 15rem;">
                <div style="display: flex;padding: 1rem 2rem;">
                  <div>
                    <img v-if="unionLevel==1" style="width: 4rem;height: 4rem;" src="../assets/lv1.jpg">
                    <img v-if="unionLevel==2" style="width: 4rem;height: 4rem;" src="../assets/lv2.jpg">
                    <img v-if="unionLevel==3" style="width: 4rem;height: 4rem;" src="../assets/lv3.jpg">
                    <img v-if="unionLevel==4" style="width: 4rem;height: 4rem;" src="../assets/lv4.jpg">
                    <img v-if="unionLevel==5" style="width: 4rem;height: 4rem;" src="../assets/lv5.jpg">
                    <img v-if="unionLevel==6" style="width: 4rem;height: 4rem;" src="../assets/lv6.jpg">
                  </div>
                  <div style="text-align: left;margin-left: 1rem;margin-top: 0.7rem;">
                    <div>{{nickName}}</div>
                    <div class="mmb">联盟积分：{{unionCoin}}</div>
                  </div>
                </div>
                <div class="letfann">
                  <div class="letfanndiv" @click="letfanndivclick(1)">
                    <div class="letfanntext">收藏</div>
                    <div class="letfannsun">{{sctotal}}</div>
                  </div>

                  <div class="letfanndiv" @click="letfanndivclick(2)">
                    <div class="letfanntext">下载</div>
                    <div class="letfannsun">{{xztotal}}</div>
                  </div>

                  <div class="letfanndiv" @click="letfanndivclick(3)">
                    <div class="letfanntext">购买</div>
                    <div class="letfannsun">{{sptotal}}</div>
                  </div>

                  <div class="letfanndiv" @click="letfanndivclick(4)">
                    <div class="letfanntext">上传</div>
                    <div class="letfannsun">{{shangchuantotal}}</div>
                  </div>
                </div>
                <el-button type="primary" @click="Upload()" style="width: 86%;margin-top: 1.35rem;margin-bottom: 1rem;">
                  上传资源</el-button>
              </el-row>

              <el-row :gutter="20" style="background-color: #FFFFFF;border-radius: 4px;">

                <div class="leftdiv">
                  <!-- 头部 -->
                  <div class="leftdivtop">
                    <div class="leftdivtoptitle">最新资源推荐</div>
                    <el-divider></el-divider>
                  </div>
                  <!-- 内容 -->
                  <div class="leftdivtopnrdiv">
                    <div class="leftdivtopnr" v-for="item in formdata2">
                      <el-link @click="Clicktoview(item.resId)">{{item.resTitle}}</el-link>&#12288;
                      <span>{{item.createTime?item.createTime.split(" ")[0]:"---"}}</span>
                    </div>
                  </div>
                </div>
              </el-row>
            </el-col>
          </el-row>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        unionLevel: localStorage.getItem("unionLevel"),
        // 联盟积分
        unionCoin: '',
        // 用户名
        nickName: '',
        // 专业类别
        subjectTypelist: [],
        // 资源类型
        resTypelist: [],
        // 机构列表
        options1: [{
          orgId: '',
          orgName: ''
        }],
        // 成员列表
        options2: [{
          value: '选项1',
          label: '张先森'
        }],
        value1: '',
        value2: '',
        // 总数
        total: 0,
        // 表格字段
        tableDataval: {
          // 资源标题
          "resTitle": "",
          // 资源类型
          "resType": "",
          // 资源来源
          "orgId": "",
          // 专业类别
          "subjectType": "",
          // 排序 1下载最多，2最新分享，3人气优先
          "orderBy": "",
          // 查询时选择的用户id
          "userId": "",
          "loginUserId": localStorage.getItem("userId")
        },

        // 分页
        pagination: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
        },
        // 表格数据
        formdata: {},
        // 最新资源表格
        formdata2: {},
        // 获取收藏总数
        sctotal: "0",
        // 获取下载总数
        xztotal: "0",
        // 视频总数
        sptotal: "0",
        // 获取上传总数
        shangchuantotal: "0",
      }
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pagination.pageSize = val;
        this.searchclick();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pagination.pageNum = val;
        this.searchclick();
      },
      // 机构下拉框值改变事件
      options1change(event, item) {
        // 获取表格数据
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/list?orgId=' + this.tableDataval.orgId,
          data: '',
        }).then((res) => {
          if (res.data.code == 200) {
            this.options2 = res.data.rows;
            this.searchclick();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 用户下拉框值改变事件
      options2change() {
        // 获取表格数据
        this.searchclick();
      },
      // 资源类型单选框
      agreeChange1() {
        // 获取表格数据
        this.searchclick();
      },
      // 资源来源单选框
      agreeChange2() {
        // 获取表格数据
        this.searchclick();
      },
      // 专业类别单选框
      // agreeChange3() {
      //   // 获取表格数据
      //   this.searchclick();
      // },
      // 资源状态单选框
      agreeChange4() {
        // 获取表格数据
        this.searchclick();
      },
      // 搜索点击事件
      searchclick() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resInfo/alllist?pageNum=' + this.pagination.pageNum + '&pageSize=' +
            this.pagination.pageSize,
          data: this.tableDataval,
        }).then((res) => {
          if (res.data.code == 200) {
            this.formdata = res.data.rows;
          }
          this.total = res.data.total
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 点击观看
      Clicktoview(e) {
        localStorage.setItem("resId", e);
        this.$router.push({
          name: 'ResourceDetails',
          params: {
            resId: e
          }
        });
      },
      // 收藏
      Collection(e) {
        let params = {
          "resId": e,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resCollectRecord',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$alert('恭喜你，收藏成功！', '收藏成功', {
              confirmButtonText: '确定'
            });
          } else {
            this.$alert(res.data.msg, '收藏失败', {
              confirmButtonText: '确定'
            });
          }
          // 获取表格数据
          this.searchclick();
          // 获取收藏总数
          this.shocang();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 取消收藏
      CancelCollect(e) {
        let params = {
          "resId": e,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resCollectRecord/cancelCollect',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$alert('取消收藏成功！', '取消收藏', {
              confirmButtonText: '确定'
            });
          } else {
            this.$alert(res.data.msg, '取消收藏失败', {
              confirmButtonText: '确定'
            });
          }
          // 获取表格数据
          this.searchclick();
          // 获取收藏总数
          this.shocang();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      //
      letfanndivclick(e) {
        switch (e) {
          case 1:
            // 指定左侧导航栏
            localStorage.setItem('BaractiveIndex', "/Resourcecollection");
            localStorage.setItem('activeIndex', "1");
            this.$router.push('/Resourcecollection');
            break;
          case 2:
            localStorage.setItem('BaractiveIndex', "/Downloadrecord");
            localStorage.setItem('activeIndex', "1");
            this.$router.push('/Downloadrecord');
            break;
          case 3:
            localStorage.setItem('BaractiveIndex', "/Purchasedvideo");
            localStorage.setItem('activeIndex', "1");
            this.$router.push('/Purchasedvideo');
            break;
          case 4:
            localStorage.setItem('BaractiveIndex', "Myresources");
            localStorage.setItem('activeIndex', "1");
            this.$router.push('/Myresources');
            break;
          default:
            // 默认跳转工作台
            localStorage.setItem('activeIndex', "1");
            this.$router.push('/Index');
        }
      },
      // 上传资源跳转
      Upload() {
        this.$router.push('/Upload');
      },
      // 获取收藏资源
      shocang() {
        let params = {
          "userId": localStorage.getItem("userId"),
          "pageNum": 1,
          "pageSize": 10000,
          resTitle: "",
          status: "",
        }
        // 获取收藏总数
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/resouse/resCollectRecord/list',
          params: params,
        }).then((res) => {
          this.sctotal = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      // 获取最新资源列表
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/resouse/resInfo/alllist?pageNum=1&pageSize=10',
        data: this.tableDataval,
      }).then((res) => {
        if (res.data.code == 200) {
          this.formdata2 = res.data.rows;
        }
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取成员名称列表
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/list?orgId=' + this.tableDataval.orgId,
        data: '',
      }).then((res) => {
        if (res.data.code == 200) {
          this.options2 = res.data.rows;
        }
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取当前用户信息
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.getItem("userId"),
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        // 联盟积分
        this.unionCoin = res.data.data.unionCoin;
        // 用户名
        this.nickName = res.data.data.nickName;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取资源类型，专业类别
      let dictType = ['res_type', 'subject_type']
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/pubilc/getDictLists',
        data: dictType
        //请求成功的回调函数
      }).then((res) => {
        this.resTypelist = res.data.data.res_type
        this.subjectTypelist = res.data.data.subject_type
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取表格数据
      this.searchclick();
      // 获取机构列表
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/pubilc/orglist',
        data: '',
      }).then((res) => {
        if (res.data.code == 200) {
          this.options1 = res.data.rows;
        }
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });


      let params = {
        "userId": localStorage.getItem("userId"),
        "pageNum": 1,
        "pageSize": 10000,
        resTitle: "",
        status: "",
      }
      // 获取收藏总数
      this.shocang();

      // 获取下载总数
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/resouse/resDownRecord/list',
        params: params,
      }).then((res) => {
        this.xztotal = res.data.total;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

      // 视频总数
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/resouse/resShopRecord/list',
        params: params,
      }).then((res) => {
        this.sptotal = res.data.total;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

      // 获取上传总数
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/resouse/resInfo/list',
        data: params,
      }).then((res) => {
        this.shangchuantotal = res.data.total;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

    }
  }
</script>

<style src="@/style/hall.css" scoped></style>
<style>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  .el-radio--mini.is-bordered {
    padding: 6px 2px 0px 2px !important;
    border-radius: 3px;
    height: 28px;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .iconstyle {
    float: left;
    margin-right: 1rem;
  }
</style>
