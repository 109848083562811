<template>
  <div class="index">
    <el-row>
      <!-- 头部 -->
      <el-col :span="24">
        <Head />
      </el-col>
    </el-row>
    <el-row :gutter="20" class="subject">
      <!-- 内容 -->
      <el-col :span="24" style="padding-right: 0px;">
        <HirehallCom />
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem;z-index: -999;">
      <!-- 底部版权 -->
      <el-col :span="24"><Copyright/></el-col>
    </el-row>
  </div>
</template>


<script>
  // 底部版权
  import Copyright from '@/components/Copyright.vue'
  import Head from '@/components/Head.vue'
  import HirehallCom from '@/components/HirehallCom.vue'

  export default {
    name: 'app',
    components: {
      Head,
      Copyright,
      HirehallCom
    },
   data: function () {
      return {

      }
    },
    // 页面加载完毕触发
    mounted() {
      localStorage.setItem('activeIndex', "6");
    },
    methods: {

    }
  }
</script>

<style src="@/style/main.css" scoped></style>
<style>
  .el-row {
    &:last-child {
      margin-bottom: 0;
    }

    margin-left: 0px !important;
    margin-right: 0px !important;
  }
</style>
