<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>协同合作管理</el-breadcrumb-item>
            <el-breadcrumb-item>我的项目详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="2" style="color: #00000000;">1</el-col>
      <el-col :span="20">
        <el-row :gutter="10">
          <div style="display: flex;justify-content: space-between;">
            <!-- 项目名称 -->
            <div style="font-size: 1.5rem;" class="toptitle">{{ruleForm.projectName}}</div>
            <div>
              <el-button type="primary" v-if="ruleForm.projectStatus == 1 || ruleForm.projectStatus == 2">合作确认中
              </el-button>
              <el-button type="primary" v-if="ruleForm.projectStatus == 3">合作中</el-button>
              <el-button type="primary" v-if="ruleForm.projectStatus == 4">评审中</el-button>
              <el-button type="primary" v-if="ruleForm.projectStatus == 5">已结项</el-button>
              <el-button type="info" v-if="ruleForm.projectStatus == 6">终止</el-button>
            </div>
          </div>
          <el-steps :active="ruleForm.projectStatus" finish-status="success" simple
            style="margin-top: 20px;margin-bottom: 20px;">
            <el-step title="发起合作"></el-step>
            <el-step title="参与合作"></el-step>
            <el-step title="确认合作"></el-step>
            <el-step title="项目记录"></el-step>
            <el-step title="申请结项"></el-step>
            <el-step title="评审验收"></el-step>
          </el-steps>
        </el-row>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>

    <el-row :gutter="20" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="2" style="color: #00000000;">1</el-col>
      <el-col :span="20">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#ffffff" text-color="#8D8D8D" active-text-color="#008FE0" style="font-weight: bold;">
          <el-menu-item index="1">基本信息</el-menu-item>
          <el-menu-item index="2" v-if="djcyannstyle">参与报名</el-menu-item>
          <el-menu-item index="3" v-if="ruleForm.projectStatus >= 2">参与合作信息</el-menu-item>
          <el-menu-item index="4" v-if="ruleForm.projectStatus >= 3">项目过程记录</el-menu-item>
          <el-menu-item index="5" v-if="ruleForm.projectStatus >= 4">预算使用记录</el-menu-item>
          <el-menu-item index="6" v-if="ruleForm.projectStatus >= 5">专家评审验收</el-menu-item>
        </el-menu>
        <!-- 基本信息 -->
        <div class="jbxxdiv" v-if="activeIndex == 1">
          <el-descriptions class="jbxxdivdescriptions" title="项目发起人信息" :column="2" border>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="项目发起人">{{ruleForm.nickName}}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="单位名称">{{ruleForm.orgName}}
            </el-descriptions-item>
            <el-descriptions-item label="联系号码">{{ruleForm.phonenumber}}</el-descriptions-item>
            <el-descriptions-item label="邮箱">{{ruleForm.email}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="jbxxdivdescriptions" title="项目信息" :column="2" border>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="项目名称">
              {{ruleForm.projectName}}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="项目编号">{{ruleForm.projectNo}}
            </el-descriptions-item>
            <el-descriptions-item label="项目类型">{{ruleForm.projectTypeName}}</el-descriptions-item>
            <el-descriptions-item label="项目合作人数">{{ruleForm.cooperationNum}}</el-descriptions-item>
            <el-descriptions-item label="允许参与报名人数">{{ruleForm.allowSignupNum}}</el-descriptions-item>
            <el-descriptions-item label="结项时间">{{ruleForm.completeTime}}</el-descriptions-item>
            <el-descriptions-item label="项目经费(元)">{{ruleForm.projectFunds}}</el-descriptions-item>
          </el-descriptions>

          <el-descriptions class="jbxxdivdescriptions" title="项目介绍" :colon="false">
            <el-descriptions-item label="" labelStyle="width:0%;">
              {{ruleForm.projectContent}}
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions class="jbxxdivdescriptions" title="项目附件" :colon="false">
            <el-descriptions-item label="" labelStyle="width:0%;">
            </el-descriptions-item>
          </el-descriptions>
          <template>
            <el-table :data="fileData" border style="width: 100%">
              <el-table-column type="index" width="50" label="序号">
              </el-table-column>
              <el-table-column prop="chapterName" label="附件名称">
              </el-table-column>
              <el-table-column prop="nftId" label="区块链地址">
              </el-table-column>
              <el-table-column prop="createTime" label="上传日期">
              </el-table-column>
              <el-table-column prop="address" label="操作" width="150">
                <template slot-scope="scope">
                  <el-button size="small" @click="filedownload(scope.row)" type="primary">
                    下载
                  </el-button>
                  <el-button size="small" @click="fileview(scope.row)" type="success">
                    查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>

        <!-- 参与报名 -->
        <div class="cybmdiv" v-if="activeIndex == 2">
          <el-descriptions class="jbxxdivdescriptions" title="报名信息" :column="2" border>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="参与合作人">{{userForm.nickName}}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="单位名称">{{userForm.orgName}}
            </el-descriptions-item>
            <el-descriptions-item label="联系号码">{{userForm.phonenumber}}</el-descriptions-item>
          </el-descriptions>

          <el-descriptions class="jbxxdivdescriptions" title="项目信息" :column="2" border>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="项目名称">
              {{ruleForm.projectName}}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="项目编号">{{ruleForm.projectNo}}
            </el-descriptions-item>
            <el-descriptions-item label="项目类型">{{ruleForm.projectTypeName}}</el-descriptions-item>
            <el-descriptions-item label="项目合作人数">{{ruleForm.cooperationNum}}人</el-descriptions-item>
            <el-descriptions-item label="报价(元)">
              <el-input :disabled="prohibit" v-model="offer" placeholder="请输入报价" style="width: 30%;"></el-input>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions class="jbxxdivdescriptions" title="方案描述" :colon="false">
            <el-descriptions-item label="" labelStyle="width:0%;" contentStyle="width:100%;">
              <el-input type="textarea" :disabled="prohibit" :rows="4" placeholder="(请介绍项目背景、意义、目标,主要内容,预期成果等,800字以内)"
                v-model="schemeDescription">
              </el-input>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions class="jbxxdivdescriptions" title="附件" :colon="false">
            <el-descriptions-item label="" labelStyle="width:0%;" contentStyle="width:100%;">
              <div class="elupload">
                <el-upload accept=".rar, .zip, .doc, .docx, .pdf, .jpg, .mp4" class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-success="uploadSuccess"
                  :on-error="errorUpload" :show-file-list="false">
                  <el-button size="small" type="primary" style="float: left;" @click="addupload()" :disabled="prohibit">
                    点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传：.rar .zip .doc .docx .pdf .jpg .mp4且不超过1024MB</div>
                </el-upload>
                <el-table :data="upfileData" style="width: 100%" :border="true">
                  <el-table-column type="index" label="序号" width="50">
                  </el-table-column>

                  <el-table-column prop="chapterName" label="附件名称">
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="dark" :content="scope.row.chapterName" placement="top">
                        <el-input v-model="scope.row.chapterName" :disabled="prohibit" placeholder="请输入附件名称"></el-input>
                      </el-tooltip>
                    </template>
                  </el-table-column>

                  <el-table-column prop="fileName" label="附件">
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="dark" :content="scope.row.fileName" placement="top">
                        <span>{{scope.row.fileName}}</span>
                      </el-tooltip>
                      <el-upload accept=".rar, .zip, .doc, .docx, .pdf, .jpg, .mp4" class="upload-demo" :action="$baseUrl.test+'/common/upload'"
                        :on-success="uploadSuccess" multiple :on-error="errorUpload" :show-file-list="false">
                        <el-button :disabled="prohibit" size="small" type="text" @click="Reupload(scope.row)">重新上传
                        </el-button>
                      </el-upload>
                    </template>
                  </el-table-column>

                  <el-table-column prop="createTime" label="上传时间">
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="dark" :content="scope.row.createTime" placement="top">
                        <el-input v-model="scope.row.createTime" disabled></el-input>
                      </el-tooltip>
                    </template>
                  </el-table-column>

                  <el-table-column prop="" label="操作" scope="scope" width="90">
                    <template slot-scope="scope">
                      <el-button type="danger" icon="el-icon-delete" circle size="small"
                        @click="deleteclick(scope.row)">
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <el-button type="primary" @click="activeIndex = '3'">返回</el-button>
          <!-- 保存&报名&取消 -->
          <div class="baocunmaominquxiao" v-if="!prohibit">
            <el-button type="danger">取消</el-button>
            <el-button style="margin: 0rem 2rem;" @click="cooProjectSignup(0)">保存为草稿</el-button>
            <el-button type="primary" @click="cooProjectSignup(1)">报名参加</el-button>
          </div>
        </div>

        <!-- 参与合作信息 -->
        <div class="canyuhezuoxx" v-if="activeIndex == 3">
          <el-descriptions class="jbxxdivdescriptions" title="参与合作报名用户列表" :colon="false">
            <el-descriptions-item label="" labelStyle="width:0%;" contentStyle="width:100%;">
              <div class="elupload">
                <el-table :data="userData" style="width: 100%" :border="true">
                  <el-table-column type="index" label="序号" width="50">
                  </el-table-column>

                  <el-table-column prop="nickName" label="参与报名用户">
                  </el-table-column>

                  <el-table-column prop="signupTime" label="报名时间">
                  </el-table-column>
                  <!-- 报名状态（0草稿，1待审核，2报名成功，3撤销报名，4报名失败） -->
                  <el-table-column prop="" label="操作" scope="scope" width="150">
                    <template slot-scope="scope">
                      <el-button v-if="scope.row.signupStatus == '1' || scope.row.signupStatus == '3'" size="small"
                        @click="cooperationclick(scope.row)" type="text">
                        报名通过
                      </el-button>
                      <el-button style="color: red;" v-if="scope.row.signupStatus == '2'" size="small"
                        @click="cooperationclick(scope.row)" type="text">
                        报名驳回
                      </el-button>
                      <el-button size="small" @click="ckxqclick(scope.row)" type="text">
                        查看详情
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-descriptions-item>
          </el-descriptions>
          <div style="padding: 10px 0px;">
            <el-row>
              <!-- 0草稿，1报名中，2合作中，3评审中，4已结项 -->
              <el-button v-if="ruleForm.projectStatus == 1 || ruleForm.projectStatus == 2" @click="confirm()"
                type="primary">确认合作</el-button>
            </el-row>
          </div>
        </div>

        <!-- 项目过程记录 -->
        <div class="processrecord" v-if="activeIndex == 4">
          <el-descriptions class="jbxxdivdescriptions" title="项目过程记录" :colon="false">
            <el-descriptions-item label="" labelStyle="width:0%;" contentStyle="width:100%;">
              <div class="elupload">
                <el-button style="float: right;" size="small" @click="addrecordclick()" type="primary" plain>
                  新增记录
                </el-button>
                <el-table :data="processrecordData" style="width: 100%" :border="true">
                  <el-table-column type="index" label="序号" width="50">
                  </el-table-column>
                  <el-table-column prop="projectPhase" label="项目阶段">
                  </el-table-column>
                  <el-table-column prop="content" label="具体内容">
                  </el-table-column>
                  <el-table-column prop="nickName" label="记录人">
                  </el-table-column>
                  <el-table-column prop="createTime" label="记录时间">
                  </el-table-column>
                  <el-table-column prop="fileName" label="附件">
                    <template slot-scope="scope">
                      <div v-for="itme in scope.row.fileList">
                        <el-link :href="itme.fileUrl" target="_blank">{{itme.fileName}}</el-link>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="" label="操作" scope="scope" width="90">
                    <template slot-scope="scope">
                      <el-button size="small" @click="editrecordclick(scope.row)" type="text">
                        编辑
                      </el-button>
                      <el-button style="color: red;" size="small" @click="removerecordclick(scope.row)" type="text">
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <!-- 预算使用记录 -->
        <div class="yusuangshiyjilu" v-if="activeIndex == 5">
          <div class="yusuang">
            <el-row type="flex" class="row-bg" justify="space-around">
              <el-col :span="12" style="margin: 0.5rem;	background-color: #f9fafc;">
                <div class="grid-content bg-purple">
                  <div style="display: flex;justify-content: center;align-items: center;">
                    <p class="xmystext">项目预算<el-button size="mini" style="margin-left: 0.5rem;">增加预算</el-button>
                    </p>
                  </div>
                  <p class="jinge">{{ruleForm.projectFunds}} <span class="yu"> 元</span></p>
                </div>
              </el-col>
              <el-col :span="12" style="margin: 0.5rem;	background-color: #f9fafc;">
                <div class="grid-content bg-purple-light">
                  <div class="grid-content bg-purple">
                    <p class="xmystext" style="margin-top: 4rem;">剩余经费</p>
                    <p class="jinge">{{ruleForm.surplusFunds}} <span class="yu"> 元</span></p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-descriptions class="jbxxdivdescriptions" title="预算使用记录" :colon="false">
            <el-descriptions-item label="" labelStyle="width:0%;" contentStyle="width:100%;">
              <div class="elupload">
                <el-button style="float: right;" size="small" @click="addyusuangclick()" type="primary" plain>
                  新增记录
                </el-button>
                <el-table :data="yusuangshiyjiluData" style="width: 100%" :border="true">
                  <el-table-column type="index" label="序号" width="50">
                  </el-table-column>
                  <el-table-column prop="purpose" label="经费用途">
                  </el-table-column>
                  <el-table-column prop="purposeName" label="对方名称">
                  </el-table-column>
                  <el-table-column prop="amount" label="支出金额（元）">
                  </el-table-column>
                  <el-table-column prop="nickName" label="记录人">
                  </el-table-column>
                  <el-table-column prop="createTime" label="记录时间" width="160">
                  </el-table-column>
                  <el-table-column prop="" label="附件">
                    <template slot-scope="scope">
                      <div v-for="itme in scope.row.fileList">
                        <el-link :href="itme.fileUrl" target="_blank">{{itme.fileName}}</el-link>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="" label="操作" scope="scope" width="90">
                    <template slot-scope="scope">
                      <el-button size="small" @click="edityusuangclick(scope.row)" type="text">
                        编辑
                      </el-button>
                      <el-button style="color: red;" size="small" @click="removeyusuangclick(scope.row)" type="text">
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <!-- 专家评审验收 -->
        <div class="check" v-if="activeIndex == 6">
          <el-descriptions class="jbxxdivdescriptions" title="结项申请人信息" :column="2" border>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="结项申请人">{{userForm.nickName}}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="单位名称">{{userForm.orgName}}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="联系电话">
              {{userForm.phonenumber}}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="结项申请时间">
              {{userForm.phonenumber}}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="jbxxdivdescriptions" title="评审专家信息" :column="2" border>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="专家姓名">{{userForm.nickName}}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="联系电话">{{userForm.orgName}}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="参与专家姓名">
              <el-input v-model="cooProjectReview.expertNames" placeholder="请输入内容"></el-input>
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="评审时间">
              <el-date-picker v-model="cooProjectReview.reviewTime" value-format="yyyy-MM-dd" format="yyyy 年 MM 月 dd 日"
                type="date" placeholder="选择日期">
              </el-date-picker>
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="评审结论">
              <el-radio-group v-model="cooProjectReview.conclusion">
                <el-radio :label="1">合格</el-radio>
                <el-radio :label="2">不合格</el-radio>
              </el-radio-group>
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:10%;" contentStyle="width:20%;" label="评审意见">
              <el-input type="textarea" :rows="2" placeholder="请输入评审意见" v-model="cooProjectReview.opinion">
              </el-input>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="jbxxdivdescriptions" style="margin-bottom: 0.5rem;" title="附件" :column="2" border>
            <el-descriptions-item label="" labelStyle="width:0%;" contentStyle="width:100%;">
              <div class="elupload">
                <el-upload accept=".rar, .zip, .doc, .docx, .pdf, .jpg, .mp4" class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-success="uploadSuccess2"
                  :on-error="errorUpload" :show-file-list="false">
                  <el-button size="small" type="primary" style="float: left;" @click="addupload()">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg .mp4且不超过1024MB</div>
                </el-upload>
                <el-table :data="upfileData2" style="width: 100%" :border="true">
                  <el-table-column type="index" label="序号" width="50">
                  </el-table-column>

                  <el-table-column prop="fileName" label="附件">
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="dark" :content="scope.row.fileName" placement="top">
                        <span>{{scope.row.fileName}}</span>
                      </el-tooltip>
                    </template>
                  </el-table-column>

                  <el-table-column prop="createTime" label="上传时间">
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="dark" :content="scope.row.createTime" placement="top">
                        <el-input v-model="scope.row.createTime" disabled></el-input>
                      </el-tooltip>
                    </template>
                  </el-table-column>

                  <el-table-column prop="" label="操作" scope="scope" width="90">
                    <template slot-scope="scope">
                      <el-button type="danger" icon="el-icon-delete" circle size="small"
                        @click="deleteclick2(scope.row)">
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-descriptions-item>
          </el-descriptions>
          <div class="buttondiv" v-if="ruleForm.projectStatus == 3">
            <el-button style="margin-right: 1rem;">取消</el-button>
            <el-button type="primary" @click="cooProjectReviewclick()">提交</el-button>
          </div>
        </div>

        <el-button type="danger" style="margin-bottom: 20px;" v-if="ruleForm.projectStatus != 0" @click="cancel()">申请结项
        </el-button>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>
    <!-- 内容弹窗 -->
    <el-dialog title="资源查看弹窗" :visible.sync="dialogVisible" width="30%">
      <div class="block">
        <el-image fit="contain" :src="fileUrlxxx"></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加项目过程记录弹窗 -->
    <el-dialog :title="recorduptext" :visible.sync="dialogVisible1" width="50%">
      <el-form ref="form" :model="dialogVisible1form" label-width="200px"
        style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: flex-start;">
        <el-form-item label="项目阶段" style="width: 80%;">
          <el-input v-model="dialogVisible1form.projectPhase"></el-input>
        </el-form-item>
        <el-form-item label="具体内容" style="width: 80%;">
          <el-input v-model="dialogVisible1form.content"></el-input>
        </el-form-item>
        <el-form-item label="附件" style="width: 80%;">
          <el-upload accept=".rar, .zip, .doc, .docx, .pdf, .jpg, .mp4" class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-remove="beforeRemove1" multiple
            :limit="3" :on-success="onsuccess1" :file-list="fileList1">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg .mp4且不超过1024MB</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible1click">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 预算使用记录 -->
    <el-dialog :title="yusuangtitle" :visible.sync="dialogVisible2" width="50%">
      <el-form ref="form" :model="dialogVisible2form" label-width="200px"
        style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: flex-start;">
        <el-form-item label="经费用途" style="width: 80%;">
          <el-input v-model="dialogVisible2form.purpose"></el-input>
        </el-form-item>
        <el-form-item label="对方名称" style="width: 80%;">
          <el-input v-model="dialogVisible2form.purposeName"></el-input>
        </el-form-item>
        <el-form-item label="支出金额（元）" style="width: 80%;">
          <el-input-number style="float: left;" v-model="dialogVisible2form.amount" :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="附件" style="width: 80%;">
          <el-upload accept=".rar, .zip, .doc, .docx, .pdf, .jpg, .mp4" class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-remove="beforeRemove2" multiple
            :limit="3" :on-success="onsuccess2" :file-list="fileList2">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg .mp4且不超过1024MB</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible2click">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="填写终止原因" :visible.sync="canceldialogVisible" width="30%" :before-close="handleClose">
      <el-input type="textarea" :rows="2" placeholder="请输入终止原因" v-model="endReason">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceldialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="canceldialogVisibleclick()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="dialogVisible3.title" :visible.sync="dialogVisible3.dialogVisible" width="70%"
      :before-close="handleClose">
      <iframe class="msgbox" :src="dialogVisible3.url"></iframe>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible3.dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script src="../../assets/js/PDFObject.js"></script>
<script>
  import $ from 'jquery';
  export default {
    name: '',
    props: ['params'],
    data: function() {
      return {
        dialogVisible3: {
          dialogVisible: false,
          title: "",
          url: "",
        },
        endReason: '',
        canceldialogVisible: false,
        prohibit: false,
        djcyannstyle: true,
        // 保存项目专家评审验收
        cooProjectReview: {},
        // 项目过程，1=添加、2=编辑
        recordupstyle: 1,
        recorduptext: "添加项目过程记录",
        // 预算使用记录，1=添加、2=编辑
        yusuangstyle: 1,
        yusuangtitle: "添加预算使用记录",
        fileList1: [],
        dialogVisible: false,
        dialogVisible1: false,
        dialogVisible1form: {
          projectPhase: "",
          content: "",
          files: "",
          processId: ""
        },
        fileList2: [],
        dialogVisible2: false,
        dialogVisible2form: {
          // 项目预算使用ID
          budgetuseId: '',
          // 合作项目ID
          projectId: '',
          // 对方名称
          purposeName: '',
          // 经费用途
          purpose: '',
          // 支出金额
          amount: ''
        },
        fileListys: [],
        uploadSuccessID: "",
        uploadSuccessID2: "",
        activeIndex: '1',
        projectId: "",
        active: 1,
        // 报价
        offer: "",
        // 方案
        schemeDescription: "",
        ruleForm: {
          // 合作项目ID
          projectId: "",
          // 单位名称
          orgName: "",
          // 发起人用户ID
          userId: "",
          // 发起人姓名
          nickName: "",
          // 发起人手机号码
          phonenumber: "",
          // 项目名称
          projectName: "",
          // 项目编号
          projectNo: "",
          // 项目类型
          projectType: "",
          // projectTypeName
          projectTypeName: "",
          // 项目合作人数
          cooperationNum: "",
          // 允许参与报名人数
          allowSignupNum: "",
          // 邀请人
          inviteMembers: [],
          // 已报名人数
          signupNum: "",
          // 结项时间
          completeTime: "",
          // 项目经费(元)
          projectFunds: "",
          // 已使用经费(元)
          usedFunds: "",
          // 剩余经费(元)
          surplusFunds: "",
          // 项目介绍
          projectContent: "",
          // 项目附件
          projectFiles: "",
          // 项目状态（0草稿，1报名中，2合作中，3评审中，4已结项）projectStatus 发起合作 参与合作 确认合作 项目记录 申请结项 评审验收
          projectStatus: "",
          // 结项申请时间
          closeApplyTime: ""
        },
        // 用户信息
        userForm: {},
        // 资源列表
        fileData: [],
        upfileData: [],
        upfileData2: [],
        // 项目参与用户列表
        userData: [],
        // 过程记录列表
        processrecordData: [],
        // 预算使用记录列表
        yusuangshiyjiluData: [],
      }
    },
    methods: {
      canceldialogVisibleclick() {
        // 112.修改合作项目状态接口
        // projectStatus：状态（0草稿，1报名中，2合作中，3评审中，4已结项，5终止）
        let params = {
          "projectId": this.projectId,
          "projectStatus": "5",
          "endReason": this.endReason
        }
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/cooperation/cooProject/changeStatus',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: res.data.msg
            });
            this.canceldialogVisible = false;
            this.getcooProject();
          } else {
            this.canceldialogVisible = false;
            this.$message({
              offset: 100,
              showClose: true,
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 取消项目
      cancel() {
        this.canceldialogVisible = true;
      },
      // 确认合作
      confirm() {
        let sun = 0;
        // 获取当前确认报名的人员（如果等于0就提示不能确认报名）
        for (let i in this.userData) {
          // 已确认的用户signupStatus == 2
          if (this.userData[i].signupStatus == 2) {
            sun = sun + 1;
          }
        }
        if (sun == 0) {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'error',
            message: "请确认一个参与合作报名用户！"
          });
        } else {
          // 112.修改合作项目状态接口
          // projectStatus：状态（0草稿，1报名中，2合作中，3评审中，4已结项）
          let params = {
            "projectId": this.projectId,
            "projectStatus": "2"
          }
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/cooperation/cooProject/changeStatus',
            data: params,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'success',
                message: res.data.msg
              });
              this.getcooProject();
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'error',
                message: res.data.msg
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      // 获取项目基本信息
      getcooProject() {
        let _this = this;
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/cooperation/cooProject/' + _this.projectId,
          data: '',
        }).then((res) => {
          res.data.data.projectStatus = Number(res.data.data.projectStatus) + 1;
          _this.ruleForm = res.data.data;
          // 如果是自己的项目就不要点击参与按钮
          if (localStorage.getItem("userId") == _this.ruleForm.userId) {
            _this.djcyannstyle = false;
            _this.activeIndex = '1';
          } else {
            _this.djcyannstyle = true;
          }
          // 获取附件列表
          if(res.data.data.projectFiles){
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/getFileList/' + res.data.data.projectFiles,
              params: '',
            }).then((res) => {
              // 先清空
              _this.fileData = [];
              for (let i in res.data.data) {
                // 获取二级资源信息
                _this.fileData.push({
                  // 文件ID
                  fileId: res.data.data[i].fileId,
                  // 创建时间
                  createTime: res.data.data[i].createTime,
                  // 附件名称
                  chapterName: res.data.data[i].fileName.split(".")[0],
                  fileName: res.data.data[i].fileName,
                  // 文件地址
                  fileUrl: res.data.data[i].fileUrl,
                  nftId: res.data.data[i].nftId,
                })
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 获取用户信息
      getUserInfo() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.getItem("userId"),
          params: '',
        }).then((res) => {
          this.userForm = res.data.data;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 根据项目ID查询项目参与用户列表接口
      getcooProjectSignup() {
        let params = {
          projectId: this.projectId,
          status: 1
        }
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/cooperation/cooProjectSignup/list',
          params: params,
        }).then((res) => {
          this.userData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 根据项目ID查询项目过程记录列表接口
      getcooProjectProcess() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/cooperation/cooProjectProcess/list?projectId=' + this.projectId,
          params: '',
        }).then((res) => {
          this.processrecordData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 根据项目ID查询项目预算使用记录列表接口
      getcooProjectBudgetuse() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/cooperation/cooProjectBudgetuse/list?projectId=' + this.projectId,
          params: '',
        }).then((res) => {
          this.yusuangshiyjiluData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 上传点击事件
      addupload() {
        this.uploadSuccessID = ""
      },
      // 重新上传点击事件
      Reupload(e) {
        this.uploadSuccessID = e.fileId
      },
      // 删除点击事件
      deleteclick(e) {
        console.log(e.fileId);
        // 循环二级附件
        for (let i = 0; i < this.upfileData.length; i++) {
          for (var key in this.upfileData[i]) {
            // 用新ID替换旧ID
            if (this.upfileData[i][key] == e.fileId) {
              this.upfileData.splice(i, 1);
            }
          }
        }
      },
      // 专家验收评审附件删除点击事件
      deleteclick2(e) {
        // 循环二级附件
        for (let i = 0; i < this.upfileData2.length; i++) {
          for (var key in this.upfileData2[i]) {
            // 用新ID替换旧ID
            if (this.upfileData2[i][key] == e.fileId) {
              this.upfileData2.splice(i, 1);
            }
          }
        }
      },
      // 文件上传成功
      uploadSuccess(response, file, fileList) {
        // 判断是否为重新上传
        if (this.uploadSuccessID != "") {
          // 循环二级附件
          for (let i = 0; i < this.fileList.length; i++) {
            for (var key in this.fileList[i]) {
              // 用新ID替换旧ID
              if (this.fileList[i][key] == this.uploadSuccessID) {
                this.fileList[i].fileId = response.data.fileId
                this.fileList[i].fileName = response.data.fileName
              }
            }
          }
          // 清空ID
          this.uploadSuccessID == ""
        } else {
          // 获取二级资源信息
          this.upfileData.push({
            // 文件ID
            fileId: response.data.fileId,
            // 创建时间
            createTime: response.data.createTime,
            // 附件名称
            fileName: response.data.fileName,
            // 文件地址
            fileUrl: response.data.fileUrl,
          })
        }
      },
      // 文件上传成功
      uploadSuccess2(response, file, fileList) {
        // 获取资源信息
        this.upfileData2.push({
          // 文件ID
          fileId: response.data.fileId,
          // 创建时间
          createTime: response.data.createTime,
          // 附件名称
          fileName: response.data.fileName,
          // 文件地址
          fileUrl: response.data.fileUrl,
        })
      },
      // 文件上传失败
      errorUpload(response, file, fileList) {
        this.$message({
          offset: 100,
          showClose: true,
          message: response.msg,
          type: 'error'
        });
      },
      // 顶栏点击事件
      handleSelect(key, keyPath) {
        let _this = this;
        _this.activeIndex = key;
        // _this.ruleForm.projectStatus = key - 1;
        switch (key) {
          case "1":
            // 获取项目基本信息
            _this.getcooProject();
            // 获取用户信息
            _this.getUserInfo();
            break;
          case "2":
            // 参与报名点击
            _this.prohibit = false;
            // 获取项目基本信息
            _this.getcooProject();
            // 获取用户信息
            _this.getUserInfo();
            break;
          case "3":
            // 根据项目ID查询项目参与用户列表接口
            _this.getcooProjectSignup();
            break;
          case "4":
            // 根据项目ID查询项目过程记录列表接口
            _this.getcooProjectProcess();
            break;
          case "5":
            // 根据项目ID查询项目预算使用记录列表接口
            _this.getcooProjectBudgetuse();
            break;
          case "6":
            // 专家评审验收
            break;
          default:
            // 获取项目基本信息
            _this.getcooProject();
            // 获取用户信息
            _this.getUserInfo();
        }
      },
      // 参与报名&&保存为草稿
      cooProjectSignup(e) {
        let files = [];
        for (let i in this.upfileData) {
          files.push(this.upfileData[i].fileId);
        }
        let d = new Date();
        let curr_date = d.getDate();
        let curr_month = d.getMonth() + 1;
        let curr_year = d.getFullYear();
        String(curr_month).length < 2 ? (curr_month = "0" + curr_month) : curr_month;
        String(curr_date).length < 2 ? (curr_date = "0" + curr_date) : curr_date;
        let yyyyMMdd = curr_year + "-" + curr_month + "-" + curr_date;
        let params = {
          /** 合作项目ID */
          projectId: this.projectId,
          /** 报名用户ID */
          signupUserId: localStorage.getItem("userId"),
          /** 报名状态（0草稿，1待审核，2报名成功，3撤销报名，4报名失败） */
          signupStatus: e,
          /** 报名时间 */
          signupTime: yyyyMMdd,
          /** 报价(元) */
          offer: this.offer,
          /** 方案描述 */
          schemeDescription: this.schemeDescription,
          /** 附件 */
          files: files.join(",")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProjectSignup/',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: e == 0 ? '保存为草稿成功!' : '报名参与成功!'
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: 'success',
              offset: 100
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 文件下载
      filedownload(e) {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/files/' + e.fileId,
          params: '',
        }).then((res) => {
          let url = res.data.data.fileUrl;
          window.open(url);

          // 记录下载(整个资源做记录)
          let params = {
            "resId": e.fileId,
            "userId": localStorage.getItem("userId")
          }
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/resouse/resDownRecord/',
            data: params,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg + "已添加至下载记录！",
                type: 'success',
                offset: 100
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: 100
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        });
      },
      // 文件查看
      fileview(e) {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/files/' + e.fileId,
          params: '',
        }).then((res) => {
          if (res.data.code == 200) {
            // 判断资源类型为图片
            if (res.data.data.fileExtension == "jpeg" || res.data.data.fileExtension == "png" || res.data.data
              .fileExtension == "jpg" || res.data.data.fileExtension == "bmp") {
              this.fileUrlxxx = res.data.data.fileUrl;
              this.dialogVisible = true;
            }
            // 如果为文档类型
            else if (res.data.data.fileExtension == "xls" || res.data.data.fileExtension == "docx" || res.data
              .data.fileExtension == "doc" || res.data.data.fileExtension == "pdf" || res.data.data
              .fileExtension == "txt" || res.data.data.fileExtension == "ppd" || res.data.data.fileExtension ==
              'pptx' || res.data.data.fileExtension == 'PPTX') {
              // 判断环境
              var hostname = window.location.hostname; // 主机名，包括域名和端口（如果有）
              if (hostname != "localhost") { // 生产环境
                let fileUrl = "https://ow365.cn/?i=33860&ssl=1&furl=" + encodeURIComponent(res.data.data.fileUrl);
                this.dialogVisible3 = {
                  'dialogVisible': true,
                  'title': "文件详情",
                  'url': fileUrl
                }
                return false;
              } else { // 测试环境
                let fileUrl = "https://ow365.cn/?i=28236&ssl=0&furl=" + encodeURIComponent(res.data.data.fileUrl);
                this.dialogVisible3 = {
                  'dialogVisible': true,
                  'title': "文件详情",
                  'url': fileUrl
                }
                return false;
              }

            }
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              offset: 100
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 查看报名详情
      ckxqclick(e) {
        this.activeIndex = '2';
        this.prohibit = true;
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/cooperation/cooProjectSignup/' + e.projectSignupId,
          params: '',
        }).then((res) => {
          // 获取参与报名的信息
          // 参与合作人
          this.userForm.nickName = res.data.data.nickName ? res.data.data.nickName : '';
          // 单位名称
          this.userForm.orgName = res.data.data.orgName ? res.data.data.orgName : '';
          // 报价
          this.offer = res.data.data.offer ? res.data.data.offer : '';
          // 方案描述
          this.schemeDescription = res.data.data.schemeDescription ? res.data.data.schemeDescription : '';
        });
        // 根据附件ID获取附件
        if(e.files){
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/files/' + e.files,
            params: '',
          }).then((res) => {
            if (res.data.code == 200) {
              // 附件
              this.upfileData = [];
              if (res.data.data.length > 0) {
                for (let i in res.data.data) {
                  this.upfileData.push(res.data.data);
                  this.upfileData.chapterName = res.data.data.fileName;
                }
              } else {
                this.upfileData.push(res.data.data);
                for (let i in this.upfileData) {
                  this.upfileData[i].chapterName = res.data.data.fileName;
                }
              }
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }


      },
      // 确认合作点击事件
      cooperationclick(e) {
        let params = {
          // 项目报名ID
          "projectSignupId": e.projectSignupId,
          // 报名状态（0草稿，1待审核，2报名成功，3撤销报名，4报名失败）
          "signupStatus": e.signupStatus == 2 ? 3 : 2
        }
        this.$confirm(e.signupStatus == 2 ? '此操作将撤销合作此项目, 是否继续?' : '此操作将确认用户合作当前项目, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/cooperation/cooProjectSignup/changeStatus',
            data: params,
          }).then((res) => {
            this.getcooProjectSignup();
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: e.signupStatus == 2 ? '撤销合作成功!' : '确认合作成功!'
            });
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消合作'
          });
        });
      },
      // 添加项目过程记录
      addrecordclick() {
        this.dialogVisible1 = true;
        this.dialogVisible1form = {};
        this.recordupstyle = 1;
        this.recorduptext = "添加项目过程记录";
        this.fileList1 = [];
      },
      // 添加项目过程记录保存事件
      dialogVisible1click() {
        let fileId = [];
        for (let i in this.fileList1) {
          fileId.push(this.fileList1[i].fileId);
        }
        let params = {
          // 合作项目ID
          projectId: this.projectId,
          // 项目阶段
          projectPhase: this.dialogVisible1form.projectPhase,
          // 具体内容
          content: this.dialogVisible1form.content,
          // 过程记录ID
          processId: this.dialogVisible1form.processId,
          // 记录人
          nickName: this.userForm.nickName,
          processId: this.dialogVisible1form.processId,
          // 附件
          files: fileId.join(),
          userId: localStorage.getItem("userId"),
        }
        this.$axios({
          // 如果是1就添加、2是修改
          method: this.recordupstyle == 1 ? 'post' : 'put',
          url: this.$baseUrl.test + '/cooperation/cooProjectProcess',
          data: params,
        }).then((res) => {
          this.dialogVisible1 = false;
          this.getcooProjectProcess();
          this.fileList1 = [];
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 项目过程记录编辑点击事件
      editrecordclick(e) {
        this.recordupstyle = 2;
        this.dialogVisible1 = true;
        this.dialogVisible1form = e;
        this.recorduptext = "编辑项目过程记录";
        // 编辑之前清空附件
        this.fileList1 = [];
        // 查询当前过程记录下的附件
        if(e.files){
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/files/getFileList/' + e.files,
            params: '',
          }).then((res) => {
            for (let i in res.data.data) {
              // 获取过程附件
              this.fileList1.push({
                // 文件ID
                fileId: res.data.data[i].fileId,
                // 附件名称
                name: res.data.data[i].fileName,
                // 文件地址
                url: res.data.data[i].fileUrl,
              })
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }

      },
      // 项目过程记录删除点击事件
      removerecordclick(e) {
        this.$confirm('此操作将删除项目过程记录, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/cooperation/cooProjectProcess/' + e.processId,
            data: '',
          }).then((res) => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: '操作成功！'
            });
            this.getcooProjectProcess();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 删除项目预算使用记录
      removeyusuangclick(e) {
        this.$confirm('此操作将删除项目过程记录, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/cooperation/cooProjectBudgetuse/' + e.budgetuseId,
            data: '',
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'success',
                message: '操作成功！'
              });
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'error',
                message: res.data.msg
              });
            }

            this.getcooProjectBudgetuse();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      onsuccess1(files, fileList1) {
        this.fileList1.push({
          "name": files.data.fileName,
          "url": files.data.fileUrl,
          "fileId": files.data.fileId
        })
      },
      // 过程附件删除
      beforeRemove1(file, fileList1) {
        this.fileList1 = fileList1;
      },
      // 添加预算使用记录
      addyusuangclick() {
        this.dialogVisible2 = true;
        this.dialogVisible2form = {};
        this.yusuangstyle = 1;
        this.yusuangtitle = "添加预算使用记录";
        this.fileList2 = [];
      },
      // 编辑预算使用记录
      edityusuangclick(e) {
        this.dialogVisible2 = true;
        this.yusuangstyle = 2;
        this.yusuangtitle = "编辑预算使用记录";
        this.dialogVisible2form = e;
        this.fileList2 = e.fileList;
      },
      // 预算附件上传
      onsuccess2(files, fileList1) {
        this.fileList2.push({
          "fileName": files.data.fileName,
          "url": files.data.fileUrl,
          "fileId": files.data.fileId
        })
      },
      // 预算附件删除
      beforeRemove2(file, fileList1) {
        return this.$confirm(`确定移除 ${ file.name }？`);
        // return this.$confirm('确定移除' + file.name + '？', '温馨提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   for (let i in this.fileList1) {
        //     if (this.fileList1[i].name == file.name) {
        //       this.fileList1.splice(i, 1);
        //     }
        //   }
        // }).catch(() => {});
      },
      // 预算使用记录
      dialogVisible2click() {
        let fileId = [];
        for (let i in this.fileList2) {
          fileId.push(this.fileList2[i].fileId);
        }
        let params = {
          // 合作项目ID
          projectId: this.projectId,
          // 经费用途
          purpose: this.dialogVisible2form.purpose,
          // 对方名称
          purposeName: this.dialogVisible2form.purposeName,
          // 支出金额
          amount: this.dialogVisible2form.amount,
          // 过程记录ID
          budgetuseId: this.dialogVisible2form.budgetuseId,
          // 附件
          files: fileId.join(),
          userId: localStorage.getItem("userId"),
        }
        if (params.purpose == "" || params.purposeName == "" || params.amount == "") {
          this.$message({
            offset: 100,
            showClose: true,
            message: "请将信息填写完整！",
            type: 'error'
          });
        } else {
          this.$axios({
            // 如果是1就添加、2是修改
            method: this.yusuangstyle == 1 ? 'post' : 'put',
            url: this.$baseUrl.test + '/cooperation/cooProjectBudgetuse/',
            data: params,
          }).then((res) => {
            this.dialogVisible2 = false;
            // 根据项目ID查询项目预算使用记录列表接口
            this.getcooProjectBudgetuse();
            // 获取项目基本信息
            this.getcooProject();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      // 保存项目专家评审验收
      cooProjectReviewclick() {
        let fileId = [];
        for (let i in this.upfileData2) {
          fileId.push(this.upfileData2[i].fileId);
        }
        let params = {
          // 评审验收ID
          reviewId: "",
          // 合作项目ID
          projectId: this.projectId,
          // 评审专家ID
          expertId: localStorage.getItem("userId"),
          // 参与专家姓名
          expertNames: this.cooProjectReview.expertNames,
          // 评审时间
          reviewTime: this.cooProjectReview.reviewTime,
          // 评审结论（0待评审，1合格，2不合格）
          conclusion: this.cooProjectReview.conclusion,
          // 评审意见
          opinion: this.cooProjectReview.opinion,
          // 附件
          files: fileId.join()
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProjectReview',
          data: params,
        }).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$alert(
              '<h4>您的项目' + this.ruleForm.projectName + '已评审验收</h4><br><h5>您的项目已评审验收，请在工作台“我的项目”中再次编辑</h5>',
              '', {
                type: 'success',
                center: true,
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                callback: action => {
                  this.$message({
                    type: 'info',
                    message: `action: ${ action }`
                  });
                }
              });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }
    },
    // 页面加载完毕事件
    mounted() {
      let _this = this;
      // 获取跳转过来的项目ID
      _this.projectId = _this.params.projectId ? _this.params.projectId : "";
      // 1:基本信息、2:参与报名、3:参与合作信息、4:项目过程记录、5:预算使用记录、6:专家评审验收
      _this.activeIndex = _this.params.projectStatus ? _this.params.projectStatus + '' : "";
      if (this.projectId) {
        // 获取项目基本信息
        _this.getcooProject();
        // 获取用户信息
        _this.getUserInfo();
        // 根据项目ID查询项目参与用户列表接口
        _this.getcooProjectSignup();
        // 根据项目ID查询项目过程记录列表接口
        _this.getcooProjectProcess();
      } else {
        history.back();
      }

    }
  }
</script>

<style src="@/style/Cooperation/ProjectdetailsCom.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }

  .el-descriptions__header {
    margin-bottom: 0px !important;
  }
</style>
