<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>培训管理</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="项目名称:">
                <template>
                  <el-input v-model="tableDataval.projectName" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
                <el-button type="primary" @click="faqi">培训立项发布</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <div>
            <el-radio-group v-model="radiovalue" @change="onSubmit">
              <el-radio-button label="我的全部"></el-radio-button>
              <el-radio-button label="我的参与"></el-radio-button>
            </el-radio-group>
          </div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="projectNo" label="项目编号">
            </el-table-column>
            <el-table-column prop="projectName" label="项目名称">
            </el-table-column>
            <el-table-column prop="projectTypeName" label="项目类型">
            </el-table-column>
            <el-table-column prop="projectStatus" label="项目状态">
              <template slot-scope="scope">
                <span v-if="scope.row.projectStatus == 0">草稿</span>
                <span v-if="scope.row.projectStatus == 1">报名中</span>
                <span v-if="scope.row.projectStatus == 2">合作中</span>
                <span v-if="scope.row.projectStatus == 3">评审中</span>
                <span v-if="scope.row.projectStatus == 4">已结项</span>
                <span v-if="scope.row.projectStatus == 5">终止</span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间" width="160">
            </el-table-column>
            <el-table-column label="操作" width="210" fixed="right">
              <template slot-scope="scope">
                <el-button v-if="userId == scope.row.userId" type="text" size="small" style="color: #F56C6C;"
                  @click="deleteclick(scope.row)">删除
                </el-button>
                <el-button v-if="userId == scope.row.userId" type="text" size="small" style="color: #409EFF;"
                  @click="editclick(scope.row)">修改</el-button>
                <el-button v-if="userId == scope.row.userId" type="text" size="small" style="color: #409EFF;"
                  @click="addkcclick(scope.row)">添加课程
                </el-button>
                <el-button type="text" size="small" style="color: #409EFF;" @click="see(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        radiovalue: '我的全部',
        userId: localStorage.getItem("userId"),
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 项目名称
          'projectName': "",
          'userId': localStorage.getItem("userId")
        },
        // 总数
        total: 0,
        // 表格数据
        tableData: [],
      }
    },
    methods: {
      faqi() {
        this.$router.push('/launchEvent')
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        if (this.radiovalue == '我的全部') {
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/train/trainProject/getAllList?pageNum=' + this.tableDataval.pageNum +
              '&pageSize=' +
              this.tableDataval.pageSize,
            data: this.tableDataval,
          }).then((res) => {
            for (let i = 0; i < res.data.rows.length; i++) {
              for (var key in res.data.rows[i]) {
                if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                  res.data.rows[i][key] = ""
                }
              }
            }
            this.total = res.data.total;
            this.tableData = res.data.rows;
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        } else {
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/train/trainProject/getJoinList?pageNum=' + this.tableDataval.pageNum +
              '&pageSize=' +
              this.tableDataval.pageSize,
            data: this.tableDataval,
          }).then((res) => {
            for (let i = 0; i < res.data.rows.length; i++) {
              for (var key in res.data.rows[i]) {
                if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                  res.data.rows[i][key] = ""
                }
              }
            }
            this.total = res.data.total;
            this.tableData = res.data.rows;
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }

      },
      // 项目删除点击事件
      deleteclick(e) {
        this.$confirm('此操作将永久删除该删除培训申报, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/train/trainProject/' + e.projectId,
            params: '',
            //请求成功的回调函数
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'success',
                message: '删除成功!'
              });
              this.onSubmit();
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'error',
                message: res.data.msg
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      editclick(e) { // 修改
        let query = {
          "projectId": e.projectId
        }
        this.$router.push({
          path: 'trainingApplication',
          query: query
        })
      },
      addkcclick(e) { // 添加课程
        let query = {
          "projectId": e.projectId
        }
        this.$router.push({
          path: 'publishCourses',
          query: query
        })
      },
      see(e) {
        let query = {
          "projectId": e.projectId
        }
        this.$router.push({
          path: 'trainingNewsletter',
          query: query
        })
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit();
    }
  }
</script>

<style src="@/style/Cooperation/MyprojectCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
