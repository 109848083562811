<template>
  <div class="WriteanarticlexiangqingCom">
    <el-row :gutter="2">
      <el-col :span="24" class="maxdiv">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/Communication' }">
              门户</el-breadcrumb-item>
            <el-breadcrumb-item v-if="comArticle.type == 0">相关资讯</el-breadcrumb-item>
            <el-breadcrumb-item v-if="comArticle.type == 1">校企资源</el-breadcrumb-item>
            <el-breadcrumb-item v-if="comArticle.type == 2">养老人才</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 标题 -->
        <h2 style="text-align: center;margin-bottom: 1rem;">{{comArticle.articleTitle}}</h2>
        <p>发布时间：{{comArticle.createTime}}</p>
        <!-- 正文 -->
        <div class="ql-editor" style="padding: 0rem 2rem 2rem 2rem;" v-html="comArticle.articleContent"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import $ from 'jquery';
  import "quill/dist/quill.snow.css";
  export default {
    name: '',
    components: {},
    data: function() {
      return {
        comArticle: {},
        articleId: this.$route.query.xxarticleId ? this.$route.query.xxarticleId : localStorage.getItem(
          "xxarticleId"),
      }
    },
    // 页面加载完毕事件
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let url = "/system/doorArticle/" + this.articleId
        // 获取文章详情
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + url,
          params: '',
        }).then((res) => {
          this.comArticle = res.data.data
          loading.close();
          let paramsx = {
            "browseNum": Number(res.data.data.browseNum) + 1,
            "articleId": this.articleId,
            // "articleCoverUrl":res.data.data.articleCover ? 'http://blockedu.zctcloud.com/'+res.data.data.articleCover.split('/')[6] : '',
          }
          // 添加预览次数
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/system/doorArticle/',
            data: paramsx,
          }).then((res) => {});
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    }
  }
</script>

<style src="@/style/Team/WriteanarticlexiangqingCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    text-align: right;
  }

  img {
    max-width: 100%;
  }

  .userintimg {
    width: 3rem;
    height: 3rem;
  }

  /deep/ .ql-editor img {
    width: 100%;
  }
</style>
