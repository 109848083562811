<template>
  <div class="index">
    <el-row>
      <!-- 头部 -->
      <el-col :span="24">
        <Head />
      </el-col>
    </el-row>
    <!-- 须知 -->
    <el-row :gutter="20" class="subject" v-if="alertsun==0">
      <el-col :span="24">
        <NoticeCom :searchThing="Notice" @changeNotice="changeNotice" />
      </el-col>
    </el-row>
    <el-row :gutter="20" class="subject" v-if="alertsun==1">
      <!-- 导航栏 -->
      <el-col :span="4" class="bar">
        <Bar />
      </el-col>
      <!-- 内容 -->
      <el-col :span="20">
        <ExpertinformationCom :expertId="expertId" :xiayibu="xiayibu" :examine="examine" :zjandgr="zjandgr"/>
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem;z-index: -999;">
      <!-- 底部版权 -->
      <el-col :span="24">
        <Copyright />
      </el-col>
    </el-row>
  </div>
</template>


<script>
  // 底部版权
  import Copyright from '@/components/Copyright.vue'
  import Head from '@/components/Head.vue'
  import Bar from '@/components/Bar.vue'
  import ExpertinformationCom from '@/components/Expert/ExpertinformationCom.vue'
  import NoticeCom from '@/components/Expert/NoticeCom.vue'

  export default {
    name: 'app',
    components: {
      Copyright,
      Head,
      Bar,
      ExpertinformationCom,
      NoticeCom
    },

   data: function () {
      return {
        Notice: 0,
        // 专家id
        expertId: '',
        alertsun: 0,
        xiayibu:'',
        examine:'',
        zjandgr:''
      }
    },
    // 页面加载完毕触发
    mounted() {
      // 把指向vue对象保存给_this
      let _this = this;
      // 定义当前头部导航栏
      localStorage.setItem('activeIndex', "1");
      // 指定左侧导航栏
      localStorage.setItem('BaractiveIndex', "/expertapplication");
      // 如果是从专家个人信息、用户个人信息点击进入的（不需要须知，需要下一步）alertsun = 1;
      if(_this.$route.params.caidanlan){
        // 不需要须知
        _this.alertsun = 1;
        // 需要下一步
        _this.xiayibu = 0;
        // 不需要审核、返回
        _this.examine =  "no";
        // 个人:A、专家:B
        _this.zjandgr =  _this.$route.params.caidanlan == 'A' ? 1:2;
      }else{
        // 个人:A、专家:B
        _this.zjandgr =  _this.$route.params.caidanlan == 'A' ? 1:2;
      }

      // 如果是从审核专家列表点击进入的（不需要须知，不需要下一步）alertsun = 1;
      if(_this.$route.params.setid){
        // 不需要须知
        _this.alertsun = 1;
        // 不需要下一步
        _this.xiayibu = 0;
        // 需要审核、返回（expertId.seeandexamine 1、查看 2、审核）
        _this.examine =  _this.$route.params.expertId.seeandexamine;
      }

      // 如果是从专家申请进入的（需要须知，需要下一步）alertsun = 0;
      if(!_this.$route.params.caidanlan && !_this.$route.params.setid){
        // 需要须知
        _this.alertsun = 0;
        // 需要下一步
        _this.xiayibu = 1;
        // 不需要审核、返回
        _this.examine =  "no";
      }

      // 如果是从专家个人信息、用户个人信息点击进入的
      // if(_this.$route.params.alertsunxxx == "ABC"){
      //   _this.alertsunxxx = _this.$route.params.alertsunxxx;
      // }
      // 获取跳转过来传的值
      _this.Notice = _this.$route.params.setid ? _this.$route.params.setid : 0;
      // 获取专家expertId
      _this.expertId = _this.$route.params.expertId ? _this.$route.params.expertId : '';
      // // 2 成为专家之后不需要须知
      // if(!_this.expertId){
      //   _this.alertsun = 0;
      // }
    },
    methods: {
      // 获取子组件点击事件
      changeNotice(e) {
        this.alertsun = 1;
      }
    }
  }
</script>


<style src="@/style/Expert/Expertapplication.css" scoped></style>
<style src="@/style/main.css" scoped></style>
<style>
  .bar {
    padding-right: 0px;
    padding-left: 10px;
    padding-right: 0px !important;
    height: 100%;
  }
</style>
