<template>
  <div class="workbench">
    <el-row :gutter="20">
      <el-col :span="24" style="height: 14.5rem;padding-right: 0px !important;">
        <el-row style="background-color: #FFFFFF;height: 14.5rem;">
          <el-col :span="5">
            <img class="xunzhang" v-if="userData.unionLevel == 1" src="@/assets/lv1.jpg" />
            <img class="xunzhang" v-if="userData.unionLevel == 2" src="@/assets/lv2.jpg" />
            <img class="xunzhang" v-if="userData.unionLevel == 3" src="@/assets/lv3.jpg" />
            <img class="xunzhang" v-if="userData.unionLevel == 4" src="@/assets/lv4.jpg" />
            <img class="xunzhang" v-if="userData.unionLevel == 5" src="@/assets/lv5.jpg" />
            <img class="xunzhang" v-if="userData.unionLevel == 6" src="@/assets/lv6.jpg" />
          </el-col>
          <el-col :span="14" class="middle">
            <div class="middlediv">
              <div class="middleusername">{{userData.nickName}}</div>
              <span class="middleuserbrand" v-if="userData.isExpert == 1">联盟养老领域金牌专家</span>
              <span class="middleuserbrand" v-if="userData.userType == 1">机构管理员</span>
              <span class="middleuserbrand" v-if="userData.userType == 0">平台管理员-秘书长</span>
            </div>
            <div class="dengji">联盟等级：{{userData.unionLevel}} &#12288;|&#12288; 联盟积分：{{userData.unionCoin}}</div>
            <!-- 进度条 -->
            <div class="progress">
              <el-progress :percentage="userData.unionLevel==1?100:0" color="#3bc1fe"></el-progress>
              <el-progress :percentage="userData.unionLevel==2?100:0" color="#3bc1fe"></el-progress>
              <el-progress :percentage="userData.unionLevel==3?100:0" color="#3bc1fe"></el-progress>
              <el-progress :percentage="userData.unionLevel==4?100:0" color="#3bc1fe"></el-progress>
              <el-progress :percentage="userData.unionLevel==5?100:0" color="#3bc1fe"></el-progress>
              <el-progress :percentage="userData.unionLevel==6?100:0" color="#3bc1fe"></el-progress>
              <span v-if="userData.unionLevel==1">100 / 600</span>
              <span v-if="userData.unionLevel==2">200 / 600</span>
              <span v-if="userData.unionLevel==3">300 / 600</span>
              <span v-if="userData.unionLevel==4">400 / 600</span>
              <span v-if="userData.unionLevel==5">500 / 600</span>
              <span v-if="userData.unionLevel==6">600 / 600</span>
            </div>
            <div class="progress">
              <span class="progressspan">V1</span>
              <span class="progressspan">V2</span>
              <span class="progressspan">V3</span>
              <span class="progressspan">V4</span>
              <span class="progressspan">V5</span>
              <span class="progressspan">V6</span>
            </div>
          </el-col>
          <el-col :span="5" style="min-width: 129px;min-height: 87px;">
            <img class="leftimg" src="@/assets/u2100.png" v-if="userData.isExpert == 1" />
            <div style="margin: 2rem 0rem;width: 129px;height: 87px;" v-else></div>
          </el-col>
          <el-col :span="5" class="Unlocktext">升级解锁权益</el-col>
          <el-col :span="12">
            <el-row style="background-color: #FFFFFF;">
              <el-col :span="4" :class="userData.unionLevel >= 0 ? 'libaoimg' : 'libaoimg1'">
                <el-badge value="V1" class="item libaoimgitem" v-if="userData.unionLevel >= 0">
                  <img v-if="userData.unionLevel >= 0" src="@/assets/icon/xs.svg" />
                  <img v-else src="@/assets/icon/bxs.svg" />
                </el-badge>
                <div>数据总览</div>
              </el-col>
              <el-col :span="4" :class="userData.unionLevel >= 2 ? 'libaoimg' : 'libaoimg1'">
                <el-badge value="V2" class="item libaoimgitem" v-if="userData.unionLevel <= 2">
                  <img v-if="userData.unionLevel >= 2" src="@/assets/icon/xs.svg" />
                  <img v-else src="@/assets/icon/bxs.svg" />
                </el-badge>
                <div>专家入驻</div>
              </el-col>
              <el-col :span="4" :class="userData.unionLevel >= 3 ? 'libaoimg' : 'libaoimg1'">
                <el-badge value="V3" class="item libaoimgitem" v-if="userData.unionLevel <= 3">
                  <img v-if="userData.unionLevel >= 3" src="@/assets/icon/xs.svg" />
                  <img v-else src="@/assets/icon/bxs.svg" />
                </el-badge>
                <div>内容推荐</div>
              </el-col>
              <el-col :span="4" :class="userData.unionLevel >= 4 ? 'libaoimg' : 'libaoimg1'">
                <el-badge value="V4" class="item libaoimgitem" v-if="userData.unionLevel <= 4">
                  <img v-if="userData.unionLevel >= 4" src="@/assets/icon/xs.svg" />
                  <img v-else src="@/assets/icon/bxs.svg" />
                </el-badge>
                <div>个人推荐</div>
              </el-col>
              <el-col :span="4" :class="userData.unionLevel >= 5 ? 'libaoimg' : 'libaoimg1'">
                <el-badge value="V5" class="item libaoimgitem" v-if="userData.unionLevel <= 5">
                  <img v-if="userData.unionLevel >= 5" src="@/assets/icon/xs.svg" />
                  <img v-else src="@/assets/icon/bxs.svg" />
                </el-badge>
                <div>版权服务</div>
              </el-col>
              <el-col :span="4" :class="userData.unionLevel >= 6 ? 'libaoimg' : 'libaoimg1'">
                <el-badge value="V6" class="item libaoimgitem" v-if="userData.unionLevel <= 6">
                  <img v-if="userData.unionLevel >= 6" src="@/assets/icon/xs.svg" />
                  <img v-else src="@/assets/icon/bxs.svg" />
                </el-badge>
                <div>个人专栏</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>

      <!-- <el-col :span="6" style="background-color: #FFFFFF;">
        <div class="leftdiv">
          <div class="leftdivtop">
            <div class="leftdivtoptitle">公告栏</div>
            <div class="leftdivtoptitleleft">更多 <i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="leftdivtopnrdiv">
            <div class="leftdivtopnr">
              <span>07-12</span>&#12288;
              <span>团队协作模块上线，请登录平台</span>
            </div>
            <div class="leftdivtopnr">
              <span>07-12</span>&#12288;
              <span>团队协作模块上线，请登录平台</span>
            </div>
            <div class="leftdivtopnr">
              <span>07-12</span>&#12288;
              <span>团队协作模块上线，请登录平台</span>
            </div>
            <div class="leftdivtopnr">
              <span>07-12</span>&#12288;
              <span>团队协作模块上线，请登录平台</span>
            </div>
            <div class="leftdivtopnr">
              <span>07-12</span>&#12288;
              <span>团队协作模块上线，请登录平台</span>
            </div>
            <div class="leftdivtopnr">
              <span>07-12</span>&#12288;
              <span>团队协作模块上线，请登录平台</span>
            </div>
            <div class="leftdivtopnr">
              <span>07-12</span>&#12288;
              <span>团队协作模块上线，请登录平台</span>
            </div>
            <div class="leftdivtopnr">
              <span>07-12</span>&#12288;
              <span>团队协作模块上线，请登录平台</span>
            </div>
          </div>
        </div>
      </el-col>
    -->
    </el-row>

    <el-row :gutter="20">

      <!-- 中间栏目 -->
      <el-col :span="18">

        <el-row>
          <!-- <el-col :span="24" v-if="userData.isExpert == 1 || userData.isOrgAdm == 0">
            <div class="zho">
              <div class="zhotitletop">
                <div class="leftdivtoptitle">资源共享任务</div>
                <div class="leftdivtoptitleleft">更多 <i class="el-icon-arrow-right"></i></div>
              </div>
              <el-row class="zhonr" style="min-height: 14.45rem;">
                <el-col :span="8" class="zhonrwaidiv">
                  <div class="zhonrdiv">
                    <div class="zhonrimgdiv">
                      <img src="@/@/assets/u2198.svg" />
                    </div>
                    <span class="zhonrdivspan">养老领域培训教程</span>
                  </div>
                  <div class="zhonrdivtextdiv">
                    <div class="zhonrdivtext">共享养老领域培训教程，资源类型为视频格式。</div>
                  </div>
                  <div class="zhonrdivdibu">
                    <div class="zhonrdivdibuleft">奖励联盟积分：<span style="color: red;">1200</span></div>
                    <div class="zhonrdivdibuzuot">179人参与</div>
                  </div>
                </el-col>
                <el-col :span="8" class="zhonrwaidiv">
                  <div class="zhonrdiv">
                    <div class="zhonrimgdiv">
                      <img src="@/@/assets/u2198.svg" />
                    </div>
                    <span class="zhonrdivspan">养老领域培训教程</span>
                  </div>
                  <div class="zhonrdivtextdiv">
                    <div class="zhonrdivtext">共享养老领域培训教程，资源类型为视频格式。</div>
                  </div>
                  <div class="zhonrdivdibu">
                    <div class="zhonrdivdibuleft">奖励联盟积分：<span style="color: red;">1200</span></div>
                    <div class="zhonrdivdibuzuot">179人参与</div>
                  </div>
                </el-col>
                <el-col :span="8" class="zhonrwaidiv">
                  <div class="zhonrdiv">
                    <div class="zhonrimgdiv">
                      <img src="@/@/assets/u2198.svg" />
                    </div>
                    <span class="zhonrdivspan">养老领域培训教程</span>
                  </div>
                  <div class="zhonrdivtextdiv">
                    <div class="zhonrdivtext">共享养老领域培训教程，资源类型为视频格式。</div>
                  </div>
                  <div class="zhonrdivdibu">
                    <div class="zhonrdivdibuleft">奖励联盟积分：<span style="color: red;">1200</span></div>
                    <div class="zhonrdivdibuzuot">179人参与</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
 -->
          <!-- <el-col :span="24" v-if="userData.isExpert == 1 || userData.isOrgAdm == 1 || userData.userType == 0">
            <div class="zho">
              <div class="zhotitletop">
                <div class="leftdivtoptitle">资源共享任务</div>
                <div class="leftdivtoptitleleft">更多 <i class="el-icon-arrow-right"></i></div>
              </div>
              <el-row class="zhonr" style="min-height: 14.45rem;">
                <el-col :span="8" class="zhonrwaidiv">
                  <div class="zhonrdiv">
                    <div class="zhonrimgdiv">
                      <img src="@/@/assets/u2198.svg" />
                    </div>
                    <span class="zhonrdivspan">养老领域培训教程</span>
                  </div>
                  <div class="zhonrdivtextdiv">
                    <div class="zhonrdivtext">共享养老领域培训教程，资源类型为视频格式。</div>
                  </div>
                  <div class="zhonrdivdibu">
                    <div class="zhonrdivdibuleft">奖励联盟积分：<span style="color: red;">1200</span></div>
                    <div class="zhonrdivdibuzuot">179人参与</div>
                  </div>
                </el-col>
                <el-col :span="8" class="zhonrwaidiv">
                  <div class="zhonrdiv">
                    <div class="zhonrimgdiv">
                      <img src="@/@/assets/u2198.svg" />
                    </div>
                    <span class="zhonrdivspan">养老领域培训教程</span>
                  </div>
                  <div class="zhonrdivtextdiv">
                    <div class="zhonrdivtext">共享养老领域培训教程，资源类型为视频格式。</div>
                  </div>
                  <div class="zhonrdivdibu">
                    <div class="zhonrdivdibuleft">奖励联盟积分：<span style="color: red;">1200</span></div>
                    <div class="zhonrdivdibuzuot">179人参与</div>
                  </div>
                </el-col>
                <el-col :span="8" class="zhonrwaidiv">
                  <div class="zhonrdiv">
                    <div class="zhonrimgdiv">
                      <img src="@/@/assets/u2198.svg" />
                    </div>
                    <span class="zhonrdivspan">养老领域培训教程</span>
                  </div>
                  <div class="zhonrdivtextdiv">
                    <div class="zhonrdivtext">共享养老领域培训教程，资源类型为视频格式。</div>
                  </div>
                  <div class="zhonrdivdibu">
                    <div class="zhonrdivdibuleft">奖励联盟积分：<span style="color: red;">1200</span></div>
                    <div class="zhonrdivdibuzuot">179人参与</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col> -->

          <el-col :span="24" style="padding-right: 0px;min-height: 18.45rem;background-color: #fff;"
            v-if="userData.isExpert == 0 || userData.isOrgAdm == 0 || userData.userType == 0">
            <div class="data_screening">
              <div class="data_screeningzho">
                <div class="data_screeningzhotitle">
                  数据总览
                  <span class="data_screeningleftdivtoptitle">每天12点左右更新前一日数据</span>
                </div>
              </div>
              <div class="zolan">
                <el-row :gutter="20">
                  <el-col :span="4" class="zolanlist">
                    <div class="zolanlistnr">
                      <div class="zolanlistnrtitle">资源共享数量</div>
                      <div class="zolanlistnrsun">{{zygxs}}</div>
                    </div>
                  </el-col>
                  <el-col :span="4" class="zolanlist">
                    <div class="zolanlistnr">
                      <div class="zolanlistnrtitle">协同合作项目数量</div>
                      <div class="zolanlistnrsun">{{xthzxms}}</div>
                    </div>
                  </el-col>
                  <el-col :span="4" class="zolanlist">
                    <div class="zolanlistnr">
                      <div class="zolanlistnrtitle">资源审核数量</div>
                      <div class="zolanlistnrsun">{{zyshs}}</div>
                    </div>
                  </el-col>
                  <el-col :span="4" class="zolanlist">
                    <div class="zolanlistnr">
                      <div class="zolanlistnrtitle">项目评审/指导数量</div>
                      <div class="zolanlistnrsun">{{xmpszds}}</div>
                    </div>
                  </el-col>
                  <el-col :span="4" class="zolanlist">
                    <div class="zolanlistnr">
                      <div class="zolanlistnrtitle">发布文章数量</div>
                      <div class="zolanlistnrsun">{{fbwzs}}</div>
                    </div>
                  </el-col>
                  <el-col :span="4" class="zolanlist">
                    <div class="zolanlistnr">
                      <div class="zolanlistnrtitle">参与答疑数量</div>
                      <div class="zolanlistnrsun">{{cydys}}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>


        </el-row>
      </el-col>

      <!-- 右侧栏目 -->
      <el-col :span="6">
        <!-- <el-row :gutter="20" style="margin-right: -10px !important;"
          v-if="userData.isExpert == 1 || userData.isOrgAdm == 1 || userData.userType == 0 || userData.userType == 1">
          <div class="leftdiv2" style="height: 19.75rem;">
            <div class="leftdivtop">
              <div class="leftdivtoptitle">专家任务栏</div>
              <div class="leftdivtoptitleleft">更多 <i class="el-icon-arrow-right"></i></div>
            </div>
            <div class="leftdivtopnrdiv">
              <div class="leftdivtopnr">
                <span>07-12</span>&#12288;
                <span>养老机构运营工作人员教学视频</span>
              </div>
              <div class="leftdivtopnr">
                <span>07-12</span>&#12288;
                <span>养老机构运营工作人员教学视频</span>
              </div>
              <div class="leftdivtopnr">
                <span>07-12</span>&#12288;
                <span>养老机构运营工作人员教学视频</span>
              </div>
              <div class="leftdivtopnr">
                <span>07-12</span>&#12288;
                <span>养老机构运营工作人员教学视频</span>
              </div>
              <div class="leftdivtopnr">
                <span>07-12</span>&#12288;
                <span>养老机构运营工作人员教学视频</span>
              </div>
              <div class="leftdivtopnr">
                <span>07-12</span>&#12288;
                <span>养老机构运营工作人员教学视频</span>
              </div>
              <div class="leftdivtopnr">
                <span>07-12</span>&#12288;
                <span>养老机构运营工作人员教学视频</span>
              </div>
              <div class="leftdivtopnr">
                <span>07-12</span>&#12288;
                <span>养老机构运营工作人员教学视频</span>
              </div>
            </div>
          </div>
        </el-row>
        -->
        <el-row :gutter="20" style="margin-right: -10px !important;">
          <div class="leftdiv2">
            <!-- 头部 -->
            <div class="leftdivtop">
              <div class="leftdivtoptitle">联盟成员</div>
              <!-- <div class="leftdivtoptitleleft">更多 <i class="el-icon-arrow-right"></i></div> -->
            </div>
            <!-- 内容 -->
            <div class="leftdivtopnrdiv">
              <div class="leftdivtopnr" v-for="itme in getOrgList">
                <span>{{itme.orgName}}</span>&#12288;&#12288;
                <span v-if="itme.orgLevel == 0">常务理事长单位</span>
                <span v-if="itme.orgLevel == 1">常务理事单位</span>
                <span v-if="itme.orgLevel == 2">理事单位</span>
              </div>
            </div>
          </div>
        </el-row>
      </el-col>

      <!-- 底部 -->
      <!-- <el-col :span="24" v-if="userData.isExpert == 1 || userData.isOrgAdm == 0" style="padding-right: 0px;">
        <div class="data_screening">
          <div class="data_screeningzho">
            <div class="data_screeningzhotitle">
              数据总览
              <span class="data_screeningleftdivtoptitle">每天12点左右更新前一日数据</span>
            </div>
          </div>
          <div class="zolan">
            <el-row :gutter="20">
              <el-col :span="4" class="zolanlist">
                <div class="zolanlistnr">
                  <div class="zolanlistnrtitle">资源共享数量</div>
                  <div class="zolanlistnrsun">{{zygxs}}</div>
                </div>
              </el-col>
              <el-col :span="4" class="zolanlist">
                <div class="zolanlistnr">
                  <div class="zolanlistnrtitle">协同合作项目数量</div>
                  <div class="zolanlistnrsun">{{xthzxms}}</div>
                </div>
              </el-col>
              <el-col :span="4" class="zolanlist">
                <div class="zolanlistnr">
                  <div class="zolanlistnrtitle">资源审核数量</div>
                  <div class="zolanlistnrsun">{{zyshs}}</div>
                </div>
              </el-col>
              <el-col :span="4" class="zolanlist">
                <div class="zolanlistnr">
                  <div class="zolanlistnrtitle">项目评审/指导数量</div>
                  <div class="zolanlistnrsun">{{xmpszds}}</div>
                </div>
              </el-col>
              <el-col :span="4" class="zolanlist">
                <div class="zolanlistnr">
                  <div class="zolanlistnrtitle">发布文章数量</div>
                  <div class="zolanlistnrsun">{{fbwzs}}</div>
                </div>
              </el-col>
              <el-col :span="4" class="zolanlist">
                <div class="zolanlistnr">
                  <div class="zolanlistnrtitle">参与答疑数量</div>
                  <div class="zolanlistnrsun">{{cydys}}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        getOrgList: [],
        userData: {},
        /** 资源共享数量 */
        zygxs: 0,
        /** 协同合作项目数 */
        xthzxms: 0,
        /** 资源审核数量 */
        zyshs: 0,
        /** 项目评审/指导数 */
        xmpszds: 0,
        /** 发布文章数 */
        fbwzs: 0,
        /** 参与答疑数 */
        cydys: 0
      }
    },
    // 页面加载完毕触发
    mounted() {
      let _this = this;
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.userId,
        data: '',
      }).then((res) => {
        if (res.data.code == 200) {
          this.userData = res.data.data;
          // 根据用户头像ID获取用户头像地址
          if(this.userData.avatar){
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + this.userData.avatar,
              data: '',
            }).then((res) => {
              // 获取头像地址
              for (var key in res.data.data) {
                if (res.data.data[key] == null || res.data.data[key] == "null") {
                  res.data.data[key] = ""
                }
              }
              let userimg = res.data.data.fileUrl
              let userImageUrl = {
                "userImageUrl": userimg
              }
              _this.userData.userimg = userimg
              _this.userData.userImageUrl = userImageUrl
            }).catch(error => {
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error',
            offset: 100
          });
        }
      }).catch(error => {
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取数据总览统计数据
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/pubilc/getSysData/' + localStorage.userId,
        params: '',
      }).then((res) => {
        /** 资源共享数量 */
        _this.zygxs = res.data.data.zygxs;
        /** 协同合作项目数 */
        _this.xthzxms = res.data.data.xthzxms;
        /** 资源审核数量 */
        _this.zyshs = res.data.data.zyshs;
        /** 项目评审/指导数 */
        _this.xmpszds = res.data.data.xmpszds;
        /** 发布文章数 */
        _this.fbwzs = res.data.data.fbwzs;
        /** 参与答疑数 */
        _this.cydys = res.data.data.cydys;
      }).catch(error => {
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      let ss = {
        status: "2"
      }
      // 获取联盟成员列表
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/org/getOrgList/?pageNum=1&pageSize=10',
        data: ss,
      }).then((res) => {
        _this.getOrgList = res.data.rows;
      }).catch(error => {
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
    },
    methods: {

    }
  }
</script>

<style src="@/style/workbench.css" scoped></style>
<style src="@/style/main.css" scoped></style>
<style>
  * {
    padding: 0rem;
  }

  .el-progress__text {
    display: none !important;
  }

  .el-progress-bar {
    padding-right: 0px !important;
  }

  .libaoimgitem .el-badge__content.is-fixed {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: #cacaca;
    transform: translateY(-50%) translateX(100%);
  }

  ::-webkit-scrollbar {
    display: none
  }
</style>
