<template>
  <div class="Head">
    <div class="list1">
      养老/家政领域教学协作共同体区块链平台
      |
      <span>{{str}}</span>
    </div>
    <div class="list3">
      已有账号？<router-link to='/Home'><span>立即登陆</span></router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: { //props列表
      // 父组件传过来的值需要使用str来在页面调用
      str: String,
    },
   data: function () {
      return {
        usertype: ''
      }
    },
    // 页面加载完毕触发
    mounted() {
      // 把指向vue对象保存给_this
      let _this = this;
    },
    methods: {}
  }
</script>

<style src="@/style/register/RegisterComHead.css" scoped></style>
<style scoped>
  .el-menu.el-menu--horizontal {
    border-bottom: solid 0px #e6e6e6 !important;
  }
  ::-webkit-scrollbar {
    display: none
  }
  .el-form-item__label {
   text-align: right;
  }
</style>
