<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>人才互聘管理</el-breadcrumb-item>
            <el-breadcrumb-item>我的收藏</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="projectNo" label="职位名称">
            </el-table-column>
            <el-table-column prop="projectName" label="公司名称" width="200">
            </el-table-column>
            <el-table-column prop="createTime" label="月薪" width="160">
            </el-table-column>
            <el-table-column prop="createTime" label="工作地点" width="160">
            </el-table-column>
            <el-table-column label="操作" width="160" fixed="right">

            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 为用户ID
          'userId': localStorage.getItem("userId"),
          // 项目名称
          'projectName': "",
          // 项目状态
          'projectStatus': "",
        },
        // 总数
        total: 0,
        tableData: []
      }
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        let data = {
          "collectUserId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/recruit/recruitInfo/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: data,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit();
    }
  }
</script>

<style src="@/style/Team/ContentmanagementCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
