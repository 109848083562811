import { render, staticRenderFns } from "./Resumemanagement.vue?vue&type=template&id=4f7ef0bc&scoped=true&"
import script from "./Resumemanagement.vue?vue&type=script&lang=js&"
export * from "./Resumemanagement.vue?vue&type=script&lang=js&"
import style0 from "@/style/Team/DeliveryrecordCom.css?vue&type=style&index=0&id=4f7ef0bc&scoped=true&lang=css&"
import style1 from "@/style/main.css?vue&type=style&index=1&id=4f7ef0bc&scoped=true&lang=css&"
import style2 from "./Resumemanagement.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f7ef0bc",
  null
  
)

export default component.exports