import { render, staticRenderFns } from "./Startcooperation.vue?vue&type=template&id=54f6bc14&scoped=true&"
import script from "./Startcooperation.vue?vue&type=script&lang=js&"
export * from "./Startcooperation.vue?vue&type=script&lang=js&"
import style0 from "@/style/Cooperation/Startcooperation.css?vue&type=style&index=0&id=54f6bc14&scoped=true&lang=css&"
import style1 from "@/style/main.css?vue&type=style&index=1&id=54f6bc14&scoped=true&lang=css&"
import style2 from "./Startcooperation.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f6bc14",
  null
  
)

export default component.exports