<template>
  <div>
    <el-row :gutter="24" class="MechanismCom">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb" style="border: 0px;margin-bottom: 1rem;margin-top: 1rem;">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>联盟专家</el-breadcrumb-item>
            <el-breadcrumb-item>联盟专家搜索</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>

      <!-- 标题&搜索框&按钮 -->
      <el-col :span="24">
        <div class="topdivss">
          <div class="topdivsstitle">联盟专家搜索</div>
          <div class="topdivssinput">
            <el-input placeholder="请输入内容" v-model="tableDataval.nickName" class="input-with-select halltopssinput"
              clearable>
              <el-button slot="append" class="halltopssann" @click="searchclick()">搜索</el-button>
            </el-input>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 搜索条件 -->
    <el-row :gutter="24" class="MechanismCom">
      <el-col :span="24">
        <div class="grid-content bg-purple" style="height: 15rem;">
          <!-- 第一排 -->
          <el-row class="diyipai" style="padding: 2rem 2rem 0rem 2rem !important;">
            <el-col :span="4">
              <div class="titlenrdiv">
                <div class="nrtitle">专业类别:&#12288; </div>
                <el-radio-group v-model="tableDataval.subjectId" @change="searchclick">
                  <el-radio fill="#000" size="mini" label="" border>不限</el-radio>
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="20" style="width: auto;">
              <el-radio-group v-model="tableDataval.subjectId" @change="searchclick">
                <div class="nrlist">
                  <div v-for="itme in subjecttype">
                    <el-radio fill="#000" size="mini" :label="itme.value" border>{{itme.label}}</el-radio>
                  </div>
                </div>
              </el-radio-group>
            </el-col>
          </el-row>
          <!-- 第二排 -->
          <el-row class="diyipai" style="padding: 1rem 2rem 0rem 2rem !important;">
            <el-col :span="4">
              <div class="titlenrdiv">
                <div class="nrtitle">细分领域:&#12288; </div>
                <el-radio-group v-model="tableDataval.orgType" @change="searchclick">
                  <el-radio fill="#000" size="mini" label="" border>不限</el-radio>
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="20" style="width: auto;">
              <el-radio-group v-model="tableDataval.orgType" @change="searchclick">
                <div class="nrlist">
                  <div>
                    <el-radio fill="#000" size="mini" label="0" border>高校及事业机构</el-radio>
                  </div>
                  <div>
                    <el-radio fill="#000" size="mini" label="1" border>企业机构</el-radio>
                  </div>
                </div>
              </el-radio-group>
            </el-col>
          </el-row>

          <!-- 第三排 -->
          <el-row class="diyipai" style="padding: 1rem 2rem 0rem 2rem !important;">
            <el-col :span="4">
              <div class="titlenrdiv">
                <div class="nrtitle">擅长领域:&#12288; </div>
                <el-radio-group v-model="tableDataval.subjectTypeId" @change="searchclick">
                  <el-radio fill="#000" size="mini" label="" border>不限
                  </el-radio>
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="20">
              <el-radio-group v-model="tableDataval.subjectTypeId" @change="searchclick" style="width: 100%;">
                <div class="nrlist">
                  <div v-for="itme in options2">
                    <el-radio fill="#000" size="mini" :label="itme.value" border>{{itme.label}}
                    </el-radio>
                  </div>
                </div>
              </el-radio-group>
            </el-col>
          </el-row>

          <!-- 第四排 -->
          <el-row class="diyipai" style="padding: 1rem 2rem 2rem 2rem!important">
            <el-col :span="4">
              <div class="titlenrdiv">
                <div class="nrtitle">专家级别:&#12288; </div>
                <el-radio-group v-model="tableDataval.expertGrade" @change="searchclick">
                  <el-radio fill="#000" size="mini" label="" border>不限</el-radio>
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="20">
              <el-radio-group v-model="tableDataval.expertGrade" @change="searchclick" style="width: 100%;">
                <div class="nrlist">
                  <div>
                    <el-radio fill="#000" size="mini" label="3" border>金牌专家
                    </el-radio>
                  </div>
                  <div>
                    <el-radio fill="#000" size="mini" label="2" border>银牌专家
                    </el-radio>
                  </div>
                  <div>
                    <el-radio fill="#000" size="mini" label="1" border>普通专家
                    </el-radio>
                  </div>
                  <div>
                    <el-radio fill="#000" size="mini" label="0" border>不合格专家
                    </el-radio>
                  </div>
                </div>
              </el-radio-group>
            </el-col>
          </el-row>

        </div>
      </el-col>
    </el-row>

    <!-- 表格 -->
    <el-row :gutter="24" class="MechanismCom" style="background-color: #00000000;">
      <el-col :span="isExpert == 1 ? 18 : 24" style="background-color: #FFFFFF;margin-right: 0.5%;">
        <div class="grid-content bg-purple">
          <div class="zuoxianr">找到 {{total}} 位专家</div>
          <!-- 第一条数据 -->
          <el-row v-for="itme in formdata" style="border-bottom: 0.0625rem solid #dcdfe5;margin-bottom: 1rem;">
            <!-- 头像部分 -->
            <el-col :span="4">
              <el-image style="width: 150px; height: 200px" :src="itme.avatar" fit="cover"></el-image>
            </el-col>
            <!-- 文字部分 -->
            <el-col :span="16">
              <div style="text-align: left;margin-top: 1rem;">
                <span class="nickNamestyle">{{itme.nickName}}</span>
                <span>{{itme.subjectName}} 领域 {{itme.subjectTypeName}}</span>
              </div>
              <!-- 简介 -->
              <div class="jjstyle">
                简介:{{itme.remark}}
              </div>
            </el-col>
            <!-- 关注查看详情部分 -->
            <el-col :span="4">
              <div>
                <!-- 已关注 -->
                <i class="el-icon-user-solid" v-if="itme.isFollow == 1" style="cursor:pointer;color: #2d7eef;"
                  @click="Guangzhou(itme)">已关注</i>
                <!-- 未关注 -->
                <i class="el-icon-user" v-if="itme.isFollow == 0" style="cursor:pointer;"
                  @click="Guangzhou(itme)">关注</i>
              </div>
              <div>
                <el-button style="margin-top: 1rem;" type="primary" size="small" @click="detailsclick(itme.expertId,itme.userId)">查看详情</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 分页 -->
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pagination.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </el-col>
      <el-col :span="6" style="background-color: #FFFFFF;width: 24.5%;" v-if="isExpert == 1">
        <div class="leftdiv">
          <div class="topnrdiv" style="display: flex;justify-content: center;">
            <div class="topleftimg">
              <img src="../../assets/jp.png" style="width: 3rem;margin-top: 1rem;margin-right: 2rem;" />
            </div>
            <div>
              <p style="text-align: left;width: 15rem;font-family: 'Arial Normal', 'Arial';font-weight: 400;font-style: normal;font-size: 1.125rem;">
                {{thisnickName}}
              </p>
              <p
                style="text-align: left;width: 15rem;font-family: 'Arial Normal', 'Arial';font-weight: 400;font-style: normal;font-size: 0.875rem;color: #7F7F7F;">
                {{thisprofessional}}{{thisexpertGrade}}
              </p>
            </div>
          </div>
          <div class="letfann">
            <div class="letfanndiv">
              <div class="letfanntext">关注专家</div>
              <div class="letfannsun">{{zjgzs}}</div>
            </div>

            <div class="letfanndiv">
              <div class="letfanntext">粉丝</div>
              <div class="letfannsun">{{fss}}</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    // 接收父组件的值
    props: ['searchThing'],
   data: function () {
      return {
        thisnickName: "",
        thisprofessional: "",
        thisexpertGrade: "",
        tableDataval: {
          nickName: "",
          // 专业类别
          subjectId: "",
          // 细分领域
          orgType: "",
          // 擅长领域
          subjectTypeId: "",
          // 专家级别
          expertGrade: "",
          // 当前登录用户
          loginUserId: ""
        },
        // 是否为专家（0否 1是）
        isExpert: "",
        // 获取专业类别
        subjecttype: [],
        // 获取擅长领域
        options2: [],
        formdata: [],
        // 总数
        total: 0,
        // 分页
        pagination: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
        },
        // 当前专家关注的专家
        zjgzs:0,
        // 当前专家的粉丝
        fss:0,
      }
    },
    methods: {
      // 关注&取消关注
      Guangzhou(e) {
        let params = {
          // 用户ID
          "userId": this.tableDataval.loginUserId,
          // 关注专家ID
          "expertId": e.expertId
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expertFollow/',
          data: params,
        }).then((res) => {
          if (res.data.code = 200) {
            if (e.isFollow == 0) {
              e.isFollow = 1;
              this.$message({
                offset: 100,
                message: '恭喜你，关注成功！',
                type: 'success'
              });
            } else {
              e.isFollow = 0;
              this.$message({
                offset: 100,
                message: '已取消关注！',
                type: 'warning'
              });
            }
            this.getExpertFollowDataById();
          }
        }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
      },
      // 专家点击查看详情
      detailsclick(e, n) {
        // 专家详情
        this.$router.push({
          name: 'Expertdetails',
          params: {
            resId: e,
            zjId: n
          }
        });
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pagination.pageSize = val;
        this.searchclick();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pagination.pageNum = val;
        this.searchclick();
      },
      searchclick() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/alllist?pageNum=' + this.pagination.pageNum + '&pageSize=' + this
            .pagination.pageSize,
          data: this.tableDataval
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            for (let i in res.data.rows) {
              res.data.rows[i].avatar = res.data.rows[i].avatar
            }
            this.formdata = res.data.rows;
          }
          this.total = res.data.total
        }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
      },
      // 获取专家关注统计数据
      getExpertFollowDataById(){
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/expertFollow/getExpertFollowDataById/' + this.tableDataval.loginUserId,
          params: '',
        }).then((e) => {
          // 获取当前专家关注的专家
          this.zjgzs = e.data.data.zjgzs;
          // 获取当前专家的粉丝
          this.fss = e.data.data.fss;
        });
      }
    },
    // 页面加载完毕事件
    mounted() {
      this.tableDataval.loginUserId = localStorage.getItem("userId");
      // 是否为专家
      this.isExpert = localStorage.getItem("isExpert");
      this.getExpertFollowDataById();
      this.tableDataval.nickName = this.searchThing?this.searchThing:"";
      this.searchclick();
      // 获取专业类别
      let dictType = ['subject_type']
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/pubilc/getDictLists',
        data: dictType
        //请求成功的回调函数
      }).then((res) => {
        // 获取一级
        for (let i in res.data.data.subject_type) {
          this.subjecttype.push({
            'value': res.data.data.subject_type[i].dictValue,
            'label': res.data.data.subject_type[i].dictLabel,
          })
        }
      }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
      // 获取擅长领域
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/subject/list?parentId=0',
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        for (let i in res.data.data) {
          this.options2.push({
            'value': res.data.data[i].subjectId,
            'label': res.data.data[i].subjectName,
            'disabled': false
          })
        }
      }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
      // 根据用户ID获取专家详细信息接口
      // this.$axios({
      //   method: 'get',
      //   url: this.$baseUrl.test + '/api/expert/getExpertByUserId/' + localStorage.getItem("userId"),
      //   params: '',
      //   //请求成功的回调函数
      // }).then((res) => {
      //   if (res.data.data) {
      //     let thisexpertId = "";
      //     // 获取专家ID,如果有专家ID的话
      //     thisexpertId = res.data.data.expertId ? res.data.data.expertId : 0;
      //     this.$axios({
      //       method: 'get',
      //       url: this.$baseUrl.test + '/api/expert/' + thisexpertId,
      //       params: '',
      //       //请求成功的回调函数
      //     }).then((res) => {
      //         // 获取专家等级
      //         this.thisexpertGrade = res.data.data.expertGrade;
      //         // 获取专家名字
      //         this.thisnickName = res.data.data.nickName;
      //         // 获取专家从事专业
      //         this.thisprofessional = res.data.data.professional;

      //     });
      //   } else {
      //     console.log("没有专家ID")
      //   }
      // });
    }
  }
</script>

<style src="@/style/Expert/ExpertsearchCom.css" scoped></style>
<style>
  .el-radio--mini.is-bordered {
    padding: 6px 15px 0 10px !important;
    border-radius: 3px;
    height: 28px;
  }
</style>
