import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
// import axios from 'axios'
import './plugins/element.js'
import $ from 'jquery';
//导入baseUrl对象
import baseUrl from "@/utils/api.js";
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import {Base64} from 'js-base64'
Vue.prototype.$Base64 = Base64;

// 使用插件
Vue.use(VueQuillEditor)
//挂载到Vue原型上
Vue.prototype.$baseUrl = baseUrl;


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')