<template>
  <div class="WriteanarticlexiangqingCom">
    <el-row :gutter="2">
      <el-col :span="24" class="maxdiv">
        <!-- 面包屑 -->
        <div class="breadcrumb" style="text-align: right;">
          <el-button type="primary" @click="shenheann()">审核</el-button>
        </div>
        <!-- 标题 -->
        <h2 style="text-align: center;margin-bottom: 1rem;">{{comArticle.noticeTitle}}</h2>
        <p>发布时间：{{comArticle.createTime}}</p>
        <!-- 正文 -->
        <div class="ql-editor" style="padding: 0rem 2rem 2rem 2rem;" v-html="comArticle.noticeContent"></div>
        <!-- 附件 -->
        <div v-if="fileData.length > 0"
          style="display: flex;flex-direction: column;align-items: flex-start;padding-left: 30px;margin: 30px 0px;">
          <el-link :underline="false" v-for="item in fileData" :href="item.fileUrl" style="margin-bottom: 10px;">
          <span style="font-weight: bold;font-size: 14px;">附件：</span>{{item.fileName}}
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight: bold;font-size: 14px;">区块链地址：</span>{{item.nftId}}
          </span>
          </el-link>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="审核" :visible.sync="centerDialogVisible" width="30%" :before-close="handleClose">
      <div>
        <el-radio v-model="radio" label="2">通过</el-radio>
        <el-radio v-model="radio" label="3">驳回</el-radio>
      </div>
      <div style="margin-top: 20px;">
        <el-input type="textarea" :rows="5" placeholder="请输入原因" v-model="textarea" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="shenhe()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import $ from 'jquery';
  import "quill/dist/quill.snow.css";
  export default {
    name: '',
    components: {},
    data: function() {
      return {
        fileData: [],
        comArticle: {},
        trainNoticeId: '',
        centerDialogVisible: false,
        radio: '2',
        textarea: ''
      }
    },
    // 页面加载完毕事件
    mounted() {
      this.trainNoticeId = this.$route.query.trainNoticeId
      this.getData();
    },
    methods: {
      handleClose() {
        this.centerDialogVisible = false;
      },
      shenheann() {
        this.centerDialogVisible = true;
      },
      shenhe() {
        if (this.radio == 3 && !this.textarea) {
          this.$message({
            offset: 100,
            type: 'error',
            message: '请输入驳回原因！'
          });
        } else {
          let paramsx = {
            "trainNoticeId": this.trainNoticeId,
            "noticeStatus": this.radio,
            "auditRemark": this.textarea,
            "auditUserId": localStorage.getItem("userId")
          }
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/train/trainNotice',
            data: paramsx,
          }).then((res) => {
            if (res.data.code == 200) {
              this.centerDialogVisible = false
              this.$message({
                offset: 100,
                type: 'success',
                message: '操作成功！'
              });
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'success',
                message: res.data.msg
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      getData() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let url = "/train/trainNotice/" + this.trainNoticeId + '?loginUserId=' + localStorage.getItem("userId")
        // 获取文章详情
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + url,
          params: '',
        }).then((res) => {
          this.comArticle = res.data.data
          loading.close();
          // 获取附件列表
          if (res.data.data.files) {
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/getFileList/' + res.data.data.files,
              params: '',
            }).then((res) => {
              loading.close();
              for (let i in res.data.data) {
                // 获取二级资源信息
                this.fileData.push({
                  // 文件ID
                  fileId: res.data.data[i].fileId,
                  // 创建时间
                  createTime: res.data.data[i].createTime,
                  // 附件名称
                  chapterName: res.data.data[i].fileName.split(".")[0],
                  fileName: res.data.data[i].fileName,
                  // 文件地址
                  fileUrl: res.data.data[i].fileUrl,
                  nftId: res.data.data[i].nftId ? res.data.data[i].nftId : '',
                })
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    }
  }
</script>

<style src="@/style/Team/WriteanarticlexiangqingCom.css" scoped></style>
<style scoped>
  .maxdiv {
    margin: 0.5rem 1rem;
  }

  .el-form-item__label {
    text-align: right;
  }

  img {
    max-width: 100%;
  }

  .userintimg {
    width: 3rem;
    height: 3rem;
  }
</style>
