<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;height: 4rem; margin-left: 0px;margin-top: 10px;">
      <!-- 面包屑 -->
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>工作台</el-breadcrumb-item>
          <el-breadcrumb-item>团队交流管理</el-breadcrumb-item>
          <el-breadcrumb-item>专栏管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="primary" style="float: right;margin-top: -1.5rem;" @click="dialogVisible = true">创建专栏
        </el-button>
      </div>

      <el-col :span="6" v-for="item in tableData" style="text-align: left;margin-top: 0.7rem;">
        <div class="kap">
          <div class="specialTitle">{{item.specialTitle}}</div>
          <div class="specialContent">{{item.specialContent}}</div>
          <div class="xiadiv">
            <span class="followNum">{{item.followNum}}关注</span>｜
            <span class="articleNum">{{item.articleNum}}篇文章</span>
          </div>
          <div class="buttondiv">
            <el-button type="primary" size="mini" plain @click="putclick(item)">修改介绍</el-button>
            <el-button type="danger" size="mini" plain @click="deleteclick(item.specialId)">删除</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="新建专栏" :visible.sync="dialogVisible" width="40%" top="10rem" :before-close="handleClose">
      <!-- 专栏名称 -->
      <el-input style="width: 80%;margin-left: 1%;margin-bottom: 2rem;" v-model="specialTitle" placeholder="请输入专栏名称">
      </el-input>
      <!-- 一句话介绍 -->
      <el-input style="width: 80%;margin-left: 1%;" type="textarea" :rows="4" v-model="specialContent"
        placeholder="请输入一句话介绍"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="pushzhuanlan">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改专栏" :visible.sync="dialogVisible1" width="40%" top="10rem" :before-close="handleClose">
      <!-- 专栏名称 -->
      <el-input style="width: 80%;margin-left: 1%;margin-bottom: 2rem;" v-model="specialTitle1" placeholder="请输入专栏名称">
      </el-input>
      <!-- 一句话介绍 -->
      <el-input style="width: 80%;margin-left: 1%;" type="textarea" :rows="4" v-model="specialContent1"
        placeholder="请输入一句话介绍"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="putzhuanlan">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>



<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        dialogVisible: false,
        dialogVisible1: false,
        specialTitle: '',
        specialContent: '',
        specialTitle1: '',
        specialContent1: '',
        specialId1: '',
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
        },
        // 表格数据
        tableData: [],
      }
    },
    methods: {
      // 获取专题栏目列表
      getcomSpecial() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comSpecial/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: {},
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 添加专栏
      pushzhuanlan() {
        let params = {
          specialTitle: this.specialTitle,
          specialContent: this.specialContent,
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comSpecial',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.dialogVisible = false;
            this.specialTitle = "";
            this.specialContent = "";
            this.getcomSpecial();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 修改
      putclick(item) {
        this.dialogVisible1 = true;
        this.specialTitle1 = item.specialTitle;
        this.specialContent1 = item.specialContent;
        this.specialId1 = item.specialId;
      },
      //
      putzhuanlan() {
        let params = {
          specialTitle: this.specialTitle1,
          specialContent: this.specialContent1,
          specialId: this.specialId1
        }
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/communication/comSpecial/',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.dialogVisible1 = false;
            this.specialTitle1 = "";
            this.specialContent1 = "";
            this.getcomSpecial();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 删除
      deleteclick(item) {
        this.$confirm('此操作将删除该专栏, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/communication/comSpecial/' + item,
            params: '',
            //请求成功的回调函数
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.getcomSpecial();
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.getcomSpecial();
    }
  }
</script>

<style src="@/style/Team/ColumnmanagementCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }

  .kap {
    background-color: #FFFFFF;
    padding: 1rem 0.8rem 0.8rem 0.8rem;
    border-radius: 0.5rem;
  }

  .specialTitle {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }

  .specialContent {
    min-height: 4rem;
    color: #CCCCCC;
  }

  .xiadiv {
    margin-bottom: 1rem;
    padding: 0rem 0.8rem 0.8rem 0rem;
    color: #CCCCCC;
  }

  .buttondiv {
    display: flex;
    justify-content: space-around;
  }

  .followNum {}

  .articleNum {}
</style>
