<template>
  <div>
    <ul class="img_ul">
      <li v-for="(item,index) in imgListsShow" :key="index">
        <img :src="item" @click="showBig(item)" alt="">
        <span class="fa fa-times deleteCurImg el-icon-close" style="cursor: pointer" @click="deleteCur(index)"></span>
      </li>
    </ul>
    <span class="upload_box">+<input type="file" id="img_upload" @change="getFullPath"></span>

    <el-dialog title="图片" :visible.sync="dialogImgShow" width="600px" :modal="false">
      <div style="text-align: center">
        <img style="width: 100%;max-height: 500px" v-if="imgFileCode" :src="imgFileCode" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // import config from '@/assets/common/api';
  import $ from 'jquery';
  export default {
    name: 'commonComponents',
   data: function () {
      return {
        url: '',
        imgLists: [],
        imgListsShow: [],
        dialogImgShow: false,
        imgFileCode: '',
      }
    },
    props: ['clearImg'],
    watch: {
      $route(to, from) {

      },
    },
    mounted() {
      let _this = this;
      if (this.clearImg) {
        this.imgLists = [];
        this.imgListsShow = [];
      }
      this.url = "https://jsonplaceholder.typicode.com/posts/";
    },
    methods: {
      showBig(url) {
        this.dialogImgShow = true;
        this.imgFileCode = url;
      },
      getFullPath() {
        let obj = document.getElementById("img_upload");
        let _this = this;
        if (obj) {
          //Internet Explorer
          if (window.navigator.userAgent.indexOf("MSIE") >= 1) {
            obj.select();
            return document.selection.createRange().text;
          }
          //Firefox
          if (window.navigator.userAgent.indexOf("Firefox") >= 1) {
            if (obj.files) {
              return obj.files.item(0).getAsDataURL();
            }
            return obj.value;
          }
          //兼容chrome、火狐等，HTML5获取路径
          if (typeof FileReader != "undefined") {
            var reader = new FileReader();
            reader.onload = function(e) {
              let database64 = "";
              database64 = e.target.result;
              database64 = database64.split(',');
              database64 = database64[1];
              let imgText = $('#img_upload').val().split('.');
              var ObjPara = {
                "NewFileName": $('#img_upload').val().split('.')[0].split('\\')[$('#img_upload').val().split('.')[0]
                  .split('\\').length - 1],
                "Suffix": imgText[imgText.length - 1],
                "FileStreamValue": database64 + "",
              }
              var file = obj.files[0];
              // 确认选择的文件是图片
              if (file.type.indexOf("image") == 0) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function(e) {
                  var newUrl = this.result;
                  _this.imgLists.push({
                    Picture: JSON.stringify(ObjPara)
                  });
                  _this.imgListsShow.push(newUrl);
                  obj.value = '';
                  _this.$emit('imgListsGet', _this.imgLists);
                };
              }
            }
            reader.readAsDataURL(obj.files[0]);
          } else if (browserVersion.indexOf("SAFARI") > -1) {
            alert("暂时不支持Safari浏览器!");
          }
        }
      },

      deleteCur(inde) {
        let _this = this;
        _this.imgLists.splice(inde, 1);
        _this.imgListsShow.splice(inde, 1);
        _this.$emit('imgListsGet', _this.imgLists);
      },
    }
  }
</script>

<style>
  .upload_box {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: relative;
    z-index: 100;
    background: #fff;
    text-align: center;
    line-height: 80px;
    border: 1px solid #ddd;
    font-size: 25px;
  }

  .upload_box input {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    z-index: 98;
    opacity: 0;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .img_ul {
    display: inline-block;
    vertical-align: top;
    width: 14rem;
    min-width: 14rem;
    height: 5rem;
    overflow-x: scroll;
    border: 1px solid #dcdfe5;
  }

  .img_ul li {
    width: 80px;
    height: 80px;
    font-size: 16px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
  }

  .img_ul li .deleteCurImg {
    position: absolute;
    right: 5px;
    top: 5px;
    color: red;
  }

  .img_ul li img {
    width: 80px;
    height: 80px;
  }

  .base_img>img {
    width: 80px;
    height: 80px;
  }
  ::-webkit-scrollbar {
    display: none
  }
</style>
