import { render, staticRenderFns } from "./trainingDetails.vue?vue&type=template&id=782432a1&scoped=true&"
import script from "./trainingDetails.vue?vue&type=script&lang=js&"
export * from "./trainingDetails.vue?vue&type=script&lang=js&"
import style0 from "@/style/Cooperation/Projectdetails.css?vue&type=style&index=0&id=782432a1&scoped=true&lang=css&"
import style1 from "@/style/main.css?vue&type=style&index=1&id=782432a1&scoped=true&lang=css&"
import style2 from "./trainingDetails.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "782432a1",
  null
  
)

export default component.exports