<template>
  <div class="MechanismCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 5.33625rem; margin-left: 0px;height: 88vh;">
      <el-col :span="24">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
          style="padding: 2rem 10% 0% 5%;margin-top: 0rem;">
          <el-form-item label="标题" prop="articleTitle" label-width="150px !important">
            <el-input v-model="ruleForm.articleTitle" placeholder="请输入5-30个字符" maxlength="30" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="简介" prop="articleIntroduce" label-width="150px !important">
            <el-input type="textarea" :rows="4" v-model="ruleForm.articleIntroduce" placeholder="请输入500字以内的简介"
              maxlength="500" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="附件" label-width="150px !important">
            <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'" ref="fjupload"
              :on-remove="fjhandleRemove" :on-exceed="fjonexceed" :on-success="fjuploadSuccess" multiple :limit="3"
              :file-list="fileList" accept=".doc,.docx,.ppt,.pptx,.pdf">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传doc/docx/ppt/pptx/pdf文件且不超过1024MB</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="封面" label-width="150px !important">
            <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :on-success="uploadSuccess"
              ref="fmupload" :on-remove="handleRemove" :on-exceed="onexceed" limit="1" style="display: flex;">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="正文" prop="articleContent" label-width="150px !important">
            <quill-editor class="editor" v-model="ruleForm.articleContent" ref="myQuillEditor" :options="editorOption"
              @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)">
            </quill-editor>
          </el-form-item>

          <el-form-item style="padding-top: 4rem;margin-top: 4rem;">
            <el-button @click="$router.back()">返回</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">发布</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import $ from 'jquery';
  import {
    quillEditor
  }
  from "vue-quill-editor"; //调用编辑器
  import "quill/dist/quill.snow.css";
  export default {
    name: '',
    components: {
      // commonComponents
      quillEditor, //使用编辑器
    },
    data: function() {
      return {
        ruleForm: {
          projectId: '', // 项目
          courseId: '', // 课程
          articleType: '',
          articleTitle: '',
          articleContent: '',
          articleIntroduce: '',
          articleCover: '',
          files: [],
          createBy: localStorage.getItem("userId"),
        },
        rules: {
          articleTitle: [{
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          }],
          articleIntroduce: [{
            required: true,
            message: '请输入简介',
            trigger: 'blur'
          }],
          articleContent: [{
            required: true,
            message: '请输入正文',
            trigger: 'blur'
          }],
        },
      }
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    methods: {
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.$refs.fmupload.clearFiles();
        this.$refs.fjupload.clearFiles();
        this.ruleForm.files = '';
        this.ruleForm.articleContent = '';
      },
      //保存&提交
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let _this = this;
            if (_this.ruleForm.projectId || _this.ruleForm.courseId) {
              _this.ruleForm.files = _this.ruleForm.files.toString();
              _this.ruleForm.articleType = _this.ruleForm.projectId ? '0' : '1'
              this.$axios({
                method: 'post',
                url: this.$baseUrl.test + '/train/trainArticle',
                data: _this.ruleForm,
              }).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: res.data.msg,
                    type: 'success',
                    offset: 100
                  });
                  _this.resetForm('ruleForm')
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: 'error',
                    offset: 100
                  });
                }
              }).catch(error => {
                // 处理错误
                this.$message({
                  offset: 100,
                  message: error.msg,
                  type: 'error'
                });
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: '发布失败，请重新登录！',
                offset: 100
              });
            }
          } else {
            return false;
          }
        });
      },
      fjuploadSuccess(response, file, fileList) {
        this.ruleForm.files.push(response.data.fileId);
      },
      fjhandleRemove(response, file, fileList) {
        console.log(file)
        // 清空ID
        this.ruleForm.files = '';
      },
      fjonexceed() {
        this.$message({
          message: "附件限制上传十件！",
          type: 'warning',
          offset: 100
        });
      },
      // 封面上传成功
      uploadSuccess(response, file, fileList) {
        this.ruleForm.articleCover = response.data.fileId + '';
      },
      // 封面删除
      handleRemove(response, file, fileList) {
        // 清空ID
        this.ruleForm.articleCover = '';
      },
      // 文件超出个数限制时的钩子
      onexceed() {
        this.$message({
          message: "封面限制上传一张！",
          type: 'warning',
          offset: 100
        });
      },
      onEditorChange() {
        //内容改变事件
        this.$emit("input", this.ruleForm.content);
      },

    },
    // 页面加载完毕事件this.$route.params
    mounted() {
      let _this = this
      _this.ruleForm.projectId = _this.$route.params.projectId ? _this.$route.params.projectId : "";

      // 发课程文章
      _this.ruleForm.courseId = _this.$route.query.trainCourseId ? _this.$route.query.trainCourseId : "";
    }
  }
</script>

<style src="@/style/Team/WriteanarticleCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    text-align: right;
  }

  .ql-container {
    height: 32rem !important;
  }

  .ql-editor {
    min-height: 32rem;
  }

  .editor {
    line-height: normal;
    height: 220px;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: '文本';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: '标题1';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: '标题2';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: '标题3';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: '标题4';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: '标题5';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: '标题6';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: '标准字体';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    content: '衬线字体';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    content: '等宽字体';
  }

  #disabledMsg.el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    color: #bfd9d8;
    background-color: #eef6f6;
    border-color: #d1e5e5;
  }

  .upload-demo {
    border-radius: 6px;
    text-align: justify;
  }
</style>
