<template>
  <div class="Head">
    <div class="list1">
      <p style="margin: -10px 0px 0px 0px;padding: 0px;font-size: 28px;">养老/家政领域教学协作共同体区块链平台</p>
      <p style="font-size: 16px;margin: 0px;padding: 0px;margin-top: -40px;font-size: 22px;">
        公共服务类国家职业教育双师型培训基地(长沙民政职业技术学院)</p>
    </div>
    <div class="list2">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#2e7ff0" active-text-color="#ffd04b" text-color="#fff" style="font-size: 1rem;">
        <el-menu-item index="1">工作台</el-menu-item>
        <el-menu-item index="2">资源共享大厅</el-menu-item>
        <el-menu-item index="3">合作项目申报</el-menu-item>
        <el-menu-item index="5">培训交流大厅</el-menu-item>
        <!-- <el-menu-item index="6">人才互聘大厅</el-menu-item> -->
        <el-menu-item index="7">师资培训专栏</el-menu-item>
        <el-menu-item index="4">联盟专家</el-menu-item>
        <el-menu-item index="8">相关资讯</el-menu-item>
      </el-menu>
    </div>
    <div class="list3">
      <div class="notice">
        <el-badge :value="allsun" class="item">
          <el-tooltip class="item" effect="dark" content="查看未读消息" placement="bottom-start" offset="10">
            <i class="el-icon-message-solid" @click="drawerclick()"
              style="color: #FFFFFF;font-size: 1.5rem;cursor:pointer;"></i>
          </el-tooltip>
        </el-badge>
      </div>
      <div class="userinformation">
        <i class="el-icon-user-solid" style="color: #FFFFFF;font-size: 1.5rem;"></i>
        <div class="username">
          {{userData.nickName}}
          <div class="school">{{userData.orgName}}</div>
        </div>
        <el-popconfirm title="是否退出系统？" @confirm="determineclick()">
          <i slot="reference" class="el-icon-switch-button"
            style="color: #FFFFFF;font-size: 1.5rem;margin-left: 1rem;margin-right: 1rem;"></i>
        </el-popconfirm>
      </div>
    </div>

    <!-- 未读消息弹窗 -->
    <el-drawer title="未读消息弹窗" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
      <div class="drawermaxdiv">
        <!-- 头部标题 -->
        <div class="drawertopdiv">
          <el-radio-group v-model="radio1" @change="elradiogroup">
            <el-radio-button label="1">待办（{{daibanxiaoxisun}}）</el-radio-button>
            <el-radio-button label="2">已办（{{yiduxiaoxisun}}）</el-radio-button>
            <el-radio-button label="3">消息（{{xitoxiaoxisun}}）</el-radio-button>
          </el-radio-group>

          <!-- 内容 -->
          <div class="drawernrdiv">
            <div class="drawernrlist" v-for="item in alllist" @click="drawernrlistclick(item)">
              <div class="drawernrtop">
                <!-- 标题 -->
                <div class="biaoti">{{item.title}}</div>
                <!-- 标签 -->
                <el-tag v-if="item.status == 0">新消息</el-tag>
                <el-tag v-if="item.status == 1">已读消息</el-tag>
              </div>
              <!-- 消息内容 -->
              <div class="xiaoxinr">{{item.content}}</div>
              <!-- 消息时间 -->
              <div class="xiaoxishij">{{item.createTime}}</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>

</template>

<script>
  export default {
    data: function() {
      return {
        radio1: "1",
        activeIndex: "",
        userData: {},
        dialogVisiblexxx: false,
        drawer: false,
        direction: 'rtl',
        // 全部消息总数
        allsun: 0,
        // 展示列表
        alllist: [],
        // 系统消息总数
        xitoxiaoxisun: 0,
        // 系统消息列表
        xitoxiaoxilist: [],
        // 待办消息总数
        daibanxiaoxisun: 0,
        // 待办消息列表
        daibanxiaoxilist: [],
        yiduxiaoxisun: 0,
        yiduxiaoxilist: [],
        pagination: {
          // 为用户ID
          userId: localStorage.userId,
          // 为消息类型，（0系统消息1待办任务）
          msgType: 0,
          // 为当前第几页
          pageNum: 1,
          // 为每页条数
          pageSize: 100,
        }
      }
    },
    // 页面加载完毕触发
    mounted() {
      if (sessionStorage.getItem('adminToken') == "") {
        this.$router.push('/');
      }
      // 把指向vue对象保存给_this
      let _this = this;
      // 从本地存储取出来
      _this.activeIndex = localStorage.activeIndex;
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.userId,
        data: '',
        //请求成功的回调函数
      }).then((res) => {
        _this.userData = res.data.data;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取系统消息
      _this.xitoxiaoxi();
      // 获取待办消息
      _this.daibanxiaoxi();
      // 获取已读消息
      _this.yiduxiaoxi();
      // 默认获取待办消息列表
      _this.alllist = _this.daibanxiaoxilist
    },
    methods: {
      xxxxxx() {
        this.dialogVisiblexxx = true
      },
      // 消息点击事件
      drawernrlistclick(e) {
        // 点击消息后就消息标记为已读
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/msg/setMsgRead/' + e.msgId,
          params: '',
          //请求成功的回调函数
        }).then((res) => {});
        // 如果是消息就不用跳转，只要待办需要跳转
        if (e.msgType == 1) {
          // businessType: 用户审核0 机构审核1 专家审核2 资源审核3 合作项目报名4 合作项目邀请5 用户待审核6
          if (e.businessType == 0) {
            this.$router.push('/User');
          } else if (e.businessType == 1) {
            this.$router.push('/Mechanism');
          } else if (e.businessType == 2) {
            this.$router.push('/Expertdata');
          } else if (e.businessType == 3) {
            this.$router.push('/Resourceaudit');
          } else if (e.businessType == 4) {
            this.$router.push('/Myproject');
          } else if (e.businessType == 5) {
            this.$router.push('/Myproject');
          } else if (e.businessType == 6) {
            this.$router.push('/User');
          }
        }
        // 系统消息点击事件
        this.xitoxiaoxi();
      },
      // 消息类型值改变事件
      elradiogroup() {
        if (this.radio1 == "1") {
          // 待办消息点击事件
          this.daibanxiaoxi();
          this.alllist = this.daibanxiaoxilist
        } else if (this.radio1 == "2") {
          // 已读消息点击事件
          this.yiduxiaoxi();
          this.alllist = this.yiduxiaoxilist
        } else {
          // 系统消息点击事件
          this.xitoxiaoxi();
          this.alllist = this.xitoxiaoxilist
        }
      },
      // 待办消息点击事件
      daibanxiaoxi() {
        // 根据用户ID获取待办列表接口
        let pn = {
          // 为用户ID
          userId: localStorage.userId,
          // 为消息类型，（0系统消息1待办任务）
          // 0未读，1已读
          status: 0,
          msgType: 1,
          // 为当前第几页
          pageNum: 1,
          // 为每页条数
          pageSize: 100,
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/msg/getMsgList?pageNum=' + pn.pageNum + '&pageSize=' + pn.pageSize,
          data: pn,
          //请求成功的回调函数
        }).then((res) => {
          // 获取待办消息总数
          this.daibanxiaoxisun = res.data.total;
          // 获取待办消息列表
          this.daibanxiaoxilist = res.data.rows;
          // 默认获取待办消息列表
          this.alllist = this.daibanxiaoxilist
          // 获取消息总数
          this.allsun = parseInt(this.xitoxiaoxisun) + parseInt(this.daibanxiaoxisun);
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 获取已读消息
      yiduxiaoxi() {
        // 根据用户ID获取待办列表接口
        let pn = {
          // 为用户ID
          userId: localStorage.userId,
          // 0未读，1已读
          status: 1,
          // 为当前第几页
          pageNum: 0,
          // 为每页条数
          pageSize: 100,
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/msg/getMsgList?pageNum=' + pn.pageNum + '&pageSize=' + pn.pageSize,
          data: pn,
          //请求成功的回调函数
        }).then((res) => {
          // 获取系统消息总数
          this.yiduxiaoxisun = res.data.total;
          // 获取系统消息列表
          this.yiduxiaoxilist = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 系统消息点击事件
      xitoxiaoxi() {
        // 根据用户ID获取待办列表接口
        let pn = {
          // 为用户ID
          userId: localStorage.userId,
          // 为消息类型，（0系统消息1待办任务）
          msgType: 0,
          // 0未读，1已读
          status: 0,
          // 为当前第几页
          pageNum: 0,
          // 为每页条数
          pageSize: 100,
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/msg/getMsgList?pageNum=' + pn.pageNum + '&pageSize=' + pn.pageSize,
          data: pn,
          //请求成功的回调函数
        }).then((res) => {
          // 获取系统消息总数
          this.xitoxiaoxisun = res.data.total;
          // 获取系统消息列表
          this.xitoxiaoxilist = res.data.rows;
          // 获取消息总数
          this.allsun = parseInt(this.xitoxiaoxisun) + parseInt(this.daibanxiaoxisun);
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 总消息点击事件
      drawerclick() {
        this.drawer = true;
        // 获取系统消息
        this.xitoxiaoxi();
        // 获取待办消息
        this.daibanxiaoxi();
        // 获取消息总数
        this.allsun = parseInt(this.xitoxiaoxisun) + parseInt(this.daibanxiaoxisun);
      },
      handleClose(done) {
        this.drawer = false;
      },
      determineclick() {
        //将token清空
        sessionStorage.setItem('adminToken', "");
        // 跳转到登陆页面
        this.$router.push('/');
      },
      handleSelect(key) {
        // 存入本地存储
        localStorage.setItem('activeIndex', key)
        this.activeIndex = key;
        if (key == 1) {
          // 工作台
          this.$router.push('/Index');
        } else if (key == 2) {
          // 资源共享大厅
          this.$router.push('/Hall');
        } else if (key == 3) {
          // 协同合作大厅
          this.$router.push('/Cooperation');
        } else if (key == 4) {
          // 联盟专家
          this.$router.push('/Union');
        } else if (key == 5) {
          // 团队交流大厅
          this.$router.push('/Communication');
        } else if (key == 6) {
          // 人才互聘大厅
          this.$router.push('/Hirehall');
        } else if (key == 7) {
          // 师资培训专栏
          this.$router.push('/teacherTrainingcolumn');
        } else if (key == 8) {
          // 相关资讯
          this.$router.push('/RelatedInformation');
        }
      },
    }
  }
</script>

<style src="@/style/head.css" scoped></style>
<style>
  .el-menu.el-menu--horizontal {
    border-bottom: solid 0px #e6e6e6 !important;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .drawernrdiv {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  .drawernrlist:hover {
    background-color: #e6f6ff;
  }

  .drawernrlist {
    background-color: #f3f3f3;
    width: 80%;
    height: 6rem;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }

  .drawernrtop {
    display: flex;
    justify-content: space-between;
  }

  .xiaoxinr {
    text-align: left;
  }

  .xiaoxishij {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }

  .el-menu-item {
    padding: 0 15px !important;
  }
</style>
