<template>
  <div class="MechanismCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 5.33625rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>人才互聘管理</el-breadcrumb-item>
            <el-breadcrumb-item>发布职位</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="24" style="padding-left: 10rem;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
          style="padding-left: 10%;">
          <p style="text-align: left;padding-left: 0rem;">职位基本信息</p>
          <el-form-item label="职位名称" prop="recruitTitle" label-width="150px !important">
            <el-input v-model="ruleForm.recruitTitle" placeholder="请填写职位名称"></el-input>
          </el-form-item>
          <el-form-item label="职位分类" prop="industry" label-width="150px !important">
            <el-select v-model="ruleForm.industry" placeholder="请选择职位分类">
              <el-option label="养老行业" value="0"></el-option>
              <el-option label="家政行业" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职位数量" prop="recruitNum" label-width="150px !important">
            <el-input-number style="width: 50%;float: left;" :min="1" v-model="ruleForm.recruitNum"
              placeholder="请填写职位数量"></el-input-number>
          </el-form-item>
          <el-form-item label="工作地址" prop="jobCity" label-width="150px !important">
            <el-cascader style="width: 50%;float: left;" v-model="ruleForm.jobCity" :options="options"
              :props="{ expandTrigger: 'hover' }" @change="chooseArea">
            </el-cascader>
          </el-form-item>
          <p style="text-align: left;padding-left: 0rem;">职位要求</p>
          <el-form-item label="工作性质" prop="nature" label-width="150px !important">
            <div style="text-align: left;">
              <el-radio v-model="ruleForm.nature" label="1" border>全职</el-radio>
              <el-radio v-model="ruleForm.nature" label="2" border>兼职</el-radio>
              <el-radio v-model="ruleForm.nature" label="3" border>实习</el-radio>
            </div>
          </el-form-item>

          <el-form-item label="工作经验" prop="experience" label-width="150px !important">
            <div style="text-align: left;">
              <el-select v-model="ruleForm.experience" placeholder="请选择">
                <el-option v-for="item in experiencelist" :key="item.dictLabel" :label="item.dictValue"
                  :value="item.dictLabel">
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="最高学历" prop="education" label-width="150px !important">
            <div style="text-align: left;">
              <el-select v-model="ruleForm.education" placeholder="请选择">
                <el-option v-for="item in education" :key="item.dictLabel" :label="item.dictValue"
                  :value="item.dictLabel">
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="薪资范围" prop="jobSalary" label-width="150px !important">
            <div style="text-align: left;">
              <el-select v-model="ruleForm.jobSalary" placeholder="请选择">
                <el-option v-for="item in job_salary" :key="item.dictLabel" :label="item.dictValue"
                  :value="item.dictLabel">
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="职位描述" prop="recruitContent" label-width="150px !important">
            <div style="text-align: left;">
              <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.recruitContent">
              </el-input>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button type="info" @click="submitForm('ruleForm',0)">存为草稿</el-button>
            <el-button type="primary" @click="submitForm('ruleForm',1)">发布</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import commonComponents from '@/components/register/commonComponents.vue'
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';

  export default {
    name: '',
    components: {
      commonComponents
    },
    props: ['recruitId'],
    data: function() {
      return {
        options: cities,
        ruleForm: {
          recruitTitle: '',
          industry: '',
          recruitNum: [],
          userId: '',
          nature: '',
          recruitContent: '',
        },
        experiencelist: [],
        education: [],
        job_salary: [],
        // 二级资源信息
        fileData: [],
        rules: {
          recruitTitle: [{
            required: true,
            message: '请输入职位名称',
            trigger: 'blur'
          }],
          industry: [{
            required: true,
            message: '请选择职位分类',
            trigger: 'blur'
          }],
          recruitNum: [{
            required: true,
            message: '请输入职位数量',
            trigger: 'blur'
          }],
          jobCity: [{
            required: true,
            message: '请选择工作地址',
            trigger: 'blur'
          }],
          nature: [{
            required: true,
            message: '请选择工作性质',
            trigger: 'blur'
          }],
        }
      }
    },
    methods: {
      // 通用根据类型获取数据字典接口
      getDictLists() {
        let params = ["job_salary", "education", "experience", "financing", "scale", "nature", "res_type"]
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/pubilc/getDictLists',
          data: params,
        }).then((res) => {
          // 岗位薪资
          this.job_salary = res.data.data.job_salary
          // 学历要求
          this.education = res.data.data.education
          // 工作经验
          this.experiencelist = res.data.data.experience
          // 融资阶段
          this.financing = res.data.data.financing
          // 公司规模
          this.scale = res.data.data.scale
          // 工作性质
          this.nature = res.data.data.nature
          this.recruitNumlist = res.data.data.res_type
        }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
      },
      chooseArea(value) { //区域地址改变
        this.ruleForm.jobCity = value.join();
      },
      // 提交事件
      submitForm(formName, sun) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
              "recruitTitle": this.ruleForm.recruitTitle,
              "industry": this.ruleForm.industry,
              "recruitNum": this.ruleForm.recruitNum,
              "nature": this.ruleForm.nature,
              "recruitContent": this.ruleForm.recruitContent,
              "recruitStatus": 0,
              "createBy": localStorage.userId
            }
            this.$axios({
              method: 'post',
              url: this.$baseUrl.test + '/recruit/recruitInfo',
              data: params
              //请求成功的回调函数
            }).then((res) => {
              console.log(res.data);
              if (res.data.code == 200) {
                // sun = 0 存为草稿
                if (sun == 0) {
                  this.$alert(
                    '<h4>您的招聘《' + this.ruleForm.recruitTitle +
                    '》已保存为草稿</h4><br><h5>您的招聘信息已经成功保存为草稿，请在工作台“人才互聘-职位管理”中再次编辑</h5>',
                    '', {
                      type: 'success',
                      center: true,
                      dangerouslyUseHTMLString: true,
                      confirmButtonText: '确定',
                      callback: action => {
                        this.$message({
                          type: 'info',
                          message: `action: ${ action }`
                        });
                      }
                    });
                  // 清空所有内容
                  this.$refs[formName].resetFields();
                  this.fileData = [];
                } else {
                  this.$alert(
                    '<h4>您发布的职位《' + this.ruleForm.recruitTitle +
                    '》已提交审核</h4><br><h5>恭喜，职位发布成功，我们将会在3个工作日内审核完成，审核通过后将直接发布至日才互聘大厅，请您及时关注平台消息提醒</h5>',
                    '', {
                      type: 'success',
                      center: true,
                      dangerouslyUseHTMLString: true,
                      confirmButtonText: '确定',
                      callback: action => {
                        this.$message({
                          type: 'info',
                          message: `action: ${ action }`
                        });
                      }
                    });
                  // 清空所有内容
                  this.$refs[formName].resetFields();
                  this.fileData = [];
                }
              } else {
                this.$message({
                  offset: 100,
                  message: res.data.msg,
                  type: 'error'
                });
              }

            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            return false;
          }
        });
      },
      // 清空事件
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    },
    // 页面加载完毕事件
    mounted() {
      if (this.recruitId) {
        // 如果是编辑进入获取招聘信息详情
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/recruit/recruitInfo/' + this.recruitId,
          params: '',
        }).then((res) => {
          this.ruleForm = res.data.data;
        }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
        this.ruleForm.userId = localStorage.userId;
      }
      this.getDictLists();
    },
    created() {
      // 把指向vue对象保存给_this
      let _this = this;
      _this.options = element_china_area_data__WEBPACK_IMPORTED_MODULE_1__.regionData
      console.log(_this.options)
    }
  }
</script>

<style src="@/style/Team/ReleasepositionCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    width: 30% !important;
    text-align: right;
  }

  .el-form-item__error {
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }

  .upload-demo {
    border-radius: 6px;
  }

  .el-upload {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    outline: 0;
    float: left !important;
  }

  .el-upload__tip[data-v-71abf336] {
    font-size: 12px;
    color: #606266;
    margin-top: 0px;
    width: 70%;
  }

  ::-webkit-scrollbar {
    display: none
  }

  h4 {
    margin: 0px;
  }

  h5 {
    margin: 0px;
    color: lightgrey;
  }
  .demo-ruleForm {
      margin-top:0px;
      height: auto;
      overflow-y: auto;
  }
</style>
