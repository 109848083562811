<template>
  <div class="Head">
    <div class="list1" style="display: flex;flex-direction: column;align-items: flex-start;">
      <p style="margin: -10px 0px 0px 0px;padding: 0px;font-size: 28px;">养老/家政领域教学协作共同体区块链平台</p>
      <p style="font-size: 16px;margin: 0px;padding: 0px;margin-top: -40px;font-size: 22px;">公共服务类国家职业教育双师型培训基地(长沙民政职业技术学院)</p>
    </div>
    <div class="list2">
      <el-menu :default-active="activeIndexnew" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#2e7ff0" active-text-color="#ffd04b" text-color="#fff" style="font-size: 1rem;">
        <el-menu-item index="1">相关资讯</el-menu-item>
        <el-menu-item index="2">校企资源</el-menu-item>
        <el-menu-item index="3">养老人才</el-menu-item>
      </el-menu>
    </div>
    <div class="list3">
      <div class="notice">
      </div>
      <div class="userinformation">
        <a @click="top(1)" style="cursor: pointer;">登录</a>
        <a @click="top(2)" style="cursor: pointer;">机构注册</a>
        <a @click="top(3)" style="cursor: pointer;">个人注册</a>
      </div>
    </div>
</div>

</template>

<script>
  export default {
    data: function() {
      return {
        activeIndexnew: "",
        pagination: {
          // 为用户ID
          userId: localStorage.userId,
          // 为消息类型，（0系统消息1待办任务）
          msgType: 0,
          // 为当前第几页
          pageNum: 1,
          // 为每页条数
          pageSize: 100,
        }
      }
    },
    // 页面加载完毕触发
    mounted() {
      // 从本地存储取出来
      this.activeIndexnew = localStorage.getItem('activeIndexnew');
    },
    methods: {
      top(item){
        if(item == 1){
          localStorage.setItem('activeIndexnew','1');
          this.$router.push('/Home')
        }
        if(item == 2){
          localStorage.setItem('activeIndexnew','2');
          this.$router.push('/Register?type=0')
        }
        if(item == 3){
          localStorage.setItem('activeIndexnew','3');
          this.$router.push('/Register?type=1')
        }
      },
      handleSelect(key) {
        this.$emit('fatherMethod',key);
      },
    }
  }
</script>

<style src="@/style/head.css" scoped></style>
<style>
  .userinformation a{
    margin-left: 10px;
    color: #FFFFFF;
    font-size: 14px;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: solid 0px #e6e6e6 !important;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .drawernrdiv {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  .drawernrlist:hover {
    background-color: #e6f6ff;
  }

  .drawernrlist {
    background-color: #f3f3f3;
    width: 80%;
    height: 6rem;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }

  .drawernrtop {
    display: flex;
    justify-content: space-between;
  }

  .xiaoxinr {
    text-align: left;
  }

  .xiaoxishij {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
</style>
