<template>
  <div class="index">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>专家管理</el-breadcrumb-item>
            <el-breadcrumb-item>专家等级证书</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purplexx">
              <el-row :gutter="20">
                <el-col :span="12">
                  <!-- 专家头像-->
                  <div class="zjtxdiv">
                    <el-image style="width: 10rem; height: 12rem;margin: 1rem 0rem;" :src="zjtxurl" fit="contain">
                    </el-image>
                  </div>
                </el-col>
                <el-col :span="12" style="padding: 2rem;">
                  <div class="zjname">{{zjname}}</div><br />
                  <div class="zjtyple">联盟养老领域金牌专家</div>
                  <div class="zjbh">联盟专家证书编号：{{expertNo}}</div>
                </el-col>
              </el-row>
            </div>
          </el-col>

          <!-- <el-col :span="12">
            <div class="grid-content bg-purplexx">
              <div class="toptitle">专家等级：金牌专家</div>
              <div class="progress">
                <el-progress :percentage="100" color="#3bc1fe"></el-progress>
                <el-progress :percentage="100" color="#3bc1fe"></el-progress>
                <el-progress :percentage="100" color="#3bc1fe"></el-progress>
                <el-progress :percentage="100" color="#3bc1fe"></el-progress>
              </div>
              <div class="progressspan">
                <span>不合格专家</span>
                <span>普通专家</span>
                <span>银牌专家</span>
                <span>金牌专家</span>
              </div>
              <div class="lbmaxdiv">
                <div>
                  <img
                    :src="'http://119.3.109.199:9091/profile/common/image/2021/10/10/72b116fbfd0f47cfbb2be085493a4119.svg'" />
                  <div>警告</div>
                </div>
                <div>
                  <img
                    :src="'http://119.3.109.199:9091/profile/common/image/2021/10/10/0a4db65a3a594559b6d913025662010f.svg'" />
                  <div>内容推荐</div>
                </div>
                <div>
                  <img
                    :src="'http://119.3.109.199:9091/profile/common/image/2021/10/10/0a4db65a3a594559b6d913025662010f.svg'" />
                  <div>项目推荐</div>
                </div>
                <div>
                  <img
                    :src="'http://119.3.109.199:9091/profile/common/image/2021/10/10/0a4db65a3a594559b6d913025662010f.svg'" />
                  <div>排名推荐</div>
                </div>
              </div>
            </div>
          </el-col> -->
          <!-- <el-col :span="24">
            <el-divider></el-divider>
            <div class="zjzs">专家证书</div>
            <div class="zjzsimg">
              <el-image style="width: 50rem; height: 30rem;margin: 1rem 0rem;" :src="zjtxurl" fit="contain">
              </el-image>
            </div>
          </el-col> -->
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
   data: function () {
      return {
        // 专家头像
        zjtxurl: "",
      }
    },
    methods: {
      // 获取用户信息
      getUserInfo() {
        let _this = this;
        // 获取当前用户信息
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.getItem("userId"),
          params: '',
          //请求成功的回调函数
        }).then((res) => {
          _this.zjname = res.data.data.nickName;
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/files/' + res.data.data.avatar,
            data: '',
            //请求成功的回调函数
          }).then((e) => {
            if (e.data.code == 200) {
              // 获取专家头像
              _this.zjtxurl = e.data.data.fileUrl
            }
          }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
        }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
      }


    },
    // 页面加载完毕事件
    mounted() {
      this.getUserInfo();
    }
  }
</script>

<style src="@/style/PersonalCenter/UnioncurrencyCom.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }

  .zjname {
    font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    text-align: left;
  }

  .zjtyple {
    font-family: 'Arial Normal', 'Arial';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #7F7F7F;
    text-align: left;
  }

  .zjbh {
    font-family: 'Arial Normal', 'Arial';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #AAAAAA;
    text-align: left;
    margin-top: 2rem;
  }

  .progress {
    display: flex;
    justify-content: flex-start;
  }

  .el-progress {
    position: relative;
    line-height: 1;
    width: 9rem;
  }

  .progressspan {
    padding-left: 1rem;
    display: flex;
    justify-content: flex-start;
  }

  .progressspan span {
    font-size: 0.8rem;
    width: 9rem;
  }

  .lbmaxdiv {
    padding-left: 1rem;
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .lbmaxdiv div {
    margin: 0rem 1.5rem;
  }

  .bg-purplexx {
    height: 15rem;
  }

  .zjzs {
    font-family: 'Arial Normal', 'Arial';
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    color: #555555;
  }
</style>
