<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>门户信息管理</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="ruleForm" label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="标题:">
                <template>
                  <el-input v-model="tableDataval.articleTitle" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态:">
                <template>
                  <el-select v-model="tableDataval.articleStatus" placeholder="请选择" clearable>
                    <!-- （0草稿，1已发布） -->
                    <el-option label="草稿" :value="0"></el-option>
                    <el-option label="已发布" :value="1"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
                <el-button type="primary" @click="fabu">发布门户信息</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#ffffff" text-color="#8D8D8D" active-text-color="#008FE0" style="font-weight: bold;">
          <el-menu-item index="">全部</el-menu-item>
          <!-- <el-menu-item index="0">相关资讯</el-menu-item> -->
          <el-menu-item index="1">校企资源</el-menu-item>
          <el-menu-item index="2">养老人才</el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <div v-for="item in tableData">
            <div style="display: flex;margin-bottom: 20px;justify-content: space-around;">
              <div style="width: 30%;">
                <img style="width: 300px;height: 180px;object-fit: scale-down;" :src="item.articleCoverUrl" />
              </div>
              <div style="width: 40%;display: flex;flex-direction: column;justify-content: space-between;">
                <h3 style="margin: 0;">{{item.articleTitle}}</h3>
                <el-button style="width: 80px;" v-if="item.articleStatus == 0">草稿</el-button>
                <el-button style="width: 80px;" type="primary" v-if="item.articleStatus == 1">已发布</el-button>
                <div>预览({{item.browseNum}})</div>
              </div>
              <div
                style="width: 30%;display: flex;flex-direction: column;justify-content: space-between;align-items: center;">
                <div>{{item.createTime}}</div>
                <div>
                  <el-button type="primary" v-if="item.articleStatus == 0" @click="releaseclick(item)">发布</el-button>
                  <el-button type="primary" v-if="item.articleStatus == 1" @click="detail(item)">查看</el-button>
                  <el-button type="danger" @click="delect(item)">删除</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        horizontal: '0',
        // 当前的类型
        activeIndex: '',
        value1: "",
        // 查询条件
        ruleForm: {
          name: '',
          value: '',
        },
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 为用户ID
          'userId': localStorage.getItem("userId"),
          // 项目名称
          'articleTitle': "",
          // 项目状态
          'articleStatus': '',
          'type': '',
        },
        // 总数
        total: 0,
        // 表格数据
        tableData: [],
      }
    },
    methods: {
      fabu() {
        this.$router.push('/Publishingportal')
      },
      // 顶栏点击事件
      handleSelect(key, keyPath) {
        this.tableDataval.pageNum = "1";
        this.tableDataval.pageSize = "10";
        this.tableDataval.articleTitle = "";
        this.tableDataval.type = key;
        this.onSubmit();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/system/doorArticle/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize + '&type=' + this.tableDataval.type + '&articleStatus=' + this
            .tableDataval.articleStatus + '&articleTitle=' + this.tableDataval.articleTitle,
          data: this.tableDataval,
          headers: {
            'Authorization': ''
          }
          //请求成功的回调函数
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.total = res.data.total;
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 查看&&审核
      examine(e, i) {
        let params = {
          "projectId": e.articleId,
          // 1:基本信息、2:参与报名、3:参与合作信息、4:项目过程记录、5:预算使用记录、6:专家评审验收
          "type": 1
        }
        // 跳转到项目详情页面弹窗
        this.$router.push({
          name: 'Projectdetails',
          params: params
        });
      },
      // 项目删除点击事件
      deleteclick(e) {
        this.$confirm('此操作将永久删除该项目, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/cooperation/cooProject/' + e.articleId,
            params: '',
            //请求成功的回调函数
          }).then((res) => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: '删除成功!'
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
            this.onSubmit();
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 删除
      delect(e) {
        this.$confirm('是否继续删除?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/system/doorArticle/' + e.articleId,
            data: {},
            //请求成功的回调函数
          }).then((res) => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: '删除成功!'
            });
            this.onSubmit();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 发布点击事件
      releaseclick(e) {
        let params = {
          "articleId": e.articleId,
          "articleStatus": 1
        }
        this.$confirm('此操作将发布该门户, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/system/doorArticle/',
            data: params,
            //请求成功的回调函数
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'success',
                message: res.data.msg
              });
              this.onSubmit();
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'error',
                message: res.data.msg
              });
              this.onSubmit();
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消发布'
          });
        });
      },
      // 查看详情
      detail(e) {
        localStorage.setItem("xxarticleId", e.articleId)
        let params = {
          "recruitId": e.recruitId
        }
        // 跳转到项目详情页面弹窗
        this.$router.push({
          name: 'Portaldetails',
          params: params
        });
      },

      // 撤销点击事件
      revokeclick(e) {
        let params = {
          "projectId": e.articleId,
          "type": "0"
        }
        this.$confirm('此操作将撤销该项目, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/cooperation/cooProject/changeStatus',
            data: params,
            //请求成功的回调函数
          }).then((res) => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: '撤销成功!'
            });
            this.onSubmit();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消撤销'
          });
        });
      },
      // 查看点击事件
      see(e) {
        let params = {
          "projectId": e.articleId,
          "type": 1
        }
        // 跳转到项目详情页面
        this.$router.push({
          name: 'Projectdetails',
          params: params
        });
      },
      // 编辑点击事件
      editclick(e) {
        // 跳转到发起项目页面
        this.$router.push({
          name: 'Startcooperation',
          params: {
            projectId: e.articleId,
          }
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit()
    }
  }
</script>

<style src="@/style/Team/ContentmanagementCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
