import { render, staticRenderFns } from "./trainingActivitydetails.vue?vue&type=template&id=51ee999a&scoped=true&"
import script from "./trainingActivitydetails.vue?vue&type=script&lang=js&"
export * from "./trainingActivitydetails.vue?vue&type=script&lang=js&"
import style0 from "@/style/Team/WriteanarticlexiangqingCom.css?vue&type=style&index=0&id=51ee999a&scoped=true&lang=css&"
import style1 from "./trainingActivitydetails.vue?vue&type=style&index=1&id=51ee999a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ee999a",
  null
  
)

export default component.exports