<template>
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="hallmaxdiv">
          <el-row>
            <el-col :span="24">
              <div class="halltop">
                <div class="halltoptitle">培训交流大厅</div>
                <div class="halltopss">
                  <el-input placeholder="请输入内容" v-model="tableDataval.articleTitle"
                    class="input-with-select halltopssinput" clearable>
                    <el-button slot="append" class="halltopssann" @click="searchclick1()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="18" style="padding-right: 10px !important;" v-loading="fullscreenLoading"
              element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)">
              <!-- 文章 -->
              <div class="grid-content bg-purple">
                <div class="zuoxianr">
                  <el-menu :default-active="aaaactiveIndex" class="el-menu-demo" mode="horizontal"
                    @select="handleSelect" active-text-color="#1891FF" text-color="#000" style="font-size: 1rem;">
                    <el-menu-item index="1">活动列表</el-menu-item>
                    <!--                    <el-menu-item index="1">文章</el-menu-item>
                    <el-menu-item index="2">问题</el-menu-item> -->
                  </el-menu>
                </div>
                <!-- 文章 -->
                <el-row v-if="formdata" v-for="item in formdata"
                  style="border-bottom: 1px solid #f2f2f2;margin-bottom: 10px;">
                  <el-col :span="16">
                    <div class="grid-content bg-purple zuoxianrtitle">
                      <div style="white-space: nowrap;">
                        <!-- 标题 -->
                        <el-link :underline="false" @click="Clicktoview2(item.articleId)"
                          style="line-height: 3rem;font-size: 1.3rem;">
                          {{item.articleTitle}}
                        </el-link>
                      </div>
                    </div>
                    <div class="zuoxianrjj">
                      <!-- 简介 -->
                      <span>{{item.articleIntroduce}}</span>
                    </div>
                    <div class="dibutext">
                      <div><i class="el-icon-chat-dot-round"></i>评论：({{item.commentNum}})</div>
                      <div><i class="el-icon-star-off"></i>关注：({{item.followNum}})</div>
                      <div><i class="el-icon-thumb"></i>点赞：({{item.fabulousNum}})</div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <!-- 图片 -->
                    <div class="homdeann" v-if="item.articleCoverUrl">
                      <el-image :src="item.articleCoverUrl" fit="contain"></el-image>
                    </div>
                  </el-col>
                </el-row>

                <!-- 问题 -->
                <el-row v-if="formdata2" v-for="item in formdata2"
                  style="border-bottom: 1px solid #f2f2f2;margin-bottom: 10px;">
                  <el-col :span="16">
                    <div class="grid-content bg-purple zuoxianrtitle">
                      <div style="white-space: nowrap;">
                        <!-- 标题 -->
                        <el-link :underline="false" @click="Clicktoview2(item.questionId)" style="line-height: 2rem;">
                          {{item.questionTitle}}
                        </el-link>
                      </div>
                    </div>
                    <div class="zuoxianrjj">
                      <!-- 简介 -->
                      <span>{{item.questionContent}}</span>
                    </div>
                    <div class="dibutext">
                      <div><i class="el-icon-chat-dot-round"></i>评论：({{item.answerNum}})</div>
                      <div><i class="el-icon-star-off"></i>关注：({{item.followNum}})</div>
                      <div><i class="el-icon-thumb"></i>点赞：({{item.fabulousNum}})</div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <!-- 图片 -->
                    <div class="homdeann" v-if="item.questionImg">
                      <el-image :src="item.questionImg" fit="contain"></el-image>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!-- 分页 -->
              <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pagination.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
              </div>
            </el-col>

            <!-- 右边内容 -->
            <el-col :span="6" style="padding-left: 10px !important;margin-top: 10px;">
              <el-row :gutter="20"
                style="background-color: #FFFFFF;border-radius: 4px;margin-bottom: 10px;height: 15rem;">
                <div style="display: flex;padding: 1rem 2rem;">
                  <div>
                    <img v-if="unionLevel==1" style="width: 4rem;height: 4rem;" src="../../assets/lv1.jpg">
                    <img v-if="unionLevel==2" style="width: 4rem;height: 4rem;" src="../../assets/lv2.jpg">
                    <img v-if="unionLevel==3" style="width: 4rem;height: 4rem;" src="../../assets/lv3.jpg">
                    <img v-if="unionLevel==4" style="width: 4rem;height: 4rem;" src="../../assets/lv4.jpg">
                    <img v-if="unionLevel==5" style="width: 4rem;height: 4rem;" src="../../assets/lv5.jpg">
                    <img v-if="unionLevel==6" style="width: 4rem;height: 4rem;" src="../../assets/lv6.jpg">
                  </div>
                  <div style="text-align: left;margin-left: 1rem;">
                    <div>{{nickName}}</div>
                    <div class="mmb">联盟积分：{{unionCoin}}</div>
                  </div>
                </div>
                <div class="letfann">
                  <div class="letfanndiv" @click="letfanndivclick(1)">
                    <div class="letfanntext">我的关注</div>
                    <div class="letfannsun">{{sctotal}}</div>
                  </div>

                  <div class="letfanndiv" @click="letfanndivclick(2)">
                    <div class="letfanntext">发布内容</div>
                    <div class="letfannsun">{{xztotal}}</div>
                  </div>
                </div>
                <el-button type="primary" @click="Upload()" style="width: 40%;margin-top: 1.35rem;margin-bottom: 1rem;">
                  发起活动
                </el-button>
                <!--                <el-button type="primary" @click="Upload()" style="width: 40%;margin-top: 1.35rem;margin-bottom: 1rem;">
                  发文章
                </el-button>
                <el-button type="primary" @click="tiwendialogVisible=true"
                  style="width: 40%;margin-top: 1.35rem;margin-bottom: 1rem;">
                  提问
                </el-button> -->
              </el-row>

              <!-- <el-row :gutter="20" style="background-color: #FFFFFF;border-radius: 4px;">

                <div class="leftdiv">
                  <div class="leftdivtop">
                    <div class="leftdivtoptitle">专题栏目</div>
                    <el-divider></el-divider>
                  </div>
                  <div class="leftdivtopnrdiv">
                    <div class="leftdivtopnr" v-for="item in formdata2">
                      <el-link @click="Clicktoview(item.resId)">{{item.resTitle}}</el-link>&#12288;
                      <span>{{item.createTime?item.createTime.split(" ")[0]:"---"}}</span>
                    </div>
                  </div>
                </div>
              </el-row> -->
            </el-col>
          </el-row>

        </div>
      </el-col>
    </el-row>
    <el-dialog title="提问" :visible.sync="tiwendialogVisible" style="margin-top: 2rem;text-align: left;" width="60%"
      :before-close="handleClose">
      <el-form :model="ruleForm" status-icon label-width="10px" class="demo-ruleForm">
        <el-form-item label="标题（最多30个字）">
          <el-input placeholder="请输入问题" v-model="ruleForm.questionTitle" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" rows="4" placeholder="请输问题背景、条件等详细信息(选填)" v-model="ruleForm.questionContent"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="最佳答案奖励" label-width="6rem !important">
          <el-input-number :controls="false" style="width: 18rem !important;" v-model.number="ruleForm.rewardIntegral"
            placeholder="请设置最佳答案奖励的联盟积分（选填）"></el-input-number>
        </el-form-item>
        <el-form-item label="插入图片" label-width="6rem !important">
          <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-preview="handlePreview"
            :on-remove="handleRemove" :on-success="handleAvatarSuccess" :before-remove="beforeRemove" multiple
            :limit="3" accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG" :on-exceed="handleExceed" :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png/jpeg文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tiwen()">发布问题</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        unionLevel: localStorage.getItem("unionLevel"),
        ruleForm: {
          questionTitle: '',
          questionContent: '',
          rewardIntegral: undefined,
          questionImg: []
        },
        fullscreenLoading: false,
        wzAdnwt: localStorage.getItem("wzAdnwt"),
        aaaactiveIndex: "1",
        // 联盟积分
        unionCoin: '',
        // 用户名
        nickName: '',
        // 总数
        total: 0,
        total2: 0,
        // 表格字段
        tableDataval: {
          // 文章标题
          "articleTitle": "",
        },
        tiwendialogVisible: false,
        // 分页
        pagination: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
        },
        // 表格数据
        formdata: {},
        // 最新资源表格
        formdata2: {},
        // 获取收藏总数
        sctotal: "0",
        // 获取下载总数
        xztotal: "0",
      }
    },
    methods: {
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      handleAvatarSuccess(res, file) {
        this.ruleForm.questionImg.push(res.data.fileId);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
      },
      handleExceed(files, fileList) {
        this.$message({
          offset: 100,
          message: `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`,
          type: 'warning'
        });
      },
      // 标题点击事件
      Clicktoview2(e) {
        localStorage.setItem('articleId', e);
        // 跳转到文章详情页面
        this.$router.push({
          name: 'Writeanarticlexiangqing'
        });
      },
      // 跳转发文章
      Upload() {
        this.$router.push('/Postarticles');
      },
      // 提问
      tiwen() {
        let _this = this;
        if (_this.ruleForm.questionImg.length > 0 && _this.ruleForm.questionImg.length == 1) {
          _this.ruleForm.questionImg = _this.ruleForm.questionImg[0]
        } else if (_this.ruleForm.questionImg.length > 0 && _this.ruleForm.questionImg.length > 1) {
          _this.ruleForm.questionImg = _this.ruleForm.questionImg.join();
        } else {
          _this.ruleForm.questionImg = "";
        }
        if (_this.ruleForm.questionTitle) {
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/communication/comQuestion',
            data: _this.ruleForm,
          }).then((res) => {
            this.$message({
              offset: 100,
              message: res.data.msg,
              type: 'success'
            });
            _this.searchclick();
            _this.tiwendialogVisible = false;
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        } else {
          this.$message({
            offset: 100,
            message: '标题不可为空！',
            type: 'warning'
          });
        }
      },
      handleSelect(key) {
        this.aaaactiveIndex = key;
        localStorage.setItem('wzAdnwt', key);
        this.pagination.pageNum = 1;
        this.pagination.pageSize = 10;
        this.searchclick();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pagination.pageSize = val;
        this.searchclick();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pagination.pageNum = val;
        this.searchclick();
      },
      searchclick1() {
        this.pagination.pageNum = 1;
        this.pagination.pageSize = 10;
        this.searchclick();
      },
      // 搜索点击事件
      searchclick() {
        this.fullscreenLoading = true;
        let url = ""
        if (this.aaaactiveIndex == 1) {
          // 文章
          url = "/communication/comArticle/list?pageNum="
        } else if (this.aaaactiveIndex == 2) {
          // 问题
          url = "/communication/comQuestion/list?pageNum="
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + url + this.pagination.pageNum +
            '&pageSize=' +
            this.pagination.pageSize,
          data: this.tableDataval,
        }).then((res) => {
          if (res.data.code == 200) {
            // 文章
            if (this.aaaactiveIndex == 1) {
              this.formdata = res.data.rows;
              this.formdata2 = {};
            } else {
              // 问题
              this.formdata2 = res.data.rows;
              this.formdata = {};
            }
          }
          this.total = res.data.total;
          this.total2 = res.data.total;
          this.fullscreenLoading = false;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 点击观看
      Clicktoview(e) {
        localStorage.setItem("resId", e);
        this.$router.push({
          name: 'ResourceDetails',
          params: {
            resId: e
          }
        });
      },
      //
      letfanndivclick(e) {
        switch (e) {
          case 1:
            // 指定左侧导航栏
            localStorage.setItem('BaractiveIndex', "/Myattention");
            localStorage.setItem('activeIndex', "1");
            this.$router.push('/Myattention');
            break;
          case 2:
            localStorage.setItem('BaractiveIndex', "/Contentmanagement");
            localStorage.setItem('activeIndex', "1");
            this.$router.push('/Contentmanagement');
            break;
          default:
            // 默认跳转工作台
            localStorage.setItem('activeIndex', "1");
            this.$router.push('/Index');
        }
      },

      // 获取收藏资源
      shocang() {
        let params = {
          "userId": localStorage.getItem("userId"),
          "pageNum": 1,
          "pageSize": 10000,
          resTitle: "",
          status: "",
        }
        // 获取收藏总数
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/resouse/resCollectRecord/list',
          params: params,
        }).then((res) => {
          this.sctotal = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.aaaactiveIndex = this.wzAdnwt ? this.wzAdnwt : "1"
      // 获取最新资源列表
      this.searchclick();
      // 获取当前用户信息
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.getItem("userId"),
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        // 联盟积分
        this.unionCoin = res.data.data.unionCoin;
        // 用户名
        this.nickName = res.data.data.nickName;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

      let params = {
        "userId": localStorage.getItem("userId"),
        "pageNum": 1,
        "pageSize": 10000,
        resTitle: "",
        status: "",
      }
      // 获取收藏总数
      this.shocang();

      // 获取下载总数
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/resouse/resDownRecord/list',
        params: params,
      }).then((res) => {
        this.xztotal = res.data.total;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
    }
  }
</script>

<style src="@/style/Team/CommunicationCom.css" scoped></style>
<style scoped>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  .el-radio--mini.is-bordered {
    padding: 6px 2px 0px 2px !important;
    border-radius: 3px;
    height: 28px;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .iconstyle {
    float: left;
    margin-right: 1rem;
  }

  .zuoxianr {
    padding: 0rem !important;
    font-size: 20px !important;
    font-weight: 800 !important;
  }

  .el-form-item__label {
    text-align: left !important;
    width: auto !important;
  }

  .zuoxianrjj {
    font-size: 18px;
    color: #616266;
    font-weight: 600;
  }
</style>
