<template>
  <div class="index">
    <el-row>
      <!-- 头部 -->
      <el-col :span="24">
        <Headnew @fatherMethod="fatherMethod" />
      </el-col>
    </el-row>
    <el-row :gutter="20" class="subject">
      <!-- 内容 -->
      <el-col :span="24" style="padding-right: 0px;">
        <!-- 相关资讯 -->
        <RelatedInformation v-if="id == 1" />
        <!-- 校企资源 -->
        <Schoolenterpriseresources v-if="id == 2" />
        <!-- 养老人才 -->
        <Elderlycaretalents v-if="id == 3" />
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem;z-index: -999;">
      <!-- 底部版权 -->
      <el-col :span="24"><Copyright/></el-col>
    </el-row>
  </div>
</template>


<script>
  // 底部版权
  import Copyright from '@/components/Copyright.vue'
  import Headnew from '@/components/Headnew.vue'
  import RelatedInformation from '@/components/RelatedInformation.vue'
  import Schoolenterpriseresources from '@/components/Schoolenterpriseresources.vue'
  import Elderlycaretalents from '@/components/Elderlycaretalents.vue'


  export default {
    name: 'app',
    components: {
      Headnew,
      Copyright,
      RelatedInformation,
      Schoolenterpriseresources,
      Elderlycaretalents
    },
   data: function () {
      return {
        id:1
      }
    },
    // 页面加载完毕触发
    mounted() {
      this.id = window.location.href.split('=')[1]?window.location.href.split('=')[1]:'1'
    },
    methods: {
      fatherMethod(id){
        this.id = id
      }
    }
  }
</script>

<style src="@/style/main.css" scoped></style>
<style>
  .el-row {
    &:last-child {
      margin-bottom: 0;
    }

    margin-left: 0px !important;
    margin-right: 0px !important;
  }
</style>
