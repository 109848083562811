<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>课程管理</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="ruleForm" label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="培训项目:">
                <template>
                  <el-input v-model="tableDataval.articleTitle" placeholder="请选择培训项目" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="课程名称:">
                <template>
                  <el-input v-model="tableDataval.articleTitle" placeholder="请输入课程名称" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
                <el-button type="primary" @click="fabu">发起培训课程</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;height: 70vh;overflow-y: auto;" v-loading="loading" element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <template>
          <div v-for="item in tableData">
            <div style="display: flex;margin-bottom: 20px;justify-content: space-around;">
              <div style="width: 40%;display: flex;flex-direction: column;justify-content: space-between;">
                <h3 style="margin: 0;">{{item.courseTitle}}</h3>
                <div style="height: 100px;margin: 0px;">
                  {{item.courseContent}}
                </div>
                <div style="display: flex;">
                  <div style="margin-right: 20px;">发起人:{{item.nickName}}</div>
                  <div style="margin-right: 20px;">主讲专家/负责人:{{item.teacherName}}</div>
                  <div>课程管理员:{{item.adminName}}</div>
                </div>
              </div>
              <div
                style="width: 30%;display: flex;flex-direction: column;justify-content: space-between;align-items: center;">
                <div>{{item.createTime}}</div>
                <div style="margin-bottom: 50px;">
                  <el-button type="primary" @click="detail(item)">查看</el-button>
                  <el-button type="danger" @click="delect(item)">删除</el-button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-col>
      <el-col :span="24">
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        horizontal: '0',
        // 当前的类型
        activeIndex: '',
        value1: "",
        // 查询条件
        ruleForm: {
          name: '',
          value: '',
        },
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 为用户ID
          'userId': localStorage.getItem("userId"),
          // 项目名称
          'articleTitle': "",
          'type': '',
        },
        // 总数
        total: 0,
        // 表格数据
        tableData: [],
        loading: true,
      }
    },
    methods: {
      fabu() {
        this.$router.push('/publishCourses')
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainCourse/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: {},
          //请求成功的回调函数
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.loading = false
          this.total = res.data.total;
          this.tableData = res.data.rows;
        }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
      },
      // 删除
      delect(e) {
        this.$confirm('是否继续删除?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/train/trainCourse/' + e.trainCourseId,
            data: {},
            //请求成功的回调函数
          }).then((res) => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: '删除成功!'
            });
            this.onSubmit();
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 查看详情
      detail(e) {
        let query = {
          "trainCourseId": e.trainCourseId
        }
        this.$router.push({
          path: 'coursePortaldetails',
          query: query
        })
      },

    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit()
    }
  }
</script>

<style src="@/style/Team/ContentmanagementCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }

  .block {
    border-top: solid #ccc 1px;
  }
</style>
