import { render, staticRenderFns } from "./LookingfortalentsCom.vue?vue&type=template&id=1ac3b684&scoped=true&"
import script from "./LookingfortalentsCom.vue?vue&type=script&lang=js&"
export * from "./LookingfortalentsCom.vue?vue&type=script&lang=js&"
import style0 from "@/style/Expert/ExpertdataCom.css?vue&type=style&index=0&id=1ac3b684&scoped=true&lang=css&"
import style1 from "./LookingfortalentsCom.vue?vue&type=style&index=1&id=1ac3b684&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ac3b684",
  null
  
)

export default component.exports