import { render, staticRenderFns } from "./publishCourses.vue?vue&type=template&id=1f26ca48&scoped=true&"
import script from "./publishCourses.vue?vue&type=script&lang=js&"
export * from "./publishCourses.vue?vue&type=script&lang=js&"
import style0 from "@/style/Team/WriteanarticleCom.css?vue&type=style&index=0&id=1f26ca48&scoped=true&lang=css&"
import style1 from "./publishCourses.vue?vue&type=style&index=1&id=1f26ca48&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f26ca48",
  null
  
)

export default component.exports