import Vue from 'vue'
import VueRouter from 'vue-router'
// 登陆页
import Home from '@/views/Home.vue'
// 门户
import Door from '@/views/Door.vue'
// 门户详情
import Portaldetails from '@/views/Portaldetails.vue'
// 首页
import Index from '@/views/Index.vue'
// 资源共享大厅
import Hall from '@/views/Hall.vue'
// 协同合作大厅
import Cooperation from '@/views/Cooperation.vue'
// 团队交流大厅
import Communication from '@/views/Team/Communication.vue'
// 人才互聘大厅
import Hirehall from '@/views/Hirehall.vue'
// 招聘单位详情
import Unitdetails from '@/views/Team/Unitdetails.vue'
// 发文章
import Postarticles from '@/views/Team/Postarticles.vue'
// 发布门户
import Publishingportal from '@/views/Team/Publishingportal.vue'
// 文章详情
import Writeanarticlexiangqing from '@/views/Team/Writeanarticlexiangqing.vue'
// 内容管理
import Contentmanagement from '@/views/Team/Contentmanagement.vue'
// 我的关注
import Myattention from '@/views/Team/Myattention.vue'
// 专栏管理
import Columnmanagement from '@/views/Team/Columnmanagement.vue'
// 发布职位
import Releaseposition from '@/views/Team/Releaseposition.vue'
// 职位管理
import Positionmanagement from '@/views/Team/Positionmanagement.vue'
// 投递记录
import Deliveryrecord from '@/views/Team/Deliveryrecord.vue'
// 职位收藏
import Positioncollection from '@/views/Team/Positioncollection.vue'
// 职位详情
import RecruitmentDetails from '@/views/Team/RecruitmentDetails.vue'
// 人才库
import Lookingfortalents from '@/views/Team/Lookingfortalents.vue'
// 简历管理
import Resumemanagement from '@/views/Team/Resumemanagement.vue'

// 联盟专家
import Union from '@/views/Union.vue'
// 注册
import Register from '@/views/register/Register.vue'
// 注册内容页面
import Inside from '@/views/register/Inside.vue'
// 专家个人信息
import Expertapplication from '@/views/Expert/Expertapplication.vue'
// 我的证书
import MyCertificate from '@/views/Hire/MyCertificate.vue'

// 专家等级证书
import Certificatedj from '@/views/Expert/Certificatedj.vue'
// 专家申请审核列表
import Expertdata from '@/views/Expert/Expertdata.vue'
// 资源审核
import Resourceaudit from '@/views/Expert/Resourceaudit.vue'
// 资源审核详情页
import Resourceauditdetails from '@/views/Expert/Resourceauditdetails.vue'
// 机构管理（平台管理员）
import Mechanism from '@/views/Systemmanagement/Mechanism.vue'
// 门户信息管理
import PortalManagement from '@/views/Systemmanagement/PortalManagement.vue'

// 用户管理（机构管理员）
import User from '@/views/Systemmanagement/User.vue'
// 上传资源
import Upload from '@/views/Resources/Upload.vue'
// 上传资源
import Myresources from '@/views/Resources/Myresources.vue'
// 资源详情,点击观看进入
import ResourceDetails from '@/views/Resources/ResourceDetails.vue'
// 收藏资源
import Resourcecollection from '@/views/Resources/Resourcecollection.vue'
// 已购视频
import Purchasedvideo from '@/views/Resources/Purchasedvideo.vue'
// 下载历史
import Downloadrecord from '@/views/Resources/Downloadrecord.vue'
// 联盟积分
import Unioncurrency from '@/views/PersonalCenter/Unioncurrency.vue'
// 修改密码
import Changepassword from '@/views/PersonalCenter/Changepassword.vue'

// 专家详情
import Expertdetails from '@/views/Expert/Expertdetails.vue'
// 联盟专家搜索
import Expertsearch from '@/views/Expert/Expertsearch.vue'
// 视频播放页面
import Video from '@/views/Expert/Video.vue'
// 发起合作
import Startcooperation from '@/views/Cooperation/Startcooperation.vue'
// 我的项目
import Myproject from '@/views/Cooperation/Myproject.vue'
// 我的项目详情
import Projectdetails from '@/views/Cooperation/Projectdetails.vue'
// 我的项目详情弹窗
import Project from '@/views/Cooperation/Project.vue'

// 师资培训专栏
import teacherTrainingcolumn from '@/views/teacherTrainingcolumn.vue'
// 发起师资培训
import launchEvent from '@/views/Hire/launchEvent.vue'
//
import trainingNewsletter from '@/views/Hire/trainingNewsletter.vue'
// 培训详情
import trainingDetails from '@/views/Hire/trainingDetails.vue'

// 发起培训活动
import trainingActivities from '@/views/Hire/trainingActivities.vue'
// 培训活动详情
import trainingActivitydetails from '@/views/Hire/trainingActivitydetails.vue'
// 培训活动列表
import trainingManagement from '@/views/Hire/trainingManagement.vue'

// 发起培训申报
import initiateTrainingApplication from '@/views/Hire/initiateTrainingApplication.vue'
// 培训申报列表
import trainingApplication from '@/views/Hire/trainingApplication.vue'
// 培训申报详情
import declarationDetails from '@/views/Hire/declarationDetails.vue'
// 培训申报审核
import trainingApplicationexamine from '@/views/Hire/trainingApplicationexamine.vue'
// 培训通知列表
import trainingNotice from '@/views/Hire/trainingNotice.vue'
// 发起培训通知
import initiateNewsexamine from '@/views/Hire/initiateNewsexamine.vue'
// 培训通知审核
import newsExamine from '@/views/Hire/newsExamine.vue'
// 课程管理
import courseManagement from '@/views/Hire/courseManagement.vue'
// 发布课程
import publishCourses from '@/views/Hire/publishCourses.vue'
// 相关资讯
import RelatedInformation from '@/views/RelatedInformation.vue'
// 培训通知
import trainingPortaldetails from '@/views/Hire/trainingPortaldetails.vue'
// 课程详情
import coursePortaldetails from '@/views/Hire/coursePortaldetails.vue'
// 相关资讯详情
import relatedInformationdetails from '@/views/Hire/relatedInformationdetails.vue'
// 相关资讯详情
import newxiangqing from '@/views/Hire/newxiangqing.vue'


Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};
//如果以上代码失效，可以把两种都写上，就可以解决了
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  // 登陆页
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  // 门户
  {
    path: '/',
    name: 'Door',
    component: Door
  },
  // 门户详情
  {
    path: '/Portaldetails',
    name: 'Portaldetails',
    component: Portaldetails
  },
  // 首页
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  // 资源共享大厅
  {
    path: '/hall',
    name: 'Hall',
    component: () => import('../views/Hall.vue')
  },
  // 协同合作大厅
  {
    path: '/cooperation',
    name: 'Cooperation',
    component: () => import('../views/Cooperation.vue')
  },
  // 团队交流大厅
  {
    path: '/hirehall',
    name: 'Hirehall',
    component: () => import('../views/Hirehall.vue')
  },
  // 相关资讯
  {
    path: '/RelatedInformation',
    name: 'RelatedInformation',
    component: () => import('../views/RelatedInformation.vue')
  },
  // 招聘单位详情
  {
    path: '/unitdetails',
    name: 'Unitdetails',
    component: () => import('../views/Team/Unitdetails.vue')
  },
  // 发文章
  {
    path: '/postarticles',
    name: 'Postarticles',
    component: () => import('../views/Team/Postarticles.vue')
  },
  // 发布门户
  {
    path: '/publishingportal',
    name: 'Publishingportal',
    component: () => import('../views/Team/Publishingportal.vue')
  },
  // 文章详情
  {
    path: '/writeanarticlexiangqing',
    name: 'Writeanarticlexiangqing',
    component: () => import('../views/Team/Writeanarticlexiangqing.vue')
  },

  // 内容管理
  {
    path: '/contentmanagement',
    name: 'Contentmanagement',
    component: () => import('../views/Team/Contentmanagement.vue')
  },
  // 我的关注
  {
    path: '/myattention',
    name: 'Myattention',
    component: () => import('../views/Team/Myattention.vue')
  },
  // 专栏管理
  {
    path: '/columnmanagement',
    name: 'Columnmanagement',
    component: () => import('../views/Team/Columnmanagement.vue')
  },
  // 人才互聘大厅
  {
    path: '/communication',
    name: 'Communication',
    component: () => import('../views/Team/Communication.vue')
  },
  // 发布职位
  {
    path: '/Releaseposition',
    name: 'Releaseposition',
    component: () => import('../views/Team/Releaseposition.vue')
  },
  // 职位管理
  {
    path: '/Positionmanagement',
    name: 'Positionmanagement',
    component: () => import('../views/Team/Positionmanagement.vue')
  },
  // 职位管理详情
  {
    path: '/Position2',
    name: 'Position2',
    component: () => import('../views/Team/Position2.vue')
  },
  // 投递记录
  {
    path: '/Deliveryrecord',
    name: 'Deliveryrecord',
    component: () => import('../views/Team/Deliveryrecord.vue')
  },
  // 职位收藏
  {
    path: '/Positioncollection',
    name: 'Positioncollection',
    component: () => import('../views/Team/Positioncollection.vue')
  },
  // 职位详情
  {
    path: '/RecruitmentDetails',
    name: 'RecruitmentDetails',
    component: () => import('../views/Team/RecruitmentDetails.vue')
  },
  // 人才库
  {
    path: '/Lookingfortalents',
    name: 'Lookingfortalents',
    component: () => import('../views/Team/Lookingfortalents.vue')
  },
  // 简历管理
  {
    path: '/Resumemanagement',
    name: 'Resumemanagement',
    component: () => import('../views/Team/Resumemanagement.vue')
  },

  // 联盟专家
  {
    path: '/union',
    name: 'Union',
    component: () => import('../views/Union.vue')
  },
  // 注册
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/Register.vue')
  },
  // 注册内容页面
  {
    path: '/inside',
    name: 'Inside',
    component: () => import('../views/register/Inside.vue')
  },
  // 专家个人信息
  {
    path: '/expertapplication',
    name: 'Expertapplication',
    component: () => import('../views/Expert/Expertapplication.vue')
  },
  // 我的证书
  {
    path: '/MyCertificate',
    name: 'MyCertificate',
    component: () => import('../views/Hire/MyCertificate.vue')
  },
  // 专家等级证书
  {
    path: '/certificatedj',
    name: 'Certificatedj',
    component: () => import('../views/Expert/Certificatedj.vue')
  },
  // 专家申请审核（平台）列表
  {
    path: '/expertdata',
    name: 'Expertdata',
    component: () => import('../views/Expert/Expertdata.vue')
  },
  // 资源审核
  {
    path: '/resourceaudit',
    name: 'Resourceaudit',
    component: () => import('../views/Expert/Resourceaudit.vue')
  },
  // 资源审核详情页
  {
    path: '/resourceauditdetails',
    name: 'Resourceauditdetails',
    component: () => import('../views/Expert/Resourceauditdetails.vue')
  },
  // 机构管理（平台管理员）
  {
    path: '/mechanism',
    name: 'Mechanism',
    component: () => import('../views/Systemmanagement/Mechanism.vue')
  },
  // 门户信息管理
  {
    path: '/portalManagement',
    name: 'PortalManagement',
    component: () => import('../views/Systemmanagement/PortalManagement.vue')
  },
  // 用户管理（机构管理员）
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/Systemmanagement/User.vue')
  },
  // 上传资源
  {
    path: '/upload',
    name: 'Upload',
    component: () => import('../views/Resources/Upload.vue')
  },
  // 我的资源
  {
    path: '/myresources',
    name: 'Myresources',
    component: () => import('../views/Resources/Myresources.vue')
  },
  // 资源详情,点击观看进入
  {
    path: '/resourceDetails',
    name: 'ResourceDetails',
    component: () => import('../views/Resources/ResourceDetails.vue')
  },
  //  我的收藏
  {
    path: '/resourcecollection',
    name: 'Resourcecollection',
    component: () => import('../views/Resources/Resourcecollection.vue')
  },
  //  已购视频
  {
    path: '/purchasedvideo',
    name: 'Purchasedvideo',
    component: () => import('../views/Resources/Purchasedvideo.vue')
  },
  //  下载历史
  {
    path: '/downloadrecord',
    name: 'Downloadrecord',
    component: () => import('../views/Resources/Downloadrecord.vue')
  },
  // 联盟积分
  {
    path: '/unioncurrency',
    name: 'Unioncurrency',
    component: () => import('../views/PersonalCenter/Unioncurrency.vue')
  },
  // 修改密码
  {
    path: '/changepassword',
    name: 'Changepassword',
    component: () => import('../views/PersonalCenter/Changepassword.vue')
  },

  // 专家详情
  {
    path: '/expertdetails',
    name: 'Expertdetails',
    component: () => import('../views/Expert/Expertdetails.vue')
  },
  // 联盟专家搜索
  {
    path: '/expertsearch',
    name: 'Expertsearch',
    component: () => import('../views/Expert/Expertsearch.vue')
  },
  // 视频播放
  {
    path: '/video',
    name: 'Video',
    component: () => import('../views/Expert/Video.vue')
  },
  // 发起合作
  {
    path: '/startcooperation',
    name: 'Startcooperation',
    component: () => import('../views/Cooperation/Startcooperation.vue')
  },
  // 我的项目
  {
    path: '/myproject',
    name: 'Myproject',
    component: () => import('../views/Cooperation/Myproject.vue')
  },
  // 我的项目详情
  {
    path: '/Projectdetails',
    name: 'Projectdetails',
    component: () => import('../views/Cooperation/Projectdetails.vue')
  },
  // 我的项目详情弹窗
  {
    path: '/Project',
    name: 'Project',
    component: () => import('../views/Cooperation/Project.vue')
  },


  // 师资培训专栏
  {
    path: '/teacherTrainingcolumn',
    name: 'teacherTrainingcolumn',
    component: () => import('../views/teacherTrainingcolumn.vue')
  },
  // 发起师资培训
  {
    path: '/launchEvent',
    name: 'launchEvent',
    component: () => import('../views/Hire/launchEvent.vue')
  },
  // 参与培训项目
  {
    path: '/trainingNewsletter',
    name: 'trainingNewsletter',
    component: () => import('../views/Hire/trainingNewsletter.vue')
  },
  // 培训详情
  {
    path: '/trainingDetails',
    name: 'trainingDetails',
    component: () => import('../views/Hire/trainingDetails.vue')
  },
  // 发起培训活动
  {
    path: '/trainingActivities',
    name: 'trainingActivities',
    component: () => import('../views/Hire/trainingActivities.vue')
  },
  // 培训活动详情
  {
    path: '/trainingActivitydetails',
    name: 'trainingActivitydetails',
    component: () => import('../views/Hire/trainingActivitydetails.vue')
  },
  // 培训活动列表
  {
    path: '/trainingManagement',
    name: 'trainingManagement',
    component: () => import('../views/Hire/trainingManagement.vue')
  },
  // 培训申报
  {
    path: '/trainingApplication',
    name: 'trainingApplication',
    component: () => import('../views/Hire/trainingApplication.vue')
  },
  // 培训申报详情
  {
    path: '/declarationDetails',
    name: 'declarationDetails',
    component: () => import('../views/Hire/declarationDetails.vue')
  },
  // 发起培训申报
  {
    path: '/initiateTrainingApplication',
    name: 'initiateTrainingApplication',
    component: () => import('../views/Hire/initiateTrainingApplication.vue')
  },
  // 培训申报审核
  {
    path: '/trainingApplicationexamine',
    name: 'trainingApplicationexamine',
    component: () => import('../views/Hire/trainingApplicationexamine.vue')
  },
  // 发起培训通知
  {
    path: '/initiateNewsexamine',
    name: 'initiateNewsexamine',
    component: () => import('../views/Hire/initiateNewsexamine.vue')
  },
  // 培训通知
  {
    path: '/trainingNotice',
    name: 'trainingNotice',
    component: () => import('../views/Hire/trainingNotice.vue')
  },
  // 培训通知审核
  {
    path: '/newsExamine',
    name: 'newsExamine',
    component: () => import('../views/Hire/newsExamine.vue')
  },
  // 课程管理
  {
    path: '/courseManagement',
    name: 'courseManagement',
    component: () => import('../views/Hire/courseManagement.vue')
  },
  // 发布课程
  {
    path: '/publishCourses',
    name: 'publishCourses',
    component: () => import('../views/Hire/publishCourses.vue')
  },
  // 培训通知
  {
    path: '/trainingPortaldetails',
    name: 'trainingPortaldetails',
    component: () => import('../views/Hire/trainingPortaldetails.vue')
  },
  // 课程详情
  {
    path: '/coursePortaldetails',
    name: 'coursePortaldetails',
    component: () => import('../views/Hire/coursePortaldetails.vue')
  },
  // 相关资讯详情
  {
    path: '/relatedInformationdetails',
    name: 'relatedInformationdetails',
    component: () => import('../views/Hire/relatedInformationdetails.vue')
  },
  {
    path: '/newxiangqing',
    name: 'newxiangqing',
    component: () => import('../views/Hire/newxiangqing.vue')
  },
  
]

const router = new VueRouter({
  routes
})

export default router
