<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>专家申请审核</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="6" style="text-align: left;margin-bottom: 2rem;">
        <el-radio-group v-model="radio1">
          <el-radio-button label="找人才"></el-radio-button>
          <el-radio-button label="人才库"></el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="ruleForm" label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="职位类型:">
                <template>
                  <el-select v-model="ruleForm.industry" placeholder="请选择职位行业">
                    <el-option label="养老行业" value="0">全职</el-option>
                    <el-option label="家政行业" value="1">兼职</el-option>
                    <el-option label="家政行业" value="2">实习</el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="毕业学校:">
                <template>
                  <el-input v-model="ruleForm.graduateSchool" placeholder="请输入毕业学校" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="行业:">
                <template>
                  <el-select v-model="ruleForm.industry" placeholder="请选择职位行业">
                    <el-option label="养老行业" value="0"></el-option>
                    <el-option label="家政行业" value="1"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="求职薪资:">
                <template>
                  <el-select v-model="ruleForm.jobSalary" placeholder="请选择求职薪资">
                    <el-option v-for="item in job_salary" :key="item.dictLabel" :label="item.dictValue"
                      :value="item.dictLabel">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="工作城市:">
                <template>
                  <el-cascader clearable style="float: left;margin-left: 0.4rem;width: 100%;" v-model="ruleForm.jobCity"
                    :options="options" :props="{ expandTrigger: 'hover' }" @change="chooseArea">
                  </el-cascader>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">

      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%" :show-header="false">
            <el-table-column prop="" label="">
              <template slot-scope="scope">

              </template>
            </el-table-column>
            <el-table-column prop="" label="">
              <template slot-scope="scope">

              </template>
            </el-table-column>
            <el-table-column prop="" label="" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" style="color: #67C23A;" @click="examine(scope.row,2)">查看简历
                </el-button>
                <el-button type="text" size="small" v-if="scope.row.status == 1" @click="examine(scope.row)">加入人才库
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="ruleForm.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import commonComponents from '@/components/register/commonComponents.vue'
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    name: '',
    components: {
      commonComponents
    },
    data: function() {
      return {
        radio1: '找人才',
        options: cities,
        // 查询条件
        ruleForm: {
          // 职位类型
          industry: '',
          // 毕业学校
          graduateSchool: '',
          // 行业
          industry: '',
          // 求职薪资
          jobSalary: '',
          // 工作城市
          jobCity: '',
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
        },
        experiencelist: [],
        education: [],
        job_salary: [],
        // 总数
        total: 0,
        tableData: [],
      }
    },
    methods: {
      chooseArea(value) { //区域地址改变
        this.ruleForm.jobCity = value.join();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.ruleForm.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.ruleForm.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/recruit/recruitResume/list?pageNum=' + this.ruleForm.pageNum +
            '&pageSize=' + this
            .ruleForm.pageSize,
          data: this.ruleForm,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      let params = ["job_salary", "education", "experience", "financing", "scale", "nature", "res_type"]
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/pubilc/getDictLists',
        data: params,
      }).then((res) => {
        // 岗位薪资
        this.job_salary = res.data.data.job_salary
        // 学历要求
        this.education = res.data.data.education
        // 工作经验
        this.experiencelist = res.data.data.experience
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      this.onSubmit();
    },
    created() {
      // 把指向vue对象保存给_this
      let _this = this;
      _this.options = element_china_area_data__WEBPACK_IMPORTED_MODULE_1__.regionData
      console.log(_this.options)
    }
  }
</script>

<style src="@/style/Expert/ExpertdataCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
