<template>
  <div class="MechanismCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 5.33625rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>培训项目立项发布</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <div style="text-align: right;padding-right: 2rem;position: fixed;top: 10rem;right: 2rem;">
        <el-button type="primary" @click="$router.back()">返回</el-button>
      </div>
      <el-col :span="24">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
          style="padding-left: 10%;padding-right: 10%;">
          <!-- 头部文字 -->
          <div class="toptext">
            <p>项目发起人信息</p>
          </div>
          <el-form-item label="项目发起人" prop="nickName">
            <el-input v-model="ruleForm.nickName" disabled></el-input>
          </el-form-item>
          <el-form-item label="单位名称" prop="orgName">
            <el-input v-model="ruleForm.orgName" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系号码" prop="phonenumber">
            <el-input v-model="ruleForm.phonenumber" placeholder="请填写手机号码"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="ruleForm.email" placeholder="请填写邮箱"></el-input>
          </el-form-item>
          <div class="toptext">
            <p>项目信息</p>
          </div>

          <!--          <el-form-item label="邀请成员">
            <el-select v-model="ruleForm.inviteMembers" filterable placeholder="请选择" multiple>
              <el-option v-for="item in signupNumlist" :key="item.userId" :label="item.nickName+'('+item.job+')'"
                :value="item.userId">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="关联培训申报">
            <el-select v-model="ruleForm.projectId" placeholder="请选择关联培训" :clearable="true">
              <el-option v-for="item in resTypelist" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称" prop="projectName">
            <el-input v-model="ruleForm.projectName" placeholder="请填写项目名称"></el-input>
          </el-form-item>
          <el-form-item label="项目编号" prop="projectNo">
            <el-input v-model="ruleForm.projectNo" disabled></el-input>
          </el-form-item>
          <el-form-item label="培训时间" prop="startTimeandcompleteTime" style="text-align: left;">
            <el-date-picker style="width: 50%;" v-model="ruleForm.startTimeandcompleteTime" type="daterange"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="报名人数" prop="allowSignupNum">
            <el-input style="width: 50%;" :disabled="allowSignupNumstyle" v-model="ruleForm.allowSignupNum"
              placeholder="报名人数"></el-input>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox label="0" style="float: left;margin-left: 1rem;">不限</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="邀请成员">
            <el-select v-model="ruleForm.inviteMembers" filterable placeholder="请选择" multiple>
              <el-option v-for="item in signupNumlist" :key="item.userId" :label="item.nickName+'('+item.job+')'"
                :value="item.userId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报名截止时间" prop="completeTime">
            <el-date-picker style="width: 100%;" v-model="ruleForm.completeTime" type="datetime" placeholder="选择日期"
              value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
            </el-date-picker>
            <span style="float: left;margin-left: 1rem;">默认截止时间为选择日期的：17:00</span>
          </el-form-item>
          <el-form-item label="项目介绍" prop="projectContent">
            <el-input type="textarea" :rows="3" v-model="ruleForm.projectContent" placeholder="项目介绍"></el-input>
          </el-form-item>

          <el-form-item label="项目附件" required>
            <div class="elupload">
              <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-success="uploadSuccess"
                :limit="10" :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-error="errorUpload"
                :show-file-list="false" ref="fjupload">
                <el-button size="small" type="primary" style="float: left;" @click="addupload()">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf
                  .jpg .mp4 单个附件不超过20MB，最多上限十条（包含但不限于以下几个附件《开班通知》《课程表》）</div>
              </el-upload>
              <el-table :data="fileData" style="width: 100%" :border="true">
                <el-table-column type="index" label="序号" width="50">
                </el-table-column>

                <!--                <el-table-column prop="chapterName" label="附件名称">
                  <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.chapterName" placement="top">
                      <el-input v-model="scope.row.chapterName" placeholder="请输入附件名称"></el-input>
                    </el-tooltip>
                  </template>
                </el-table-column> -->

                <el-table-column prop="fileName" label="附件">
                  <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.fileName" placement="top">
                      <span>{{scope.row.fileName}}</span>
                    </el-tooltip>
                    <!--                    <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-success="uploadSuccess"
                      multiple :on-error="errorUpload" :show-file-list="false">
                      <el-button size="small" type="text" @click="Reupload(scope.row)">重新上传</el-button>
                    </el-upload> -->
                  </template>
                </el-table-column>

                <el-table-column prop="createTime" label="上传时间">
                  <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.createTime" placement="top">
                      <el-input v-model="scope.row.createTime" disabled></el-input>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column prop="" label="操作" scope="scope" width="90">
                  <template slot-scope="scope">
                    <el-button type="danger" icon="el-icon-delete" circle size="small" @click="deleteclick(scope.row)">
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button type="info" @click="submitForm('ruleForm',0)">存为草稿</el-button>
            <el-button type="primary" @click="submitForm('ruleForm',1)">培训项目立项发布</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    name: '',
    data: function() {
      return {
        allowSignupNumstyle: false,
        // 不限
        checkedCities: '',
        // 当前重新上传的文件ID
        uploadSuccessID: '',
        ruleForm: {
          // 合作项目ID
          projectId: "",
          // 单位名称
          orgName: "",
          // 发起人用户ID
          userId: "",
          // 发起人姓名
          nickName: "",
          // 发起人手机号码
          phonenumber: "",
          // 项目名称
          projectName: "",
          // 项目编号
          projectNo: "",
          // 邀请人
          inviteMembers: [],
          // 已报名人数
          signupNum: "",
          startTimeandcompleteTime: [],
          // 培结束时间
          endTime: "",
          // 已使用经费(元)
          usedFunds: "",
          // 剩余经费(元)
          surplusFunds: "",
          // 项目介绍
          projectContent: "",
          // 项目附件
          projectFiles: "",
          // 项目状态（0草稿，1报名中，2合作中，3评审中，4已结项）
          projectStatus: "1",
          // 结项申请时间
          closeApplyTime: ""
        },
        // 可拉取的用户
        signupNumlist: [],
        // 二级资源信息
        fileData: [],
        // 资源类型
        resTypelist: [],
        // 专业类别
        subjectTypelist: [],
        rules: {
          nickName: [{
            required: true,
            message: '请输入项目发起人姓名',
            trigger: 'blur'
          }],
          orgName: [{
            required: true,
            message: '请输入单位名称',
            trigger: 'blur'
          }],
          projectName: [{
            required: true,
            message: '请填写项目名称',
            trigger: 'blur'
          }],
          projectNo: [{
            required: true,
            message: '请填写项目编号',
            trigger: 'blur'
          }],
          startTimeandcompleteTime: [{
            required: true,
            message: '请选择培训时间',
            trigger: 'blur'
          }],
          completeTime: [{
            required: true,
            message: '请选择报名截止时间',
            trigger: 'blur'
          }],
          projectContent: [{
            required: true,
            message: '请输入项目介绍',
            trigger: 'blur'
          }],
          phonenumber: [{
            required: true,
            message: '请输入联系号码',
            trigger: 'blur'
          }],
        },
        newprojectId: ''
      }
    },
    methods: {
      resetForm(formName) { // 重置
        this.$refs[formName].resetFields();
        this.$refs.fjupload.clearFiles();
        this.ruleForm.projectFiles = '';
        this.fileData = []
        this.ruleForm.inviteMembers = []
        this.thisgetprojectNo();
      },
      // 不限
      handleCheckedCitiesChange(val) {
        if (val == true) {
          this.allowSignupNumstyle = true;
          this.ruleForm.allowSignupNum = '';
        } else {
          this.allowSignupNumstyle = false;
        }
        console.log(this.checkedCities);
      },
      // 上传点击事件
      addupload() {
        this.uploadSuccessID = ""
      },
      // 重新上传点击事件
      Reupload(e) {
        this.uploadSuccessID = e.fileId
      },
      // 删除点击事件
      deleteclick(e) {
        console.log(e.fileId);
        // 循环二级附件
        for (let i = 0; i < this.fileData.length; i++) {
          for (var key in this.fileData[i]) {
            // 用新ID替换旧ID
            if (this.fileData[i][key] == e.fileId) {
              this.fileData.splice(i, 1);
            }
          }
        }
      },
      // 超出提示
      handleExceed(files, fileList) {
        this.$message({
          offset: 100,
          showClose: true,
          message: '上传附件不能超过 10条!',
          type: 'error'
        });
      },
      // 文件上传之前
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
          this.$message({
            offset: 100,
            showClose: true,
            message: '上传附件大小不能超过 20MB!',
            type: 'error'
          });
          return isLt2M;
        }
        if (this.fileData.length == 10) {
          this.$message({
            offset: 100,
            showClose: true,
            message: '上传附件不能超过 10条!',
            type: 'error'
          });
          return false
        }
      },
      // 文件上传成功
      uploadSuccess(response, file, fileList) {
        // 判断是否为重新上传
        if (this.uploadSuccessID != "") {
          // 循环二级附件
          for (let i = 0; i < this.fileData.length; i++) {
            for (var key in this.fileData[i]) {
              // 用新ID替换旧ID
              if (this.fileData[i][key] == this.uploadSuccessID) {
                this.fileData[i].fileId = response.data.fileId
                this.fileData[i].fileName = response.data.fileName
              }
            }
          }
          // 清空ID
          this.uploadSuccessID == ""
        } else {
          // 获取二级资源信息
          this.fileData.push({
            // 文件ID
            fileId: response.data.fileId,
            // 创建时间
            createTime: response.data.createTime,
            // 附件名称
            fileName: response.data.fileName,
            // 文件地址
            fileUrl: response.data.fileUrl,
          })
        }
      },
      // 文件上传失败
      errorUpload(response, file, fileList) {
        console.log(response.data.fileId);
        this.$message({
          offset: 100,
          showClose: true,
          message: response.msg,
          type: 'error'
        });
      },
      // 提交事件
      submitForm(formName, sun) {
        if (this.allowSignupNumstyle == true) {
          this.ruleForm.allowSignupNum = 0;
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data = {
              ...this.ruleForm
            }
            let projectFiles = [];
            for (let i in this.fileData) {
              projectFiles.push(this.fileData[i].fileId);
            }
            // 附件ID
            data.projectFiles = projectFiles ? projectFiles.join(',') : '';
            // 邀请成员ID
            data.inviteMembers = this.ruleForm.inviteMembers ? this.ruleForm.inviteMembers.join(',') : '';
            // 项目状态（0草稿，1报名中，2合作中，3评审中，4已结项）
            data.projectStatus = sun == 0 ? 0 : 1;
            data.startTime = this.ruleForm.startTimeandcompleteTime[0]
            data.endTime = this.ruleForm.startTimeandcompleteTime[1]
            let method = this.newprojectId ? 'put' : 'post' //有ID走修改、否则新增

            // 判断报名时间是否大于培训开始时间
            let date1 = new Date(data.startTime); // 培训开始时间
            let date2 = new Date(this.ruleForm.completeTime); //报名时间
            if (date2 < date1 || date2 == date1) { // 报名时间等于或者小于培训开始时间
              this.$axios({
                method: method,
                url: this.$baseUrl.test + '/train/trainProject/',
                data: data
                //请求成功的回调函数
              }).then((res) => {
                if (res.data.code == 200) {
                  this.resetForm('ruleForm');
                  // sun = 0 存为草稿
                  if (sun == 0) {
                    this.$alert(
                      '<h4>您发起的培训项目已保存为草稿</h4><br><h5>您发起的培训项目已经成功保存为草稿，请在工作台“培训管理”中再次编辑</h5>',
                      '', {
                        type: 'success',
                        center: true,
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: '确定',
                        callback: action => {}
                      });
                  } else {
                    this.$alert(
                      '<h4>您发起的培训项目已发布至师资培训专栏</h4><br><h5>恭喜，发起合作成功，您可前往师资培训专栏查看项目</h5>',
                      '', {
                        type: 'success',
                        center: true,
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: '确定',
                        callback: action => {}
                      });
                  }
                } else {
                  this.$message({
                    offset: 100,
                    showClose: true,
                    message: res.data.msg,
                    type: 'error'
                  });
                }

              }).catch(error => {
                // 处理错误
                this.$message({
                  offset: 100,
                  message: error.msg,
                  type: 'error'
                });
              });
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                message: "报名时间不能大于培训开始时间！",
                type: 'error'
              });
            }

          } else {
            return false;
          }
        });
      },
      getUserInfo() {
        let _this = this;
        // 获取当前用户信息
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.getItem("userId"),
          params: '',
          //请求成功的回调函数
        }).then((res) => {
          // 用户名称
          _this.ruleForm.nickName = res.data.data.nickName;
          // 单位名称
          _this.ruleForm.orgName = res.data.data.orgName;
          // 当前用户联系号码
          _this.ruleForm.phonenumber = JSON.parse(JSON.stringify(res.data.data.phonenumber));
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      thisgetprojectNo() {
        let str = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
          'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ];
        let res = "";
        for (let i = 0; i < 12; i++) {
          let id = Math.ceil(Math.random() * 35);
          this.ruleForm.projectNo += str[id];
        }
      }

    },
    // 页面加载完毕事件
    mounted() {
      let _this = this;
      _this.newprojectId = _this.$route.query.projectId ? _this.$route.query.projectId : "";
      _this.ruleForm.userId = localStorage.userId;
      _this.ruleForm.projectId = _this.newprojectId ? _this.newprojectId : "";
      // 如果是我的项目列表页面跳转过来的
      if (_this.newprojectId) {
        // 就通过跳转过来的项目ID获取项目详情
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/train/trainProject/' + _this.newprojectId,
          data: '',
        }).then((res) => {
          let inviteMembers = [];
          for (let i in res.data.data.inviteMembers.split(",")) {
            inviteMembers.push(Number(res.data.data.inviteMembers[i]));
          }
          res.data.data.inviteMembers = inviteMembers
          res.data.data.startTimeandcompleteTime = []
          res.data.data.startTimeandcompleteTime[0] = res.data.data.startTime
          res.data.data.startTimeandcompleteTime[1] = res.data.data.endTime
          _this.ruleForm = res.data.data
          // 获取附件列表
          if (res.data.data.projectFiles) {
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/getFileList/' + res.data.data.projectFiles,
              params: '',
            }).then((res) => {
              for (let i in res.data.data) {
                // 获取二级资源信息
                this.fileData.push({
                  // 文件ID
                  fileId: res.data.data[i].fileId,
                  // 创建时间
                  createTime: res.data.data[i].createTime,
                  // 附件名称
                  chapterName: res.data.data[i].fileName.split(".")[0],
                  fileName: res.data.data[i].fileName,
                  // 文件地址
                  fileUrl: res.data.data[i].fileUrl,
                })
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }

        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }

      // 获取可拉取的用户列表
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/list?orgId=',
        data: '',
      }).then((res) => {
        if (res.data.code == 200) {
          _this.signupNumlist = res.data.rows;
        }
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取当前用户信息
      this.getUserInfo();
      // 获取资源类型，专业类别
      let dictType = {
        'projectStatus': '4'
      }
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/train/trainReport/list',
        data: dictType
        //请求成功的回调函数
      }).then((res) => {
        let data = []
        for (var i = 0; i < res.data.rows.length; i++) {
          data.push({
            value: res.data.rows[i].trainReportId,
            label: res.data.rows[i].projectName,
          })
        }
        _this.resTypelist = data
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取随机数
      _this.thisgetprojectNo();
    }
  }
</script>

<style src="@/style/Cooperation/StartcooperationCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    width: 30% !important;
    text-align: right;
  }

  .el-form-item__error {
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }

  .upload-demo {
    border-radius: 6px;
    text-align: left;
  }

  .el-upload {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    outline: 0;
    float: left !important;
  }

  /deep/ .el-upload__tip {
    font-size: 12px;
    color: #606266;
    margin-top: 0px;
    width: 100%;
  }

  ::-webkit-scrollbar {
    display: none
  }

  h4 {
    margin: 0px;
  }

  h5 {
    margin: 0px;
    color: lightgrey;
  }

  .demo-ruleForm {
    height: 81vh;
    overflow-y: auto;
  }
</style>
