<template>
  <div class="MechanismCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 5.33625rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>培训项目课程创建</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="24">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
          style="padding: 2rem 10% 0% 5%;margin-top: 0rem;">
          <el-form-item label="关联培训" prop="projectId" label-width="150px !important">
            <el-select v-model="ruleForm.projectId" :disabled="ruleForm.projectId != ''" placeholder="请选择关联培训"
              :clearable="true">
              <el-option v-for="item in resTypelist" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程名称" prop="courseTitle" label-width="150px !important">
            <el-input v-model="ruleForm.courseTitle" placeholder="请输入课程名称">
            </el-input>
          </el-form-item>
          <el-form-item label="主讲专家/负责人" prop="teacherId" label-width="150px !important">
            <el-select v-model="ruleForm.teacherId" placeholder="请选择主讲专家/负责人" :clearable="true" filterable>
              <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程管理员" prop="adminId" label-width="150px !important">
            <el-select v-model="ruleForm.adminId" placeholder="请选择课程管理员" :clearable="true" filterable>
              <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程介绍" prop="courseContent" label-width="150px !important">
            <el-input type="textarea" :rows="4" v-model="ruleForm.courseContent" placeholder="请输入500字以内的简介"
              maxlength="500" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="附件" prop="resType" label-width="150px !important">
            <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-remove="fjhandleRemove"
              ref="fjupload" :on-exceed="fjonexceed" :on-success="fjuploadSuccess" multiple :limit="10"
              :file-list="fileList" :before-upload="beforeAvatarUpload" accept=".doc,.docx,.ppt,.pptx,.pdf">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传doc/docx/ppt/pptx/pdf文件 单个附件不超过20MB，最多上限十条</div>
            </el-upload>
          </el-form-item>
          <!--          <el-form-item label="封面" prop="resType" label-width="150px !important">
            <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :on-success="uploadSuccess"
              :on-remove="handleRemove" :on-exceed="onexceed" limit="1" style="display: flex;">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item> -->
          <!--          <el-form-item label="正文" prop="resType" label-width="150px !important">
            <quill-editor class="editor" v-model="ruleForm.articleContent" ref="myQuillEditor" :options="editorOption"
              @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)">
            </quill-editor>
          </el-form-item> -->

          <el-form-item style="padding-top: 4rem;">
            <el-button @click="resetForm('ruleForm')">重置</el-button>
            <el-button type="primary" @click="submitForm('ruleForm',1)">提交课程</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import $ from 'jquery';
  import {
    quillEditor
  }
  from "vue-quill-editor"; //调用编辑器
  import "quill/dist/quill.snow.css";
  export default {
    name: '',
    components: {
      // commonComponents
      quillEditor, //使用编辑器
    },
    data: function() {
      return {
        tableDataval: {

        },
        userList: [],
        resTypelist: [],
        ruleForm: {
          projectId: '',
          userId: localStorage.getItem("userId"),
          courseTitle: "",
          courseContent: "",
          teacherId: "",
          adminId: "",
          courseFiles: [],

          // articleContent: '', // 正文
          // articleCover: '', // 封面
        },
        rules: {
          projectId: [{
            required: true,
            message: '请选择关联培训',
            trigger: 'change'
          }],
          courseTitle: [{
            required: true,
            message: '请输入课程标题',
            trigger: 'blur'
          }],
          courseContent: [{
            required: true,
            message: '请输入课程介绍',
            trigger: 'blur'
          }],
          teacherId: [{
            required: true,
            message: '请选择主讲专家/负责人',
            trigger: 'change'
          }],
          adminId: [{
            required: true,
            message: '请选择课程管理员',
            trigger: 'change'
          }],
        }
      }
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    mounted() {
      this.getresTypelist()
      this.getUserlist()
    },
    methods: {
      getUserlist() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/list',
          params: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          let data = []
          for (let i = 0; i < res.data.rows.length; i++) {
            data.push({
              value: res.data.rows[i].userId,
              label: res.data.rows[i].nickName
            })
          }
          this.userList = data
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 获取关联培训列表
      getresTypelist() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainProject/list',
          data: {},
        }).then((res) => {
          let data = []
          for (let i = 0; i < res.data.rows.length; i++) {
            data.push({
              value: Number(res.data.rows[i].projectId),
              label: res.data.rows[i].projectName
            })
          }
          this.resTypelist = data
          // 获取跳转过来的项目ID
          this.ruleForm.projectId = this.$route.query.projectId ? Number(this.$route.query.projectId) : "";
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.$refs.fjupload.clearFiles();
        this.ruleForm.courseFiles = [];
      },
      //保存&提交
      submitForm(formName, sun) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let _this = this;
            _this.ruleForm.courseFiles = this.ruleForm.courseFiles.toString();
            this.$axios({
              method: 'post',
              url: this.$baseUrl.test + '/train/trainCourse',
              data: _this.ruleForm,
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: 'success',
                  offset: 100
                });
                this.resetForm('ruleForm')
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error',
                  offset: 100
                });
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            return false;
          }
        });
      },
      // 文件上传之前
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
          this.$message({
            offset: 100,
            showClose: true,
            message: '上传附件大小不能超过 20MB!',
            type: 'error'
          });
        }
        return isLt2M;
      },
      // 超出提示
      fjonexceed(files, fileList) {
        this.$message({
          offset: 100,
          showClose: true,
          message: '上传附件不能超过 10条!',
          type: 'error'
        });
      },
      fjuploadSuccess(response, file, fileList) {
        this.ruleForm.courseFiles.push(response.data.fileId);
      },
      fjhandleRemove(response, file, fileList) {
        this.ruleForm.courseFiles = [];
      },
      // 封面上传成功
      uploadSuccess(response, file, fileList) {
        console.log(response)
        console.log(file)
        console.log(fileList)
        this.ruleForm.articleCover = response.data.fileId + '';
      },
      // 封面删除
      handleRemove(response, file, fileList) {
        // 清空ID
        this.ruleForm.articleCover = '';
      },
      // 文件超出个数限制时的钩子
      onexceed() {
        this.$message({
          message: "封面限制上传一张！",
          type: 'warning',
          offset: 100
        });
      },
      onEditorChange() {
        //内容改变事件
        this.$emit("input", this.ruleForm.content);
      },
      fanhui() {
        window.history.go(-1)
      }

    },
  }
</script>

<style src="@/style/Team/WriteanarticleCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    text-align: right;
  }

  .ql-container {
    height: 32rem !important;
  }

  .ql-editor {
    min-height: 32rem;
  }

  .editor {
    line-height: normal;
    height: 220px;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: '文本';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: '标题1';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: '标题2';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: '标题3';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: '标题4';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: '标题5';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: '标题6';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: '标准字体';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    content: '衬线字体';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    content: '等宽字体';
  }

  #disabledMsg.el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    color: #bfd9d8;
    background-color: #eef6f6;
    border-color: #d1e5e5;
  }

  .upload-demo {
    border-radius: 6px;
    text-align: justify;
  }

  .demo-ruleForm {
    height: auto;
  }

  .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 50% !important;
    float: left;
  }
</style>
