<template>
  <div class="RegisterCom">
    <el-row :gutter="20" style="padding: 0rem 5rem;">
      <el-col :span="24" style="padding-left: 20px;">
        <div class="stepbar">
          <el-steps :active="active" simple>
            <el-step title="1:注册账号" icon="el-icon-edit"></el-step>
            <el-step title="2:完善个人信息" icon="el-icon-user-solid"></el-step>
            <el-step title="3:完成" icon="el-icon-check"></el-step>
          </el-steps>
        </div>

        <!-- 注册账号 -->
        <div class="Form1" v-if="stepsun==1">
          <el-form :model="ruleForm" :rules="rules1" ref="ruleForm" label-width="130px !important"
            class="demo-ruleForm">
            <el-form-item label="创建新账号:" prop="userName">
              <el-input clearable @blur="userNameblur" v-model="ruleForm.userName" placeholder="请设置登录账号、6-20位数字和字母组合">
              </el-input>
            </el-form-item>
            <el-form-item label="手机号码:" prop="phonenumber">
              <el-input @blur="phonenumberblur" maxlength="11" clearable v-model="ruleForm.phonenumber"
                placeholder="请输入手机号码">
              </el-input>
            </el-form-item>

            <el-form-item clearable label="密码:" prop="password">
              <el-input clearable v-model="ruleForm.password" placeholder="请输入密码" show-password></el-input>
            </el-form-item>

            <el-form-item clearable label="确认密码:" prop="confirmpwd">
              <el-input clearable v-model="ruleForm.confirmpwd" placeholder="请输入确认密码" show-password></el-input>
            </el-form-item>

            <el-form-item label="图形码:" prop="Graphic">
              <el-input clearable v-model="ruleForm.Graphic" style="width: 50%;margin-right: 10%;"
                placeholder="请输入图形验证码">
              </el-input>
              <div style="width: 40%;float: right;">
                <el-tooltip class="item" effect="dark" content="点击切换验证码" placement="bottom">
                  <img @click="verificationimgclick()" style="width: 100%;" :src="verificationimgsrc" />
                </el-tooltip>
              </div>
            </el-form-item>

            <!-- <el-form-item label="验证码:" prop="Verification">
              <el-input clearable v-model="ruleForm.Verification" style="width: 50%;margin-right: 10%;"
                placeholder="请输入验证码">
              </el-input>
              <el-button type="primary" @click="huoqyzma" :disabled="submitdx" plain style="width: 40%;" size="mini">
                {{percentagetext}}
              </el-button>
            </el-form-item> -->

            <el-form-item style="text-align: right;">
              <el-button type="primary" @click="submitForm('ruleForm')" style="width: 8rem;">{{text}}</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 完善个人信息 -->
        <div class="Form1" v-if="stepsun==2">
          <el-form :model="ruleForm" :rules="rules3" ref="ruleForm" label-width="110px !important"
            class="demo-ruleForm">
            <el-form-item label="姓名:" prop="nickName">
              <el-input clearable v-model="ruleForm.nickName" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别:" prop="sex" style="text-align: left;">
              <el-radio v-model="ruleForm.sex" label="0">男</el-radio>
              <el-radio v-model="ruleForm.sex" label="1">女</el-radio>
            </el-form-item>


            <el-form-item label="是否为学生:" prop="isStudent" style="text-align: left;">
              <el-radio v-model="ruleForm.isStudent" label="1">是</el-radio>
              <el-radio v-model="ruleForm.isStudent" label="0">否</el-radio>
            </el-form-item>


            <el-form-item label="机构:" prop="inputOrg">
              <el-input clearable v-model="ruleForm.inputOrg" placeholder="请输入您的所在的机构"></el-input>
              <!--              <el-select clearable v-model="ruleForm.orgId" placeholder="请选择" style="width: 100%;">
                <el-option v-for="item in orglistdata" :key="item.orgId" :label="item.orgName" :value="item.orgId">
                </el-option>
              </el-select> -->
            </el-form-item>

            <el-form-item label="部门:" prop="deptName">
              <el-input clearable v-model="ruleForm.deptName" placeholder="请输入您的所在的部门"></el-input>
            </el-form-item>
            <el-form-item label="职务:" prop="job">
              <el-input clearable v-model="ruleForm.job" placeholder="请输入您的职务"></el-input>
            </el-form-item>
            <el-form-item label="证件类型:" prop="certType">
              <el-select clearable v-model="ruleForm.certType" placeholder="请选择" style="width: 100%;">
                <el-option label="身份证" value="0">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件号码:" prop="certNo">
              <el-input clearable v-model="ruleForm.certNo" placeholder="请输入证件号码"></el-input>
            </el-form-item>
            <!--            <el-form-item label="身份证（正反面）扫描件:" prop="certUrl">
              <div style="display: inline-block;width: 100%">
                <el-upload class="imgupload" :action="$baseUrl.test+'/common/upload'" name="file"
                  list-type="picture-card" :on-exceed="certsonexceed" :on-preview="cert" :on-remove="certUrlRemove"
                  :on-success="certUrlsuccess" :limit="2" accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG" :file-list="fileListss">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <p style="color: red;font-size: 0.8rem;">（请上传扫描件，大小不能超过5MB，1-2张）</p>
                <p style="color: red;font-size: 0.8rem;">（支持jpeg、jpg、png格式）</p>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="certurl" alt="" accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG">
                </el-dialog>
              </div>
            </el-form-item> -->

            <el-form-item style="text-align: right;">
              <el-button v-if="stepsun==2" @click="previous('ruleForm')" style="width: 20%;">上一步</el-button>
              <el-button v-if="stepsun==2" @click="submitForm('ruleForm')" style="width: 25%;">保存为草稿</el-button>
              <el-button v-if="stepsun==2" type="primary" @click="submitForm('ruleForm')" style="width: 25%;">提交注册申请
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 完成 -->
        <div class="Form1" v-if="stepsun==3">
          <img class="wanchengimg" src="../../assets/u382.png" />
          <p class="wanchengaccount">你的帐户：{{ruleForm.userName}} 已申请成功！</p>
          <!-- <p class="wanchengtext">您的申请已提交联盟进行审核，请在3个工作日内登录平台查收审核消息。</p> -->
        </div>


      </el-col>
    </el-row>
  </div>
</template>

<script>
  import commonComponents from '@/components/register/commonComponents.vue'
  import $ from 'jquery';

  export default {
    // 这里原来是name: 'commonComponents',但是会报错。
    name: '',
    components: {
      commonComponents
    },
    data: function() {
      return {
        fileListss: [],
        // 定时数
        percentage: 60,
        // 获取验证码按钮文字
        percentagetext: "获短信取验证码",
        // 获取短信验证码
        submitdx: false,
        // 机构列表
        orglistdata: [],
        dialogVisible8: true,
        clearImg: true,
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        activeIndex: "",
        active: 0,
        text: "下 一 步",
        verificationimgsrc: '',
        stepsun: 1,
        // 验证码text
        yzmtext: '',
        // certUrl: [],
        ruleForm: {
          // uuid
          uuid: '',
          // 账号
          userName: '',
          // 手机号码
          phonenumber: '',
          // 图形码
          Graphic: '',
          // 密码
          password: '',
          // 确认密码
          confirmpwd: '',
          // 验证码
          // Verification: '',

          // 姓名
          nickName: '',
          // 性别
          sex: '',
          // 是否为学生
          isStudent: '',
          // 机构
          orgId: '',
          // 机构名称输入的
          inputOrg: '',
          // 部门
          deptName: '',
          // 职务
          job: '',
          // 证件类型
          certType: '0',
          // 证件号码
          certNo: '',
          // // 身份证照片ID
          // certUrl: [],
        },
        rules1: {
          userName: [{
              required: true,
              message: '请创建一个新账号，6-20位数字和字母组合',
              trigger: 'blur'
            },
            {
              min: 6,
              max: 20,
              message: '长度在 6 到 20 个字符',
              trigger: 'blur'
            }
          ],
          // Verification: [{
          //   required: true,
          //   message: '请输入验证码',
          //   trigger: 'blur'
          // }],
          phonenumber: [{
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '请输入密码，8-16位数字和字母组合',
            trigger: 'blur'
          }],
          confirmpwd: [{
            required: true,
            message: '请确认密码',
            trigger: 'blur'
          }],
          Graphic: [{
            required: true,
            message: '请输入图形码',
            trigger: 'blur'
          }]
        },
        rules3: {
          nickName: [{
            required: true,
            message: '请输姓名',
            trigger: 'blur'
          }],
          sex: [{
            required: true,
            message: '请选择性别',
            trigger: 'blur'
          }],
          isStudent: [{
            required: true,
            message: '请选择是否为学生',
            trigger: 'blur'
          }],
          certType: [{
            required: true,
            message: '请输入证件类型',
            trigger: 'blur'
          }],
          inputOrg: [{
            required: true,
            message: '请输入机构名称',
            trigger: 'blur'
          }],
          // orgId: [{
          //   required: true,
          //   message: '请选择机构',
          //   trigger: 'blur'
          // }],
          // deptName: [{
          //   required: true,
          //   message: '请输入所在部门',
          //   trigger: 'blur'
          // }],
          // job: [{
          //   required: true,
          //   message: '请输入职务',
          //   trigger: 'blur'
          // }],
          certNo: [{
            required: true,
            message: '请输入证件号码',
            trigger: 'blur'
          }],
          // certUrl: [{
          //   required: true,
          //   message: '请上传身份证正反签字扫描件',
          //   trigger: 'blur'
          // }],
        }
      }
    },
    // 页面加载完毕触发
    mounted() {
      // 获取机构列表
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/pubilc/orglist?status=2',
        data: '',
        //请求成功的回调函数
      }).then((res) => {
        this.orglistdata = res.data.rows;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取验证码
      this.verificationimgclick();
    },
    methods: {
      verificationimgclick() {
        // 获取图形码接口
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/captchaImg',
          data: "",
        }).then((res) => {
          // 图片地址
          this.verificationimgsrc = res.data.img;
          // uuid
          this.ruleForm.uuid = res.data.uuid;
          // 验证码文字
          this.yzmtext = res.data.code;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 身份证正反签字扫描件路径
      certUrlsuccess(response, file, fileList) {
        this.ruleForm.certUrl.push(JSON.stringify(response.data.fileId));
        if (this.ruleForm.certUrl.length > 2) {
          this.ruleForm.certUrl.splice(0, 1);
        }
        this.certurl = file.url;
        this.fileListss.push({
          url: file.response.data.fileUrl,
          name: file.response.data.fileName
        })
      },
      // 身份证正反签字扫描件路径删除
      certUrlRemove(file, fileList) {
        // this.ruleForm.certUrl = '';
        // this.fileListss = [];
        for (let x in this.fileListss) {
          if (this.fileListss[x].url == file.url) {
            this.fileListss.splice(x, 1);
            this.ruleForm.certUrl.splice(x, 1);
          }
        }
      },
      certsonexceed(response, file, fileList) {
        this.$message({
          offset: 100,
          message: '只能上传一张，请先删除！',
          type: 'error'
        });
      },
      //图片放大查看事件
      cert(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      // 上一步
      previous(formName) {
        this.active = 0;
        this.stepsun = 1;
      },
      // 手机号码验证
      phonenumberblur() {
        if (!(/^1[3456789]\d{9}$/.test(this.ruleForm.phonenumber))) {
          this.$message({
            offset: 100,
            message: '手机号码格式不正确！',
            type: 'error'
          });
        } else {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/user/getUserList?phonenumber=' + this.ruleForm.phonenumber,
            data: "",
            headers: {
              'Authorization': ''
            }
          }).then((res) => {
            // 返回成功
            if (res.data.rows == "") {
              this.$message({
                offset: 100,
                message: '手机号码可用！',
                type: 'success'
              });
            } else { //返回失败
              this.$message({
                offset: 100,
                message: '手机号码已被注册！',
                type: 'warning'
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      // 创建新账号验证
      userNameblur() {
        if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.ruleForm.userName))) {
          this.$message({
            offset: 100,
            message: '账号格式不正确！',
            type: 'error'
          });
        } else {
          this.$message({
            offset: 100,
            message: '账号可使用！',
            type: 'success'
          });
        }
      },
      // 获取验证码
      // huoqyzma() {
      //   let params = {
      //     uuid: this.ruleForm.uuid,
      //     code: this.ruleForm.Graphic,
      //   }
      //   // 如果有验证码就验证
      //   if (this.ruleForm.Graphic) {
      //     this.$axios({
      //       method: 'post',
      //       url: this.$baseUrl.test + '/api/pubilc/validateCaptcha',
      //       data: params,
      //     }).then((res) => {
      //       // 返回成功
      //       if (res.data.code == 200) {
      //         // 如果有输入手机号码
      //         if (this.ruleForm.phonenumber) {
      //           this.$axios({
      //             method: 'get',
      //             url: this.$baseUrl.test + '/sms/getPhoneCode/' + this.ruleForm.phonenumber,
      //             params: "",
      //           }).then((res) => {
      //             // 返回成功
      //             if (res.data.code == 200) {
      //               this.$alert('验证码已发送至您的手机请注意查收！', '温馨提示', {
      //                 confirmButtonText: '确定',
      //                 callback: action => {}
      //               });
      //               this.submitdx = true;
      //               // 一秒执行一次
      //               this.timer = setInterval(() => {
      //                 // 倒计时60秒
      //                 this.percentage = this.percentage - 1;
      //                 this.percentagetext = this.percentage + "秒"
      //                 // 到了0秒的时候
      //                 if (this.percentage == 0) {
      //                   // 结束倒计时
      //                   clearInterval(this.timer);
      //                   // 获取短信按钮可点击
      //                   this.submitdx = false;
      //                   // 改变获取短信验证码文字
      //                   this.percentagetext = "获短信取验证码";
      //                 }
      //               }, 1000)
      //             } else { //返回失败
      //               this.$message({
      //                 offset: 100,
      //                 message: res.data.msg,
      //                 type: 'error'
      //               });
      //               // 获取验证码
      //               this.verificationimgclick();
      //             }
      //           });
      //         } else {
      //           this.$message({
      //             offset: 100,
      //             message: "请输入手机号码！",
      //             type: 'error'
      //           });
      //           // 获取验证码
      //           this.verificationimgclick();
      //         }
      //       }
      //       // 返回失败
      //       else {
      //         this.$message({
      //           offset: 100,
      //           message: "验证码错误！",
      //           type: 'error'
      //         });
      //         this.verificationimgclick();
      //       }
      //     });
      //   } else {
      //     this.$message({
      //       offset: 100,
      //       message: "请输入验证码！",
      //       type: 'error'
      //     });
      //     // 获取验证码
      //     this.verificationimgclick();
      //   }
      // },
      // 弱密码 长度8 大写+小写+特殊字符
      isWeakPassword(password) {
        return /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、！!？'‘“”~ `]).*$/
          .test(password);
      },
      // 下一步事件
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 第三步请求数据
            if (this.stepsun < 2) {
              // 密码验证
              if ((this.ruleForm.password != '') && (this.ruleForm.confirmpwd != '') && (this.ruleForm.password !=
                  this.ruleForm.confirmpwd)) {
                this.$message({
                  offset: 100,
                  message: '两次密码输入不一致！',
                  type: 'warning'
                });
              } else if (!this.isWeakPassword(this.ruleForm.password)) { // 弱密码验证
                this.$message({
                  offset: 100,
                  showClose: true,
                  message: '密码需要包含大写字母+小写字母+数字+特殊符号并且大于8位',
                  type: 'warning'
                });
              } else {
                if (this.active++ > 1) this.active = 0;
                this.stepsun = this.stepsun + 1;
              }
            } else {
              // if(typeof(this.ruleForm.certUrl)=='string'){
              // }else{
              //   this.ruleForm.certUrl = this.ruleForm.certUrl.join();
              // }
              this.$axios({
                method: 'post',
                url: this.$baseUrl.test + '/api/user/userRegister',
                data: this.ruleForm,
                headers: {
                  'Authorization': ''
                }
              }).then((res) => {
                // 返回成功
                if (res.data.code == 200) {
                  this.stepsun = 3;
                  if (this.active++ > 2) this.active = 0;
                } else { //返回失败
                  this.$message({
                    offset: 100,
                    message: res.data.msg,
                    type: 'warning'
                  });
                }
              }).catch(error => {
                // 处理错误
                this.$message({
                  offset: 100,
                  message: error.msg,
                  type: 'error'
                });
              });
            }
          }
        });
      }
    }
  }
</script>

<style src="@/style/register/InsideCom.css" scoped></style>
<style scoped>
  .el-step__main {
    white-space: normal;
    display: flex;
    justify-content: center;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }

  .demo-ruleForm {
    height: 55vh;
    overflow-y: auto;
    margin-top: 15vh;
    border-radius: 10px;
  }
</style>
