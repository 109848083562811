import { render, staticRenderFns } from "./Workbench.vue?vue&type=template&id=68da52e8&scoped=true&"
import script from "./Workbench.vue?vue&type=script&lang=js&"
export * from "./Workbench.vue?vue&type=script&lang=js&"
import style0 from "@/style/workbench.css?vue&type=style&index=0&id=68da52e8&scoped=true&lang=css&"
import style1 from "@/style/main.css?vue&type=style&index=1&id=68da52e8&scoped=true&lang=css&"
import style2 from "./Workbench.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68da52e8",
  null
  
)

export default component.exports