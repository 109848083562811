<template>
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="hallmaxdiv">
          <el-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
            <el-col :span="24" style="padding-right: 10px !important;margin-top: 0.5rem;background-color: #FFFFFF;">
              <!-- 面包屑 -->
              <div class="breadcrumb">
                <el-breadcrumb>
                  <el-breadcrumb-item>资源共享大厅</el-breadcrumb-item>
                  <el-breadcrumb-item>资源详情</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <!-- 内容 -->
              <div class="topdivnr">
                <!-- 头部标题 -->
                <div class="toptitle">
                  <img v-if="formdata.resType == 0" src="../../assets/icon/mp4.png" class="iconstyle" />
                  <img v-else src="../../assets/icon/wendang.png" class="iconstyle" />
                  {{formdata.resTitle}}
                </div>
                <!-- 详细信息 -->
                <div class="information">
                  <div>类型：{{formdata.resTypeName}}</div>
                  <i class="el-icon-minus"></i>
                  <div>{{formdata.createTime}}</div>
                  <i class="el-icon-minus"></i>
                  <div>分享者：{{formdata.userNickName}}</div>
                  <i class="el-icon-minus"></i>
                  <div>分享机构：{{formdata.userOrgName}}</div>
                  <i class="el-icon-minus"></i>
                  <div>下载：{{formdata.downCount}}次</div>
                  <i class="el-icon-minus"></i>
                  <div>浏览：{{formdata.browseCount}}次</div>
                  <i class="el-icon-minus"></i>
                  <div>资源等级：{{formdata.resGrade}}星</div>
                </div>
                <!-- 联盟积分 -->
                <div class="lmb">
                  联盟积分：{{formdata.price}}
                </div>
                <!-- 收藏 -->
                <div class="shocang">
                  <!-- price 0为免费资源，1为付费资源 -->
                  <!-- isShop 0未购买，1已购买 -->
                  <!-- price:{{formdata.price}}
                  </br>
                  </br>如果是当前用户就不用购买
                  isShop:{{formdata.isShop}} -->
                  <!-- 收费资源 未购买 -->
                  <el-button :disabled="resourcesid == userId" v-if="formdata.price > 1 && formdata.isShop == '0'"
                    type="primary" @click="purchase()" style="margin-right: 2rem;width: 12rem;">
                    购买
                  </el-button>
                  <!-- 收费资源 已购买 -->
                  <el-button v-else-if="formdata.price > 1 && formdata.isShop == '1'" type="primary" style="margin-right: 2rem;width: 12rem;"
                    @click="watchclick(formdata.fileList,3)">已购买
                  </el-button>
                  <el-button v-else type="primary" style="margin-right: 2rem;width: 12rem;"
                    @click="watchclick(formdata.fileList,3)">浏览
                  </el-button>
                  <!-- <a :href="filesurl" download="xxx.jpeg">下载</a> -->
                  <i v-if="formdata.isCollect==1" @click="CancelCollect()" class="el-icon-star-on"
                    style="cursor:pointer;color: #0066FF;">已收藏</i>
                  <i v-else class="el-icon-star-off" @click="Collection()" style="cursor:pointer;">收藏</i>
                  <el-button style="margin-left: 2rem;width: 6rem;" @click="fanhuiliebiao()">返回列表</el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="18" style="padding-right: 10px !important;margin-top: 0.5rem;">
              <!-- 左边下内容 -->
              <div class="grid-content bg-purple">
                <div class="leftdivtop">
                  <div class="leftdivtoptitle">资源简介</div>
                  <el-divider></el-divider>
                </div>
                <el-row>
                  <el-col :span="24">
                    <!-- 简介内容 -->
                    <div class="zuoxianrjj">
                      <span>{{formdata.resContent}}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <template>
                      <el-table :data="formdata.fileList" style="width: 100%">
                        <el-table-column type="index" label="序号" width="100">
                        </el-table-column>
                        <el-table-column prop="" label="章节">
                          <template slot-scope="scope">
                            {{scope.row.resDetailTitle}}:{{scope.row.chapterName}}
                          </template>
                        </el-table-column>
                        <el-table-column prop="txHash" label="区块链地址">
                        </el-table-column>
                        <el-table-column prop="fileExtension" label="文件类型" width="100">
                        </el-table-column>
                        <el-table-column prop="" label="操作" width="150">
                          <template slot-scope="scope">
                            <!-- 如果为文档或者图片类型，直接预览 -->
                            <el-button
                              v-if="(scope.row.fileSize/1024) < 5 && (scope.row.fileExtension == 'png'|| scope.row.fileExtension == 'jpeg' || scope.row.fileExtension == 'jpg' || scope.row.fileExtension == 'XLS' || scope.row.fileExtension == 'txt' || scope.row.fileExtension == 'doc' || scope.row.fileExtension == 'docx' || scope.row.fileExtension == 'ppd' || scope.row.fileExtension == 'pdf' || scope.row.fileExtension == 'pptx' || scope.row.fileExtension == 'PPTX')"
                              type="text" @click="watchclick(scope.row.fileId)">
                              点击预览
                            </el-button>

                            <!-- 如果为文档类型，通过收费来判断是否为0、购买下载  1、点击下载 -->
                            <el-button
                              v-if="formdata.isShop == '0' && (scope.row.fileExtension == 'XLS' || scope.row.fileExtension == 'txt' || scope.row.fileExtension == 'doc' || scope.row.fileExtension == 'docx' || scope.row.fileExtension == 'ppd' || scope.row.fileExtension == 'pdf' || scope.row.fileExtension == 'pptx' || scope.row.fileExtension == 'PPTX')"
                              type="text" @click="purchase()">
                              购买下载
                            </el-button>
                            <el-button
                              v-if="formdata.isShop == '1' && (scope.row.fileExtension == 'XLS' || scope.row.fileExtension == 'txt' || scope.row.fileExtension == 'doc' || scope.row.fileExtension == 'docx' || scope.row.fileExtension == 'ppd' || scope.row.fileExtension == 'pdf' || scope.row.fileExtension == 'pptx' || scope.row.fileExtension == 'PPTX')"
                              type="text" @click="watchclick(scope.row.fileId,5)">
                              点击下载
                            </el-button>

                            <!-- 如果为媒体类型或者压缩包类型，通过收费来判断是否为0、购买下载  1、点击下载 -->
                            <el-button
                              v-if="formdata.isShop == '0' && (scope.row.fileExtension == 'zip'||scope.row.fileExtension == 'rar')"
                              type="text" @click="purchase()">
                              购买下载
                            </el-button>
                            <!-- 收费资源已购买的zip、rar -->
                            <el-button
                              v-if="formdata.isShop == '1' && (scope.row.fileExtension == 'zip'||scope.row.fileExtension == 'rar')"
                              type="text" @click="watchclick(scope.row.fileId,2)">
                              点击下载
                            </el-button>

                            <!-- 如果为媒体类型，通过收费来判断是否为0、购买观看  1、进入观看 -->
                            <el-button
                              v-if="formdata.isShop == '0' && (scope.row.fileExtension == 'mp3'||scope.row.fileExtension == 'mp4')"
                              type="text" @click="purchase()">
                              购买观看
                            </el-button>
                            <!-- 收费资源已购买的mp3、mp4 -->
                            <el-button
                              v-if="formdata.isShop == '1' && (scope.row.fileExtension == 'mp3'||scope.row.fileExtension == 'mp4')"
                              type="text" @click="watchclick(scope.row.fileId,1)">
                              进入观看
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                  </el-col>
                </el-row>
              </div>
            </el-col>

            <!-- 右边内容 -->
            <el-col :span="6" style="padding-left: 10px !important;margin-top: 0.5rem;">
              <el-row :gutter="20" style="background-color: #FFFFFF;border-radius: 4px;">
                <div class="leftdiv">
                  <!-- 头部 -->
                  <div class="leftdivtop">
                    <div class="leftdivtoptitle">相关资源推荐</div>
                    <el-divider></el-divider>
                  </div>
                  <!-- 内容 -->
                  <div class="leftdivtopnrdiv" v-for="item in tuijlist">
                    <div class="leftdivtopnr">
                      <el-link @click="Clicktoview(item.resId)">{{item.resTitle}}</el-link>&#12288;
                      <span>{{item.createTime}}</span>
                    </div>
                  </div>
                </div>
              </el-row>
            </el-col>
          </el-row>

          <!-- 内容弹窗 -->
          <el-dialog title="资源查看弹窗" :visible.sync="dialogVisible" width="30%">
            <div class="block">
              <el-image fit="contain" :src="fileUrl"></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
          </el-dialog>

          <!-- 购买弹窗 -->
          <el-dialog style="text-align: left;" :title="formdata.resTitle+'支付'" :visible.sync="dialogVisible2"
            width="30%">
            <p>资源所需联盟积分:<span style="color: red;">{{formdata.price}}</span></p>
            <p>当前拥有联盟积分:<span style="color: red;">{{unionCoin}}</span></p>
            <span>是否支付？</span>
            <span style="float: right;">联盟积分不足？<el-link type="primary">点击充值</el-link></span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="Confirmpayment()">确 定</el-button>
            </span>
          </el-dialog>

          <el-dialog :title="dialogVisible3.title" :visible.sync="dialogVisible3.dialogVisible" width="70%"
            :before-close="handleClose">
            <iframe class="msgbox" :src="dialogVisible3.url"></iframe>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogVisible3.dialogVisible = false">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script src="../../assets/js/PDFObject.js"></script>
<script>
  export default {
    data: function() {
      return {
        searchThing:'',
        dialogVisible3: {
          dialogVisible: false,
          title: "",
          url: "",
        },
        // 发布资源用户ID
        resourcesid: '',
        tuijlist: [],
        filesurl: '',
        userId: localStorage.getItem("userId"),
        fileUrl: '',
        loading: true,
        // 资源详情信息
        formdata: {},
        dialogVisible: false,
        dialogVisible2: false,
        ruleForm: {
          type: [],
          resource: '',
          desc: ''
        },
        rules: {
          resource: [{
            required: true,
            message: '请选择通过或者不通过',
            trigger: 'change'
          }],
          desc: [{
            required: true,
            message: '请输入审核理由',
            trigger: 'blur'
          }]
        },
        unionCoin: 0
      }
    },
    // 页面加载完毕触发
    mounted() {
      if(this.$route.params.resId){ // 如果有
        localStorage.setItem("resId",this.$route.params.resId);
      }
      this.searchThing = localStorage.getItem("resId")
      // 获取资源详情
      this.getdata();
    },
    methods: {
      fanhuiliebiao(){
        this.$router.push({
          name: 'Hall'
        });
      },
      // 点击观看
      Clicktoview(e) {
        localStorage.setItem("resId", e);
        window.location.reload();
      },
      // 获取当前分享者的分享资源列表
      thisuserlist(e) {
        let params = {
          userId: e
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resInfo/list?pageNum=1&pageSize=10',
          data: params,
        }).then((res) => {
          this.tuijlist = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 获取资源详情
      getdata() {
        let params = {
          "resId": this.searchThing ? this.searchThing : localStorage.getItem('resId'),
          "loginUserId": this.userId
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resInfo/getResInfo',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            this.formdata = res.data.data;
            this.resourcesid = res.data.data.userId;
            this.thisuserlist(res.data.data.userId)
          } else {
            window.history.back();
            // this.$message({
            //   offset: 100,
            //   message: res.data.code + '请从资源列表进入！',
            //   type: 'error'
            // });
            // this.$router.push({
            //   name: 'Hall'
            // });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 点击预览&&点击下载事件
      watchclick(e, n) {
        // 如果点击的是列表里的下载就只下载当前的文件
        if (n == 2) {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/files/' + e,
            params: '',
          }).then((res) => {
            if (res.data.code == 200) {
              let url = res.data.data.fileUrl;
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              // 生成时间戳
              let timestamp = new Date().getTime()
              link.download = timestamp + '.txt'
              document.body.appendChild(link)
              link.click()
              // 记录下载（整个资源做记录）
              let params = {
                "resId": this.searchThing,
                "userId": this.userId
              }
              this.$axios({
                method: 'post',
                url: this.$baseUrl.test + '/resouse/resDownRecord/',
                data: params,
              }).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: res.data.msg + "已添加至下载记录！",
                    type: 'success',
                    offset: 100
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: 'error',
                    offset: 100
                  });
                }
              }).catch(error => {
                // 处理错误
                this.$message({
                  offset: 100,
                  message: error.msg,
                  type: 'error'
                });
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: 100
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
        // 如果点击的是上面的下载按钮下载当前资源中除开图片以外的所有文件
        else if (n == 3) { //上面的总按钮
          for (let i in e) {
            // 下载资源中所有收费的资源
            // if (e[i].price > 0 && (e[i].fileExtension != 'png' || e[i].fileExtension != 'jpeg' || e[i].fileExtension != 'jpg')) {
            // 下载资源中图片以外的资源
            if (e[i].fileExtension == 'png' || e[i].fileExtension == 'jpeg' || e[i].fileExtension == 'jpg') {
              // 如果是图片就不下载
            } else if (e[i].fileExtension == 'mp3' || e[i].fileExtension == 'mp4') {
              // 播放当前视频(整个)
              this.$router.push({
                name: 'Video',
                params: {
                  "resId": this.searchThing,
                  // "fileId": e[i].fileId
                }
              });
            } else {
              this.$axios({
                method: 'get',
                url: this.$baseUrl.test + '/api/files/' + e[i].fileId,
                params: '',
              }).then((res) => {
                let url = res.data.data.fileUrl;
                // 生成一个a标签
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                // 生成时间戳
                let timestamp = new Date().getTime()
                link.download = timestamp + '.txt'
                document.body.appendChild(link)
                link.click()
              }).catch(error => {
                // 处理错误
                this.$message({
                  offset: 100,
                  message: error.msg,
                  type: 'error'
                });
              });
              setTimeout(function() {
                //做操作
                console.log("xiazaiwanchen");
              }, 2000); //触发事件
            }
          }
        }
        // 如果是视频资源就跳转到视频播放页面
        else if (n == 1) {
          // 播放当前视频(单个)
          this.$router.push({
            name: 'Video',
            params: {
              "resId": this.searchThing,
              "fileId": e
            }
          });
        }
        // 如果是文档&图片中的点击下载按钮就是直接下载
        else if (n == 5) {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/files/' + e,
            params: '',
          }).then((res) => {
            let url = res.data.data.fileUrl;
            // 生成一个a标签
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            // 生成时间戳
            let timestamp = new Date().getTime()
            link.download = timestamp + '.txt'
            document.body.appendChild(link)
            link.click()

            // 记录下载(整个资源做记录)
            let params = {
              "resId": this.searchThing,
              "userId": this.userId
            }
            this.$axios({
              method: 'post',
              url: this.$baseUrl.test + '/resouse/resDownRecord/',
              data: params,
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg + "已添加至下载记录！",
                  type: 'success',
                  offset: 100
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error',
                  offset: 100
                });
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
        // 否则就是查看当前文档and照片
        else {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/files/' + e,
            params: '',
          }).then((res) => {
            if (res.data.code == 200) {
              // 判断资源类型为图片
              if (res.data.data.fileExtension == "jpeg" || res.data.data.fileExtension == "png" || res.data.data
                .fileExtension == "jpg" || res.data.data.fileExtension == "bmp") {
                this.fileUrl = res.data.data.fileUrl;
                this.dialogVisible = true;
              }
              // 如果为文档类型
              else if (res.data.data.fileExtension == "xls" || res.data.data.fileExtension == "docx" || res.data
                .data.fileExtension == "doc" || res.data.data.fileExtension == "pdf" || res.data.data
                .fileExtension == "txt" || res.data.data.fileExtension == "ppd" || res.data.data.fileExtension ==
                'pptx' || res.data.data.fileExtension == 'PPTX') {
                let fileUrl = "https://ow365.cn/?i=33860&ssl=1&furl=" + encodeURIComponent(res.data.data.fileUrl);
                this.dialogVisible3 = {
                  'dialogVisible': true,
                  'title': "文件详情",
                  'url': fileUrl
                }
                return false;
              }
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: 100
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      handleClose() {
        this.dialogVisible3.dialogVisible = false
      },

      // 点击购买
      purchase() {
        // 获取当前用户拥有的联盟积分
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/getUserInfo/' + this.userId,
          params: '',
          //请求成功的回调函数
        }).then((res) => {
          this.unionCoin = res.data.data.unionCoin;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
        this.dialogVisible2 = true;
      },
      // 确定支付
      Confirmpayment() {
        let params = {
          // 资源ID
          "resId": this.searchThing,
          // 购买用户ID
          "userId": this.userId,
          // 购买价格（联盟积分）
          "price": this.formdata.price,
          // 购买类型（0购买资源，1购买章节）
          "shopType": 0
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resShopRecord',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              message: '恭喜购买成功！',
              type: 'success'
            });
            // 获取资源详情
            this.getdata();
            this.dialogVisible2 = false;
          } else {
            this.$message({
              offset: 100,
              message: '购买失败！' + res.data.msg,
              type: 'error'
            });
            // 获取资源详情
            this.getdata();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 收藏
      Collection() {
        let params = {
          "resId": this.searchThing,
          "userId": this.userId
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resCollectRecord',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$alert('恭喜你，收藏成功！', '收藏成功', {
              confirmButtonText: '确定'
            });
            // 获取资源详情
            this.getdata();
          } else {
            this.$alert(res.data.msg, '收藏失败', {
              confirmButtonText: '确定'
            });
            // 获取资源详情
            this.getdata();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 取消收藏
      CancelCollect() {
        let params = {
          "resId": this.searchThing,
          "userId": this.userId
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resCollectRecord/cancelCollect',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$alert('取消收藏成功！', '取消收藏', {
              confirmButtonText: '确定'
            });
            // 获取资源详情
            this.getdata();
          } else {
            this.$alert(res.data.msg, '取消收藏失败', {
              confirmButtonText: '确定'
            });
            // 获取资源详情
            this.getdata();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
  }
</script>

<style src="@/style/Resources/resourceDetailsCom.css" scoped></style>
<style>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  .el-form-item__label {
    width: 15% !important;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .msgbox {
    width: 80% !important;
    height: 60vh;
    margin-top: 0px;
  }

  .iconstyle {
    width: 2.7rem;
    height: 2.7rem;
    float: left;
    margin-right: 1rem;
  }
</style>
