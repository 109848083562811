<template>
  <div class="index">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>个人中心</el-breadcrumb-item>
            <el-breadcrumb-item>联盟积分</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="toptitle">当前可用联盟积分</div>
              <el-row :gutter="20">
                <el-col :span="12">
                  <br /><br />
                  <div class="lmb" style="color: #0066FF;">{{unionCoin}}</div>
                </el-col>
                <el-col :span="12" style="padding: 2rem;">
                  <!-- <el-button type="primary" size="small">充 值</el-button>
                  <el-button size="small">提 现</el-button> -->
                </el-col>
              </el-row>
            </div>
          </el-col>

          <!-- <el-col :span="8" style="min-height: 10rem;">
            <div class="grid-content bg-purple">
              <div class="toptitle">
                联盟积分总收益
                <br />
                <span class="topspantext">联盟积分总收益指：在平台中获得的联盟积分收入</span>
                <br />
                <el-link target="_blank" class="topspantext">收益地址:{{integralAddress}}</el-link>
              </div>
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="lmb" style="color: #67C23A;">{{lmbzsy}}</div>
                </el-col>
              </el-row>
            </div>
          </el-col> -->

          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="toptitle">联盟积分总消费<br />
                <span class="topspantext">联盟积分消费益指：在平台中消费的联盟积分总和</span>
              </div><br />
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="lmb">{{lmbzxf}}</div>
                </el-col>
                <el-col :span="12" style="padding: 2rem;">
                  <!-- <el-button size="small">收益详情</el-button> -->
                </el-col>
              </el-row>
            </div>
          </el-col>


        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <!-- <el-menu-item index="0">充值记录</el-menu-item>
          <el-menu-item index="1">提现记录</el-menu-item> -->
          <el-menu-item index="2">收益记录</el-menu-item>
          <el-menu-item index="3">消费记录</el-menu-item>
        </el-menu>
        <div class="line"></div>
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="payTime" label="日期">
            </el-table-column>
            <el-table-column prop="orderNo" label="单号">
            </el-table-column>
            <!-- <el-table-column prop="" label="人民币（元）">
              <template slot-scope="scope">
                {{scope.row.price/10}}
              </template>
            </el-table-column> -->
            <el-table-column prop="price" label="联盟积分">
            </el-table-column>
            <el-table-column prop="profitAddress" label="收益地址" v-if="tableDataval.payType == 2">
            </el-table-column>
            <el-table-column prop="consumptionAddress" label="消费地址" v-if="tableDataval.payType == 3">
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    data: function() {
      return {
        activeIndex: '2',
        tableData: [],
        lmbzsy: '',
        lmbzxf: '',
        unionCoin: '',
        integralAddress: '',
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 类型
          'payType': '2',
          // 用户ID
          'userId': localStorage.getItem("userId"),
        },
      }
    },
    methods: {
      handleSizeChange(val) {
        this.tableDataval.pageSize = val;
        this.getlist();
      },
      handleCurrentChange(val) {
        this.tableDataval.pageNum = val;
        this.getlist();
      },
      handleSelect(key) {
        this.tableDataval.payType = key;
        this.getlist();
      },
      // 获取列表
      getlist() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/resouse/resPayRecord/list',
          params: this.tableDataval,
        }).then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }

    },
    // 页面加载完毕事件
    mounted() {
      this.getlist();
      // 获取联盟积分总收益
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/resouse/resInfo/getUserResData/' + this.tableDataval.userId,
        params: '',
      }).then((res) => {
        this.lmbzsy = res.data.data.lmbzsy ? res.data.data.lmbzsy : 0;
        this.lmbzxf = res.data.data.lmbzxf ? res.data.data.lmbzxf : 0;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 当前可用联盟积分
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/getUserInfo/' + this.tableDataval.userId,
        params: this.tableDataval,
      }).then((res) => {
        this.integralAddress = res.data.data.integralAddress ? res.data.data.integralAddress : 0;
        this.unionCoin = res.data.data.unionCoin ? res.data.data.unionCoin : 0;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
    }
  }
</script>

<style src="@/style/PersonalCenter/UnioncurrencyCom.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }
</style>
