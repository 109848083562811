<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>协同合作管理</el-breadcrumb-item>
            <el-breadcrumb-item>项目详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <el-col :span="2" style="color: #00000000;">1</el-col>
        <el-col :span="20">
          <!-- 基本信息 -->
          <div class="jbxxdiv">
            <!-- 项目名称 -->
            <h2 style="text-align: center;">{{ruleForm.projectName}}</h2>
            <p
              style="font-family: 'PingFang SC ', 'PingFang SC';font-weight: 400;font-style: normal;font-size: 16px;color: rgba(0, 0, 0, 0.447058823529412);text-align: center;line-height: 22px;">
              {{ruleForm.completeTime}}
            </p>
            <el-descriptions class="jbxxdivdescriptions" title="项目发起人信息" :column="1">
              <el-descriptions-item label="项目发起人">
                {{ruleForm.nickName}}
              </el-descriptions-item>
              <el-descriptions-item label="单位名称">{{ruleForm.orgName}}
              </el-descriptions-item>
              <el-descriptions-item label="联系号码">{{ruleForm.phonenumber}}</el-descriptions-item>
              <el-descriptions-item label="邮箱">{{ruleForm.email}}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="jbxxdivdescriptions" title="项目信息" :column="1">
              <el-descriptions-item label="项目名称">
                {{ruleForm.projectName}}
              </el-descriptions-item>
              <el-descriptions-item label="项目编号">
                {{ruleForm.projectNo}}
              </el-descriptions-item>
              <el-descriptions-item label="项目类型">{{ruleForm.projectTypeName}}</el-descriptions-item>
              <el-descriptions-item label="项目合作人数">{{ruleForm.cooperationNum}}</el-descriptions-item>
              <el-descriptions-item label="允许参与报名人数">
                {{ruleForm.allowSignupNum}}
                &emsp;&emsp;&emsp;&emsp;
                <span style="color: red;">
                  已报名人数：{{ruleForm.signupNum}}
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="结项时间">{{ruleForm.completeTime}}</el-descriptions-item>
              <el-descriptions-item label="项目经费(元)">{{ruleForm.projectFunds}}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions class="jbxxdivdescriptions" title="项目介绍" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
                {{ruleForm.projectContent}}
              </el-descriptions-item>
            </el-descriptions>

            <el-descriptions class="jbxxdivdescriptions" title="项目附件" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
              </el-descriptions-item>
            </el-descriptions>
            <template>
              <el-table :data="fileData" border style="width: 100%">
                <el-table-column type="index" width="50" label="序号">
                </el-table-column>
                <el-table-column prop="chapterName" label="附件名称">
                </el-table-column>
                <el-table-column prop="nftId" label="区块链地址">
                </el-table-column>
                <el-table-column prop="createTime" label="上传日期">
                </el-table-column>
                <el-table-column prop="" label="操作" scope="scope" width="90">
                  <template slot-scope="scope">
                    <el-button style="color: Success;" type="danger" icon="el-icon-download" circle size="small"
                      @click="downloadclick(scope.row)">
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
          <!-- 参与合作 -->
          <el-button style="margin: 3rem;width: 10rem;" type="primary" @click="see(ruleForm.projectId)"
            v-if="djcyannstyle">点击参与</el-button>
          <el-button style="margin: 3rem;width: 10rem;" type="primary" @click="see(ruleForm.projectId)"
            v-if="!djcyannstyle">点击查看</el-button>
        </el-col>
        <el-col :span="2"></el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    name: '',
    props: ['params'],
    data: function() {
      return {
        activeIndex: '1',
        projectId: "",
        active: 1,
        projectStatus: "",
        djcyannstyle: true,
        ruleForm: {
          // 合作项目ID
          projectId: "",
          // 单位名称
          orgName: "",
          // 发起人用户ID
          userId: "",
          // 发起人姓名
          nickName: "",
          // 发起人手机号码
          phonenumber: "",
          // 项目名称
          projectName: "",
          // 项目编号
          projectNo: "",
          // 项目类型
          projectTypeName: "",
          // 项目合作人数
          cooperationNum: "",
          // 允许参与报名人数
          allowSignupNum: "",
          // 邀请人
          inviteMembers: [],
          // 已报名人数
          signupNum: "",
          // 结项时间
          completeTime: "",
          // 项目经费(元)
          projectFunds: "",
          // 已使用经费(元)
          usedFunds: "",
          // 剩余经费(元)
          surplusFunds: "",
          // 项目介绍
          projectContent: "",
          // 项目附件
          projectFiles: "",
          // 项目状态（0草稿，1报名中，2合作中，3评审中，4已结项）
          projectStatus: "",
          // 结项申请时间
          closeApplyTime: ""
        },
        // 资源列表
        fileData: [],
      }
    },
    methods: {
      // 参与项目
      see(e) {
        let params = {
          "projectId": e,
          "projectStatus": 2
        }
        // 跳转到项目详情页面弹窗
        this.$router.push({
          name: 'Projectdetails',
          params: params
        });
      },
      // 下载文件
      downloadclick(e) {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/files/' + e.fileId,
          params: '',
        }).then((res) => {
          // ShowUrl 表示路径  Name 表示需要的名称
          let ShowUrl = res.data.data.fileUrl;
          const x = new window.XMLHttpRequest();
          x.open('GET', ShowUrl, true);
          x.responseType = 'blob';
          x.onload = () => {
            const url = window.URL.createObjectURL(x.response);
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank'
            a.download = res.data.data.fileName;
            a.style.display = 'none'
            document.body.append(a)
            a.click();
          };
          x.send();

          // 记录下载(整个资源做记录)
          let params = {
            "resId": this.searchThing,
            "userId": localStorage.getItem("userId")
          }
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/resouse/resDownRecord/',
            data: params,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg + "已添加至下载记录！",
                type: 'success',
                offset: 100
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: 100
              });
            }
          }).catch(error => {
            // 处理错误
            // this.$message({
            //   offset: 100,
            //   message: error.msg,
            //   type: 'error'
            // });
          });
        }).catch(error => {
          // 处理错误
          // this.$message({
          //   offset: 100,
          //   message: error.msg,
          //   type: 'error'
          // });
        });
      }
    },
    // 页面加载完毕事件
    mounted() {
      let _this = this;
      // 获取跳转过来的项目ID
      _this.projectId = _this.params.projectId ? _this.params.projectId : "";
      // 跳转过来的状态 1报名中，2合作中，3评审中，4已结项
      _this.active = _this.params.projectStatus ? _this.params.projectStatus : "";
      if (_this.projectId == "") {
        window.history.back(); // 后退到上一页
      } else {
        // 获取项目基本信息
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/cooperation/cooProject/' + _this.projectId,
          data: '',
        }).then((res) => {
          _this.ruleForm = res.data.data;
          // 如果是自己的项目就不要点击参与按钮
          if (localStorage.getItem("userId") == _this.ruleForm.userId) {
            _this.djcyannstyle = false;
          } else {
            _this.djcyannstyle = true;
          }

          if (res.data.data.projectFiles) {
            // 获取附件列表
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/getFileList/' + res.data.data.projectFiles,
              params: '',
            }).then((res) => {
              for (let i in res.data.data) {
                // 获取二级资源信息
                _this.fileData.push({
                  // 文件ID
                  fileId: res.data.data[i].fileId,
                  // 创建时间
                  createTime: res.data.data[i].createTime,
                  // 附件名称
                  chapterName: res.data.data[i].fileName.split(".")[0],
                  fileName: res.data.data[i].fileName,
                  // 文件地址
                  fileUrl: res.data.data[i].fileUrl,
                  nftId: res.data.data[i].nftId,
                })
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }
    }
  }
</script>

<style src="@/style/Cooperation/ProjectCom.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }

  .el-descriptions__header {
    margin-bottom: 0px !important;
  }
</style>
