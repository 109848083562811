<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>人才互聘管理</el-breadcrumb-item>
            <el-breadcrumb-item>简历管理</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="24" style="padding: 0rem 10rem;">
        <div style="display: flex;">
          <p style="text-align: left;font-weight: 800;border-bottom: #0066FF solid 3px;width: 4rem;">个人信息</p>
          <el-button icon="el-icon-edit" type="text" style="margin-left: 1rem;font-size: 1rem;"
            @click="disabledtrue=!disabledtrue">编辑</el-button>
        </div>
        <div style="text-align: left;margin-top: 2rem;display: flex;">
          <p style="margin: 0rem;margin-right: 1rem;">个人照片:</p>
          <img style="width: 120px; height: 150px;object-fit: fill;" src="../../assets/zjimg.jpg" />
        </div>
        <div style="text-align: left;margin-top: 2rem;display: flex;padding: 1rem 0rem;">
          <div style="width: 40%;">&#8195;&#8195;姓名：
            <el-input v-model="userData.nickName" style="width: 50%;" :disabled="disabledtrue"></el-input>
          </div>
          <div style="width: 40%;">&#8195;&#8195;性别：
            <el-select v-model="userData.sex" style="width: 50%;" :disabled="disabledtrue">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="0"></el-option>
            </el-select>
          </div>
        </div>
        <div style="text-align: left;margin-top: 2rem;display: flex;padding: 1rem 0rem;">
          <div style="width: 40%;">出生日期：<el-date-picker v-model="userData.birthday" type="date" style="width: 50%;">
            </el-date-picker>
          </div>
          <div style="width: 40%;">手机号码：<el-input v-model="userData.phonenumber" style="width: 50%;"></el-input>
          </div>
        </div>
        <div style="text-align: left;margin-top: 2rem;display: flex;padding: 1rem 0rem;">
          <div style="width: 40%;">毕业时间：<el-date-picker v-model="value1" type="date" style="width: 50%;">
            </el-date-picker>
          </div>
          <div style="width: 40%;">毕业学校：<el-input v-model="input" style="width: 50%;"></el-input>
          </div>
        </div>
        <div style="text-align: left;margin-top: 2rem;display: flex;padding: 1rem 0rem;">
          <div style="width: 40%;">通讯地址：<el-input v-model="userData.postAddress" style="width: 50%;"></el-input>
          </div>
          <div style="width: 40%;">&#8195;&#8195;专业：<el-select v-model="value" style="width: 50%;">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="0"></el-option>
            </el-select>
          </div>
        </div>

      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="projectNo" label="职位名称">
            </el-table-column>
            <el-table-column prop="projectName" label="职位分类" width="200">
            </el-table-column>
            <el-table-column prop="createTime" label="投递时间" width="160">
            </el-table-column>
            <el-table-column label="操作" width="160" fixed="right">

            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        disabledtrue: true,
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 为用户ID
          'applyUserId': localStorage.getItem("userId"),
          // 项目名称
          'projectName': "",
          // 项目状态
          'projectStatus': "",
        },
        // 总数
        total: 0,
        tableData: [],
        userData: {}
      }
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      //
      getUserInfo() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.getItem("userId"),
          params: '',
          //请求成功的回调函数
        }).then((res) => {
          this.userData = res.data.data;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 全部
      onSubmit() {
        let data = {
          "applyUserId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/recruit/recruitApply/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: data,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit();
      this.getUserInfo();
    }
  }
</script>

<style src="@/style/Team/ContentmanagementCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
