<template>
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="HirehallCom">
          <el-row style="margin-top: 10px;">
            <el-col :span="24">
              <div class="HirehallCom">
                <div class="HirehallComtitle">人才互聘大厅</div>
                <div class="HirehallComss">
                  <el-input placeholder="请输入内容" v-model="pagination.recruitTitle"
                    class="input-with-select HirehallComssinput" clearable>
                    <el-button slot="append" class="HirehallComssann" @click="onSubmit()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <div class="zhaopingdanwei">
                <p style="padding: 1.2rem 1.2rem 0rem 1.2rem;text-align: left;font-weight: bold;">
                  招聘单位
                  <el-link type="info">更多<i class="el-icon-arrow-right"></i></el-link>
                </p>
                <el-divider style="margin-top: 0rem;"></el-divider>
                <div class="danweilist">
                  <el-row :gutter="20" style="margin: 0rem;">
                    <el-col :span="4" v-for="item in orglistData">
                      <div class="danweiliistdiv" @click="gotoxiangqing(item)">
                        <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                          fit="contain">
                        </el-image>
                        <p>{{item.orgName}}</p>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="gzuodid">
                <span style="font-size: 1rem;margin-right: 1rem;">工作地点:</span>
                <el-radio size="mini" v-model="pagination.jobCity" label="" border>全国</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="110000" border>北京</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="310000" border>上海</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="440300" border>深圳</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="440100" border>广州</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="330100" border>杭州</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="510100" border>成都</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="320100" border>南京</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="420100" border>武汉</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="610100" border>西安</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="350200" border>厦门</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="430100" border>长沙</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="320500" border>苏州</el-radio>
                <el-radio size="mini" v-model="pagination.jobCity" label="120000" border>天津</el-radio>
              </div>
              <!-- 筛选条件 -->
              <div class="gzuodid" style="margin-top: 0rem;">
                <span style="font-size: 1rem;margin-right: 1rem;">筛选条件:</span>
                <el-select size="mini" v-model="pagination.experience" placeholder="工作经验" clearable>
                  <el-option v-for="item in experience" :key="item.dictCode" :label="item.dictValue"
                    :value="item.dictLabel">
                  </el-option>
                </el-select>
                <el-select size="mini" v-model="pagination.education" placeholder="学历要求" clearable>
                  <el-option v-for="item in education" :key="item.dictCode" :label="item.dictValue"
                    :value="item.dictLabel">
                  </el-option>
                </el-select>
                <el-select size="mini" v-model="pagination.job_salary" placeholder="薪资要求" clearable>
                  <el-option v-for="item in job_salary" :key="item.dictCode" :label="item.dictValue"
                    :value="item.dictLabel">
                  </el-option>
                </el-select>
                <el-select size="mini" v-model="pagination.financing" placeholder="融资阶段" clearable>
                  <el-option v-for="item in financing" :key="item.dictCode" :label="item.dictValue"
                    :value="item.dictLabel">
                  </el-option>
                </el-select>
                <el-select size="mini" v-model="pagination.scale" placeholder="公司规模" clearable>
                  <el-option v-for="item in scale" :key="item.dictCode" :label="item.dictValue" :value="item.dictLabel">
                  </el-option>
                </el-select>
                <el-select size="mini" v-model="pagination.industry" placeholder="行业领域" clearable>
                  <el-option v-for="item in subject_type" :key="item.dictCode" :label="item.dictLabel"
                    :value="item.dictLabel">
                  </el-option>
                </el-select>
                <el-select size="mini" v-model="pagination.nature" placeholder="工作性质" clearable>
                  <el-option v-for="item in nature" :key="item.dictCode" :label="item.dictValue"
                    :value="item.dictLabel">
                  </el-option>
                </el-select>
              </div>
              <div class="zhaopingxxlist">
                <el-row :gutter="20" v-loading="loading" element-loading-text="拼命加载中"
                  element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                  <el-col :span="18" class="zplist" v-if="tableData.length">
                    <div style="background-color: #f1f2f5;">
                      <div v-for="item in tableData">
                        <div class="zplistdiv" @click="xiangq(item)">
                          <div style="display: flex;width: 70%;">
                            <div style="width: 60%;text-align: left;padding-left: 1rem;">
                              <!-- 公司名称 -->
                              <div style="font-weight: 800;font-size: 1.2rem;margin-bottom: 0.5rem;">
                                {{item.recruitTitle}}
                              </div>
                              <div>
                                <!-- 薪资 -->
                                <span style="color: red;font-weight: 500;" v-if="item.jobSalary==1">3k以下</span>
                                <span style="color: red;font-weight: 500;" v-if="item.jobSalary==2">3-5k</span>
                                <span style="color: red;font-weight: 500;" v-if="item.jobSalary==3">5-10k</span>
                                <span style="color: red;font-weight: 500;" v-if="item.jobSalary==4">10-15k</span>
                                <span style="color: red;font-weight: 500;" v-if="item.jobSalary==5">15-20k</span>
                                <span style="color: red;font-weight: 500;" v-if="item.jobSalary==6">20-30k</span>
                                <span style="color: red;font-weight: 500;" v-if="item.jobSalary==7">30-50k</span>
                                <span style="color: red;font-weight: 500;" v-if="item.jobSalary==8">50k以上</span>
                                &emsp;&emsp;
                                <!-- 学历 -->
                                <span style="" v-if="item.education==0"> 不限 </span>
                                <span style="" v-if="item.education==1"> 初中及以下 </span>
                                <span style="" v-if="item.education==2"> 中专/技校 </span>
                                <span style="" v-if="item.education==3"> 高中 </span>
                                <span style="" v-if="item.education==4"> 大专 </span>
                                <span style="" v-if="item.education==5"> 本科 </span>
                                <span style="" v-if="item.education==6"> 硕士 </span>
                                <span style="" v-if="item.education==7"> 博士 </span>
                                /
                                <!-- 经验 -->
                                <span style="" v-if="item.experience==0"> 不限 </span>
                                <span style="" v-if="item.experience==1"> 在校生 </span>
                                <span style="" v-if="item.experience==2"> 应届生 </span>
                                <span style="" v-if="item.experience==3"> 1年以内 </span>
                                <span style="" v-if="item.experience==4"> 1-3年 </span>
                                <span style="" v-if="item.experience==5"> 3-5年 </span>
                                <span style="" v-if="item.experience==6"> 5-10年 </span>
                                <span style="" v-if="item.experience==7"> 10年以上 </span>

                              </div>

                            </div>

                            <div style="width: 40%;">
                              <div>集团</div>
                              <div style="margin-top: 0.5rem;">
                                <!-- 行业、上市、大小 -->
                                <span style="" v-if="item.industry==0"> 养老行业 </span>
                                <span style="" v-if="item.industry==1"> 家政行业 </span>

                                <span style="" v-if="item.financing==0">| 不限 </span>
                                <span style="" v-if="item.financing==1">| 未融资 </span>
                                <span style="" v-if="item.financing==2">| 天使轮 </span>
                                <span style="" v-if="item.financing==3">| A轮 </span>
                                <span style="" v-if="item.financing==4">| B轮 </span>
                                <span style="" v-if="item.financing==5">| C轮 </span>
                                <span style="" v-if="item.financing==6">| D轮及以上 </span>
                                <span style="" v-if="item.financing==7">| 已上市 </span>
                                <span style="" v-if="item.financing==8">| 不需要融资 </span>

                                <span style="" v-if="item.scale==0">| 不限 </span>
                                <span style="" v-if="item.scale==1">| 0-20人 </span>
                                <span style="" v-if="item.scale==2">| 20-99人 </span>
                                <span style="" v-if="item.scale==3">| 100-499人 </span>
                                <span style="" v-if="item.scale==4">| 500-999人 </span>
                                <span style="" v-if="item.scale==5">| 1000-9999人 </span>
                                <span style="" v-if="item.scale==6">| 10000人以上 </span>
                              </div>
                            </div>
                          </div>
                          <div style="width: 30%;">公司图标</div>
                        </div>
                        <div
                          style="background-color: #FFFFFF;margin-bottom: 1rem;padding: 0.5rem 2rem;display: flex;justify-content: space-between;">
                          <div>
                            <!-- 头像 -->

                            <!-- 名字 -->
                            发布人
                          </div>
                          <div>
                            <!-- 日期 -->
                            {{item.updateTime}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col v-else :span="18" class="zplist">
                    <el-empty :image-size="200"></el-empty>
                  </el-col>
                  <el-col :span="6" class="userif">
                    <div class="userifdiv">
                      <el-row :gutter="20"
                        style="background-color: #FFFFFF;border-radius: 4px;margin-bottom: 10px;height: 15rem;">
                        <div style="display: flex;padding: 1rem 2rem;">
                          <div>
                            <img v-if="unionLevel==1" style="width: 4rem;height: 4rem;" src="../assets/lv1.jpg">
                            <img v-if="unionLevel==2" style="width: 4rem;height: 4rem;" src="../assets/lv2.jpg">
                            <img v-if="unionLevel==3" style="width: 4rem;height: 4rem;" src="../assets/lv3.jpg">
                            <img v-if="unionLevel==4" style="width: 4rem;height: 4rem;" src="../assets/lv4.jpg">
                            <img v-if="unionLevel==5" style="width: 4rem;height: 4rem;" src="../assets/lv5.jpg">
                            <img v-if="unionLevel==6" style="width: 4rem;height: 4rem;" src="../assets/lv6.jpg">
                          </div>
                          <div style="text-align: left;margin-left: 1rem;">
                            <div>{{nickName}}</div>
                            <div class="mmb">联盟积分：{{unionCoin}}</div>
                          </div>
                        </div>
                        <div class="letfann">
                          <div class="letfanndiv" @click="letfanndivclick(1)">
                            <div class="letfanntext">发布职位</div>
                            <div class="letfannsun">{{fbzws}}</div>
                          </div>

                          <div class="letfanndiv" @click="letfanndivclick(2)">
                            <div class="letfanntext">人才库</div>
                            <div class="letfannsun">{{scs}}</div>
                          </div>
                        </div>
                        <el-button type="primary" @click="Upload()"
                          style="width: 60%;margin-top: 1.35rem;margin-bottom: 1rem;">
                          发布职位
                        </el-button>
                      </el-row>

                    </div>
                  </el-col>
                </el-row>
              </div>
              <!-- 分页 -->
              <div class="block" style="margin-bottom: 3rem;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pagination.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
              </div>
            </el-col>
          </el-row>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        loading: true,
        scs: 0,
        fbzws: 0,
        unionLevel: localStorage.getItem("unionLevel"),
        // 联盟积分
        unionCoin: '',
        // 用户名
        nickName: '',
        tableData: [],
        total: '',
        orglistData: [],
        // 分页
        pagination: {
          'pageNum': 1,
          'pageSize': 10,
          'recruitTitle': '',
          'jobCity': '',
          'experience': '',
          'education': '',
          'job_salary': '',
          'financing': '',
          'scale': '',
          'industry': '',
          'nature': '',
        },
        // 岗位薪资
        job_salary: [],
        // 学历要求
        education: [],
        // 工作经验
        experience: [],
        // 融资阶段
        financing: [],
        // 公司规模
        scale: [],
        // 工作性质
        nature: [],
        // 专业类别
        subject_type: [],
        educationvalue: '',
        job_salaryvalue: '',
        financingvalue: '',
        scalevalue: '',
        subject_typevalue: '',
        naturevalue: ''
      }
    },
    methods: {
      Upload() {
        localStorage.setItem('BaractiveIndex', "/Releaseposition");
        localStorage.setItem('activeIndex', "1");
        this.$router.push('/Releaseposition');
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pagination.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pagination.pageNum = val;
        this.onSubmit();
      },
      // 招聘信息点击事件
      Clicktoview(e) {
        localStorage.setItem("resId", e);
        this.$router.push({
          name: 'ResourceDetails',
          params: {
            resId: e
          }
        });
      },
      // 前往招聘单位详情页面
      gotoxiangqing(e) {
        this.$router.push({
          name: 'Unitdetails',
          params: {
            orgId: e.orgId
          }
        });
        localStorage.setItem('xggorgId', e.orgId) //存起来
      },
      // 通用根据类型获取数据字典接口
      getDictLists() {
        let params = ["job_salary", "education", "experience", "financing", "scale", "nature", "subject_type"]
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/pubilc/getDictLists',
          data: params,
        }).then((res) => {
          // 岗位薪资
          this.job_salary = res.data.data.job_salary
          // 学历要求
          this.education = res.data.data.education
          // 工作经验
          this.experience = res.data.data.experience
          // 融资阶段
          this.financing = res.data.data.financing
          // 公司规模
          this.scale = res.data.data.scale
          // 工作性质
          this.nature = res.data.data.nature
          // 专业类别
          this.subject_type = res.data.data.subject_type
          console.log(res.data);
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      onSubmit() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/recruit/recruitInfo/list?isRecommendRecruitOrg?pageNum=' +
            this.pagination.pageNum + '&pageSize=' + this.pagination.pageSize,
          data: this.pagination,
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            this.tableData = res.data.rows;
            this.total = res.data.total;
          } else {
            this.tableData = [];
            this.loading = false;
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 跳转
      xiangq(e) {
        let params = {
          "recruitId": e.recruitId
        }
        // 跳转到项目详情页面弹窗
        this.$router.push({
          name: 'RecruitmentDetails',
          params: params
        });
      }

    },
    // 页面加载完毕事件
    mounted() {
      let _this = this;
      _this.onSubmit();
      _this.getDictLists()
      // 获取当前用户信息
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.getItem("userId"),
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        // 联盟积分
        _this.unionCoin = res.data.data.unionCoin;
        // 用户名
        _this.nickName = res.data.data.nickName;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/recruit/recruitInfo/getUserRecruitData/' + localStorage.getItem("userId"),
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        // 联盟积分
        _this.fbzws = res.data.data ? res.data.data.zps : 0;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      let params3 = {
        "education": 1,
        "major": 1,
        "status": 1
      }
      // 根据用户ID获取收藏的人才列表接口
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/recruit/recruitResume/list?pageNum=1&pageSize=10',
        params: params3,
        //请求成功的回调函数
      }).then((res) => {
        // 收藏的人才数
        _this.scs = res.data.data ? res.data.data : 0;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
    }
  }
</script>

<style src="@/style/HirehallCom.css" scoped></style>
<style scoped>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  .el-radio--mini.is-bordered {
    padding: 6px 2px 0px 2px !important;
    border-radius: 3px;
    height: 28px;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .iconstyle {
    float: left;
    margin-right: 1rem;
  }

  .gzuodid {
    text-align: left;
    padding: 0.5rem 0.5rem;
  }

  .gzuodid .el-select {
    width: 8rem;
    margin-right: 1rem;
  }

  .gzuodid .el-radio {
    margin-right: 0px;
  }

  .zplistdiv {
    display: flex;
    padding: 1rem;
    border-bottom: solid #eeeeee 1px;
    cursor: pointer;
  }

  .zplist {
    padding-left: 0px !important;
  }

  .HirehallCom {
    height: 12.9375rem;
    background-image: url(../assets/u614.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .HirehallComss {
    margin-top: -70px;
  }

  .el-empty {
    padding: 0px;
  }
</style>
