<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <!-- 面包屑 -->
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>工作台</el-breadcrumb-item>
          <el-breadcrumb-item>团队交流管理</el-breadcrumb-item>
          <el-breadcrumb-item>我的关注</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#ffffff" text-color="#8D8D8D" active-text-color="#008FE0" style="font-weight: bold;">
          <el-menu-item index="1">我关注的专栏({{total}})</el-menu-item>
          <el-menu-item index="2">我关注的文章({{getlisttotal}})</el-menu-item>
          <el-menu-item index="3">我关注的问题({{cytotal}})</el-menu-item>
        </el-menu>
      </el-col>
    </el-row>

    <div class="tableData" v-if="activeIndex == 1">
      <el-row :gutter="20">
        <el-col :span="6" v-for="item in tableData" style="text-align: left;margin-top: 0.7rem;">
          <div class="kap">
            <div class="specialTitle">{{item.specialTitle}}</div>
            <div class="specialContent">{{item.specialContent}}</div>
            <div class="xiadiv">
              <span class="followNum">{{item.followNum}}关注</span>｜
              <span class="articleNum">{{item.articleNum}}篇文章</span>
            </div>
            <div class="buttondiv">
              <el-button type="primary" size="mini" plain @click="putclick(item)">进入专栏</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 我的关注文章 -->
    <div class="tableData1" v-if="activeIndex == 2">
      <!--  -->
      <div class="tableData1div" v-for="item in tableData1">
        <!-- 图片 -->
        <div style="margin-right: 1rem;">
          <el-image style="width: 200px; height: 100px" :src="item.articleCoverUrl" fit="contain">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <!-- 内容 -->
        <div
          style="width: 70%;display: flex;flex-direction: column;justify-content: space-around;align-items: flex-start;">
          <div>
            <!-- 标题 -->
            <el-link :underline="false" @click="Clicktoview2(item.articleId)"
              style="line-height: 2rem;font-size: 1.3rem;">
              {{item.articleTitle}}
            </el-link>
          </div>
          <div>
            <el-link :underline="false" @click="Clicktoview2(item.articleId)" style="line-height: 2rem;">
              预览（{{item.browseNum}}）
            </el-link>
            <el-link :underline="false" @click="Clicktoview2(item.articleId)" style="line-height: 2rem;">
              关注（{{item.followNum}}）
            </el-link>
            <el-link :underline="false" @click="Clicktoview2(item.articleId)" style="line-height: 2rem;">
              回复（{{item.commentNum}}）
            </el-link>
            <el-link :underline="false" @click="Clicktoview2(item.articleId)" style="line-height: 2rem;">
              点赞（{{item.fabulousNum}}）
            </el-link>
          </div>
        </div>
        <!-- 日期 -->
        <div style="text-align: end;padding-top: 1.2rem;">{{item.updateTime}}</div>
      </div>
    </div>
    <div class="tableData2" v-if="activeIndex == 3">
      <div class="tableData1div" v-for="item in tableData2">
        <!-- 内容 -->
        <div style="width: 70%;display: flex;flex-direction: column;
        justify-content: space-around;align-items: flex-start;">
          <div>
            <!-- 标题 -->
            <el-link :underline="false" @click="Clicktoview2(item.articleId)"
              style="line-height: 2rem;font-size: 1.3rem;">
              {{item.articleTitle}}
            </el-link>
          </div>
          <div>
            <el-link :underline="false" @click="Clicktoview2(item.articleId)" style="line-height: 2rem;">
              预览（{{item.browseNum}}）
            </el-link>
            <el-link :underline="false" @click="Clicktoview2(item.articleId)" style="line-height: 2rem;">
              关注（{{item.followNum}}）
            </el-link>
            <el-link :underline="false" @click="Clicktoview2(item.articleId)" style="line-height: 2rem;">
              回复（{{item.commentNum}}）
            </el-link>
            <el-link :underline="false" @click="Clicktoview2(item.articleId)" style="line-height: 2rem;">
              点赞（{{item.fabulousNum}}）
            </el-link>
          </div>
        </div>
        <!-- 日期 -->
        <div style="text-align: end;padding-top: 1.2rem;">{{item.updateTime}}</div>
      </div>
    </div>
    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        // 当前的类型
        activeIndex: '1',
        value1: "",
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 为用户ID
          'userId': localStorage.getItem("userId"),
          // 项目名称
          'projectName': "",
          // 项目状态
          'projectStatus': "",
        },
        // 我关注的专栏
        total: 0,
        // 我关注的问题
        cytotal: 0,
        // 我关注的文章
        getlisttotal: 0,
        // 表格数据
        tableData: [{
          articleNum: 0,
          createBy: null,
          createTime: "2022-05-03 10:22:38",
          followNum: 0,
          followUserId: null,
          params: {},
          remark: null,
          searchValue: null,
          specialContent: "今天早上测试今天早上测试今天早上测试",
          specialId: 6,
          specialTitle: "今天早上测试",
          updateBy: null,
          updateTime: "2022-05-03 10:22:37",
        }],
        tableData1: [],
        tableData2: [{
          articleContent: null,
          articleCover: "1247",
          articleCoverUrl: "http://blockedu.zctcloud.com/ec15420d49b64773bc8f71557d1526f8.jpeg",
          articleId: 5,
          articleIntroduce: "封面上传封面上传封面上传封面上传封面上传封面上传",
          articleStatus: "0",
          articleTitle: "封面上传",
          avatar: null,
          browseNum: 3,
          commentNum: 0,
          createBy: null,
          createTime: "2022-03-06 17:36:52",
          fabulousNum: 0,
          followNum: 0,
          followUserId: null,
          isFabulous: null,
          isFollow: null,
          nickName: null,
          params: {},
          remark: null,
          searchValue: null,
          updateBy: null,
          updateTime: "2022-03-12 20:37:25",
        }],
      }
    },
    methods: {
      // 顶栏点击事件
      handleSelect(key, keyPath) {
        this.tableDataval.pageNum = "1";
        this.tableDataval.pageSize = "10";
        this.tableDataval.projectName = "";
        this.tableDataval.projectStatus = "";
        this.activeIndex = key;
        switch (key) {
          case "1":
            // 获取我关注的专栏
            this.getzhuanlan();
            break;
          case "2":
            // 获取我关注的文章
            this.getlist();
            break;
          case "3":
            // 获取我关注的问题
            this.getJoinList();
            break;
          default:
            this.getzhuanlan();
        }
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
      },
      // 标题点击事件
      Clicktoview2(e) {
        // 跳转到文章详情页面
        this.$router.push({
          name: 'Writeanarticlexiangqing'
        });
        localStorage.setItem('articleId', e);
      },
      // 获取我关注的专栏
      getzhuanlan() {
        let userId = {
          "userId": localStorage.getItem("userId")
        };
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comSpecialFollow/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: userId,
          //请求成功的回调函数
        }).then((res) => {
          // this.tableData = res.data.rows;
        });
      },
      // 获取我关注的文章
      getlist() {
        let userId = {
          "userId": localStorage.getItem("userId")
        };
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comArticle/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: userId,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData1 = res.data.rows;
          this.getlisttotal = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 获取我关注的问题
      getJoinList() {
        let userId = {
          "userId": localStorage.getItem("userId")
        };
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comQuestionFollow/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: userId,
          //请求成功的回调函数
        }).then((res) => {
          // this.tableData2 = res.data.rows;
          this.cytotal = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.getzhuanlan();
    }
  }
</script>

<style src="@/style/Team/MyattentionCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }

  .tableData {
    margin-top: 0.3rem;
  }

  .tableData1 {
    margin-top: 1rem;
  }

  .tableData1div {
    background-color: #FFFFFF;
    padding: 1rem;
    display: flex;
    border-bottom: #CCCCCC solid 0.0625rem;
    min-height: 6rem;
  }

  .tableData2 {
    margin-top: 1rem;
  }

  .el-image {
    background-color: #cccccc;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
  }

  .kap {
    background-color: #FFFFFF;
    padding: 1rem 0.8rem 0.8rem 0.8rem;
    border-radius: 0.5rem;
  }

  .specialTitle {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }

  .specialContent {
    min-height: 4rem;
    color: #CCCCCC;
  }

  .xiadiv {
    margin-bottom: 1rem;
    padding: 0rem 0.8rem 0.8rem 0rem;
    color: #CCCCCC;
  }

  .buttondiv {
    display: flex;
    justify-content: space-around;
  }
</style>
