<template>
  <div class="ResourceauditCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>专家管理</el-breadcrumb-item>
            <el-breadcrumb-item>资源审核</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="ruleForm" label-width="80px">
            <el-col :span="8">
              <el-form-item label="资源标题:" label-width="150px !important">
                <template>
                  <el-input style="width: 70%;" v-model="tableDataval.resTitle" placeholder="请输入关键字" clearable>
                  </el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="资源类型:" label-width="150px !important">
                <template>
                  <el-select v-model="tableDataval.resType" placeholder="请选择" style="width: 70%;" clearable>
                    <el-option v-for="item in resTypelist" :key="item.dictValue" :label="item.dictLabel"
                      :value="item.dictValue">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <el-button>重置</el-button>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-badge :value="caibanvalue" class="item" style="margin-left: 1rem;">
          <el-button @click="tabletopclick(1)" :type="primary1">全部</el-button>
        </el-badge>
        <el-badge :value="rows" class="item" style="margin-left: 1rem;">
          <el-button @click="tabletopclick(2)" :type="primary2">待审核</el-button>
        </el-badge>
        <el-badge :value="caibanvalue" class="item" style="margin-left: 1rem;">
          <el-button @click="tabletopclick(3)" :type="primary3">已审核</el-button>
        </el-badge>
      </el-col>
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="resTitle" label="资源标题">
            </el-table-column>
            <el-table-column prop="" label="资源类型">
              <template slot-scope="scope">
                {{scope.row.resTypeName?scope.row.resTypeName:scope.row.resType}}
              </template>
            </el-table-column>
            <el-table-column prop="userNickName" label="发布者">
            </el-table-column>
            <el-table-column prop="userOrgName" label="发布机构">
            </el-table-column>
            <!-- <el-table-column prop="" label="指定审核员" width="160" fixed="right">
              <template slot-scope="scope">

              </template>
            </el-table-column> -->
            <el-table-column prop="" label="操作" width="160" fixed="right">
              <template slot-scope="scope">
                <el-button :disabled="userType === 0 || userType === 1 || userType === 3" v-if="scope.row.status == '1'"
                  style="color: #409EFF;" type="text" size="small" @click="examine(scope.row.resId,1)">
                  审核
                </el-button>
                <el-button else="" type="text" style="color: #67C23A;" size="small" @click="examine(scope.row.resId)">
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
        <!-- 弹窗 -->
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
          <span>审核理由:</span>
          <el-input v-model="parameter.auditReason" placeholder="请输入活动名称"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="toexamine(0)">退回审核</el-button>
            <el-button type="primary" @click="toexamine(1)">通过审核</el-button>
          </span>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    data: function() {
      return {
        primary1: 'primary',
        primary2: '',
        primary3: '',
        dialogVisible: false,
        value1: "",
        // 查询条件
        ruleForm: {
          name: '',
          value: ''
        },
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          "resTitle": "",
          "resType": "",
          "status": ''
        },
        // 总数
        total: 0,
        tableData: [],
        thisuserId: localStorage.getItem("userId"),
        resIds: '',
        // 审核参数
        parameter: {
          "resId": '',
          "status": '',
          "auditReason": ''
        },
        // 待审核总算
        rows: '',
        // 资源类型
        resTypelist: [],
        userType: 0
      }
    },
    methods: {
      // all全部
      tabletopclick(e) {
        if (e == 1) { //全部
          delete this.tableDataval.statuss
          this.tableDataval.status = '';
          this.primary1 = 'primary';
          this.primary2 = '';
          this.primary3 = '';
        } else if (e == 2) { //待审核
          delete this.tableDataval.statuss
          this.tableDataval.status = '1';
          this.primary1 = '';
          this.primary2 = 'primary';
          this.primary3 = '';
        } else if (e == 3) { //已审核
          delete this.tableDataval.status
          this.tableDataval.statuss = ['2', '3'];
          this.primary1 = '';
          this.primary2 = '';
          this.primary3 = 'primary';
        }
        this.onSubmit();
      },
      // 获取当前缓存里面的用户id
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      onSubmit() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resInfo/shlist?pageNum=' + this.tableDataval.pageNum + "&pageSize=" +
            this.tableDataval.pageSize,
          data: this.tableDataval,
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.total = res.data.total;
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 获取待审核总算
      resType() {
        let tableDataval = {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          "resTitle": "",
          "resType": "",
          "status": '1'
        };
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/resouse/resInfo/shlist?pageNum=' + this.tableDataval.pageNum + "&pageSize=" +
            this.tableDataval.pageSize,
          data: tableDataval,
        }).then((res) => {
          this.rows = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 审核事件
      examine(e, i) {
        let row = {
          resId: e,
          setid: i ? 1 : 0,
        }
        this.$router.push({
          name: 'Resourceauditdetails',
          params: {
            resId: row,
          }
        });
      },
      // 审核事件
      toexamine(e) {
        // 验证是否为空理由
        if (this.parameter.auditReason == null || this.parameter.auditReason == "") {
          this.$message('审核理由不可为空！');
        } else {
          if (e == 1) {
            this.parameter.status = 2
          } else {
            this.parameter.status = 1
          }
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/resouse/resInfo/changeStatus',
            data: this.parameter,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!',
                offset: 100
              });
              // 关闭弹窗
              this.dialogVisible = false
              this.onSubmit();
              // 获取待审核总算
              this.resType();
            } else {
              this.$message.error(res.data.msg);
              this.onSubmit();
              // 获取待审核总算
              this.resType();
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      // 退回事件
      toreturn() {
        // 关闭弹窗
        this.dialogVisible = false
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      }
    },
    // 页面加载完毕事件
    mounted() {
      // 获取资源类型
      let dictType = ['res_type']
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/pubilc/getDictLists',
        data: dictType
        //请求成功的回调函数
      }).then((res) => {
        this.resTypelist = res.data.data.res_type
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取待审核总算
      this.resType();
      //ajax请求
      this.onSubmit();
      this.userType = localStorage.getItem('userType') ? Number(localStorage.getItem('userType')) + '' : ''
    }
  }
</script>

<style src="@/style/Expert/ResourceauditCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
