<template>
  <div class="MechanismCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 5.33625rem; margin-left: 0px;height: auto;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>发布培训项目通知</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <div style="text-align: right;padding-right: 2rem;position: fixed;top: 10rem;right: 2rem;">
      </div>
      <el-col :span="24">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
          style="padding: 2rem 10% 0% 5%;margin-top: 0rem;">
          <el-form-item label="通知标题" prop="noticeTitle" label-width="150px !important">
            <el-input v-model="ruleForm.noticeTitle" placeholder="请输入5-30个字符" maxlength="30" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="通知简介" prop="noticeIntroduce" label-width="150px !important">
            <el-input type="textarea" :rows="4" v-model="ruleForm.noticeIntroduce" placeholder="请输入500字以内的简介"
              maxlength="500" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="类型" prop="type" label-width="150px !important">
            <div style="text-align: left;">
              <el-radio v-model="ruleForm.type" label="0">普通</el-radio>
              <el-radio v-model="ruleForm.type" label="1">开班</el-radio>
              <el-radio v-model="ruleForm.type" label="2">结业</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="附件" label-width="150px !important">
            <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-remove="fjhandleRemove"
              ref="fjupload" :on-exceed="fjonexceed" :before-upload="beforeAvatarUpload" :on-success="fjuploadSuccess"
              multiple :limit="10" :file-list="fileList" accept=".doc,.docx,.ppt,.ppt,.pdf">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip" style="width: 100%;">只能上传doc/docx/ppt/pptx/pdf文件 单个附件不超过20MB，最多上限十条（包含但不限于以下几个附件《开班通知》《课程表》）</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="封面" label-width="150px !important">
            <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :on-success="uploadSuccess"
              ref="fmupload" :on-remove="handleRemove" :on-exceed="onexceed" limit="1" style="display: flex;">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="正文" prop="noticeContent" label-width="150px !important">
            <quill-editor class="editor" v-model="ruleForm.noticeContent" ref="myQuillEditor" :options="editorOption"
              @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)">
            </quill-editor>
          </el-form-item>

          <el-form-item style="padding-top: 4rem;">
            <el-button type="primary" @click="$router.back()">返回</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
            <el-button type="primary" @click="submitForm('ruleForm',1)">提交</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import $ from 'jquery';
  import {
    quillEditor
  }
  from "vue-quill-editor"; //调用编辑器
  import "quill/dist/quill.snow.css";
  export default {
    name: '',
    components: {
      // commonComponents
      quillEditor, //使用编辑器
    },
    data: function() {
      return {
        trainNoticeId: '',
        ruleForm: {
          noticeTitle: '',
          noticeContent: '',
          noticeIntroduce: '',
          noticeCover: '',
          files: [],
          type: '1',
          noticeStatus: '1',
          createBy: localStorage.getItem("userId"),
        },
        rules: {
          noticeTitle: [{
            required: true,
            message: '请输入通知标题',
            trigger: 'blur'
          }],
          noticeIntroduce: [{
            required: true,
            message: '请输入通知简介',
            trigger: 'blur'
          }],
          noticeContent: [{
            required: true,
            message: '请输入通知内容',
            trigger: 'blur'
          }],
        }
      }
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    methods: {
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.$refs.fmupload.clearFiles();
        this.$refs.fjupload.clearFiles();
      },

      //  保存
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let _this = this;
            _this.ruleForm.files = this.ruleForm.files.toString();
            _this.ruleForm.noticeStatus = '1'
            if (!_this.ruleForm.noticeContent) {
              this.$message({
                message: "请输入通知内容！",
                type: 'error',
                offset: 100
              });
            } else {
              this.$axios({
                method: this.trainNoticeId ? 'put' : 'post',
                url: this.$baseUrl.test + '/train/trainNotice',
                data: _this.ruleForm,
              }).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: res.data.msg,
                    type: 'success',
                    offset: 100
                  });
                  _this.resetForm('ruleForm')
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: 'error',
                    offset: 100
                  });
                }
              }).catch(error => {
                // 处理错误
                this.$message({
                  offset: 100,
                  message: error.msg,
                  type: 'error'
                });
              });
            }

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      fjuploadSuccess(response, file, fileList) {
        this.ruleForm.files.push(response.data.fileId);
      },
      fjhandleRemove(response, file, fileList) {
        // 清空ID
        this.ruleForm.files = '';
      },
      fjonexceed() {
        this.$message({
          offset: 100,
          showClose: true,
          message: '上传附件不能超过 10条!',
          type: 'error'
        });
      },
      // 文件上传之前
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
          this.$message({
            offset: 100,
            showClose: true,
            message: '上传附件大小不能超过 20MB!',
            type: 'error'
          });
        }
        return isLt2M;
      },
      // 封面上传成功
      uploadSuccess(response, file, fileList) {
        this.ruleForm.noticeCover = response.data.fileId + '';
      },
      // 封面删除
      handleRemove(response, file, fileList) {
        // 清空ID
        this.ruleForm.noticeCover = '';
      },
      // 文件超出个数限制时的钩子
      onexceed() {
        this.$message({
          message: "封面限制上传一张！",
          type: 'warning',
          offset: 100
        });
      },
      onEditorChange() {
        //内容改变事件
        this.$emit("input", this.ruleForm.content);
      },
      fanhui() {
        window.history.go(-1)
      },
      getData() {
        let url = "/train/trainNotice/" + this.trainNoticeId + '?loginUserId=' + localStorage.getItem("userId")
        // 获取文章详情
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + url,
          params: '',
        }).then((res) => {
          if (res.data.code == 200) {
            this.ruleForm = res.data.data
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.trainNoticeId = this.$route.query.trainNoticeId
      if(this.trainNoticeId){
        this.getData();
      }
    }
  }
</script>

<style src="@/style/Team/WriteanarticleCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    text-align: right;
  }

  .ql-container {
    height: 32rem !important;
  }

  .ql-editor {
    min-height: 32rem;
  }

  .editor {
    line-height: normal;
    height: 220px;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: '文本';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: '标题1';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: '标题2';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: '标题3';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: '标题4';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: '标题5';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: '标题6';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: '标准字体';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    content: '衬线字体';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    content: '等宽字体';
  }

  #disabledMsg.el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    color: #bfd9d8;
    background-color: #eef6f6;
    border-color: #d1e5e5;
  }

  .upload-demo {
    border-radius: 6px;
    text-align: justify;
  }
</style>
