<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>培训课程详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div style="text-align: right;padding-right: 2rem;position: fixed;top: 10rem;right: 2rem;">
          <el-button type="primary" @click="$router.back()">返回</el-button>
        </div>
        <el-col :span="20">
          <!-- 基本信息 -->
          <div class="jbxxdiv">
            <el-descriptions class="jbxxdivdescriptions" title="课程介绍" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
                <div style="margin: 20px;">
                  课程名称：{{ruleForm.courseTitle}}
                </div>
                <div style="margin: 20px;">
                  课程介绍：{{ruleForm.courseContent}}
                </div>
              </el-descriptions-item>
            </el-descriptions>

            <el-descriptions class="jbxxdivdescriptions" title="课程附件" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
              </el-descriptions-item>
            </el-descriptions>
            <template>
              <el-table :data="fileData" border style="width: 100%">
                <el-table-column type="index" width="50" label="序号">
                </el-table-column>
                <el-table-column prop="chapterName" label="附件名称">
                </el-table-column>
                <el-table-column prop="nftId" label="区块链地址">
                </el-table-column>
                <el-table-column prop="createTime" label="上传日期">
                </el-table-column>
                <el-table-column prop="" label="操作" scope="scope" width="90">
                  <template slot-scope="scope">
                    <span style="color: red;margin-right: 10px;" v-if="userType != 2"
                      @click="shanchufuj(scope.row)">删除</span>
                    <span style="color: #0066FF;" @click="chakan(scope.row)">下载</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </el-col>
      </el-col>
    </el-row>
    <el-row :span="24" style="background-color: #f1f2f5;height: 10px;"></el-row>
    <el-row>
      <el-col :span="18" style="padding-right: 10px !important;">
        <!-- 文章 -->
        <div v-if="formdata.length" class="grid-content bg-purple" style="height: 43.5vh;overflow-x: auto;">
          <el-row v-for="item in formdata" style="border-bottom: 1px solid #f2f2f2;margin-bottom: 10px;">
            <el-col :span="16">
              <div class="grid-content bg-purple zuoxianrtitle">
                <div style="white-space: nowrap;">
                  <!-- 标题 -->
                  <el-link :underline="false" @click="Clicktoview2(item.articleId)"
                    style="line-height: 3rem;font-size: 1.3rem;">
                    {{item.articleTitle}}
                  </el-link>
                </div>
              </div>
              <div class="zuoxianrjj">
                <!-- 简介 -->
                <span>{{item.articleIntroduce}}</span>
              </div>
              <div class="dibutext">
                <div><i class="el-icon-chat-dot-round"></i>评论：({{item.commentNum}})</div>
                <div><i class="el-icon-star-off"></i>关注：({{item.followNum}})</div>
                <div><i class="el-icon-thumb"></i>点赞：({{item.fabulousNum}})</div>
              </div>
            </el-col>
            <el-col :span="8">
              <!-- 图片 -->
              <div class="homdeann" v-if="item.articleCoverUrl">
                <el-image :src="item.articleCoverUrl" fit="contain"></el-image>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-empty style="background-color: #FFFFFF;" v-else description="暂无数据"></el-empty>
        <!-- 分页 -->
        <div class="block" style="background-color: #FFFFFF;">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pagination.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </el-col>

      <!-- 右边内容 -->
      <el-col :span="6">
        <el-row :gutter="20" style="background-color: #FFFFFF;border-radius: 4px;margin-bottom: 10px;height: 15rem;">
          <div style="display: flex;padding: 1rem 2rem;">
            <div>
              <img v-if="unionLevel==1" style="width: 4rem;height: 4rem;" src="../../assets/lv1.jpg">
              <img v-if="unionLevel==2" style="width: 4rem;height: 4rem;" src="../../assets/lv2.jpg">
              <img v-if="unionLevel==3" style="width: 4rem;height: 4rem;" src="../../assets/lv3.jpg">
              <img v-if="unionLevel==4" style="width: 4rem;height: 4rem;" src="../../assets/lv4.jpg">
              <img v-if="unionLevel==5" style="width: 4rem;height: 4rem;" src="../../assets/lv5.jpg">
              <img v-if="unionLevel==6" style="width: 4rem;height: 4rem;" src="../../assets/lv6.jpg">
            </div>
            <div style="text-align: left;margin-left: 1rem;">
              <div>{{nickName}}</div>
              <div class="mmb">联盟积分：{{unionCoin}}</div>
            </div>
          </div>
          <div class="letfann">
            <div class="letfanndiv" @click="letfanndivclick(1)">
              <div class="letfanntext">我的关注</div>
              <div class="letfannsun">{{sctotal}}</div>
            </div>

            <div class="letfanndiv" @click="letfanndivclick(2)">
              <div class="letfanntext">发布内容</div>
              <div class="letfannsun">{{xztotal}}</div>
            </div>
          </div>
          <el-button v-if="ProjectSignup || userType !=2" type="primary" @click="Upload()"
            style="width: 40%;margin-top: 1.35rem;margin-bottom: 1rem;">
            发起课题讨论
          </el-button>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    name: '',
    props: ['params'],
    data: function() {
      return {
        activeIndex: '1',
        trainCourseId: "",
        active: 1,
        djcyannstyle: true,
        unionCoin: "",
        sctotal: "",
        xztotal: "",
        unionLevel: "1",
        nickName: "",
        ruleForm: {
          // 合作课程ID
          trainCourseId: "",
          // 单位名称
          orgName: "",
          // 发起人用户ID
          userId: localStorage.getItem("userId"),
          // 发起人姓名
          nickName: "",
          // 发起人手机号码
          phonenumber: "",
          // 课程名称
          projectName: "",
          // 课程编号
          projectNo: "",
          // 课程类型
          projectTypeName: "",
          // 课程合作人数
          cooperationNum: "",
          // 允许参与报名人数
          allowSignupNum: "",
          // 邀请人
          inviteMembers: [],
          // 已报名人数
          signupNum: "",
          // 结项时间
          completeTime: "",
          // 课程经费(元)
          projectFunds: "",
          // 已使用经费(元)
          usedFunds: "",
          // 剩余经费(元)
          surplusFunds: "",
          // 课程介绍
          projectContent: "",
          // 课程附件
          courseFiles: "",
          // 结项申请时间
          closeApplyTime: ""
        },
        // 资源列表
        fileData: [],
        // 表格数据
        formdata: {},
        // 分页
        pagination: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
        },
        total: "0",
        ProjectSignup: false,
        userType: '',
        projectId: '',
      }
    },
    methods: {
      // 通过项目ID获取当前用户是否报名通过
      getProjectSignup() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + "/train/trainProjectSignup/getProjectSignup",
          data: {
            'projectId': this.projectId,
            'signupUserId': localStorage.getItem("userId")
          },
        }).then((res) => {
          if (res.data.data) {
            this.ProjectSignup = res.data.data.signupStatus == 2 ? true : false
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pagination.pageSize = val;
        this.searchclick();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pagination.pageNum = val;
        this.searchclick();
      },
      // 标题点击事件
      Clicktoview2(e) {
        localStorage.setItem('articleId', e);
        let params = {
          "articleId": e,
        }
        // 跳转到培训活动详情
        this.$router.push({
          path: 'trainingActivitydetails',
          query: params
        })
      },
      // 跳转发文章
      Upload() {
        let query = {
          "trainCourseId": this.trainCourseId,
        }
        // 跳转到课程详情页面弹窗
        this.$router.push({
          name: 'trainingActivities',
          query: query
        });
      },
      // 获取文章列表
      searchclick() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + "/train/trainArticle/list?pageNum=" + this.pagination.pageNum + '&pageSize=' +
            this.pagination.pageSize,
          data: {
            'courseId': this.trainCourseId
          },
        }).then((res) => {
          if (res.data.code == 200) {
            this.formdata = res.data.rows;
          }
          this.total = res.data.total;
          loading.close();
        });
      },
      // 删除附件
      shanchufuj(row) {
        // 当前项目ID
        let courseFiles = this.ruleForm.courseFiles.split(",")
        for (var i = 0; i < courseFiles.length; i++) {
          if (courseFiles[i] == row.fileId) {
            // 删除当前附件ID
            courseFiles.splice(i, 1)
          }
        }
        let data = {
          'trainCourseId': this.trainCourseId,
          'courseFiles': courseFiles.join(',')
        }
        this.$confirm('此操作将永久删除该附件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/train/trainCourse',
            data: data,
            //请求成功的回调函数
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'success',
                message: '操作成功!'
              });
              this.onSubmit();
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'error',
                message: res.data.msg
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },

      // 下载文件
      chakan(row) {
        this.getBlob(row.fileUrl).then(blob => {
          this.DownloadItem(blob, row.fileName);
        });
      },
      getBlob(url) {
        return new Promise(resolve => {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.responseType = 'blob';
          xhr.onload = () => {
            if (xhr.status === 200) {
              resolve(xhr.response);
            }
          };
          xhr.send();
        });
      },
      DownloadItem(blob, fileName) {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      },
      onSubmit() {
        let _this = this
        // 获取课程基本信息
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/train/trainCourse/' + _this.trainCourseId + '?loginUserId=' + localStorage
            .getItem("userId"),
          data: '',
        }).then((res) => {
          _this.ruleForm = res.data.data;
          // 如果是自己的课程就不要点击参与按钮
          if (localStorage.getItem("userId") == _this.ruleForm.userId) {
            _this.djcyannstyle = false;
          } else {
            _this.djcyannstyle = true;
          }
          // 获取附件列表
          if (res.data.data.courseFiles) {
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/getFileList/' + res.data.data.courseFiles,
              params: '',
            }).then((res) => {
              _this.fileData = []
              for (let i in res.data.data) {
                // 获取二级资源信息
                _this.fileData.push({
                  // 文件ID
                  fileId: res.data.data[i].fileId,
                  // 创建时间
                  createTime: res.data.data[i].createTime,
                  // 附件名称
                  chapterName: res.data.data[i].fileName.split(".")[0],
                  fileName: res.data.data[i].fileName,
                  // 文件地址
                  fileUrl: res.data.data[i].fileUrl,
                  nftId: res.data.data[i].nftId,
                })
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }

        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }
    },
    // 页面加载完毕事件
    mounted() {
      let _this = this;
      // 获取跳转过来的课程ID
      _this.trainCourseId = _this.$route.query.trainCourseId ? _this.$route.query.trainCourseId : "";
      _this.projectId = _this.$route.query.projectId ? _this.$route.query.projectId : "";
      _this.userType = localStorage.getItem("userType");
      _this.searchclick();
      _this.onSubmit();
      _this.getProjectSignup();
    }
  }
</script>

<style src="@/style/trainingDetails.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }

  .el-descriptions__header {
    margin-bottom: 0px !important;
  }

  .jbxxdiv {
    margin: 10px;
  }
</style>
