<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>培训通知列表</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="ruleForm" label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="标题:">
                <template>
                  <el-input v-model="tableDataval.noticeTitle" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态:">
                <template>
                  <el-select v-model="tableDataval.noticeStatus" placeholder="请选择" clearable>
                    <!-- （0草稿，1已发布） -->
                    <el-option label="草稿" :value="0"></el-option>
                    <el-option label="已发布" :value="1"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
                <el-button type="primary" @click="fabu">培训通知发布</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24"
      style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;height: 70vh;overflow-y: auto;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <div v-for="item in tableData" style="border-bottom: solid 1px #ccc;padding-top: 25px;">
            <div style="display: flex;margin-bottom: 20px;justify-content: space-around;">
              <div style="width: 30%;">
                <img v-if="item.noticeCoverUrl" style="width: 300px;height: 180px;object-fit: scale-down;"
                  :src="item.noticeCoverUrl" />
                <el-empty v-else style="width: 300px;height: 140px;" description="暂无封面"></el-empty>
              </div>
              <div style="width: 40%;display: flex;flex-direction: column;justify-content: space-between;">
                <div>
                  <h3 style="margin: 0;">{{item.noticeTitle}}</h3>
                  <p style="overflow: hidden;height: 90px;text-overflow: ellipsis;">{{item.noticeIntroduce}}</p>
                </div>
                <div>预览({{item.browseNum}})</div>
              </div>
              <div style="width: 100px;margin: 0 20px;">
                <h3 style="margin: 0;">当前状态</h3>
                <p v-if="item.noticeStatus == 0" style="color: #67C23A;">草稿</p>
                <p v-if="item.noticeStatus == 1" style="color: #67C23A;">待审核</p>
                <p v-if="item.noticeStatus == 2" style="color: #67C23A;">已通过</p>
                <p v-if="item.noticeStatus == 3" style="color: #D9001B;">被驳回</p>
                <el-tooltip v-if="item.noticeStatus == 3" class="item" effect="dark" :content="item.auditRemark"
                  placement="top">
                  <el-button type="text"
                    style="color: #D9001B;width: 100px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                    原因：{{item.auditRemark}}</el-button>
                </el-tooltip>
              </div>
              <div
                style="width: 30%;display: flex;flex-direction: column;justify-content: space-between;align-items: center;">
                <div style="margin-top: 50px;">
                  <el-button type="text" style="color: #F56C6C;" v-if="tableDataval.userId == item.createBy"
                    @click="delect(item)">删除
                  </el-button>
                  <el-button type="text" style="color: #409EFF;" v-if="tableDataval.userId == item.createBy"
                    @click="editclick(item)">修改
                  </el-button>
                  <el-button type="text" style="color: #409EFF;" @click="view(item)">查看详情</el-button>
                </div>
                <div>{{item.createTime}}</div>
              </div>
            </div>
          </div>
        </template>
      </el-col>
    </el-row>
    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        horizontal: '0',
        // 当前的类型
        activeIndex: '',
        value1: "",
        // 查询条件
        ruleForm: {
          name: '',
          value: '',
        },
        // 查询表单
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 用户ID
          'userId': localStorage.getItem("userId"),
        },
        // 总数
        total: 0,
        // 表格数据
        tableData: [],
      }
    },
    methods: {
      fabu() {
        this.$router.push('/initiateNewsexamine')
      },
      // 顶栏点击事件
      handleSelect(key, keyPath) {
        this.tableDataval.pageNum = "1";
        this.tableDataval.pageSize = "10";
        this.tableDataval.noticeTitle = "";
        this.tableDataval.type = key;
        this.onSubmit();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainNotice/list?pageNum=' + this.tableDataval.pageNum + '&pageSize=' +
            this.tableDataval.pageSize,
          data: this.tableDataval,
          headers: {
            'Authorization': ''
          }
          //请求成功的回调函数
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          loading.close();
          this.total = res.data.total;
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 删除
      delect(e) {
        this.$confirm('是否继续删除?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/train/trainNotice/' + e.trainNoticeId,
            data: {},
            //请求成功的回调函数
          }).then((res) => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: '删除成功!'
            });
            this.onSubmit();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        });
      },
      // 编辑点击事件
      editclick(e) {
        let query = {
          "trainNoticeId": e.trainNoticeId
        }
        this.$router.push({
          name: 'initiateNewsexamine',
          query: query
        });
      },
      view(e) {
        // 跳转到文章详情页面
        let query = {
          "trainNoticeId": e.trainNoticeId
        }
        this.$router.push({
          name: 'relatedInformationdetails',
          query: query
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit()
    }
  }
</script>

<style src="@/style/Team/ContentmanagementCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }

  .block {
    background-color: #fff;
    border-top: solid 1px #ccc;
  }

  .el-empty__image {
    width: 130px;
  }
</style>
