"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// let AUTH_TOKEN = sessionStorage.getItem('adminToken')
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);


// 请求拦截器
_axios.interceptors.request.use(config => {
  // 在发送请求前做些什么
  // 添加headers
  config.headers['Authorization'] = sessionStorage.getItem('adminToken');
  // config.headers['Content-Type'] = 'application/json';
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});
// Add a response interceptor
// 响应拦截器
_axios.interceptors.response.use(response => {
  // 只在code为某个值时做处理，例如200
  if (response.data && response.data.code === 200) {
    // 业务逻辑处理
    sessionStorage.setItem('Relogin',2)
    return response;
  } else if (response.data && response.data.code === 401) {
    sessionStorage.setItem('Relogin',1)
    var hostname = window.location.hostname; // 主机名，包括域名和端口（如果有）
    if (hostname == "localhost") {
      window.location.assign("http://localhost:8080/#/Home"); // 跳转到登陆页面
      sessionStorage.setItem('adminToken', ""); // 将token清空
    } else {
      window.location.assign("http://"+hostname + "/#/Home"); // 跳转到登陆页面
      sessionStorage.setItem('adminToken', ""); // 将token清空
    }
    return ;
  } else {
    return Promise.reject(response.data);
  }
}, error => {
  // 对响应错误做点什么
  return Promise.reject(error);
});

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
