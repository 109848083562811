<template>
  <div class="index">
    <el-row>
      <!-- 头部 -->
      <el-col :span="24">
        <Headnew @fatherMethod="fatherMethod" />
      </el-col>
    </el-row>
    <el-row :gutter="20" class="subject">
      <!-- 内容 -->
      <el-col :span="24" style="padding-right: 0px;">
        <newxiangqing />
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem;z-index: -999;">
      <!-- 底部版权 -->
      <el-col :span="24"><Copyright/></el-col>
    </el-row>
  </div>
</template>


<script>
  // 底部版权
  import Copyright from '@/components/Copyright.vue'
  import Headnew from '@/components/Headnew.vue'
  import newxiangqing from '@/components/Hire/newxiangqing.vue'

  export default {
    name: 'app',
    components: {
      Headnew,
      newxiangqing,
      Copyright
    },
   data: function () {
      return {
      }
    },
    // 页面加载完毕触发
    mounted() {

    },
    methods: {
      fatherMethod(id){
        this.$router.push('/?id='+id);
      }
    }
  }
</script>

<style src="@/style/main.css" scoped></style>
<style>
  .el-row {
    &:last-child {
      margin-bottom: 0;
    }
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
</style>
