<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>培训申报</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="项目名称:">
                <template>
                  <el-input v-model="tableDataval.projectName" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
                <el-button type="primary" @click="faqi">发起培训申报</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;" v-loading="loading" element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <template>
          <el-table :data="tableData" border style="width: 100%;max-height: 69vh;">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="projectNo" label="项目编号">
            </el-table-column>
            <el-table-column prop="projectName" label="项目名称">
            </el-table-column>
            <el-table-column prop="projectTypeName" label="项目类型">
            </el-table-column>
            <el-table-column prop="projectStatus" label="项目状态">
              <template slot-scope="scope">
                <!-- 项目状态（0草稿，1待审核，2基地审核通过，3基地审核不通过，4主管部门审核通过，5主管部门审核不通过） -->
                <span v-if="scope.row.projectStatus == 0">草稿</span>
                <span v-if="scope.row.projectStatus == 1">待审核</span>
                <span v-if="scope.row.projectStatus == 2">基地审核通过</span>
                <span v-if="scope.row.projectStatus == 3">基地审核不通过</span>
                <span v-if="scope.row.projectStatus == 4">主管部门审核通过</span>
                <span v-if="scope.row.projectStatus == 5">主管部门审核不通过</span>
              </template>
            </el-table-column>
            <el-table-column label="审核意见">
              <template slot-scope="scope">
                <!-- 基地审核不通过auditRemarkJd，主管部门审核不通过auditRemarkZg -->
                <span v-if="scope.row.auditRemarkJd">{{scope.row.auditRemarkJd}}</span>
                <span v-if="scope.row.auditRemarkZg">{{scope.row.auditRemarkZg}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间" width="160">
            </el-table-column>
            <el-table-column label="操作" width="210" fixed="right">
              <template slot-scope="scope">
                <el-button v-if="userId == scope.row.userId && scope.row.projectStatus == 0" type="text" size="small"
                  style="color: #F56C6C;" @click="deleteclick(scope.row)">删除
                </el-button>
                <el-button
                  v-if="userId == scope.row.userId && (scope.row.projectStatus == 3 || scope.row.projectStatus == 0 || scope.row.projectStatus == 5)"
                  type="text" size="small" style="color: #409EFF;" @click="editclick(scope.row)">修改</el-button>
                <el-button type="text" size="small" style="color: #409EFF;" @click="see(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        radiovalue: '我的全部',
        userId: localStorage.getItem("userId"),
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 项目名称
          'projectName': "",
          'userId': localStorage.getItem("userId")
        },
        // 总数
        total: 0,
        // 表格数据
        tableData: [],
        loading: false,
      }
    },
    methods: {
      faqi() {
        this.$router.push('/initiateTrainingApplication')
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        this.loading = true
        let token = sessionStorage.getItem('adminToken')
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainReport/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' +
            this.tableDataval.pageSize,
          data: this.tableDataval,
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.loading = false
          this.total = res.data.total;
          this.tableData = res.data.rows;
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 培训申报删除点击事件
      deleteclick(e) {
        this.$confirm('此操作将永久删除该删除培训申报, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/train/trainReport/' + e.trainReportId,
            params: '',
            //请求成功的回调函数
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'success',
                message: '删除成功!'
              });
              this.onSubmit();
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                type: 'error',
                message: res.data.msg
              });
            }
          }).catch(() => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'info',
              message: '已取消删除'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      editclick(e) { // 修改
        let query = {
          "trainReportId": e.trainReportId
        }
        this.$router.push({
          path: 'initiateTrainingApplication',
          query: query
        })
      },
      see(e) { // 查看详情
        let query = {
          "trainReportId": e.trainReportId
        }
        this.$router.push({
          path: 'declarationDetails',
          query: query
        })
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit();
    }
  }
</script>

<style src="@/style/Cooperation/MyprojectCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
