<template>
  <div class="index">
    <el-row>
      <!-- 头部 -->
      <el-col :span="24">
        <RegisterComHead str="机构入链注册" />
      </el-col>
    </el-row>
    <el-row :gutter="20" class="subject">
      <!-- 内容 机构注册-->
      <el-col :span="24">
        <InsideCom />
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem;z-index: -999;">
      <!-- 底部版权 -->
      <el-col :span="24">
        <Copyright />
      </el-col>
    </el-row>
  </div>
</template>


<script>
  // 底部版权
  import Copyright from '@/components/Copyright.vue'
  import RegisterComHead from '@/components/register/RegisterComHead.vue'
  import InsideCom from '@/components/register/InsideCom.vue'



  export default {
    name: 'app',
    components: {
      RegisterComHead,
      Copyright,
      InsideCom
    },
   data: function () {
      return {
        usertype: ''
      }
    },
    // 页面加载完毕触发
    mounted() {
      // 把指向vue对象保存给_this
      let _this = this;
      // 获取跳转过来的参数
      _this.usertype = _this.$route.query.type;
    },
    methods: {

    }
  }
</script>

<style src="@/style/main.css" scoped></style>
<style>

</style>
