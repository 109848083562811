<template>
  <div class="WriteanarticlexiangqingCom">
    <el-row :gutter="2">
      <el-col :span="24" class="maxdiv">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>门户</el-breadcrumb-item>
            <el-breadcrumb-item>相关资讯</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div style="text-align: right;padding-right: 2rem;position: fixed;top: 10rem;right: 15rem;">
          <el-button type="primary" @click="$router.back()">返回</el-button>
        </div>
        <!-- 标题 -->
        <h2 style="text-align: center;margin-bottom: 1rem;">{{comArticle.noticeTitle}}</h2>
        <p>发布时间：{{comArticle.createTime}}</p>
        <!-- 正文 -->
        <div class="ql-editor" style="padding: 0rem 2rem 2rem 2rem;" v-html="comArticle.noticeContent"></div>
        <!-- 附件 -->
        <div v-if="fileData.length > 0"
          style="display: flex;flex-direction: column;align-items: flex-start;padding-left: 30px;margin: 30px 0px;">
          <p>附件：</p>
          <el-link :underline="false" v-for="item in fileData" :href="item.fileUrl">{{item.fileName}}</el-link>
          <p>区块链地址：</p>
          <el-link :underline="false" v-for="item in fileData">{{item.nftId}}</el-link>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import $ from 'jquery';
  import "quill/dist/quill.snow.css";
  export default {
    name: '',
    components: {},
    data: function() {
      return {
        fileData: [],
        comArticle: {},
        trainNoticeId: '',
      }
    },
    mounted() {
      this.trainNoticeId = this.$route.query.trainNoticeId ? this.$route.query.trainNoticeId : ''
      this.getData();
    },
    methods: {
      getData() {
        let url = "/train/trainNotice/" + this.trainNoticeId + "?loginUserId=" + localStorage.getItem("userId")
        this.$axios({        // 获取文章详情
          method: 'get',
          url: this.$baseUrl.test + url,
          params: '',
        }).then((res) => {
          if (res.data.code == 200) {
            this.comArticle = res.data.data
            let paramsx = {
              "browseNum": Number(res.data.data.browseNum) + 1,
              "trainNoticeId": this.trainNoticeId,
            }
            this.$axios({            // 添加预览次数
              method: 'put',
              url: this.$baseUrl.test + '/system/doorArticle/',
              data: paramsx,
            }).then((res) => {});
            if(res.data.data.files){            // 获取附件列表
              this.$axios({
                method: 'get',
                url: this.$baseUrl.test + '/api/files/getFileList/' + res.data.data.files,
                params: '',
              }).then((res) => {
                for (let i in res.data.data) {
                  // 获取二级资源信息
                  this.fileData.push({
                    // 文件ID
                    fileId: res.data.data[i].fileId,
                    // 创建时间
                    createTime: res.data.data[i].createTime,
                    // 附件名称
                    chapterName: res.data.data[i].fileName.split(".")[0],
                    fileName: res.data.data[i].fileName,
                    // 文件地址
                    fileUrl: res.data.data[i].fileUrl,
                    nftId: res.data.data[i].nftId,
                  })
                }
              }).catch(error => {
                this.$message({
                  offset: 100,
                  message: error.msg,
                  type: 'error'
                });
              });
            }
          }
        }).catch(error => {
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
    }
  }
</script>

<style src="@/style/Team/WriteanarticlexiangqingCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    text-align: right;
  }

  img {
    max-width: 100%;
  }

  .userintimg {
    width: 3rem;
    height: 3rem;
  }
</style>
