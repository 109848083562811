<template>
  <div class="index">
    <el-container>
      <el-header>
        <div class="newtopdiv">
          <div class="topfanhuiann" @click="fanhui"><i class="el-icon-d-arrow-left"></i> 返回课程主页</div>
          <div class="toptitle">正在播放：{{filetitle}}</div>
          <div></div>
        </div>
      </el-header>
      <el-container>
        <el-main>
          <div class="videodiv">
            <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
              :options="playerOptions">
            </video-player>
          </div>
        </el-main>
        <el-aside width="300px">
          <div class="fileListdiv">
            <!-- 资源标题 -->
            <div class="filetoptitle">{{videotitle}}</div>
            <!-- 资源评分 -->
            <div class="filetoptitle">
              <el-rate v-model="resGrade" disabled show-score text-color="#ff9900"></el-rate>
            </div>
            <!-- 发布者 -->
            <div class="filetopjj">发布者：{{userNickName}}</div>
            <!-- 视频列表 -->
            <div class="videolist" v-for="item in videolist">
              <!-- 章节名称 -->
              <p class="videolistp" @click="chapterNameclick(item)">
                {{item.chapterName}} : {{item.createTime}}
              </p>
              <!-- 时长 -->
            </div>
          </div>
        </el-aside>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import 'videojs-contrib-hls'
  import 'videojs-flash'
  import 'vue-video-player/src/custom-theme.css'
  import 'video.js/dist/video-js.css'
  import {
    videoPlayer
  } from 'vue-video-player'
  export default {
    name: 'Live-Demo',
    components: {
      videoPlayer
    },
    data: function() {
      return {
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          autoplay: true, // 如果为true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: true, // 是否视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "video/mp4", // 类型
            src: '' // 视频url地址
          }],
          poster: "http://119.3.109.199:9091/profile/common/image/2021/12/09/9eebe03463964963a5d8a978d20bc08e.png", //你的封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: true // 是否显示全屏按钮
          }
        },
        // 视频列表
        videolist: {},
        // 资源标题
        videotitle: "",
        // 资源等级
        resGrade: "",
        // 发布者
        userNickName: "",
        // 章节标题
        filetitle: "第一章节",
        searchThing:{}
      }
    },

    methods: {
      // 列表点击事件
      chapterNameclick(e) {
        this.filetitle = e.chapterName;
        this.playerOptions.sources[0].src = e.filePath
      },
      // 返回点击事件
      fanhui() {
        this.$router.push({
          name: 'ResourceDetails',
          params: {
            resId: this.searchThing.resId
          }
        });
      }
      // playerReadied(player) {
      //   var hls = player.tech({
      //     IWillNotUseThisInPlugins: true
      //   }).hls
      //   player.tech_.hls.xhr.beforeRequest = function(options) {
      //     return options
      //   }
      // },
      // onPlayerPlay(player) {
      //   this.$refs.videoPlayer.player.play();
      // },
      // onPlayerPause(player) {
      //   this.$refs.videoPlayer.player.pause();
      // }
    },
    // 页面加载完毕触发
    mounted() {
      this.searchThing.fileId = this.$route.params.fileId
      this.searchThing.resId = this.$route.params.resId
      // 单个文件ID
      let fileId = this.$route.params.fileId ? this.$route.params.fileId : 0;
      // 资源ID
      let resId = this.$route.params.resId ? this.$route.params.resId : 0;
      let params = {
        "resId": resId,
        "loginUserId": localStorage.getItem("userId")
      }
      // 获取整个资源
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/resouse/resInfo/getResInfo',
        data: params,
      }).then((res) => {
        this.videotitle = res.data.data.resTitle;
        // 发布者
        this.userNickName = res.data.data.userNickName;
        // 资源等级
        this.resGrade = res.data.data.resGrade;
        // 如果是视频类型
        let data = []
        for (let i in res.data.data.fileList) {
          if (res.data.data.fileList[i].fileExtension = "mp4") {
            data.push(res.data.data.fileList[i]);
          }
        }
        // 获取视频列表
        this.videolist = data;
        this.playerOptions.sources[0].src = data[0].filePath;
        this.filetitle = data[0].chapterName;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 如果有单个资源
      // if(fileId){
      //   this.$axios({
      //     method: 'post',
      //     url: this.$baseUrl.test + '/resouse/resInfo/getResInfo',
      //     data: params,
      //   }).then((res) => {
      //     this.videotitle = res.data.data.resTitle;
      //   });
      // }
    }
  }
</script>


<style src="@/style/Expert/VideoCom.css" scoped></style>
<style>

</style>
