<template>
  <div class="index">
    <el-row>
      <!-- 头部 -->
      <el-col :span="24">
        <Head />
      </el-col>
    </el-row>
    <el-row :gutter="20" class="subject">
      <!-- 导航栏 -->
      <el-col :span="4" class="bar">
        <Bar />
      </el-col>
      <!-- 内容 -->
      <el-col :span="20">
        <DeliveryrecordCom :params="params"/>
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem;z-index: -999;">
      <!-- 底部版权 -->
      <el-col :span="24">
        <Copyright />
      </el-col>
    </el-row>
  </div>
</template>


<script>
  // 底部版权
  import Copyright from '@/components/Copyright.vue'
  import Head from '@/components/Head.vue'
  import Bar from '@/components/Bar.vue'
  import DeliveryrecordCom from '@/components/Team/DeliveryrecordCom.vue'


  export default {
    name: 'app',
    components: {
      Copyright,
      Head,
      Bar,
      DeliveryrecordCom
    },

   data: function () {
      return {
      }
    },
    // 实例已经创建完成之后被调用。
    created(){
    },
    // 页面加载完毕触发
    mounted() {
      // 把指向vue对象保存给_this
      let _this = this;
      // 定义当前头部导航栏
      localStorage.setItem('activeIndex', "1");
      // 指定左侧导航栏
      localStorage.setItem('BaractiveIndex', "/DeliveryrecordCom");
      // 指定左侧导航栏
      _this.forceRerender();
    },
    methods: {
    },
  }
</script>


<style src="@/style/Team/DeliveryrecordCom.css" scoped></style>
<style src="@/style/main.css" scoped></style>
<style>
  .bar {
    padding-right: 0px;
    padding-left: 10px;
    padding-right: 0px !important;
    height: 100%;
  }
</style>
