<template>
  <div class="Bar">
    <el-row class="tac" style="overflow-y: auto;height: calc(100vh - 120px);">
      <el-col :span="12" style="width: 100%;height: 50%;">
        <el-menu :default-active="BaractiveIndex" :default-openeds="openedsIndex" style="height: 100%;"
          class="el-menu-vertical-demo" @open="handleOpen" background-color="#FFF" text-color="#000"
          active-text-color="#179400" collapse-transition="true">
          <el-submenu :index="index" style="text-align: initial;" v-for="(item,index) in menuList"
            v-bind:key="item.path">
            <template slot="title">
              <i :class="item.icon?item.icon:'el-icon-eleme'" style="color:#696969"></i>
              <span style="color: #676767;font-size: 1rem;font-weight: bold;">{{item.menuName}}</span>
            </template>
            <el-menu-item-group v-for="item2 in item.children">
              <el-menu-item :index="item2.path" @click="treeNavSwitch(item2.path)">{{item2.menuName}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        // 当前激活菜单的 index
        BaractiveIndex: '',
        // 当前打开的一级栏目
        openedsIndex: '',
        // 获取用户权限，用户类型（0平台管理员-秘书长  1机构管理员 2普通用户 ）
        userType: localStorage.getItem("userType"),
        // 是否为专家（0否 1是）
        isExpert: localStorage.getItem("isExpert"),
        // 菜单栏
        menuList: [],
      }
    },
    // 页面加载完毕触发
    mounted() {
      // 把指向vue对象保存给_this
      let _this = this;
      // 从本地存储取出来
      _this.BaractiveIndex = localStorage.getItem("BaractiveIndex");
      _this.menuList = JSON.parse(localStorage.getItem("menuList"));
    },
    methods: {
      // 一级菜单栏点击事件
      handleOpen(key, keyPath) {
        if (key == 0) {
          // 跳转路由
          this.$router.push('/index');
        }
      },
      // 二级菜单栏点击事件
      treeNavSwitch(nav) {
        this.BaractiveIndex = nav;  // 当前激活
        if (nav.slice(0, 1) == '{') {
          this.$router.push({
            name: 'Expertapplication',
            params: {
              caidanlan: "A"
            }
          });
        } else if (nav == '/expertapplication') {
          this.$router.push({
            name: 'Expertapplication',
            params: {
              caidanlan: "B"
            }
          });
        }else{
          this.$router.push(nav);
          localStorage.setItem('BaractiveIndex', nav);
        }
      }
    }
  }
</script>

<style src="@/style/bar.css" scoped></style>
<style>
  .el-menu-item-group__title {
    display: none !important;
  }

  ::-webkit-scrollbar {
    display: none
  }
</style>
