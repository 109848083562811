<template>
  <div class="index">
    <el-row>
      <!-- 头部 -->
      <el-col :span="24">
        <Head />
      </el-col>
    </el-row>
    <el-row :gutter="24" class="subject">
      <!-- 内容 -->
      <el-col :span="24">
        <ExpertdetailsCom :searchThing="Notice" />
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem;z-index: -999;">
      <!-- 底部版权 -->
      <el-col :span="24">
        <Copyright />
      </el-col>
    </el-row>
  </div>
</template>


<script>
  // 底部版权
  import Copyright from '@/components/Copyright.vue'
  import Head from '@/components/Head.vue'
  import Bar from '@/components/Bar.vue'
  import ExpertdetailsCom from '@/components/Expert/ExpertdetailsCom.vue'

  export default {
    name: 'app',
    components: {
      Copyright,
      Head,
      Bar,
      ExpertdetailsCom
    },

   data: function () {
      return {
        Notice: {},
      }
    },
    // 路由跳转
    created() {
      // 获取跳转过来传的值
      let _this = this;
      let zjxx = {
        expertId : _this.$route.params.resId ? _this.$route.params.resId : 0,
        zjuserId : _this.$route.params.zjId ? _this.$route.params.zjId : 0
      }
      _this.Notice = zjxx;
    },
    // 页面加载完毕触发
    mounted() {
      // 把指向vue对象保存给_this
      let _this = this;
      // 定义当前头部导航栏
      localStorage.setItem('activeIndex', "4");
    },
    methods: {

    },
  }
</script>


<style src="@/style/Expert/expertdetailsCom.css" scoped></style>
<style src="@/style/main.css" scoped></style>
<style>
  .bar {
    padding-right: 0px;
    padding-left: 10px;
    padding-right: 0px !important;
    height: 100%;
  }
</style>
