<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>培训申报审核</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="项目名称:">
                <template>
                  <el-input v-model="tableDataval.projectName" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%" v-loading="loading" element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <el-table-column prop="projectNo" label="培训申报编号">
            </el-table-column>
            <el-table-column prop="projectName" label="培训名称">
            </el-table-column>
            <el-table-column prop="orgName" label="培训发起主体信息">
            </el-table-column>
            <el-table-column prop="allowSignupNum" label="培训人数">
            </el-table-column>
            <el-table-column prop="projectContent" label="项目介绍" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间" width="160">
            </el-table-column>
            <!-- /** 项目状态（0草稿，1待审核，2基地审核通过，3基地审核不通过，4主管部门审核通过，3主管部门审核不通过） */ -->
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-link :underline="false" v-if="scope.row.projectStatus == 0" target="_blank">草稿</el-link>
                <el-link :underline="false" v-else-if="scope.row.projectStatus == 1" type="primary">待审核</el-link>
                <el-link :underline="false" v-else-if="scope.row.projectStatus == 2" type="success">基地审核通过</el-link>
                <el-link :underline="false" v-else-if="scope.row.projectStatus == 3" type="danger">基地审核不通过</el-link>
                <el-link :underline="false" v-else-if="scope.row.projectStatus == 4" type="success">主管部门审核通过</el-link>
                <el-link :underline="false" v-else="scope.row.projectStatus == 5" type="danger">主管部门审核不通过</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="auditRemarkJd" label="驳回原因">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.projectStatus == 5 || scope.row.projectStatus == 3">{{scope.row.auditRemarkJd}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="160" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" style="color: #409EFF;" @click="detail(scope.row)">审核</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        loading: false,
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 项目名称
          'projectName': "",
          // 获取用户权限，用户类型（0平台管理员-秘书长  1机构管理员 2普通用户 ）
          'projectStatus': localStorage.getItem("userType") == 3 ? 2 : 1,
        },
        // 总数
        total: 0,
        // 表格数据
        tableData: [],
      }
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainReport/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.loading = false
          this.total = res.data.total;
          this.tableData = res.data.rows;
        }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
      },
      detail(e) {
        let query = {
          "trainReportId": e.trainReportId,
          "shenghe": 1
        }
        this.$router.push({
          path: 'declarationDetails',
          query: query
        })
      },
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit();
    }
  }
</script>

<style src="@/style/Cooperation/MyprojectCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
