<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>专家申请审核</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="ruleForm" label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="申报时间:">
                <div class="block">
                  <el-date-picker style="width:100%;" v-model="tableDataval.submitTime1" type="daterange"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="专家姓名:">
                <template>
                  <el-input v-model="tableDataval.nickName" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="申报专业:">
                <template>
                  <el-select v-model="tableDataval.subjectId" placeholder="请选择" clearable>
                    <el-option v-for="item in subjectlist" :key="item.subjectName" :label="item.subjectName"
                      :value="item.subjectId">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-badge class="item" style="margin-left: 1rem;">
          <el-button @click="all(1)" :type="primary1">全部</el-button>
        </el-badge>
        <el-badge :value="caibanvalue" class="item" style="margin-left: 1rem;">
          <el-button @click="caiban(2)" :type="primary2">待审核</el-button>
        </el-badge>
        <el-badge class="item" style="margin-left: 1rem;">
          <el-button @click="Reviewed(3)" :type="primary3">已审核</el-button>
        </el-badge>
      </el-col>
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="submitTime" label="申报时间" width="160">
            </el-table-column>
            <el-table-column prop="nickName" label="专家姓名" width="160">
            </el-table-column>
            <el-table-column prop="orgName" label="所在机构">
            </el-table-column>
            <el-table-column prop="subjectTypeName" label="申报专业" width="150">
            </el-table-column>
            <el-table-column prop="phonenumber" label="联系方式" width="160">
            </el-table-column>
            <el-table-column prop="nickName" label="机构管理员" width="150">
            </el-table-column>
            <el-table-column prop="" label="状态" width="150">
              <template slot-scope="scope">
                <p v-if="scope.row.status==0">新建</p>
                <p v-if="scope.row.status==1">待审核</p>
                <p v-if="scope.row.status==2">有效</p>
                <p v-if="scope.row.status==3">退回</p>
                <p v-if="scope.row.status==4">失效</p>
                <p v-if="scope.row.status==5">退出</p>
              </template>
            </el-table-column>
            <!-- status;/** 状态（0新建 1待审核 2有效 3退回 4失效 5退出） */ -->
            <el-table-column prop="" label="是否为热门专家" width="120">
              <template slot-scope="scope">
                <el-switch @change="isRecommendchange(scope.row)" :disabled="scope.row.status==0"
                  v-model="scope.row.isRecommend==1?true:false" active-color="#409EFF" inactive-color="#909399">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" v-if="scope.row.status == 1" @click="examine(scope.row)">审核
                </el-button>
                <el-button type="text" size="small" style="color: #67C23A;" @click="examine(scope.row,2)">查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    data: function() {
      return {
        value1: "",
        // 查询条件
        ruleForm: {
          name: '',
          value: ''
        },
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          "submitTime1": "",
          "submitTime2": "",
          "nickName": "",
          "subjectId": '',
          "status": ''
        },
        // 总数
        total: 0,
        // 待办总数
        caibanvalue: "",
        tableData: [],
        primary1: 'primary',
        primary2: '',
        primary3: '',
        // 申报专业
        subjectlist: [],
      }
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      all() {
        this.primary1 = 'primary';
        this.primary2 = '';
        this.primary3 = '';
        this.onSubmit();
      },
      // 待审核
      caiban() {
        this.primary1 = '';
        this.primary2 = 'primary';
        this.primary3 = '';
        this.tableDataval.status = 1;
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/shlist?pageNum=' + this.tableDataval.pageNum + '&pageSize=' + this
            .tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 已审核
      Reviewed() {
        this.primary1 = '';
        this.primary2 = '';
        this.primary3 = 'primary';
        this.tableDataval.status = 2;
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/shlist?pageNum=' + this.tableDataval.pageNum + '&pageSize=' + this
            .tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 全部
      onSubmit() {
        this.tableDataval.status = "";
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/shlist?pageNum=' + this.tableDataval.pageNum + '&pageSize=' + this
            .tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.total = res.data.total;
          this.tableData = res.data.rows;


          // 表格字段
          let tableDataval = {
            'pageNum': 1,
            'pageSize': 1000,
            "submitTime1": "",
            "submitTime2": "",
            "nickName": "",
            "subjectId": '',
            "status": '1'
          };
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/api/expert/shlist?pageNum=' + tableDataval.pageNum + '&pageSize=' +
              tableDataval.pageSize,
            data: tableDataval,
            //请求成功的回调函数
          }).then((res) => {
            // 获取待审核总数
            this.caibanvalue = res.data.total;
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });

        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 查看&&审核
      examine(e, i) {
        let row = {
          // 专家ID
          expertId: e.expertId,
          // 用户ID
          userId: e.userId,
          // 查看&&审核
          seeandexamine: i ? 1 : 2
        }
        this.$router.push({
          name: 'Expertapplication',
          params: {
            setid: 1,
            expertId: row,
            alertsun: 1
          }
        });
      },
      // 是否为推荐专家
      isRecommendchange(e) {
        let params = {
          // 是否推荐（0否，1是）
          isRecommend: e.isRecommend == 0 ? 1 : 0,
          expertId: e.expertId
        }
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/api/expert',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              showClose: true,
              message: res.data.msg,
              type: 'success'
            });
            this.onSubmit();
          } else {
            this.$message({
              offset: 100,
              showClose: true,
              message: res.data.msg,
              type: 'error'
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit();
      // 获取专家行业列表接口
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/subject/list?parentId=0',
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        this.subjectlist = res.data.data;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      this.primary1 = 'primary';
      this.primary2 = '';
    }
  }
</script>

<style src="@/style/Expert/ExpertdataCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
