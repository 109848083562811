// 统一管理api 接口
let base = process.env.NODE_ENV === "development" ? "" : process.env.VUE_APP_BASE_API
let urlObj = {
  test: "http://1.12.75.43:9095/prod-api", //测试
  // test: "http://119.3.109.199:9090/prod-api", //测试（学校使用过）
  // test: "http://172.28.6.131:9090/prod-api", //正式环境（内网地址）
  // test: "http://210.52.82.46:9090/prod-api", //正式环境（外网地址）
  // test: "https://ylfwpt.csmzxy.edu.cn/prod-api", //正式环境（HTTPS）
  // test: "http://192.168.0.105:9091", //任总本地
}
for (let i in urlObj) {
  urlObj[i] = base + urlObj[i]
}
export default urlObj
