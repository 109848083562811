<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>协同合作管理</el-breadcrumb-item>
            <el-breadcrumb-item>我的项目</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="ruleForm" label-width="80px !important">
            <el-col :span="6">
              <el-form-item label="项目名称:">
                <template>
                  <el-input v-model="tableDataval.projectName" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态:">
                <template>
                  <el-select v-model="tableDataval.projectStatus" placeholder="请选择" clearable @change="onSubmit">
                    <!-- （0草稿，1报名中，2合作中，3评审中，4已结项） -->
                    <el-option label="草稿" :value="0"></el-option>
                    <el-option label="报名中" :value="1"></el-option>
                    <el-option label="合作中" :value="2"></el-option>
                    <el-option label="评审中" :value="3"></el-option>
                    <el-option label="已结项" :value="4"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#ffffff" text-color="#8D8D8D" active-text-color="#008FE0" style="font-weight: bold;">
          <el-menu-item index="1">全部项目({{total}})</el-menu-item>
          <el-menu-item index="2">发起项目({{getlisttotal}})</el-menu-item>
          <el-menu-item index="3">参与项目({{cytotal}})</el-menu-item>
          <el-menu-item index="4">完成项目({{wctotal}})</el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="projectNo" label="项目编号">
            </el-table-column>
            <el-table-column prop="projectName" label="项目名称">
            </el-table-column>
            <el-table-column prop="projectTypeName" label="项目类型" width="160">
            </el-table-column>
            <el-table-column label="状态" width="150">
              <template slot-scope="scope">
                <!-- （0草稿，1报名中，2合作中，3评审中，4已结项） -->
                <span v-if="scope.row.projectStatus == 0">草稿</span>
                <span v-if="scope.row.projectStatus == 1">报名中</span>
                <span v-if="scope.row.projectStatus == 2">合作中</span>
                <span v-if="scope.row.projectStatus == 3">评审中</span>
                <span v-if="scope.row.projectStatus == 4">已结项</span>
                <span v-if="scope.row.projectStatus == 5">已终止</span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间" width="160">
            </el-table-column>
            <el-table-column label="操作" width="160" fixed="right">
              <template slot-scope="scope">
                <!-- 草稿状态 -->
                <div v-if="scope.row.projectStatus == 0">
                  <el-button type="text" size="small" style="color: #409EFF;" @click="editclick(scope.row)">编辑
                  </el-button>
                  <el-button type="text" size="small" style="color: #409EFF;" @click="releaseclick(scope.row)">发布
                  </el-button>
                  <el-button type="text" size="small" style="color: #F56C6C;" @click="deleteclick(scope.row)">删除
                  </el-button>
                </div>
                <!-- 报名中 -->
                <div v-if="scope.row.projectStatus == 1">
                  <el-button type="text" size="small" style="color: #409EFF;" @click="confirmclick(scope.row)">确认报名
                  </el-button>
                  <el-button type="text" size="small" style="color: #F56C6C;" @click="revokeclick(scope.row)">撤销
                  </el-button>
                  <el-button type="text" size="small" style="color: #409EFF;" @click="editclick(scope.row)">编辑
                  </el-button>
                </div>
                <!-- 合作中 -->
                <div v-if="scope.row.projectStatus == 2">
                  <el-button type="text" size="small" style="color: #409EFF;" @click="examine(scope.row)">项目记录
                  </el-button>
                  <!--                  <el-button type="text" size="small" style="color: #409EFF;" @click="sqjx(scope.row)">申请结项
                  </el-button> -->
                </div>
                <!-- 评审中 -->
                <div v-if="scope.row.projectStatus == 3">
                  <el-button type="text" size="small" style="color: #409EFF;" @click="examine(scope.row)">查看
                  </el-button>
                </div>
                <!-- 已结项 -->
                <div v-if="scope.row.projectStatus == 4">
                  <el-button type="text" size="small" style="color: #409EFF;" @click="examine(scope.row)">查看
                  </el-button>
                </div>
                <!-- 已终止 -->
                <div v-if="scope.row.projectStatus == 5">
                  <el-button type="text" size="small" style="color: #409EFF;" @click="examine(scope.row)">查看
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    props: ['params'],
    data: function() {
      return {
        // 当前的类型
        activeIndex: '1',
        value1: "",
        // 查询条件
        ruleForm: {
          name: '',
          value: '',
        },
        // 表格字段
        tableDataval: {
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10,
          // 为用户ID
          'userId': localStorage.getItem("userId"),
          // 项目名称
          'projectName': "",
          // 项目状态
          'projectStatus': "",
        },
        // 总数
        total: 0,
        // 我参与的项目总数
        cytotal: 0,
        // 发起项目
        getlisttotal: 0,
        // 完成的项目
        wctotal: 0,
        // 表格数据
        tableData: [],
        // 申报专业
        subjectlist: [],
      }
    },
    methods: {
      // 顶栏点击事件
      handleSelect(key, keyPath) {
        this.tableDataval.pageNum = "1";
        this.tableDataval.pageSize = "10";
        this.tableDataval.projectName = "";
        this.tableDataval.projectStatus = "";
        switch (key) {
          case "1":
            this.tableDataval.projectStatus = "";
            this.onSubmit();
            break;
          case "2":
            // 根据条件获取我发起的合作项目列表接口
            this.getlist();
            break;
          case "3":
            // 根据条件获取我参与的合作项目列表接口
            this.getJoinList();
            break;
          case "4":
            // 获取已经完成的合作项目列表接口
            this.tableDataval.projectStatus = 4;
            this.onSubmit();
            break;
          default:
            // 默认获取全部
            this.tableDataval.projectStatus = "";
            this.onSubmit();
        }
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      onSubmit() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProject/getAllList?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          if (this.tableDataval.projectStatus == 4) {
            this.wctotal = res.data.total;
          } else {
            this.total = res.data.total;
          }
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 根据条件获取我发起的合作项目列表接口
      getlist() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProject/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.getlisttotal = res.data.total;
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      getlistsun() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProject/list?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          this.getlisttotal = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 根据条件获取我参与的合作项目列表接口
      getJoinList() {
        this.tableDataval.projectStatus = "";
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProject/getJoinList?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (var key in res.data.rows[i]) {
              if (res.data.rows[i][key] === null || res.data.rows[i][key] === "null") {
                res.data.rows[i][key] = ""
              }
            }
          }
          this.tableData = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 获取我参与的合作项目列表总数
      getJoinListcytotal() {
        this.tableDataval.projectStatus = "";
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProject/getJoinList?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          // 获取总数
          this.cytotal = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 查看&&审核
      examine(e, i) {
        let params = {
          "projectId": e.projectId,
          "projectStatus": 1
        }
        // 跳转到项目详情页面弹窗
        this.$router.push({
          name: 'Projectdetails',
          params: params
        });
      },
      // 申请结项
      // sqjx(e, i) {
      //   let params = {
      //     "projectId": e.projectId,
      //     "projectStatus": 3
      //   }
      //   // 跳转到项目详情页面弹窗
      //   this.$router.push({
      //     name: 'Projectdetails',
      //     params: params
      //   });
      // },
      // 项目删除点击事件
      deleteclick(e) {
        this.$confirm('此操作将永久删除该项目, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'delete',
            url: this.$baseUrl.test + '/cooperation/cooProject/' + e.projectId,
            params: '',
            //请求成功的回调函数
          }).then((res) => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: '删除成功!'
            });
            this.onSubmit();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 发布点击事件
      releaseclick(e) {
        let params = {
          "projectId": e.projectId,
          "projectStatus": "1"
        }
        this.$confirm('此操作将发布该项目, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/cooperation/cooProject/changeStatus',
            data: params,
            //请求成功的回调函数
          }).then((res) => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: '发布成功!'
            });
            this.onSubmit();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消发布'
          });
        });
      },

      // 撤销点击事件
      revokeclick(e) {
        let params = {
          "projectId": e.projectId,
          "projectStatus": "0"
        }
        this.$confirm('此操作将撤销该项目, 是否继续?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/cooperation/cooProject/changeStatus',
            data: params,
            //请求成功的回调函数
          }).then((res) => {
            this.$message({
              offset: 100,
              showClose: true,
              type: 'success',
              message: '撤销成功!'
            });
            this.onSubmit();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(() => {
          this.$message({
            offset: 100,
            showClose: true,
            type: 'info',
            message: '已取消撤销'
          });
        });
      },
      // 确认报名点击事件
      confirmclick(e) {
        let params = {
          "projectId": e.projectId,
          "projectStatus": 2
        }
        // 跳转到项目详情页面
        this.$router.push({
          name: 'Projectdetails',
          params: params
        });
      },
      // 查看点击事件
      see(e) {
        let params = {
          "projectId": e.projectId,
          "projectStatus": 1
        }
        // 跳转到项目详情页面
        this.$router.push({
          name: 'Projectdetails',
          params: params
        });
      },
      // 编辑点击事件
      editclick(e) {
        // 跳转到发起项目页面
        this.$router.push({
          name: 'Startcooperation',
          params: {
            projectId: e.projectId,
          }
        });
      },
    },
    // 页面加载完毕事件
    mounted() {
      if (this.params.zt == 1) {
        this.activeIndex = '3'
      } else if (this.params.zt == 2) {
        this.activeIndex = '2'
      } else {
        // this.activeIndex = '4'
        this.activeIndex = '1'
      }
      this.onSubmit();
      this.getJoinListcytotal();
      this.getlistsun()();
      // 获取专家行业列表接口
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/subject/list?parentId=0',
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        this.subjectlist = res.data.data;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
    }
  }
</script>

<style src="@/style/Cooperation/MyprojectCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
