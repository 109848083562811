import { render, staticRenderFns } from "./Columnmanagement.vue?vue&type=template&id=20fd33de&scoped=true&"
import script from "./Columnmanagement.vue?vue&type=script&lang=js&"
export * from "./Columnmanagement.vue?vue&type=script&lang=js&"
import style0 from "@/style/Team/Columnmanagement.css?vue&type=style&index=0&id=20fd33de&scoped=true&lang=css&"
import style1 from "@/style/main.css?vue&type=style&index=1&id=20fd33de&scoped=true&lang=css&"
import style2 from "./Columnmanagement.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20fd33de",
  null
  
)

export default component.exports