<template>
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="hallmaxdiv">
          <el-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
            <el-col :span="24" style="padding-right: 10px !important;margin-top: 0.5rem;background-color: #FFFFFF;">
              <!-- 面包屑 -->
              <div class="breadcrumb">
                <el-breadcrumb separator="/">
                  <el-breadcrumb-item>工作台</el-breadcrumb-item>
                  <el-breadcrumb-item>专家管理</el-breadcrumb-item>
                  <el-breadcrumb-item>资源审核</el-breadcrumb-item>
                  <el-breadcrumb-item>资源审核详情</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="topbuttondiv">
                  <el-button type="primary" @click="toexamineclick()" v-if="searchThing.setid=='1'"> 审 核 </el-button>
                  <el-button @click="returnclick()"> 返 回 </el-button>
                </div>
              </div>
              <!-- 内容 -->
              <div class="topdivnr">
                <!-- 头部标题 -->
                <div class="toptitle">
                  <i class="el-icon-video-play"></i>
                  {{formdata.resTitle}}
                </div>
                <!-- 详细信息 -->
                <div class="information">
                  <div>类型：{{formdata.resTypeName}}</div>
                  <i class="el-icon-minus"></i>
                  <div>{{formdata.createTime}}</div>
                  <i class="el-icon-minus"></i>
                  <div>分享者：{{formdata.userNickName}}</div>
                  <i class="el-icon-minus"></i>
                  <div>分享机构：{{formdata.userOrgName}}</div>
                  <i class="el-icon-minus"></i>
                  <div>下载：{{formdata.downCount}}次</div>
                  <i class="el-icon-minus"></i>
                  <div>浏览：{{formdata.browseCount}}次</div>
                </div>
                <!-- 联盟积分 -->
                <div class="lmb">
                  联盟积分：{{formdata.price}}
                </div>
                <!-- 收藏 -->
                <div class="shocang">
                  <i class="el-icon-star-off"></i>
                  收藏
                </div>
              </div>
            </el-col>
            <el-col :span="18" style="padding-right: 10px !important;margin-top: 0.5rem;">
              <!-- 左边下内容 -->
              <div class="grid-content bg-purple">
                <div class="leftdivtop">
                  <div class="leftdivtoptitle">资源简介</div>
                  <el-divider></el-divider>
                </div>
                <el-row>
                  <el-col :span="24">
                    <!-- 简介内容 -->
                    <div class="zuoxianrjj">
                      <span>{{formdata.resContent}}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <template>
                      <el-table :data="formdata.fileList" style="width: 100%">
                        <el-table-column type="index" label="序号" width="100">
                        </el-table-column>
                        <el-table-column prop="" label="章节">
                          <template slot-scope="scope">
                            {{scope.row.resDetailTitle}}:{{scope.row.chapterName}}
                          </template>
                        </el-table-column>
                        <el-table-column prop="fileExtension" label="文件类型" width="100">
                        </el-table-column>
                        <el-table-column prop="" label="操作" width="100">
                          <template slot-scope="scope">
                            <el-button
                              v-if="scope.row.fileExtension == 'jpeg' || scope.row.fileExtension == 'png' || scope.row.fileExtension == 'jpg' || scope.row.fileExtension == 'bmp'"
                              type="text" @click="watchclick(scope.row.fileId)">
                              点击预览
                            </el-button>
                            <el-button v-if="scope.row.fileExtension == 'zip' || scope.row.fileExtension == 'rar' ||
                            (scope.row.fileExtension == 'XLS' || scope.row.fileExtension == 'xls' ||
                                  scope.row.fileExtension == 'txt' || scope.row.fileExtension == 'TXT' ||
                                  scope.row.fileExtension == 'doc' || scope.row.fileExtension == 'DOC' ||
                                  scope.row.fileExtension == 'docx' || scope.row.fileExtension == 'DOCX' ||
                                  scope.row.fileExtension == 'ppd' || scope.row.fileExtension == 'PPD' ||
                                  scope.row.fileExtension == 'pptx' || scope.row.fileExtension == 'PPTX' ||
                                  scope.row.fileExtension == 'pdf' || scope.row.fileExtension == 'PDF') " type="text"
                              @click="watchclick(scope.row.fileId)">点击下载</el-button>
                            <el-button
                              v-if="scope.row.fileExtension == 'mp3' || scope.row.fileExtension == 'mp4' || scope.row.fileExtension == 'MP4'"
                              type="text" @click="watchclick(scope.row.fileId)">点击播放</el-button>
                            <el-button v-if="(scope.row.fileSize/1024) < 5 && (scope.row.fileExtension == 'XLS' || scope.row.fileExtension == 'xls' ||
                                    scope.row.fileExtension == 'txt' || scope.row.fileExtension == 'TXT' ||
                                    scope.row.fileExtension == 'doc' || scope.row.fileExtension == 'DOC' ||
                                    scope.row.fileExtension == 'docx' || scope.row.fileExtension == 'DOCX' ||
                                    scope.row.fileExtension == 'ppd' || scope.row.fileExtension == 'PPD' ||
                                    scope.row.fileExtension == 'pptx' || scope.row.fileExtension == 'PPTX' ||
                                    scope.row.fileExtension == 'pdf' || scope.row.fileExtension == 'PDF')" type="text"
                              @click="watchclick(scope.row.fileId)">点击预览</el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                  </el-col>
                </el-row>
              </div>
            </el-col>

            <!-- 右边内容 -->
            <el-col :span="6" style="padding-left: 10px !important;margin-top: 0.5rem;">
              <el-row :gutter="20" style="background-color: #FFFFFF;border-radius: 4px;">
                <div class="leftdiv">
                  <!-- 头部 -->
                  <div class="leftdivtop">
                    <div class="leftdivtoptitle">相关资源推荐</div>
                    <el-divider></el-divider>
                  </div>
                  <!-- 内容 -->
                  <div class="leftdivtopnrdiv">
                    <div class="leftdivtopnr">
                      <span>07-12</span>&#12288;
                      <span>团队协作模块上线，请登录平台</span>
                    </div>
                    <div class="leftdivtopnr">
                      <span>07-12</span>&#12288;
                      <span>团队协作模块上线，请登录平台</span>
                    </div>
                    <div class="leftdivtopnr">
                      <span>07-12</span>&#12288;
                      <span>团队协作模块上线，请登录平台</span>
                    </div>
                    <div class="leftdivtopnr">
                      <span>07-12</span>&#12288;
                      <span>团队协作模块上线，请登录平台</span>
                    </div>
                    <div class="leftdivtopnr">
                      <span>07-12</span>&#12288;
                      <span>团队协作模块上线，请登录平台</span>
                    </div>
                    <div class="leftdivtopnr">
                      <span>07-12</span>&#12288;
                      <span>团队协作模块上线，请登录平台</span>
                    </div>
                    <div class="leftdivtopnr">
                      <span>07-12</span>&#12288;
                      <span>团队协作模块上线，请登录平台</span>
                    </div>
                    <div class="leftdivtopnr">
                      <span>07-12</span>&#12288;
                      <span>团队协作模块上线，请登录平台</span>
                    </div>
                  </div>
                </div>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <!-- 审核弹窗 -->
      <el-dialog title="审核" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px !important" class="demo-ruleForm">
          <el-form-item label="审核：" prop="resource" style="text-align: left;">
            <el-radio-group v-model="ruleForm.resource">
              <el-radio label="通过"></el-radio>
              <el-radio label="未通过"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="资源等级：" prop="resGrade" style="text-align: left;">
            <el-rate v-model="ruleForm.resGrade" show-text :texts="ratetexts">
            </el-rate>
          </el-form-item>

          <el-form-item label="理由：" prop="desc">
            <el-input type="textarea" v-model="ruleForm.desc" placeholder="请输入审核理由"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            <el-button @click="dialogVisible = false">取 消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 内容弹窗 -->
      <el-dialog title="资源查看弹窗" :visible.sync="dialogVisible2" width="30%" :before-close="handleClose">
        <div class="block">
          <el-image fit="contain" :src="fileUrl"></el-image>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible2 = false">关 闭</el-button>
        </span>
      </el-dialog>

      <el-dialog :title="dialogVisible3.title" :visible.sync="dialogVisible3.dialogVisible" width="70%"
        :before-close="handleClose">
        <iframe class="msgbox" :src="dialogVisible3.url"></iframe>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible3.dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
  export default {
    // 接收父组件的值
    props: ['searchThing'],
    data: function() {
      return {
        dialogVisible3: {
          dialogVisible: false,
          title: "",
          url: "",
        },
        ratetexts: ['一星', '两星', '三星', '四星', '五星'],
        // 资源状态
        status: '',
        loading: true,
        // 资源详情信息
        formdata: {},
        dialogVisible: false,
        dialogVisible2: false,
        ruleForm: {
          resGrade: "",
          resource: '',
          desc: ''
        },
        fileUrl: '',
        rules: {
          resource: [{
            required: true,
            message: '请选择通过或者不通过',
            trigger: 'change'
          }],
          resGrade: [{
            required: true,
            message: '请选择资源等级',
            trigger: 'blur'
          }],
          desc: [{
            required: true,
            message: '请输入审核理由',
            trigger: 'blur'
          }]
        }
      }
    },
    // 页面加载完毕触发
    mounted() {
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/resouse/resInfo/' + this.searchThing.resId,
        params: '',
      }).then((res) => {
        if (res.data.code == 200) {
          this.status = res.data.data.status;
          this.loading = false;
          this.formdata = res.data.data;
        } else {
          this.$message({
            message: res.data.code + '请从资源审核列表进入！',
            type: 'error',
            offset: 100
          });
          this.$router.push({
            name: 'Resourceaudit'
          });
        }
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
    },
    methods: {
      // 点击观看
      watchclick(e) {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/files/' + e,
          params: '',
        }).then((res) => {
          if (res.data.code == 200) {
            // 判断资源类型为图片
            if (res.data.data.fileExtension == "jpeg" || res.data.data.fileExtension == "png" || res.data.data
              .fileExtension == "jpg" || res.data.data.fileExtension == "bmp") {
              this.fileUrl = res.data.data.fileUrl;
              this.dialogVisible2 = true;
            }
            // 如果为视频类型
            else if (res.data.data.fileExtension == "mp4" || res.data.data.fileExtension == "MP4") {
              // 播放当前视频(单个)
              this.$router.push({
                name: 'Video',
                params: {
                  "resId": this.searchThing.resId,
                  "fileId": e
                }
              });
            }
            // 如果为文档类型
            else if (res.data.data.fileExtension == "xls" || res.data.data.fileExtension == "XLS" ||
              res.data.data.fileExtension == "docx" || res.data.data.fileExtension == "DOCX" ||
              res.data.data.fileExtension == "doc" || res.data.data.fileExtension == "DOC" ||
              res.data.data.fileExtension == "pdf" || res.data.data.fileExtension == "PDF" ||
              res.data.data.fileExtension == "txt" || res.data.data.fileExtension == "TXT" ||
              res.data.data.fileExtension == "ppd" || res.data.data.fileExtension == "PPD") {
              let fileUrl = "https://ow365.cn/?i=33860&ssl=1&furl=" + encodeURIComponent(res.data.data.fileUrl);
              this.dialogVisible3 = {
                'dialogVisible': true,
                'title': "文件详情",
                'url': fileUrl
              }
              return false;
            } else { // 判断资源类型为压缩包
              let url = res.data.data.fileUrl;
              (function(url) {
                let name = url.substring(url.lastIndexOf("/") + 1);
                let objectURL = window.URL.createObjectURL(new Blob([url]));
                let a = document.createElement('a')
                a.href = objectURL
                a.download = name
                a.click()
                a.remove()
              })(url)
            }
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              offset: 100
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 审核点击事件
      toexamineclick() {
        this.dialogVisible = true
      },
      // 返回点击事件
      returnclick() {
        this.$router.push('/resourceaudit');
      },
      handleClose(done) {
        this.$confirm('审核还未结束，是否确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      // 提交审核
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          let params = {
            "resId": this.searchThing.resId,
            // 2有效 3退回
            "status": this.ruleForm.resource == "通过" ? 2 : 3,
            "auditReason": this.ruleForm.desc,
            "resGrade": this.ruleForm.resGrade,
          }
          if (valid) {
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/resouse/resInfo/changeStatus',
              data: params,
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  offset: 100,
                  message: res.data.msg,
                  type: 'success'
                });
                this.ruleForm = {};
                this.dialogVisible = false;
                // 审核按钮已经审核过后审核按钮隐藏
                this.searchThing.setid = 0;
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
  }
</script>

<style src="@/style/Expert/ResourceauditdetailsCom.css" scoped></style>
<style scoped>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  .el-form-item__label {
    width: 15% !important;
  }

  ::-webkit-scrollbar {
    display: none
  }
</style>
