<template>
  <div class="MechanismCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 5.33625rem; margin-left: 0px;height: 65rem;">
      <el-col :span="24">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="220px !important" class="demo-ruleForm"
          style="padding: 2rem 10% 0% 5%;margin-top: 0rem;text-align: initial;">
          <el-form-item label="请选择门户信息类型">
            <el-radio-group v-model="ruleForm.type">
              <!-- <el-radio :label="0">相关资讯</el-radio> -->
              <el-radio :label="1">校企资源</el-radio>
              <el-radio :label="2">养老人才</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="文章标题" prop="articleTitle" label-width="220px !important">
            <el-input v-model="ruleForm.articleTitle" placeholder="请输入文章标题" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="文章简介" prop="articleIntroduce" label-width="220px !important">
            <el-input type="textarea" :rows="4" v-model="ruleForm.articleIntroduce" placeholder="请输入500字以内的简介"
              maxlength="500" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="封面" prop="resType" label-width="220px !important">
            <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :on-success="uploadSuccess"
              :on-remove="handleRemove" :on-exceed="onexceed" limit="1" style="display: flex;">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="正文" prop="resType" label-width="220px !important">
            <quill-editor class="editor" v-model="ruleForm.articleContent" ref="myQuillEditor" :options="editorOption"
              @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)">
            </quill-editor>
          </el-form-item>

          <el-form-item style="padding-top: 4rem;margin-top: 1rem;">
            <el-button @click="resetForm('ruleForm')">重置</el-button>
            <el-button type="primary" @click="submitForm()">发布</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import $ from 'jquery';
  import {
    quillEditor
  }
  from "vue-quill-editor"; //调用编辑器
  import "quill/dist/quill.snow.css";
  export default {
    name: '',
    components: {
      // commonComponents
      quillEditor, //使用编辑器
    },
    data: function() {
      return {
        ruleForm: {
          type: 1,
          articleStatus: 1, // 文章状态（0草稿，1已发布）
          articleTitle: '',
          articleContent: '',
          articleIntroduce: '',
          articleCover: '',
          createBy: localStorage.getItem("userId"),
        },
        rules: {
          articleTitle: [{
            required: true,
            message: '请输入文章标题',
            trigger: 'blur'
          }],
          articleIntroduce: [{
            required: true,
            message: '请输入文章简介',
            trigger: 'blur'
          }]
        }
      }
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    methods: {
      //保存&提交
      submitForm() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/system/doorArticle',
          data: this.ruleForm,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: 'success',
              offset: 100
            });
            location.reload();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              offset: 100
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 封面上传成功
      uploadSuccess(response, file, fileList) {
        this.ruleForm.articleCover = response.data.fileId + '';
      },
      // 封面删除
      handleRemove(response, file, fileList) {
        // 清空ID
        this.ruleForm.articleCover = '';
      },
      // 文件超出个数限制时的钩子
      onexceed() {
        this.$message({
          message: "封面限制上传一张！",
          type: 'warning',
          offset: 100
        });
      },
      onEditorChange() {
        //内容改变事件
        this.$emit("input", this.ruleForm.content);
      },

    },
    // 页面加载完毕事件
    mounted() {

    }
  }
</script>

<style src="@/style/Team/WriteanarticleCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    text-align: right;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .ql-container {
    height: 32rem !important;
  }

  .ql-editor {
    min-height: 32rem;
  }

  .editor {
    line-height: normal;
    height: 25rem;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: '文本';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: '标题1';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: '标题2';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: '标题3';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: '标题4';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: '标题5';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: '标题6';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: '标准字体';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    content: '衬线字体';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    content: '等宽字体';
  }

  #disabledMsg.el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    color: #bfd9d8;
    background-color: #eef6f6;
    border-color: #d1e5e5;
  }
</style>
