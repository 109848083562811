<template>
  <div id="app">
    <HelloWorld/>
    <Copyright/>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import Copyright from '@/components/Copyright.vue'

export default {
  name: 'app',
  components: {
    HelloWorld,Copyright
  }
}
</script>

<style scoped>
  /* 设置页面背景需要在父组件里面设置 */
  #app{
    height: 100%;
    width: 100%;
    background-image: url(../assets/bej.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 0px;
    position: absolute;
  }
</style>
