import { render, staticRenderFns } from "./Hirehall.vue?vue&type=template&id=48bda559&scoped=true&"
import script from "./Hirehall.vue?vue&type=script&lang=js&"
export * from "./Hirehall.vue?vue&type=script&lang=js&"
import style0 from "@/style/main.css?vue&type=style&index=0&id=48bda559&scoped=true&lang=css&"
import style1 from "./Hirehall.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bda559",
  null
  
)

export default component.exports