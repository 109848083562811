<template>
  <div class="ExpertdataCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>培训申报审核</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-col :span="2" style="color: #00000000;">1</el-col>
        <el-col :span="20">
          <div style="text-align: right;padding-right: 2rem;position: fixed;top: 10rem;right: 2rem;">
            <el-button type="primary" @click="$router.back()">返回</el-button>
            <!-- 基地:0  主管部门:3  主体单位:1  普通用户:2 -->
            <!-- （0草稿，1待审核，2基地审核通过，3基地审核不通过，4主管部门审核通过，5主管部门审核不通过） -->
            <el-button v-if="userType != 2 && ruleForm.projectStatus != 0 && shenghe == 1" style="width: auto;"
              type="primary" @click="dialogVisible = true">审核</el-button>
            <!-- 报名 -->
            <el-button v-if="userType == 2" style="width: auto;" type="primary" @click="application(ruleForm)">
              {{djcyannstyle ? '我要报名' : '已报名'}}
            </el-button>
          </div>
          <!-- 基本信息 -->
          <div class="jbxxdiv">
            <!-- 项目名称 -->
            <h2 style="text-align: center;">{{ruleForm.projectName}}</h2>
            <p
              style="font-family: 'PingFang SC ', 'PingFang SC';font-weight: 400;font-style: normal;font-size: 16px;color: rgba(0, 0, 0, 0.447058823529412);text-align: center;line-height: 22px;">
              {{ruleForm.completeTime}}
            </p>
            <el-descriptions class="jbxxdivdescriptions" title="项目发起人信息" :column="1">
              <el-descriptions-item label="项目发起人">
                {{ruleForm.nickName}}
              </el-descriptions-item>
              <el-descriptions-item label="单位名称">{{ruleForm.orgName}}
              </el-descriptions-item>
              <el-descriptions-item label="联系号码">{{ruleForm.phonenumber}}</el-descriptions-item>
              <el-descriptions-item label="邮箱">{{ruleForm.email}}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="jbxxdivdescriptions" title="申报信息" :column="1">
              <el-descriptions-item label="项目名称">
                {{ruleForm.projectName}}
              </el-descriptions-item>
              <el-descriptions-item label="项目编号">
                {{ruleForm.projectNo}}
              </el-descriptions-item>
              <!-- <el-descriptions-item label="项目合作人数">{{ruleForm.cooperationNum}}</el-descriptions-item> -->
              <!--              <el-descriptions-item label="允许参与报名人数">
                {{ruleForm.allowSignupNum}}
                &emsp;&emsp;&emsp;&emsp;
                <span style="color: red;">
                  已报名人数：{{ruleForm.cooperationNum}}
                </span>
              </el-descriptions-item> -->
              <el-descriptions-item label="培训时间">{{ruleForm.completeTime}}</el-descriptions-item>
              <el-descriptions-item label="项目经费(元)">{{ruleForm.projectFunds}}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions class="jbxxdivdescriptions" title="项目介绍" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
                {{ruleForm.projectContent}}
              </el-descriptions-item>
            </el-descriptions>

            <!--            <el-descriptions class="jbxxdivdescriptions" title="参与人员名单" :colon="false"></el-descriptions>
            <el-table :data="trainProjectSignup" border style="width: 100%">
              <el-table-column type="index" width="50" label="序号">
              </el-table-column>
              <el-table-column prop="nickName" label="用户姓名">
              </el-table-column>
              <el-table-column prop="orgName" label="所属单位">
              </el-table-column>
              <el-table-column prop="signupTime" label="报名日期" width="160">
              </el-table-column>
              <el-table-column prop="signupTime" label="报名状态" width="160">
                <template slot-scope="scope">
                  <span v-if="scope.row.signupStatus == 0">草稿</span>
                  <span v-if="scope.row.signupStatus == 1">待审核</span>
                  <span v-if="scope.row.signupStatus == 2">报名成功</span>
                  <span v-if="scope.row.signupStatus == 3">撤销报名</span>
                  <span v-if="scope.row.signupStatus == 4">报名失败</span>
                </template>
              </el-table-column>

              <el-table-column prop="" label="操作" scope="scope" width="200">
                <template slot-scope="scope">
                  <el-switch :disabled="switchDisabled" @change="signupStatusswitch(scope.row)"
                    v-model="scope.row.signupStatusswitch" active-text="报名成功" inactive-text="待审核" />
                </template>
              </el-table-column>
            </el-table> -->

            <el-descriptions class="jbxxdivdescriptions" title="培训申报附件" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
              </el-descriptions-item>
            </el-descriptions>
            <template>
              <el-table :data="fileData" border style="width: 100%">
                <el-table-column type="index" width="50" label="序号">
                </el-table-column>
                <el-table-column prop="fileName" label="附件名称">
                </el-table-column>
                <el-table-column prop="nftId" label="区块链地址">
                </el-table-column>
                <el-table-column prop="createTime" label="上传日期">
                </el-table-column>
                <el-table-column prop="" label="操作" scope="scope" width="90">
                  <template slot-scope="scope">
                    <p style="color: #0066FF;" @click="chakan(scope.row)">下载</p>
                  </template>
                </el-table-column>
              </el-table>
            </template>


            <!--            <el-descriptions class="jbxxdivdescriptions" title="项目课程" :colon="false">
              <el-descriptions-item label="" labelStyle="width:0%;">
              </el-descriptions-item>
            </el-descriptions>
            <template>
              <el-table :data="fileData2" border style="width: 100%">
                <el-table-column type="index" width="50" label="序号" />
                <el-table-column prop="courseTitle" label="课程名称" />
                <el-table-column prop="courseContent" label="课程介绍" :show-overflow-tooltip="true" />
                <el-table-column prop="teacherName" label="课程主讲" />
                <el-table-column prop="adminName" label="课程管理员" />
                <el-table-column prop="createTime" label="课程时间" />
                <el-table-column prop="" label="操作" scope="scope" width="90">
                  <template slot-scope="scope">
                    <el-link :underline="false" @click="downloadclick(scope.row)">查看</el-link>
                  </template>
                </el-table-column>
              </el-table>
            </template> -->
          </div>

        </el-col>
        <el-col :span="2"></el-col>
      </el-col>
    </el-row>
    <el-dialog title="审核" :visible.sync="dialogVisible" width="30%" :before-close="beforeClose">
      <!-- 基地 -->
      <div style="text-align: left;" v-if="userType == 0">
        <el-radio v-model="projectStatus" label="2">通过</el-radio>
        <el-radio v-model="projectStatus" label="3">驳回</el-radio>
      </div>
      <!-- 主管部门 -->
      <div style="text-align: left;" v-if="userType == 3">
        <el-radio v-model="projectStatus" label="4">通过</el-radio>
        <el-radio v-model="projectStatus" label="5">驳回</el-radio>
      </div>
      <div style="margin-top: 20px;">
        <el-input v-if="userType == 0" type="textarea" :rows="5" placeholder="请输入原因" v-model="auditRemarkJd" />
        <el-input v-if="userType == 3" type="textarea" :rows="5" placeholder="请输入原因" v-model="auditRemarkZg" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="examine()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    name: '',
    props: ['params'],
    data: function() {
      return {
        shenghe: 2,
        switchDisabled: true,
        projectStatus: '',
        textarea: '',
        projectId: "",
        djcyannstyle: true,
        dialogVisible: false,
        auditRemarkJd: "",
        auditRemarkZg: "",
        userType: '',
        ruleForm: {
          // 合作项目ID
          projectId: "",
          // 单位名称
          orgName: "",
          // 发起人用户ID
          userId: "",
          // 发起人姓名
          nickName: "",
          // 发起人手机号码
          phonenumber: "",
          // 项目名称
          projectName: "",
          // 项目编号
          projectNo: "",
          // 项目合作人数
          cooperationNum: "",
          // 允许参与报名人数
          allowSignupNum: "",
          // 邀请人
          inviteMembers: [],
          // 已报名人数
          signupNum: "",
          // 结项时间
          completeTime: "",
          // 项目经费(元)
          projectFunds: "",
          // 已使用经费(元)
          usedFunds: "",
          // 剩余经费(元)
          surplusFunds: "",
          // 项目介绍
          projectContent: "",
          // 项目附件
          projectFiles: "",
          // 结项申请时间
          closeApplyTime: ""
        },
        // 资源列表
        fileData: [],
        // 课程
        fileData2: [],
        // 参与名单
        trainProjectSignup: [],
      }
    },
    methods: {
      chakan(row) {
        this.getBlob(row.fileUrl).then(blob => {
          this.DownloadItem(blob, row.fileName);
        });
      },
      getBlob(url) {
        return new Promise(resolve => {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.responseType = 'blob';
          xhr.onload = () => {
            if (xhr.status === 200) {
              resolve(xhr.response);
            }
          };
          xhr.send();
        });
      },
      DownloadItem(blob, fileName) {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      },
      beforeClose() {
        this.dialogVisible = false
      },
      examine() { // 审核
        let params = {
          "trainReportId": this.projectId, //"这里是培训申报ID",
          "projectStatus": this.projectStatus, // "项目状态（0草稿，1待审核，2基地审核通过，3基地审核不通过，4主管部门审核通过，5主管部门审核不通过）",
          "auditRemarkJd": this.auditRemarkJd,
          "auditRemarkZg": this.auditRemarkZg,
          "auditUserId": localStorage.getItem("userId")
        }
        if (!this.projectStatus) {
          this.$message({
            message: "请选择通过或驳回！",
            type: 'error',
            offset: 100
          });
        } else if ((this.projectStatus == 3 && !this.auditRemarkJd) || (this.projectStatus == 5 && !this
            .auditRemarkZg)) {
          this.$message({
            message: "请输入驳回原因！",
            type: 'error',
            offset: 100
          });
        } else {
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/train/trainReport',
            data: params,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功！",
                type: 'success',
                offset: 100
              });
              this.dialogVisible = false;
              this.projectStatus = '';
              this.auditRemarkJd = '';
              this.auditRemarkZg = '';
              this.$router.push({
                path: 'trainingApplicationexamine',
                query: {}
              })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: 100
              });
            }
          });
        }
      },
      // 获取参与人员列表
      getTrainprojectSignuplist() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/train/trainProjectSignup/list?projectId=' + this.projectId,
          params: '',
        }).then((res) => {
          for (var i = 0; i < res.data.rows.length; i++) {
            if (res.data.rows[i].signupStatus == 2) {
              res.data.rows[i].signupStatusswitch = true
            } else {
              res.data.rows[i].signupStatusswitch = false
            }
          }
          this.trainProjectSignup = res.data.rows
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      signupStatusswitch(item) { // 报名状态审核
        let params = {
          "projectSignupId": item.projectSignupId,
          "signupStatus": item.signupStatusswitch ? '2' : '1',
        }
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/train/trainProjectSignup/changeStatus',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "操作成功！",
              type: 'success',
              offset: 100
            });
            this.getTrainprojectSignuplist()
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              offset: 100
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      application(e) { // 项目报名
        let params = {
          "signupStatus": '1',
          "projectId": this.projectId,
          "signupUserId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainProjectSignup/',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "恭喜你，报名成功！",
              type: 'success',
              offset: 100
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              offset: 100
            });
          }
          this.getTrainprojectSignuplist()
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 下载文件
      downloadclick(e) {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/files/' + e.fileId,
          params: '',
        }).then((res) => {
          let url = res.data.data.fileUrl;
          // 生成一个a标签
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          // 生成时间戳
          let timestamp = new Date().getTime()
          link.download = timestamp + '.txt'
          document.body.appendChild(link)
          link.click()

          // 记录下载(整个资源做记录)
          let params = {
            "resId": this.searchThing,
            "userId": localStorage.getItem("userId")
          }
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/resouse/resDownRecord/',
            data: params,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg + "已添加至下载记录！",
                type: 'success',
                offset: 100
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: 100
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 获取课程列表
      getTrainCourselist() {
        let params = {
          "projectId": this.projectId,
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainCourse/list',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.fileData2 = res.data.rows
          } else {

          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }

    },
    // 页面加载完毕事件
    mounted() {
      let _this = this;
      _this.userType = localStorage.getItem('userType') ? localStorage.getItem('userType') : ''
      // 获取跳转过来的项目ID
      _this.projectId = _this.$route.query.trainReportId ? _this.$route.query.trainReportId : "";
      _this.shenghe = _this.$route.query.shenghe ? _this.$route.query.shenghe : 2;

      _this.switchDisabled = localStorage.getItem('userType') == 2 ? true : false
      // 获取项目基本信息
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/train/trainReport/' + _this.projectId,
        data: '',
      }).then((res) => {
        _this.ruleForm = res.data.data;
        // 如果是自己的项目就不要点击参与按钮
        if (localStorage.getItem("userId") == _this.ruleForm.userId) {
          _this.djcyannstyle = false;
        } else {
          _this.djcyannstyle = true;
        }
        // 获取附件列表
        if (res.data.data.projectFiles) {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/files/getFileList/' + res.data.data.projectFiles,
            params: '',
          }).then((res) => {
            for (let i in res.data.data) {
              // 获取二级资源信息
              _this.fileData.push({
                // 文件ID
                fileId: res.data.data[i].fileId,
                // 创建时间
                createTime: res.data.data[i].createTime,
                // 附件名称
                chapterName: res.data.data[i].fileName.split(".")[0],
                fileName: res.data.data[i].fileName,
                // 文件地址
                fileUrl: res.data.data[i].fileUrl,
                // 区块地址
                nftId: res.data.data[i].nftId,
              })
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }

      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      _this.getTrainprojectSignuplist()
      _this.getTrainCourselist()
    }
  }
</script>

<style src="@/style/Cooperation/ProjectCom.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }

  .el-descriptions__header {
    margin-bottom: 0px !important;
  }

  .jbxxdiv {
    padding-bottom: 2rem;
  }
</style>
