<template>
  <div class="WriteanarticlexiangqingCom">
    <el-row :gutter="2">
      <!-- 文章详情 -->
      <el-col :span="24" class="maxdiv">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb>
            <el-breadcrumb-item>师资培训专栏</el-breadcrumb-item>
            <el-breadcrumb-item>培训活动详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div style="text-align: right;padding-right: 2rem;position: fixed;top: 10rem;right: 2rem;">
          <el-button type="primary" @click="$router.back()">返回</el-button>
        </div>
        <!-- 标题 -->
        <h2 style="text-align: left;padding-left: 2rem;margin-bottom: 0rem;">{{comArticle.articleTitle}}</h2>
        <!-- 用户信息 -->
        <div class="userint" style="margin-left: 2rem;margin-top: 1rem;margin-bottom: 1rem;">
          <div style="width: 3rem;">
            <img v-if="comArticle.unionLevel == 1" src="../../assets/lv1.jpg" />
            <img v-if="comArticle.unionLevel == 2" src="../../assets/lv2.jpg" />
            <img v-if="comArticle.unionLevel == 3" src="../../assets/lv3.jpg" />
            <img v-if="comArticle.unionLevel == 4" src="../../assets/lv4.jpg" />
            <img v-if="comArticle.unionLevel == 5" src="../../assets/lv5.jpg" />
            <img v-if="comArticle.unionLevel == 6" src="../../assets/lv6.jpg" />
          </div>
          <div class="userintdiv">
            <p>{{comArticle.nickName}}</p>
            <p>{{comArticle.createTime}}</p>
          </div>
        </div>
        <!-- 正文 -->
        <div class="ql-editor" style="padding: 0rem 2rem;" v-html="comArticle.articleContent"></div>
        <!-- 附件 -->
        <div style="display: flex;flex-direction: column;align-items: flex-start;padding-left: 30px;margin-top: 50px;">
          <el-link :underline="false" v-for="item in comArticle.fileList" :href="item.fileUrl">{{item.fileName}}
          </el-link>
        </div>
        <!-- 评论、关注、点赞 -->
        <div class="dibutext">
          <div style="line-height: 2.5rem;"><i class="el-icon-chat-dot-round"></i>评论：({{total}})</div>
          <div class="shozhi" @click="comArticleFollow" v-if="comArticle.isFollow == 1">
            <i class="el-icon-star-on"></i>已关注：({{comArticle.followNum}})
          </div>
          <div class="shozhi" @click="comArticleFollow" v-else>
            <i class="el-icon-star-off"></i>关注：({{comArticle.followNum}})
          </div>
          <div class="shozhi" @click="wzdz()" v-if="comArticle.isFabulous == 1"><i
              class="el-icon-thumb"></i>已点赞：({{comArticle.fabulousNum}})</div>
          <div class="shozhi" @click="wzdz()" v-else><i class="el-icon-thumb"></i>点赞：({{comArticle.fabulousNum}})
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 评论栏 -->
    <el-row :gutter="2">
      <el-col :span="24" class="maxdiv" style="margin-top: 1rem;">

        <h3 style="text-align: left;padding-left: 2rem;">{{total}}个评论</h3>

        <el-divider></el-divider>
        <!-- 请写下你的评论 -->
        <div class="halltopss">
          <el-input placeholder="请写下您的评论" v-model="comComment.commentContent" class="input-with-select halltopssinput"
            clearable>
            <el-button slot="append" class="halltopssann" @click="searchclick()">发 布</el-button>
          </el-input>
        </div>

        <!-- 文章评论列表 -->
        <div class="pllistmax" v-for="item in CommentData">
          <div class="pllist">
            <!-- 用户信息 -->
            <div class="userint">
              <div>
                <img class="userintimg" v-if="item.unionLevel == 1" src="../../assets/lv1.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 2" src="../../assets/lv2.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 3" src="../../assets/lv3.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 4" src="../../assets/lv4.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 5" src="../../assets/lv5.jpg" />
                <img class="userintimg" v-if="item.unionLevel == 6" src="../../assets/lv6.jpg" />
              </div>
              <div class="userintdiv">
                <p>{{item.nickName}}</p>
                <p>{{item.commentTime}}</p>
              </div>
            </div>
            <!-- 评论正文 -->
            <div class="pllistzw">
              {{item.commentContent}}
            </div>
            <!-- 评论数&点赞数 -->
            <div class="pllistpldz">
              <div class="pllistpldz1 shozhi" @click="zhuijiapl(item)"><i
                  class="el-icon-chat-dot-round"></i>评论：({{item.replyNum}})</div>
              <div class="shozhi" @click="pldz(item)"><i class="el-icon-thumb"></i>点赞：({{item.fabulousNum}})</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 评论的评论弹出窗 -->
    <el-dialog title="追加评论" :visible.sync="dialogVisible" style="text-align: left;" width="30%"
      :before-close="handleClose">
      <span style="color: #CCCCCC;">原评论{{zjpl.commentContent}}</span>
      <el-input style="margin-top: 1rem;" type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tijiaozjpl">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import $ from 'jquery';
  import "quill/dist/quill.snow.css";
  export default {
    name: '',
    components: {},
    data: function() {
      return {
        zjpl: {},
        textarea: "",
        dialogVisible: false,
        articleId: '',
        // 默认是文章
        comArticle: {},
        comComment: {
          "articleId": '',
          "commentContent": "",
          "commentUserId": localStorage.getItem("userId"),
          // 这里是评论父ID
          "parentId": ""
        },
        CommentData: {},
        total: 0,
        zdyesAndno: true,
        yesAndno: true,
      }
    },

    methods: {
      zhuijiapl(itme) {
        this.dialogVisible = true;
        this.zjpl = itme;
      },
      // 文章点赞
      wzdz() {
        let params = {
          "articleId": this.articleId,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainArticleFabulous',
          data: params,
        }).then((res) => {
          this.$message({
            offset: 100,
            message: '操作成功！',
            type: 'success'
          });
          this.getData();
          this.getcomComment();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 评论点赞
      pldz(item) {
        let params = {
          "commentId": item.commentId,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + "/train/trainArticleFabulous",
          data: params,
        }).then((res) => {
          this.$message({
            offset: 100,
            message: '操作成功！',
            type: 'success'
          });
          this.getData();
          this.getcomComment();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      // 提交追加评论
      tijiaozjpl() {
        let _this = this;
        if (_this.textarea) {
          let params = {
            "articleId": _this.articleId,
            "commentContent": _this.textarea,
            "commentUserId": localStorage.getItem("userId"),
            "parentId": _this.zjpl.commentId ? _this.zjpl.commentId : ""
          }
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/train/trainComment',
            data: params,
          }).then((res) => {
            this.$message({
              offset: 100,
              message: '恭喜你，评论成功！',
              type: 'success'
            });
            _this.textarea = "";
            _this.dialogVisible = false;
            _this.CommentData = res.data.data;
            _this.comComment.commentContent = "";
            _this.getcomComment();
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        } else {
          this.$message({
            offset: 100,
            message: '请输入评论内容！',
            type: 'erroe'
          });
        }

      },
      getData() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let params = {
          loginUserId: localStorage.getItem("userId")
        }
        // 培训活动详情
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + "/train/trainArticle/" + this.articleId,
          params: params,
        }).then((res) => {
          loading.close();
          this.comArticle = res.data.data
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 根据文章ID查询文章评论列表
      getcomComment() {
        let params = {
          pageNum: 1,
          pageSize: 10,
          articleId: this.articleId,
        }
        this.$axios({
          method: "post",
          url: this.$baseUrl.test + "/train/trainComment/list",
          data: params,
        }).then((res) => {
          // 获取文章评论列表
          this.CommentData = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 发布评论
      searchclick() {
        let _this = this;
        let params = {
          "questionId": _this.articleId,
          "answerContent": _this.comComment.commentContent,
          "answerUserId": localStorage.getItem("userId"),
          "parentId": _this.zjpl.commentId ? _this.zjpl.commentId : ""
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainComment',
          data: _this.comComment,
        }).then((res) => {
          this.$message({
            offset: 100,
            message: '恭喜你，评论成功！',
            type: 'success'
          });
          _this.CommentData = res.data.data;
          _this.comComment.commentContent = "";
          _this.getcomComment();
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 文章关注
      comArticleFollow() {
        let _this = this;
        let params = {
          "articleId": this.articleId,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainArticleFollow',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              message: '操作成功！',
              type: 'success'
            });
            this.getData();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      /* 问题关注*/
      comQuestionFollow() {
        let _this = this;
        let params = {
          "questionId": this.articleId,
          "userId": localStorage.getItem("userId")
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/communication/comQuestionFollow',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              message: '操作成功！',
              type: 'success'
            });
            this.getData();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

    },
    // 页面加载完毕事件
    mounted() {
      let _this = this
      _this.articleId = _this.$route.query.articleId ? _this.$route.query.articleId : "";
      _this.comComment.articleId = _this.$route.query.articleId ? _this.$route.query.articleId : "";
      _this.getData();
      _this.getcomComment();
    }
  }
</script>

<style src="@/style/Team/WriteanarticlexiangqingCom.css" scoped></style>
<style scoped>
  .el-form-item__label {
    text-align: right;
  }

  img {
    max-width: 100%;
  }

  .userintimg {
    width: 3rem;
    height: 3rem;
  }
</style>
