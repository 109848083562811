<template>
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="hallmaxdiv">
          <el-row>
            <el-col :span="24">
              <div class="halltop">
                <div class="halltoptitle">师资培训专栏</div>
                <div class="halltopss">
                  <el-input placeholder="请输入内容" v-model="tableDataval.projectName" class="input-with-select halltopssinput" clearable>
                    <el-button slot="append" class="halltopssann" @click="alllist()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-row :gutter="20" style="margin-bottom: 10px;">

              <el-col :span="24" style="padding-left: 0px;">
                <!-- 表格内容 -->
                <div style="background-color: #FFFFFF;border-radius: 10px;">
                  <h3 style="padding: 10px;text-align: left;margin: 0px;">培训列表</h3>
                  <el-row :gutter="20" class="biaogemaxdiv">
                    <el-col :span="6" v-for="item in ruleForm">
                      <div class="biaogenrdiv">
                        <div class="biaogenrbiaoti">{{item.projectName}}</div>
                        <div class="biaogenrtext">
                          <!-- <p>项目经费：{{item.projectFunds}}</p> -->
                          <p>项目类型：{{item.projectTypeName}}</p>
                          <p>发起单位：{{item.orgName}}</p>
                          <p>发布时间：{{item.completeTime}}</p>
                        </div>
                        <div class="canyuxiangm" @click="see(item)">参与项目</div>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 分页 -->
                  <div class="block">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                      :current-page="tableDataval.pageNum" :page-sizes="[8, 20, 50, 100]" :page-size="10"
                      layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                  </div>
                </div>
              </el-col>

              <!-- 右边内容 -->
              <!--              <el-col :span="6" class="leftnrdiv">
                <el-row :gutter="20" style="background-color: #FFFFFF;border-radius: 4px;">
                  <div style="display: flex;padding: 1rem 2rem 0rem 2rem;">
                    <div>
                      <img v-if="unionLevel==1" style="width: 4rem;height: 4rem;" src="../assets/lv1.jpg">
                      <img v-if="unionLevel==2" style="width: 4rem;height: 4rem;" src="../assets/lv2.jpg">
                      <img v-if="unionLevel==3" style="width: 4rem;height: 4rem;" src="../assets/lv3.jpg">
                      <img v-if="unionLevel==4" style="width: 4rem;height: 4rem;" src="../assets/lv4.jpg">
                      <img v-if="unionLevel==5" style="width: 4rem;height: 4rem;" src="../assets/lv5.jpg">
                      <img v-if="unionLevel==6" style="width: 4rem;height: 4rem;" src="../assets/lv6.jpg">
                    </div>
                    <div style="text-align: left;margin-left: 1rem;">
                      <div>{{nickName}}</div>
                      <div class="mmb">联盟积分：{{unionCoin}}</div>
                    </div>
                  </div>
                  <div class="letfann">
                    <div class="letfanndiv" style="cursor:pointer;" @click="tiaozhuan(1)">
                      <div class="letfanntext">参与</div>
                      <div class="letfannsun">{{canyusun}}</div>
                    </div>

                    <div class="letfanndiv" style="cursor:pointer;" @click="tiaozhuan(2)">
                      <div class="letfanntext">发起</div>
                      <div class="letfannsun">{{faqisun}}</div>
                    </div>

                    <div class="letfanndiv" style="cursor:pointer;" @click="tiaozhuan(3)">
                      <div class="letfanntext">完成</div>
                      <div class="letfannsun">{{wancssun}}</div>
                    </div>
                  </div>
                  <el-button type="primary" @click="faqihezuo"
                    style="width: 75%;margin-top: 1.35rem;margin-bottom: 2rem;">发起培训</el-button>
                </el-row>
              </el-col> -->
            </el-row>
          </el-row>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        unionLevel: localStorage.getItem("unionLevel"),
        canyusun: 0,
        faqisun: 0,
        wancssun: 0,
        // 联盟积分
        unionCoin: '',
        // 用户名
        nickName: '',
        input3: "",
        total: 0,
        options1: [{
            value: '选项1',
            label: '产学研项目'
          },
          {
            value: '选项1',
            label: '教学内容和课程体系改革项目'
          },
          {
            value: '选项1',
            label: '师资培训项目'
          },
          {
            value: '选项1',
            label: '实践条件和实践基地建设项目'
          },
          {
            value: '选项1',
            label: '创新创业教育改革项目'
          },
          {
            value: '选项1',
            label: '创新创业联合基金项目'
          },
          {
            value: '选项1',
            label: '科研协作项目'
          },
          {
            value: '选项1',
            label: '技术开发项目'
          },
          {
            value: '选项1',
            label: '产品研发项目'
          },
          {
            value: '选项1',
            label: '项目咨询服务'
          },
          {
            value: '选项1',
            label: '其他'
          }
        ],
        // 表格字段
        tableDataval: {
          projectName: "",
          // 资源类型
          resType: "",
          // 机构ID
          orgId: "",
          // 用户ID
          userId: "",
          // 专业类别
          projectStatus: "1",
          // 排序 1下载最多，2最新分享，3人气优先
          orderBy: "",
          // 为当前第几页
          pageNum: 1,
          // 为每页条数
          pageSize: 8,
        },
        value1: '',
        ruleForm: [],
      }
    },
    methods: {
      // 发起合作
      faqihezuo() {
        this.$router.push('/launchEvent');
      },
      // 参与项目
      see(e) {
        let params = {
          "projectId": e.projectId
        }
        // 跳转到项目详情页面弹窗
        this.$router.push({
          path: 'trainingNewsletter',
          query: params
        })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.alllist();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.alllist();
      },
      alllist() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/train/trainProject/alllist?pageNum=' + this.tableDataval.pageNum +
            '&pageSize=' + this.tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          this.ruleForm = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 获取参与、发起、完成
      cyhqwc() {
        let tableDataval = {
          'userId': localStorage.getItem("userId"),
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProject/getJoinList',
          data: tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          // 参与总数
          this.canyusun = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProject/list',
          data: tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          this.faqisun = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/cooperation/cooProject/getAllList',
          data: tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          for (let i in res.data.rows) {
            if (res.data.rows[i].projectType == 4) {
              this.wancssun = this.wancssun + 1;
            }
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 参与,发起,完成
      tiaozhuan(e) {
        let params = {
          zt: e
        }
        if (e == 1) {
          this.$router.push({
            name: 'Myproject',
            params: params
          });
        } else if (e == 2) {
          this.$router.push({
            name: 'Myproject',
            params: params
          });
        } else {
          this.$router.push({
            name: 'Myproject',
            params: params
          });
        }
      }

    },
    // 页面加载完毕事件
    mounted() {
      // 获取当前用户信息
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/getUserInfo/' + localStorage.getItem("userId"),
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        // 联盟积分
        this.unionCoin = res.data.data.unionCoin;
        // 用户名
        this.nickName = res.data.data.nickName;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取协同合作项目大厅列表接口
      this.alllist();
      // 参与获取完成
      this.cyhqwc();
    },
  }
</script>

<style src="@/style/teacherTrainingcolumn.css" scoped></style>
<style>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .biaogemaxdiv {
    margin-left: -10px;
    margin-right: -10px;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .biaogenrbiaoti {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
