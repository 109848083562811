<template>
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="hallmaxdiv">
          <el-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
            <el-col :span="24" style="padding-right: 10px !important;margin-top: 0.5rem;background-color: #FFFFFF;">
              <!-- 面包屑 -->
              <div class="breadcrumb">
                <el-breadcrumb separator="/">
                  <el-breadcrumb-item>联盟专家</el-breadcrumb-item>
                  <el-breadcrumb-item>专家详情</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <!-- 内容 -->
              <div class="topdivnr">
                <!-- 专家姓名 -->
                <div class="toptitle">
                  {{nickName}}
                  <!-- 未关注 -->
                  <el-tooltip v-if="guanzhustyle == 0" class="item" effect="dark" content="点击关注" placement="top">
                    <i class="el-icon-user" style="cursor:pointer;" @click="Guangzhou"></i>
                  </el-tooltip>
                  <!-- 已关注 -->
                  <el-tooltip v-else class="item" effect="dark" content="取消关注" placement="top">
                    <i class="el-icon-user-solid" style="cursor:pointer;color: #2d7eef;" @click="Guangzhou"></i>
                  </el-tooltip>
                  <!-- 金牌专家 -->
                  <i class="el-icon-medal"></i>
                </div>
                <div style="display: flex;">
                  <div style="width: 80%;">
                    <el-descriptions class="zhuanjainxx" style="padding: 1rem 2rem;">
                      <el-descriptions-item label="所在机构名称">{{orgName}}</el-descriptions-item>
                      <el-descriptions-item label="所在部门">{{deptName}}</el-descriptions-item>
                      <el-descriptions-item label="职务">{{job}}</el-descriptions-item>
                      <el-descriptions-item label="擅长领域">{{subjectName}}</el-descriptions-item>
                      <el-descriptions-item label="专家级别">{{expertGrade}}</el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <div style="width: 20%; margin-bottom: 1rem;">
                    <!-- 专家头像 -->
                    <el-image style="height: 13rem;" :src="avatarimg" fit="contain">
                    </el-image>
                  </div>
                </div>
              </div>
            </el-col>
            <!-- 左边下内容 -->
            <el-col :span="18" style="padding-right: 10px !important;margin-top: 0.5rem;">
              <div class="grid-content bg-purple">
                <div class="leftdivtop" style="margin-top:0rem;">
                  <div class="leftdivtoptitle">个人简介</div>
                  <el-divider></el-divider>
                  <br />
                  {{remark}}
                </div>
              </div>

              <div class="leftdivtop">
                <div class="leftdivtoptitle" style="padding: 1rem;">工作简历</div>
                <template>
                  <el-table :data="jlruleForm" height="250" border style="width: 100%;margin-top: 1rem;padding: 1rem;">
                    <el-table-column prop="startTime" label="起时间">
                    </el-table-column>
                    <el-table-column prop="endTime" label="止时间">
                    </el-table-column>
                    <el-table-column prop="workUnit" label="工作单位">
                    </el-table-column>
                    <el-table-column prop="professional" label="从事专业">
                    </el-table-column>
                    <el-table-column prop="position" label="担任职务">
                    </el-table-column>
                  </el-table>
                </template>
              </div>

              <div class="leftdivtop">
                <div class="leftdivtoptitle" style="padding: 1rem;">教研科研项目经验</div>
                <template>
                  <el-table :data="experienceruleForm" height="250" border
                    style="width: 100%;margin-top: 1rem;padding: 1rem;">
                    <el-table-column prop="projectName" label="项目名称" width="180">
                    </el-table-column>
                    <el-table-column prop="projectContent" label="项目描述" width="180">
                    </el-table-column>
                    <el-table-column prop="researchTime" label="项目研究时间">
                    </el-table-column>
                    <el-table-column prop="responsibleContent" label="负责内容">
                    </el-table-column>
                  </el-table>
                </template>
              </div>

              <div class="leftdivtop">
                <div class="leftdivtoptitle" style="padding: 1rem;">相关领域成果/荣誉</div>
                <template>
                  <el-table :data="achievementstableData" height="250" border style="width: 100%;margin-top: 1rem;">
                    <el-table-column prop="achievements" label="成果名称/荣誉">
                    </el-table-column>
                    <el-table-column prop="content" label="成果描述">
                    </el-table-column>
                    <el-table-column prop="identity" label="参与身份">
                    </el-table-column>
                  </el-table>
                </template>
              </div>

            </el-col>



            <!-- <div class="grid-content bg-purple">
                <div class="leftdivtop">
                  <div class="leftdivtoptitle">相关领域成果/荣誉</div>
                  <el-divider></el-divider>
                  <template>
                    <el-table :data="achievementstableData" height="250" border style="width: 100%;margin-top: 1rem;">
                      <el-table-column prop="achievements" label="成果名称/荣誉">
                      </el-table-column>
                      <el-table-column prop="content" label="成果描述">
                      </el-table-column>
                      <el-table-column prop="identity" label="参与身份">
                      </el-table-column>
                    </el-table>
                  </template>
                </div>
              </div> -->


            <!-- 右边下内容 -->
            <el-col :span="6" style="padding-left: 10px !important;margin-top: 0.5rem;">
              <el-row :gutter="20" style="background-color: #FFFFFF;border-radius: 4px;">
                <div class="leftdiv">
                  <div class="topnrdiv" style="display: flex;justify-content: center;">
                    <div class="topleftimg">
                      <img src="../../assets/jp.png" style="width: 3rem;margin-top: 1rem;margin-right: 2rem;" />
                    </div>
                    <div>
                      <p
                        style="text-align: left;width: 15rem;font-family: 'Arial Normal', 'Arial';font-weight: 400;font-style: normal;font-size: 1.125rem;">
                        {{nickName}}
                      </p>
                      <p
                        style="text-align: left;width: 15rem;font-family: 'Arial Normal', 'Arial';font-weight: 400;font-style: normal;font-size: 0.875rem;color: #7F7F7F;">
                        {{professional}}{{expertGrade}}
                      </p>
                    </div>
                  </div>
                  <div class="letfann">
                    <div class="letfanndiv">
                      <div class="letfanntext">关注专家</div>
                      <div class="letfannsun">{{zjgzs}}</div>
                    </div>

                    <div class="letfanndiv">
                      <div class="letfanntext">粉丝</div>
                      <div class="letfannsun">{{fss}}</div>
                    </div>
                  </div>
                </div>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    // 接收父组件的值
    props: ['searchThing'],
    data: function() {
      return {
        // 当前用户ID
        userId: localStorage.getItem("userId"),
        loading: true,
        // 资源详情信息
        formdata: {},
        // 专家姓名
        nickName: '',
        // 专家头像
        avatarimg: '',
        // 所在机构名称
        orgName: '',
        // 所在部门
        deptName: '',
        // 个人简介
        remark: '',
        // 职务
        job: '',
        // 擅长领域
        professional: '',
        // 二级领域
        subjectName: '',
        // 专家级别
        expertGrade: '',
        // 工作简历表格
        jlruleForm: [],
        // 教研科研项目经验表格
        experienceruleForm: [],
        // 相关领域成果/荣誉
        achievementstableData: [],
        // 关注&不关注
        guanzhustyle: 0,
        // 当前专家关注的专家
        zjgzs: 0,
        // 当前专家的粉丝
        fss: 0,
      }
    },
    // 页面加载完毕触发
    mounted() {
      // 定义当前头部导航栏
      localStorage.setItem('activeIndex', "4");
      // 专家userid
      console.log(this.searchThing.zjuserId);
      // 专家ID
      console.log(this.searchThing.expertId);

      // 根据专家ID查询专家教研科研项目经验接口
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/researchProject/list?expertId=' + this.searchThing.expertId,
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        this.experienceruleForm = res.data.rows;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

      // 根据专家ID查询专家相关领域成果/荣誉
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/relatedFields/list?expertId=' + this.searchThing.expertId,
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        this.achievementstableData = res.data.rows;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

      // 根据专家ID获取简历
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/resume/list?expertId=' + this.searchThing.expertId,
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        this.jlruleForm = res.data.rows;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

      // 获取专家详情
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/expert/' + this.searchThing.expertId,
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        this.loading = false;
        this.professional = res.data.data.professional;
        if (res.data.data.expertGrade = 0) {
          this.expertGrade = "不合格专家"
        } else if (res.data.data.expertGrade = 1) {
          this.expertGrade = "普通专家"
        } else if (res.data.data.expertGrade = 2) {
          this.expertGrade = "银牌专家"
        } else if (res.data.data.expertGrade = 3) {
          this.expertGrade = "金牌专家"
        }
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取专家的用户信息
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/getUserInfo/' + this.searchThing.zjuserId,
        params: "",
      }).then((res) => {
        // 专家姓名
        this.nickName = res.data.data.nickName;
        // 所在机构名称
        this.orgName = res.data.data.nickName;
        // 职务
        this.job = res.data.data.job;
        // 部门
        this.deptName = res.data.data.deptName;
        // 个人简介
        this.remark = res.data.data.remark;
        // 获取专家头像
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/files/' + res.data.data.avatar,
          params: "",
        }).then((e) => {
          // 获取专家头像
          this.avatarimg = e.data.data.fileUrl;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取当前用户是否关注此专家
      let paramssss = {
        // 为专家ID
        "expertId": this.searchThing.zjuserId,
        // 为当前登录用户ID
        "loginUserId": this.userId
      }
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/expert/getExpertInfo',
        data: paramssss,
      }).then((res) => {
        if (res.data.data.isFollow == 1) {
          this.guanzhustyle = 1;
        } else {
          this.guanzhustyle = 0;
        }
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 根据专家ID查询所选的申报专业列表
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/expertSubject/getSubjectListByExpertId/' + this.searchThing.expertId,
        params: '',
        //请求成功的回调函数
      }).then((res) => {
        for (let i in res.data.rows) {
          this.subjectName += res.data.rows[i].subjectName + '、'
        }
        this.subjectName = this.subjectName.substr(0, this.subjectName.length - 1);
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      this.getExpertFollowDataById();
    },
    methods: {
      // 关注
      Guangzhou() {
        let params = {
          "userId": this.userId,
          "expertId": this.searchThing.zjuserId
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expertFollow/',
          data: params,
        }).then((res) => {
          if (res.data.code = 200) {
            if (this.guanzhustyle == 0) {
              this.guanzhustyle = 1;
              this.$message({
                offset: 100,
                message: '恭喜你，关注成功！',
                type: 'success'
              });
            } else {
              this.guanzhustyle = 0;
              this.$message({
                offset: 100,
                message: '已取消关注！',
                type: 'warning'
              });
            }
            this.getExpertFollowDataById();
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      getExpertFollowDataById() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/expertFollow/getExpertFollowDataById/' + this.searchThing.zjuserId,
          params: '',
        }).then((res) => {
          // 获取当前专家关注的专家
          this.zjgzs = res.data.data.zjgzs;
          // 获取当前专家的粉丝
          this.fss = res.data.data.fss;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }

    },
  }
</script>

<style src="@/style/Expert/expertdetailsCom.css" scoped></style>
<style scoped>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  .el-form-item__label {
    width: 15% !important;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .msgbox {
    margin-top: 5rem;
    width: 80% !important;
  }

  .msgbox iframe {
    height: 30rem;
    width: 100% !important;
  }

  .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 2rem;
    padding-top: 2rem;
    font-size: 1rem;
  }
</style>
