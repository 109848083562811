<template>
  <div class="index">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 4rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>个人中心</el-breadcrumb-item>
            <el-breadcrumb-item>修改密码</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 80vh; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: right;margin-top: 0.7rem;">

        <el-form :model="ruleForm" label-position="right" status-icon label-width="25% !important"
          class="demo-ruleForm">
          <el-form-item label="旧密码" prop="password">
            <el-input type="password" size="medium" v-model="ruleForm.password"></el-input>
          </el-form-item>

          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" size="medium" v-model="ruleForm.newPassword" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="确认密码" prop="checkPass">
            <el-input type="password" size="medium" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item style="width: 100%;text-align: center;">
            <el-button @click="resetForm()" size="small">重置</el-button>
            <el-button type="primary" size="small" @click="submitForm('ruleForm')">提交</el-button>
          </el-form-item>
        </el-form>

      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    data() {
      return {
        ruleForm: {
          newPassword: '',
          checkPass: '',
          password: ''
        },
      }
    },
    methods: {
      submitForm(formName) {
        let params = {
          "userId": localStorage.getItem("userId"),
          "password": this.ruleForm.password,
          "newPassword": this.ruleForm.newPassword
        }
        if (this.ruleForm.password == "") {
          this.$message({
            offset: 100,
            showClose: true,
            message: '请填写老密码！',
            type: 'warning'
          });
        } else if (this.ruleForm.newPassword == "") {
          this.$message({
            offset: 100,
            showClose: true,
            message: '请填写新密码！',
            type: 'warning'
          });
        } else if (this.ruleForm.checkPass == "") {
          this.$message({
            offset: 100,
            showClose: true,
            message: '请再次输入密码！',
            type: 'warning'
          });
        } else if (this.ruleForm.checkPass != this.ruleForm.newPassword) {
          this.$message({
            offset: 100,
            showClose: true,
            message: '两次输入密码不一致！',
            type: 'warning'
          });
        }
        // 弱密码验证
        else if (!this.isWeakPassword(this.ruleForm.checkPass)) {
          this.$message({
            offset: 100,
            showClose: true,
            message: '密码需要包含大写字母+小写字母+数字+特殊符号并且大于8位',
            type: 'warning'
          });
        } else {
          // 请求接口
          this.$axios({
            method: 'put',
            url: this.$baseUrl.test + '/api/user/resetPwd',
            data: params,
            //请求成功的回调函数
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                offset: 100,
                showClose: true,
                message: '操作成功，请重新登录！',
                type: 'success'
              });
              //将token清空
              sessionStorage.setItem('adminToken', "");
              // 跳转到登陆页面
              this.$router.push('/');
            } else {
              this.$message({
                offset: 100,
                showClose: true,
                message: '操作失败！',
                type: 'error'
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      resetForm() {
        this.ruleForm = {
          newPassword: '',
          checkPass: '',
          password: ''
        }
      },
      // 弱密码 长度8 大写+小写+特殊字符
      isWeakPassword(password) {
        return /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？'‘“”~ `]).*$/
          .test(password);
      },
    },
  }
</script>

<style src="@/style/PersonalCenter/ChangepasswordCom.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }

  .demo-ruleForm {
    height: 22vh;
    overflow-y: auto;
    margin-top: 15vh;
    border-radius: 10px;
  }
</style>
