<template>
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="hallmaxdiv">
          <el-row>
            <el-col :span="24">
              <div class="halltop">
                <div class="halltoptitle">联盟专家</div>
                <div class="halltopss">
                  <el-input placeholder="请输入内容" v-model="input3" class="input-with-select halltopssinput" clearable>
                    <el-button slot="append" class="halltopssann" @click="halltopssann">搜索</el-button>
                  </el-input>
                  <div style="width: 45rem;height: 3rem;line-height: 4rem;">
                    <router-link to='/Expertapplication' style="text-decoration:none">
                      <a style="color: #FFFFFF;">联盟专家申请</a>
                    </router-link>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24" class="tabaleliet" style="margin-bottom: -1rem;">
              <div class="rmzjtj">热门专家推荐</div>
              <div><img src="../assets/u1662.svg" style="margin-bottom: 2rem;margin-top: 1rem;"></div>
            </el-col>
            <el-col :span="24" class="tabaleliet">
              <el-carousel indicator-position="outside" :interval="8000" height="1000">
                <el-carousel-item>
                  <div style="height: 15.625rem;">
                    <!-- 第一行专家 热门专家推荐-->
                    <el-col :span="4" v-for="item in Hotdata.slice(0,6)" :key="item.dictValue">
                      <div class="zjimg">
                        <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">
                          <el-image fit="cover" style="width: 10rem; height: 13rem;" :src="item.avatar"></el-image>
                        </el-link>
                        <div class="zjname">
                          <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">{{item.nickName}}
                          </el-link>
                        </div>
                        <div class="zjjj">
                          <span v-if="item.expertGrade == 1">普通专家，</span>
                          <span v-if="item.expertGrade == 2">银牌专家，</span>
                          <span v-if="item.expertGrade == 3">金牌专家，</span>
                        </div>
                      </div>
                    </el-col>
                  </div>
                </el-carousel-item>
                <el-carousel-item v-if="Hotdata.length > 6">
                  <div style="height: 15.625rem;">
                    <!-- 第一行专家 热门专家推荐-->
                    <el-col :span="4" v-for="item in Hotdata.slice(6,12)" :key="item.dictValue">
                      <div class="zjimg">
                        <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">
                          <el-image style="width: 10rem; height: 13rem;" :src="item.avatar" fit="cover"></el-image>
                        </el-link>
                        <div class="zjname">
                          <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">{{item.nickName}}
                          </el-link>
                        </div>
                        <div class="zjjj">
                          <span v-if="item.expertGrade == 1">普通专家，</span>
                          <span v-if="item.expertGrade == 2">银牌专家，</span>
                          <span v-if="item.expertGrade == 3">金牌专家，</span>
                        </div>
                      </div>
                    </el-col>
                  </div>
                </el-carousel-item>
              </el-carousel>

            </el-col>

            <el-col :span="24" class="tabaleliet">
              <!-- 第二行专家 -->
              <el-col :span="4" class="toptitle1">
                <div class="dibutext"><i class="el-icon-minus"></i>竞赛指导</div>
              </el-col>
              <!-- Psychologydata -->
              <el-col :span="4" v-for="item in Psychologydata" :key="item.dictValue">
                <div class="zjimg">
                  <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">
                    <el-image style="width: 10rem; height: 13rem;" :src="item.avatar" fit="cover"></el-image>
                  </el-link>
                  <div class="zjname">
                    <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">{{item.nickName}}
                    </el-link>
                  </div>
                  <div class="zjjj">
                    {{item.subjectName}}专业
                    <span v-if="item.expertGrade == 1">普通专家，</span>
                    <span v-if="item.expertGrade == 2">银牌专家，</span>
                    <span v-if="item.expertGrade == 3">金牌专家，</span>
                  </div>
                </div>
              </el-col>

            </el-col>

            <el-col :span="24" class="tabaleliet">
              <!-- 第三行专家 -->
              <el-col :span="4" class="toptitle2">
                <div class="dibutext"><i class="el-icon-minus"></i>课程改革</div>
              </el-col>
              <el-col :span="4" v-for="item in Curriculumdata" :key="item.dictValue">
                <div class="zjimg">
                  <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">
                    <el-image style="width: 10rem; height: 13rem;" :src="item.avatar" fit="cover"></el-image>
                  </el-link>
                  <div class="zjname">
                    <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">{{item.nickName}}
                    </el-link>
                  </div>
                  <div class="zjjj">
                    课程改革领域
                    <span v-if="item.expertGrade == 1">普通专家，</span>
                    <span v-if="item.expertGrade == 2">银牌专家，</span>
                    <span v-if="item.expertGrade == 3">金牌专家，</span>
                  </div>
                </div>
              </el-col>
            </el-col>

            <el-col :span="24" class="tabaleliet">
              <!-- 第四行专家 -->
              <el-col :span="4" class="toptitle3">
                <div class="dibutext"><i class="el-icon-minus"></i>社会服务</div>
              </el-col>
              <el-col :span="4" v-for="item in Productdata" :key="item.dictValue">
                <div class="zjimg">
                  <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">
                    <el-image style="width: 10rem; height: 13rem;" :src="item.avatar" fit="cover"></el-image>
                  </el-link>
                  <div class="zjname">
                    <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">{{item.nickName}}
                    </el-link>
                  </div>
                  <div class="zjjj">
                    社会服务领域
                    <span v-if="item.expertGrade == 1">普通专家，</span>
                    <span v-if="item.expertGrade == 2">银牌专家，</span>
                    <span v-if="item.expertGrade == 3">金牌专家，</span>
                  </div>
                </div>
              </el-col>
            </el-col>

            <el-col :span="24" class="tabaleliet">
              <!-- 第五行专家 -->
              <el-col :span="4" class="toptitle4">
                <div class="dibutext"><i class="el-icon-minus"></i>产品研发</div>
              </el-col>
              <el-col :span="4" v-for="item in Productdata" :key="item.dictValue">
                <div class="zjimg">
                  <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">
                    <el-image style="width: 10rem; height: 13rem;" :src="item.avatar" fit="cover"></el-image>
                  </el-link>
                  <div class="zjname">
                    <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">{{item.nickName}}
                    </el-link>
                  </div>
                  <div class="zjjj">
                    产品研发领域
                    <span v-if="item.expertGrade == 1">普通专家，</span>
                    <span v-if="item.expertGrade == 2">银牌专家，</span>
                    <span v-if="item.expertGrade == 3">金牌专家，</span>
                  </div>
                </div>
              </el-col>
            </el-col>

            <el-col :span="24" class="tabaleliet">
              <!-- 第六行专家 -->
              <el-col :span="4" class="toptitle5">
                <div class="dibutext"><i class="el-icon-minus"></i>创新创业</div>
              </el-col>
              <el-col :span="4" v-for="item in innovatedata" :key="item.dictValue">
                <div class="zjimg">
                  <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">
                    <el-image style="width: 10rem; height: 13rem;" :src="item.avatar" fit="cover"></el-image>
                  </el-link>
                  <div class="zjname">
                    <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">{{item.nickName}}
                    </el-link>
                  </div>
                  <div class="zjjj">
                    创新创业领域
                    <span v-if="item.expertGrade == 1">普通专家，</span>
                    <span v-if="item.expertGrade == 2">银牌专家，</span>
                    <span v-if="item.expertGrade == 3">金牌专家，</span>
                  </div>
                </div>
              </el-col>
            </el-col>

            <el-col :span="24" class="tabaleliet">
              <!-- 第七行专家 -->
              <el-col :span="4" class="toptitle6">
                <div class="dibutext"><i class="el-icon-minus"></i>标准研发</div>
              </el-col>
              <el-col :span="4" v-for="item in Standarddata" :key="item.dictValue">
                <div class="zjimg">
                  <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">
                    <el-image style="width: 10rem; height: 13rem;" :src="item.avatar" fit="cover"></el-image>
                  </el-link>
                  <div class="zjname">
                    <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">{{item.nickName}}
                    </el-link>
                  </div>
                  <div class="zjjj">
                    标准研发领域
                    <span v-if="item.expertGrade == 1">普通专家，</span>
                    <span v-if="item.expertGrade == 2">银牌专家，</span>
                    <span v-if="item.expertGrade == 3">金牌专家，</span>
                  </div>
                </div>
              </el-col>
            </el-col>

            <el-col :span="24" class="tabaleliet">
              <!-- 第八行专家 -->
              <el-col :span="4" class="toptitle7">
                <div class="dibutext"><i class="el-icon-minus"></i>专业咨询</div>
              </el-col>
              <el-col :span="4" v-for="item in majordata" :key="item.dictValue">
                <div class="zjimg">
                  <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">
                    <el-image style="width: 10rem; height: 13rem;" :src="item.avatar" fit="cover"></el-image>
                  </el-link>
                  <div class="zjname">
                    <el-link :underline="false" @click="detailsclick(item.expertId,item.userId)">{{item.nickName}}
                    </el-link>
                  </div>
                  <div class="zjjj">
                    专业咨询领域
                    <span v-if="item.expertGrade == 1">普通专家，</span>
                    <span v-if="item.expertGrade == 2">银牌专家，</span>
                    <span v-if="item.expertGrade == 3">金牌专家，</span>
                  </div>
                </div>
              </el-col>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        input3: '',
        params: {
          'submitTime1': "",
          'submitTime2': "",
          'nickName': "",
          'subjectId': "",
          // 热门专家推荐 是否推荐（0否，1是）
          'isRecommend': "1",
          'field': ""
        },
        // 热门专家推荐
        Hotdata: [],
        // 竞赛指导领域
        Psychologydata: [],
        // 课程改革领域
        Curriculumdata: [],
        // 产品研发领域
        Productdata: [],
        // 创新创业领域
        innovatedata: [],
        // 标准研发领域
        Standarddata: [],
        // 专业咨询领域
        majordata: [],
      }
    },

    methods: {
      // 第一行专家 热门专家推荐 是否推荐（0否，1是）
      Oneline() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/alllist?pageNum=1&pageSize=12',
          data: this.params,
          //请求成功的回调函数
        }).then((res) => {
          // 空值转""
          for (var key in res.data.rows) {
            if (res.data.rows[key] == null || res.data.rows[key] == "null") {
              res.data.rows[key] = ""
            }
          }
          this.Hotdata = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 第二行 竞赛指导领域
      Twoline() {
        let params = {
          // 所属领域（1竞赛指导领域、2课程改革领域、3社会服务、4产品研发领域、5创新创业、6标准研发、7专业咨询）
          subjectTypeId: "1"
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/alllist?pageNum=1&pageSize=5',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          // 空值转""
          for (var key in res.data.rows) {
            if (!res.data.rows[key]) {
              res.data.rows[key] = ""
            }
          }
          this.Psychologydata = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 第三行 课程改革领域
      Threeline() {
        let params = {
          // 所属领域（1竞赛指导领域、2课程改革领域、3社会服务、4产品研发领域、5创新创业、6标准研发、7专业咨询）
          subjectTypeId: "2"
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/alllist?pageNum=1&pageSize=5',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          // 空值转""
          for (var key in res.data.rows) {
            if (!res.data.rows[key]) {
              res.data.rows[key] = ""
            }
          }
          this.Curriculumdata = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 第四行 产品研发领域
      Fourline() {
        let params = {
          // 所属领域（1竞赛指导领域、2课程改革领域、3社会服务、4产品研发领域、5创新创业、6标准研发、7专业咨询）
          subjectTypeId: "4"
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/alllist?pageNum=1&pageSize=5',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          // 空值转""
          for (var key in res.data.rows) {
            if (!res.data.rows[key]) {
              res.data.rows[key] = ""
            }
          }
          // 产品研发领域
          this.Productdata = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 第五行 创新创业领域
      Fiveline() {
        let params = {
          // 所属领域（1竞赛指导领域、2课程改革领域、3社会服务、4产品研发领域、5创新创业、6标准研发、7专业咨询）
          subjectTypeId: "5"
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/alllist?pageNum=1&pageSize=5',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          // 空值转""
          for (var key in res.data.rows) {
            if (!res.data.rows[key]) {
              res.data.rows[key] = ""
            }
          }
          // 创新创业领域
          this.innovatedata = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 第六行标准研发领域
      sixline() {
        let params = {
          // 所属领域（1竞赛指导领域、2课程改革领域、3社会服务、4产品研发领域、5创新创业、6标准研发、7专业咨询）
          subjectTypeId: "6"
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/alllist?pageNum=1&pageSize=5',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          // 空值转""
          for (var key in res.data.rows) {
            if (!res.data.rows[key]) {
              res.data.rows[key] = ""
            }
          }
          // 产品研发领域
          this.Standarddata = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 第七行专业咨询领域
      sevenline() {
        let params = {
          // 所属领域（1竞赛指导领域、2课程改革领域、3社会服务、4产品研发领域、5创新创业、6标准研发、7专业咨询）
          subjectTypeId: "7"
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/expert/alllist?pageNum=1&pageSize=5',
          data: params,
          //请求成功的回调函数
        }).then((res) => {
          // 空值转""
          for (var key in res.data.rows) {
            if (!res.data.rows[key]) {
              res.data.rows[key] = ""
            }
          }
          // 专业咨询领域
          this.majordata = res.data.rows;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 专家点击查看详情
      detailsclick(e, n) {
        // 专家详情
        this.$router.push({
          name: 'Expertdetails',
          params: {
            resId: e,
            zjId: n
          }
        });
      },

      // 搜索点击事件
      halltopssann() {
        // 跳转到专家搜索页面
        this.$router.push({
          name: 'Expertsearch',
          params: {
            alertsun: this.input3
          }
        });
      }
    },

    // 页面加载完毕触发
    mounted() {
      // 定义当前头部导航栏
      localStorage.setItem('activeIndex', "4");
      // 第一行专家 热门专家推荐
      this.Oneline();
      // 第二行 竞赛指导领域
      this.Twoline();
      // 第三行 课程改革领域
      this.Threeline();
      // 第四行 产品研发领域
      this.Fourline();
      // 第五行 创新创业领域
      this.Fiveline();
      // 第六行标准研发领域
      this.sixline();
      // 第七行专业咨询领域
      this.sevenline();
    }
  }
</script>

<style src="@/style/union.css" scoped></style>
<style src="@/style/main.css" scoped></style>
<style>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none
  }
</style>
