<template>
  <div class="NoticeCom">
    <div class="toptitle">
      申请进入养老/家政领域教学协作共同体联盟专家库须知
    </div>
    <div class="nrtext">
      <div id="u2108_text" class="text ">
        <p><span
            style="font-size: 14px;">养老/家政领域教学协同共同体联盟专家库是根据《XXXXXXX》、《XXXXXX实施条例》、教育部《XXXXX专家管理办法》等法律法规依法建立，是规范平台资源、项目合作评审活动，维护评审
            活动的公平、公正的重要基础工作。</span></p>
        <p><span>一、联盟专家应当具备的条件</span></p>
        <p><span>（一）具有良好的职业道德，廉洁自律，遵纪守法，无行贿、受贿、欺诈等不良信用记录；</span></p>
        <p><span>（二）申请人须提交申请信息至联盟常务理事单位；</span></p>
        <p><span>（三）具有中级专业技术职称或同等专业水平且从事相关 领域工作满８年，或者具有高级专业技术职称或同等专业水平；</span></p>
        <p><span>（四）熟悉养老/家政相关政策法规；</span></p>
        <p><span>（五）承诺以独立身份参加评审工作，依法履行评审专家工作职责并承担相应法律责任的中国公民；</span></p>
        <p><span>（六）不满65周岁，身体健康，能够承担评审工作；</span></p>
        <p><span>（七）申请成为评审专家前三年内，无不良行为记录。</span></p>
        <p><span>二、入库流程</span></p>
        <p><span>（一）申请人注册并登录</span><span style="color:#000000;">养老/家政领域教学协作共同体区块链平台</span><span>。</span></p>
        <p><span>（二）申请人进入联盟专家，点击联盟专家申请，递交入库申请资料（包括申请人签名的承诺函、个人信用查询记录；职称证、资格证、学历学位证、身份证等证件原件及复印件）进行资料核查。</span></p>
        <p><span>（三）联盟专家委员会对申请人填报的信息进行核对。联盟专家委员会对申请材料进行核对，确保填报信息与上传证件信息准确一致。</span></p>
        <p><span>三、符合联盟专家规定条件，自愿申请成为联盟专家的人员，应当在系统中填报以下申请材料：</span></p>
        <p><span>（一）个人基本信息；</span></p>
        <p><span>（二）个人学习和工作经历，并上传学历学位证书；</span></p>
        <p><span>（三）本人签署的申请书和承诺书；</span></p>
        <p><span>（四）个人专业技术职称证书或者具有同等专业水平的证明材料；</span></p>
        <p><span>（五）证明本人身份的有效证件；</span></p>
      </div>
    </div>
    <el-button type="primary" class="toyiann" @click="sendMsg">同意</el-button>
  </div>
</template>

<script>
  export default {
    props: {
      // 接收父组件的值
      Notice: Number
    },
    name: "NoticeCom",
    methods: {
      sendMsg() {
        this.$emit('changeNotice', 1);
      }
    }
  }
</script>

<style src="@/style/Expert/NoticeCom.css" scoped></style>
<style>
  ::-webkit-scrollbar {
    display: none
  }
</style>
