<template>
  <div class="RegisterCom">
    <el-row :gutter="20" style="padding: 0rem 5rem;">
      <el-col :span="24" style="padding-left: 20px;">
        <div class="stepbar">
          <el-steps :active="active" simple>
            <el-step title="1:注册账号" icon="el-icon-edit"></el-step>
            <el-step title="2:完善机构信息" icon="el-icon-upload"></el-step>
            <el-step title="3:完善个人信息" icon="el-icon-user-solid"></el-step>
            <el-step title="4:完成" icon="el-icon-check"></el-step>
          </el-steps>
        </div>

        <!-- 注册账号 -->
        <div class="Form1" v-if="stepsun==1">
          <el-form :model="ruleForm" :rules="rules1" ref="ruleForm" label-width="150px !important"
            class="demo-ruleForm">
            <el-form-item label="创建新账号:" prop="userName">
              <el-input @blur="userNameblur" clearable v-model="ruleForm.userName" placeholder="请设置登录账号、6-20位数字和字母组合">
              </el-input>
            </el-form-item>
            <el-form-item label="手机号码:" prop="phonenumber">
              <el-input maxlength="11" @blur="phonenumberblur" clearable v-model="ruleForm.phonenumber"
                placeholder="请输入手机号码">
              </el-input>
            </el-form-item>

            <el-form-item label="密码:" prop="password">
              <el-input clearable v-model="ruleForm.password" placeholder="请输入密码" show-password></el-input>
            </el-form-item>

            <el-form-item label="确认密码:" prop="confirmpwd">
              <el-input clearable v-model="ruleForm.confirmpwd" placeholder="请输入确认密码" show-password></el-input>
            </el-form-item>

            <el-form-item label="图形码:" prop="Graphic">
              <el-input clearable v-model="ruleForm.Graphic" style="width: 50%;margin-right: 10%;"
                placeholder="请输入图形验证码">
              </el-input>
              <div style="width: 40%;float: right;">
                <el-tooltip class="item" effect="dark" content="点击切换验证码" placement="bottom">
                  <img @click="verificationimgclick()" style="width: 100%;" :src="verificationimgsrc" />
                </el-tooltip>
              </div>
            </el-form-item>

            <!-- <el-form-item label="验证码:" prop="Verification">
              <el-input clearable v-model="ruleForm.Verification" style="width: 50%;margin-right: 10%;"
                placeholder="请输入验证码">
              </el-input>
              <el-button type="primary" @click="huoqyzma" :disabled="submitdx" plain style="width: 40%;" size="mini">
                {{percentagetext}}
              </el-button>
            </el-form-item> -->

            <el-form-item style="text-align: left;">
              <el-button v-if="stepsun==2" @click="previous(1)" style="width: width: 20%;">上一步</el-button>
              <el-button type="primary" @click="submitForm('ruleForm')" style="width: width: 25%;">{{text}}</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 完善机构信息 -->
        <div class="Form1" v-if="stepsun==2">
          <el-form :model="ruleForm" :rules="rules2" ref="ruleForm" label-width="150px !important"
            class="demo-ruleForm">
            <el-form-item label="机构名称:" prop="orgName">
              <el-input clearable v-model="ruleForm.orgName" placeholder="请输入机构名称"></el-input>
            </el-form-item>
            <el-form-item label="统一社会信用代码:" prop="orgCertNo">
              <el-input clearable @blur="orgCertNoblur" v-model="ruleForm.orgCertNo" placeholder="请输入统一社会信用代码">
              </el-input>
            </el-form-item>

            <el-form-item label="机构所在区域:" prop="areaId">
              <div id="app">
                <div style="width: 100%;">
                  <el-cascader style="width: 100%;" v-model="ruleForm.areaId" :options="options"
                    :props="{ expandTrigger: 'hover' }" @change="chooseArea">
                  </el-cascader>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="机构详细地址:" prop="address">
              <el-input clearable v-model="ruleForm.address" placeholder="请输入机构详细地址"></el-input>
            </el-form-item>

            <el-form-item label="邮政编码:" prop="postCode">
              <el-input clearable v-model="ruleForm.postCode" placeholder="请输入邮政编码"></el-input>
            </el-form-item>

            <el-form-item label="上传营业执照:" prop="">
              <div style="display: inline-block;width: 100%">
                <el-upload class="imgupload" :show-file-list="true" :file-list="fileListss"
                  :action="$baseUrl.test+'/common/upload'" name="file" list-type="picture-card"
                  :on-exceed="businessonexceed" :on-preview="business" :on-remove="businessUrlRemove"
                  :on-success="businessUrlsuccess" :limit="1" accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG">
                  <i class="el-icon-plus"></i>
                </el-upload>

                <p style="color: red;font-size: 0.8rem;">（请上传扫描件，大小不能超过5MB）</p>
                <p style="color: red;font-size: 0.8rem;">（支持jpeg、jpg、png格式）</p>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="businessurl" alt="">
                </el-dialog>
              </div>
            </el-form-item>

            <el-form-item style="text-align: left;">
              <el-button v-if="stepsun==2" @click="previous(2)" style="width: width: 20%;">上一步</el-button>
              <el-button type="primary" @click="submitForm('ruleForm')" style="width: width: 25%;">{{text}}</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 完善个人信息 -->
        <div class="Form1" v-if="stepsun==3">
          <el-form :model="ruleForm" :rules="rules3" ref="ruleForm" label-width="180px !important"
            class="demo-ruleForm">
            <el-form-item label="姓名:" prop="nickName">
              <el-input clearable v-model="ruleForm.nickName" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别:" prop="sex" style="text-align: left;">
              <el-radio v-model="ruleForm.sex" label="0">男</el-radio>
              <el-radio v-model="ruleForm.sex" label="1">女</el-radio>
            </el-form-item>
            <el-form-item label="部门:" prop="deptName">
              <el-input clearable v-model="ruleForm.deptName" placeholder="请输入您的所在的部门"></el-input>
            </el-form-item>
            <el-form-item label="职务:" prop="job">
              <el-input clearable v-model="ruleForm.job" placeholder="请输入您的职务"></el-input>
            </el-form-item>
            <el-form-item label="证件类型:" prop="certType">
              <el-select clearable v-model="ruleForm.certType" placeholder="请选择" style="width: 100%;">
                <el-option label="身份证" value="0">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件号码:" prop="certNo">
              <el-input clearable v-model="ruleForm.certNo" placeholder="请输入身份证号码"></el-input>
            </el-form-item>
            <!--            <el-form-item label="身份证（正反面）扫描件:" prop="certUrl">
              <div style="display: inline-block;width: 100%">
                <el-upload class="imgupload" :action="$baseUrl.test+'/common/upload'" name="file" :show-file-list="true"
                  :file-list="fileListsfz" list-type="picture-card" :on-exceed="certsonexceed" :on-preview="cert"
                  :on-remove="certUrlRemove" :on-success="certUrlsuccess" :limit="2"
                  accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <p style="color: red;font-size: 0.8rem;">（请上传扫描件，大小不能超过5MB，1-2张）</p>
                <p style="color: red;font-size: 0.8rem;">（支持jpeg、jpg、png格式）</p>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="certurl" alt="">
                </el-dialog>
              </div>
            </el-form-item> -->

            <el-form-item label="上传机构注册委托证明:" prop="entrustmentUrl">
              <div style="display: inline-block;width: 100%">
                <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'" :on-success="handleChange"
                  :on-remove="entrustmentUrlRemove" :file-list="fileListxx" :limit="1"
                  accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">（请上传扫描件，大小不能超过5MB，只能上传一个）</div>
                  <div slot="tip" class="el-upload__tip">（支持jpeg、jpg、png格式）</div>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label-width="150px  !important">
              下载<el-link type="primary" @click="downloadclick">《机构注册委托证明》</el-link>
            </el-form-item>
            <el-form-item style="text-align: left;">
              <el-button v-if="stepsun==3" @click="previous(3)" style="width: 20%;">上一步</el-button>
              <el-button v-if="stepsun==3" @click="submitForm('ruleForm')" style="width: 25%;">保存为草稿</el-button>
              <el-button v-if="stepsun==3" type="primary" @click="submitForm('ruleForm')">提交入链申请
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 完成 -->
        <div class="Form1" v-if="stepsun==4">
          <img class="wanchengimg" src="../../assets/u382.png" />
          <p class="wanchengaccount">你的帐户：{{ruleForm.userName}} 已申请成功！</p>
          <!-- <p class="wanchengtext">您的申请已提交联盟进行审核，请在3个工作日内登录平台查收审核消息。</p> -->
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
  import commonComponents from '@/components/register/commonComponents.vue'
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';

  export default {
    name: '',
    components: {
      commonComponents
    },
    data: function() {
      return {
        // 定时数
        percentage: 60,
        // 获取验证码按钮文字
        percentagetext: "获短信取验证码",
        submitdx: false,
        dialogVisible8: true,
        clearImg: true,
        businessurl: '',
        // 放大的图片路径
        // certurl: '',
        fileListxx: [],
        fileListss: [],
        fileListsfz: [],
        dialogVisible: false,
        disabled: false,
        options: cities,
        activeIndex: "",
        active: 0,
        text: "下 一 步",
        stepsun: 1,
        verificationimgsrc: '',
        // 验证码text
        yzmtext: '',
        ruleForm: {
          // uuid
          uuid: '',
          // 用户账号
          userName: '',
          // 手机号码
          phonenumber: '',
          // 图形码
          Graphic: '',
          // 密码
          password: '',
          // 确认密码
          confirmpwd: '',
          // 验证码
          Verification: '',

          // 机构名称
          orgName: '',
          // 统一社会信用代码
          orgCertNo: '',
          // 机构所在区域id
          areaId: '',
          // 机构详细地址
          address: '',
          // 邮政编码
          postCode: '',
          // 营业执照
          businessUrl: '',

          // 姓名
          nickName: '',
          // 性别
          sex: '',
          // 所在部门
          deptName: '',
          // 职务
          job: '',
          // 证件类型
          certType: '0',
          // 证件号码
          certNo: '',
          // 身份证照片ID
          // certUrl: [],
          // 机构注册委托证明
          entrustmentUrl: ''
        },
        rules1: {
          userName: [{
              required: true,
              message: '请创建一个新账号，6-20位数字和字母组合',
              trigger: 'blur'
            },
            {
              min: 6,
              max: 20,
              message: '长度在 6 到 20 个字符',
              trigger: 'blur'
            }
          ],
          // Verification: [{
          //   required: true,
          //   message: '请输入验证码',
          //   trigger: 'blur'
          // }],
          phonenumber: [{
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '请输入密码，8-16位数字和字母组合',
            trigger: 'blur'
          }],
          confirmpwd: [{
            required: true,
            message: '请确认密码',
            trigger: 'blur'
          }],
          Graphic: [{
            required: true,
            message: '请输入图形码',
            trigger: 'blur'
          }]
        },
        rules2: {
          orgName: [{
            required: true,
            message: '请填写营业执照上登记的公司名称',
            trigger: 'blur'
          }],
          orgCertNo: [{
            required: true,
            message: '请填写营业执照上的统一社会信用代码',
            trigger: 'blur'
          }],
          postCode: [{
            required: true,
            message: '请输入邮政编码',
            trigger: 'blur'
          }],
          areaId: [{
            required: true,
            message: '请选择机构所在区域',
            trigger: 'blur'
          }],
          address: [{
            required: true,
            message: '请输入机构详细地址',
            trigger: 'blur'
          }],
          businessUrl: [{
            required: true,
            message: '请上传上传营业执照',
            trigger: 'blur'
          }],
        },
        rules3: {
          nickName: [{
            required: true,
            message: '请输姓名',
            trigger: 'blur'
          }],
          sex: [{
            required: true,
            message: '请选择性别',
            trigger: 'blur'
          }],
          deptName: [{
            required: true,
            message: '请输入所在部门',
            trigger: 'blur'
          }],
          job: [{
            required: true,
            message: '请输入职务',
            trigger: 'blur'
          }],
          certType: [{
            required: true,
            message: '请输入证件类型',
            trigger: 'blur'
          }],
          certNo: [{
            required: true,
            message: '请输入证件号码',
            trigger: 'blur'
          }],
          // certUrl: [{
          //   required: true,
          //   message: '请上传身份证正反签字扫描件',
          //   trigger: 'blur'
          // }],
          entrustmentUrl: [{
            required: true,
            message: '请上传机构注册委托证明',
            trigger: 'blur'
          }],
        }
      }
    },
    // 页面加载完毕触发
    mounted() {
      // 把指向vue对象保存给_this
      let _this = this;
      _this.options = element_china_area_data__WEBPACK_IMPORTED_MODULE_1__.regionData
      console.log(_this.options)
    },
    methods: {
      // 下载《机构注册委托证明》
      downloadclick() {
        window.open("http://119.3.109.199:9091/profile/common/image/2021/12/09/32e265820e8847ac94097a515cbe7ba7.doc");
      },
      chooseArea(value) { //区域地址改变
        this.ruleForm.areaId = value.join();
      },
      // 身份证正反签字扫描件路径
      certUrlsuccess(response, file, fileList) {
        this.ruleForm.certUrl.push(JSON.stringify(response.data.fileId));
        if (this.ruleForm.certUrl.length > 2) {
          this.ruleForm.certUrl.splice(0, 1);
        }
        this.certurl = file.url;
        this.fileListsfz.push({
          url: file.response.data.fileUrl,
          name: file.response.data.fileName
        })
      },
      // 身份证正反签字扫描件路径删除
      certUrlRemove(file, fileList) {
        for (let x in this.fileListsfz) {
          if (this.fileListsfz[x].url == file.url) {
            this.fileListsfz.splice(x, 1);
            this.ruleForm.certUrl.splice(x, 1);
          }
        }
      },
      // 机构注册委托证明路径
      handleChange(response, file, fileList) {
        this.fileListxx.push({
          url: file.response.data.fileUrl,
          name: file.response.data.fileName
        })
        this.ruleForm.entrustmentUrl = JSON.stringify(response.data.fileId);
      },
      // 机构注册委托证明路径删除
      entrustmentUrlRemove(file, fileList) {
        this.ruleForm.entrustmentUrl = '';
        this.fileListxx = fileList;
      },
      // 上传营业执照
      businessUrlsuccess(response, file, fileList) {
        this.businessurl = file.url;
        this.fileListss.push({
          url: file.response.data.fileUrl,
          name: file.response.data.fileName
        })
        this.ruleForm.businessUrl = JSON.stringify(response.data.fileId);
      },
      // 营业执照超过指定个数
      businessonexceed(response, file, fileList) {
        this.$message({
          offset: 100,
          message: '只能上传一张，请先删除！',
          type: 'error'
        });
      },
      // 身份证超过指定个数
      certsonexceed(response, file, fileList) {
        this.$message({
          offset: 100,
          message: '只能上传一张，请先删除！',
          type: 'error'
        });
      },
      // 营业执照删除
      businessUrlRemove(file, fileList) {
        this.ruleForm.businessUrl = '';
        this.fileListss = [];
      },
      //图片放大查看事件
      cert(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      business(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      // 图片上传时调用
      handleBeforeUpload(file) {
        const uploadLimit = 2
        const uploadTypes = ['jpg', 'png', 'doc', 'docx', 'xlsx', 'zip', 'rar', 'pdf']
        const filetype = file.name.replace(/.+\./, '')
        const isRightSize = (file.size || 0) / 1024 / 1024 < uploadLimit
        if (!isRightSize) {
          this.$message.error('文件大小超过 ' + uploadLimit + 'MB')
          return false
        }
        if (uploadTypes.indexOf(filetype.toLowerCase()) === -1) {
          this.$message.warning({
            message: '请上传后缀名为jpg、png、txt、pdf、doc、docx、xlsx、zip或rar的附件'
          })
          return false
        }
        return true
      },
      // 上一步
      previous(e) {
        if (e == 2) {
          this.active = 0;
          this.stepsun = 1;
        } else if (e == 2) {
          this.active = 1;
          this.stepsun = 2;
        } else if (e == 3) {
          this.active = 1;
          this.stepsun = 2;
        }
      },
      // 手机号码验证
      phonenumberblur() {
        if (!(/^1[3456789]\d{9}$/.test(this.ruleForm.phonenumber))) {
          this.$message({
            offset: 100,
            message: '手机号码格式不正确！',
            type: 'error'
          });
        } else {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/user/getUserList?phonenumber=' + this.ruleForm.phonenumber,
            data: "",
            headers: {
              'Authorization': ''
            }
          }).then((res) => {
            // 返回成功
            if (res.data.rows == "") {
              this.$message({
                offset: 100,
                message: '手机号码可用！',
                type: 'success'
              });
            } else { //返回失败
              this.$message({
                offset: 100,
                message: '手机号码已被注册！',
                type: 'warning'
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      // 创建新账号验证
      userNameblur() {
        if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.ruleForm.userName))) {
          this.$message({
            offset: 100,
            message: '账号格式不正确！',
            type: 'error'
          });
        } else {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/user/getUserList?userName=' + this.ruleForm.userName,
            data: "",
          }).then((res) => {
            // 返回成功
            if (res.data.rows == "") {
              this.$message({
                offset: 100,
                message: '账号可用！',
                type: 'success'
              });
            } else { //返回失败
              this.$message({
                offset: 100,
                message: '账号已被注册！',
                type: 'warning'
              });
            }
          }).catch(error => {
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
      },
      // 社会统一验证码
      // orgCertNoblur() {
      //   if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{15,18}$/.test(this.ruleForm.orgCertNo))) {
      //     this.$message({
      //       offset: 100,
      //       message: '社会统一验证码格式不正确！',
      //       type: 'error'
      //     });
      //   } else {
      //     this.$message({
      //       offset: 100,
      //       message: '社会统一验证码可使用！',
      //       type: 'success'
      //     });
      //   }
      // },
      // 获取验证码
      // huoqyzma() {
      //   let params = {
      //     uuid: this.ruleForm.uuid,
      //     code: this.ruleForm.Graphic,
      //   }
      //   // 如果有验证码就验证
      //   if (this.ruleForm.Graphic) {
      //     this.$axios({
      //       method: 'post',
      //       url: this.$baseUrl.test + '/api/pubilc/validateCaptcha',
      //       data: params,
      //     }).then((res) => {
      //       // 返回成功
      //       if (res.data.code == 200) {
      //         // 如果有输入手机号码
      //         if (this.ruleForm.phonenumber) {
      //           this.$axios({
      //             method: 'get',
      //             url: this.$baseUrl.test + '/sms/getPhoneCode/' + this.ruleForm.phonenumber,
      //             params: "",
      //           }).then((res) => {
      //             // 返回成功
      //             if (res.data.code == 200) {
      //               this.$alert('验证码已发送至您的手机请注意查收！', '温馨提示', {
      //                 confirmButtonText: '确定',
      //                 callback: action => {}
      //               });
      //               this.submitdx = true;
      //               // 一秒执行一次
      //               this.timer = setInterval(() => {
      //                 // 倒计时60秒
      //                 this.percentage = this.percentage - 1;
      //                 this.percentagetext = this.percentage + "秒"
      //                 // 到了0秒的时候
      //                 if (this.percentage == 0) {
      //                   // 结束倒计时
      //                   clearInterval(this.timer);
      //                   // 获取短信按钮可点击
      //                   this.submitdx = false;
      //                   // 改变获取短信验证码文字
      //                   this.percentagetext = "获短信取验证码";
      //                 }
      //               }, 1000)
      //             } else { //返回失败
      //               this.$message({
      //                 offset: 100,
      //                 message: res.data.msg,
      //                 type: 'error'
      //               });
      //               // 获取验证码
      //               this.verificationimgclick();
      //             }
      //           });
      //         } else {
      //           this.$message({
      //             offset: 100,
      //             message: "请输入手机号码！",
      //             type: 'error'
      //           });
      //           // 获取验证码
      //           this.verificationimgclick();
      //         }
      //       }
      //       // 返回失败
      //       else {
      //         this.$message({
      //           offset: 100,
      //           message: "验证码错误！",
      //           type: 'error'
      //         });
      //         this.verificationimgclick();
      //       }
      //     });
      //   } else {
      //     this.$message({
      //       offset: 100,
      //       message: "请输入验证码！",
      //       type: 'error'
      //     });
      //     // 获取验证码
      //     this.verificationimgclick();
      //   }
      // },
      // 弱密码 长度8 大写+小写+特殊字符
      isWeakPassword(password) {
        return /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、！!？'‘“”~ `]).*$/
          .test(password);
      },
      // 下一步事件
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 第一步请求数据
            if (this.stepsun == 1) {
              // 密码验证
              if ((this.ruleForm.password != '') && (this.ruleForm.confirmpwd != '') && (this.ruleForm.password !=
                  this.ruleForm.confirmpwd)) {
                this.$message({
                  offset: 100,
                  message: '两次密码输入不一致！',
                  type: 'warning'
                });
              } else if (!this.isWeakPassword(this.ruleForm.password)) { // 弱密码验证
                this.$message({
                  offset: 100,
                  showClose: true,
                  message: '密码需要包含大写字母+小写字母+数字+特殊符号并且大于8位',
                  type: 'warning'
                });
              } else {
                if (this.active++ > 2) this.active = 0;
                this.stepsun = this.stepsun + 1;
              }
            }
            // 第二步请求
            else if (this.stepsun == 2) {
              if (this.active++ > 2) this.active = 0;
              this.stepsun = this.stepsun + 1;
              // if(this.fileListss.length == 0){
              //   this.$message({
              //     offset: 100,
              //     message: '请上传营业执照！',
              //     type: 'error'
              //   });
              // }else{
              //   if (this.active++ > 2) this.active = 0;
              //   this.stepsun = this.stepsun + 1;
              // }
            }
            // 第三步请求数据
            else {
              // if (this.ruleForm.certUrl.length < 1) {
              //   this.$message({
              //     offset: 100,
              //     message: '请上传身份证正反照！',
              //     type: 'warning'
              //   });
              // } else
              if (this.ruleForm.entrustmentUrl == "") {
                this.$message({
                  offset: 100,
                  message: '请上传机构注册委托证明！',
                  type: 'warning'
                });
              } else {
                // if(typeof(this.ruleForm.certUrl)=='string'){
                // }else{
                //   this.ruleForm.certUrl = this.ruleForm.certUrl.join();
                // }
                this.$axios({
                  method: 'post',
                  url: this.$baseUrl.test + '/api/user/orgRegister',
                  data: this.ruleForm,
                }).then((res) => {
                  // 返回成功
                  if (res.data.code == 200) {
                    this.stepsun = 4;
                    if (this.active++ > 3) this.active = 0;
                  } else { //返回失败
                    this.$message({
                      offset: 100,
                      message: res.data.msg,
                      type: 'warning'
                    });
                  }
                }).catch(error => {
                  // 处理错误
                  this.$message({
                    offset: 100,
                    message: error.msg,
                    type: 'error'
                  });
                });
              }
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      verificationimgclick() {
        // 获取图形码接口
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/captchaImg',
          data: "",
        }).then((res) => {
          // 图片地址
          this.verificationimgsrc = res.data.img;
          // uuid
          this.ruleForm.uuid = res.data.uuid;
          // 验证码文字
          this.yzmtext = res.data.code;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      }
    },
  }
</script>

<style src="@/style/register/InsideCom.css" scoped></style>
<style scoped>
  .el-step__main {
    white-space: normal;
    /* text-align: left; */
    display: flex;
    justify-content: center;
  }

  .el-form-item__label {
    width: 22% !important;
  }

  ::-webkit-scrollbar {
    display: none;
    text-align: right;
  }

  .demo-ruleForm {
    height: 55vh;
    overflow-y: auto;
    margin-top: 15vh;
    border-radius: 10px;
  }
</style>
