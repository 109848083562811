<template>
  <div class="MechanismCom">
    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;">
      <el-col :span="24">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>工作台</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>机构管理</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col>
        <el-row :gutter="20">
          <el-form :model="ruleForm" label-width="100px !important">
            <el-col :span="8">
              <el-form-item label="机构名称:">
                <template>
                  <el-input style="width: 100%;" v-model="tableDataval.orgName" placeholder="请输入" clearable></el-input>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="状态:">
                <template>
                  <el-select v-model="tableDataval.status" placeholder="请选择" style="width: 70%;" clearable
                    @change="onSubmit">
                    <el-option label="待审核" value="1"></el-option>
                    <el-option label="启用" value="2"></el-option>
                    <el-option label="退回" value="3"></el-option>
                    <el-option label="禁用" value="4"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="background-color: #FFFFFF;min-height: 10rem; margin-left: 0px;margin-top: 10px;">
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <el-button @click="all()" :type="primary1">全部</el-button>
        <el-badge :value="caibanvalue" class="item" style="margin-left: 1rem;">
          <el-button type="primary" @click="caiban()" :type="primary2">待办</el-button>
        </el-badge>
      </el-col>
      <el-col :span="24" style="text-align: left;margin-top: 0.7rem;">
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="orgId" label="联盟ID" width="80">
            </el-table-column>
            <el-table-column prop="orgName" label="机构名称">
            </el-table-column>
            <el-table-column prop="orgType" label="机构类型" width="150">
              <template slot-scope="scope">
                <el-link :underline="false" target="_blank" v-if="scope.row.orgType == 0">高校及事业机构</el-link>
                <el-link :underline="false" target="_blank" v-if="scope.row.orgType == 1">企业机构</el-link>
                <el-link :underline="false" target="_blank" v-if="scope.row.orgType == 2">政府机关</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="orgLevel" label="机构级别" width="150">
              <template slot-scope="scope">
                <el-link :underline="false" target="_blank" v-if="scope.row.orgLevel == 0">常务理事长单位</el-link>
                <el-link :underline="false" target="_blank" v-if="scope.row.orgLevel == 1">常务理事单位</el-link>
                <el-link :underline="false" target="_blank" v-if="scope.row.orgLevel == 2">理事单位</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="chainAddress" label="机构入链地址">
            </el-table-column>
            <el-table-column prop="" label="状态" width="100">
              <template slot-scope="scope">
                <el-link :underline="false" type="danger" v-if="scope.row.status == 1">待审核</el-link>
                <el-link :underline="false" type="primary" v-if="scope.row.status == 2">启用</el-link>
                <el-link :underline="false" target="_blank" v-if="scope.row.status == 3">退回</el-link>
                <el-link :underline="false" type="danger" v-if="scope.row.status == 4">禁用</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="admNickName" label="机构管理员" width="100">
            </el-table-column>
            <el-table-column prop="" label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="see(scope.row)" style="color: #909399;">查看
                </el-button>
                <el-button v-if="scope.row.status == 1" type="text" size="small" @click="examine(scope.row)"
                  style="color: #E6A23C;">审核
                </el-button>
                <el-button v-if="scope.row.status != 1" type="text" size="small" @click="edit(scope.row)">编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tableDataval.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </template>
      </el-col>
    </el-row>

    <!-- 机构修改||机构查看详情弹窗 -->
    <el-dialog :title="dialogtitle" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <el-row>
        <el-col :span="24">
          <!-- 专业信息 -->
          <div class="Form1">
            <el-row>
              <el-col :span="24">
                <div style="padding: 1rem;">
                  <el-form :model="DetailTable" label-width="120px !important" class="demo-ruleForm">
                    <!-- 头部文字 -->
                    <div class="toptext">
                      <p>机构信息</p>
                    </div>
                    <el-row>
                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="机构名称:">
                          <el-input v-if="buttonstate == 2" v-model="DetailTable.orgName" style="width:80%;"></el-input>
                          <span v-if="buttonstate == 1 || buttonstate == 3">{{DetailTable.orgName}}</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="机构类型:">
                          <el-link :underline="false" target="_blank" v-if="DetailTable.orgType == 0">高校及事业机构</el-link>
                          <el-link :underline="false" target="_blank" v-if="DetailTable.orgType == 1">企业机构</el-link>
                          <el-link :underline="false" target="_blank" v-if="DetailTable.orgType == 2">政府机关</el-link>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="邮政编码:">
                          <el-input v-if="buttonstate == 2" v-model="DetailTable.postCode" style="width:80%;">
                          </el-input>
                          <span v-if="buttonstate == 1 || buttonstate == 3">{{DetailTable.postCode}}</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="机构所在区域:">
                          <template>
                            <div id="app">
                              <div style="text-align: left;">
                                <el-cascader style="width: 80%;" :disabled="buttonstate == 1 || buttonstate == 3"
                                  v-model="DetailTable.areaId" :options="options" :props="{ expandTrigger: 'hover' }"
                                  @change="chooseArea">
                                </el-cascader>
                              </div>
                            </div>
                          </template>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="统一社会信用代码:">
                          <el-input v-if="buttonstate == 2" v-model="DetailTable.orgCertNo" style="width:80%;">
                          </el-input>
                          <span v-if="buttonstate == 1 || buttonstate == 3">{{DetailTable.orgCertNo}}</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="机构详细地址:">
                          <el-input v-if="buttonstate == 2" v-model="DetailTable.address" style="width:80%;"></el-input>
                          <span v-if="buttonstate == 1 || buttonstate == 3">{{DetailTable.address}}</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="营业执照:">
                          <div style="display: inline-block;width: 100%" v-if="buttonstate == 2">
                            <el-upload class="imgupload" :action="$baseUrl.test+'/common/upload'" name="file"
                              list-type="picture-card" :on-preview="business" :on-remove="businessUrlRemove"
                              :on-success="businessUrlsuccess" :limit="1">
                              <i class="el-icon-plus"></i>
                            </el-upload>

                            <el-form-item label="原营业执照:" v-if="DetailTable.businessUrl">
                              <div class="demo-image__preview">
                                <el-image fit="contain" style="width: 100px; height: 100px;margin-left: 1rem;"
                                  @click="img1click(DetailTable.businessUrl)" :src="DetailTable.businessUrl"
                                  :preview-src-list="DetailTable.businessUrl">
                                </el-image>
                              </div>
                            </el-form-item>
                          </div>

                          <div class="demo-image__preview"
                            v-if="(buttonstate == 1 || buttonstate == 3) && DetailTable.businessUrl">
                            <el-image fit="contain" style="width: 100px; height: 100px" :src="DetailTable.businessUrl"
                              @click="img1click(DetailTable.businessUrl)" :preview-src-list="DetailTable.businessUrl">
                            </el-image>
                          </div>
                        </el-form-item>
                      </el-col>

                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p style="width: 8rem;">机构管理员信息</p>
                      </div>
                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="姓名:">
                          <el-input v-if="buttonstate == 2" v-model="DetailTable.admNickName" style="width:80%;">
                          </el-input>
                          <span v-if="buttonstate == 1 || buttonstate == 3">{{DetailTable.admNickName}}</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="性别:">
                          <el-select v-if="buttonstate == 2" v-model="DetailTable.sex" value="DetailTable.sex"
                            placeholder="请选择">
                            <el-option label="男" value="0"></el-option>
                            <el-option label="女" value="1"></el-option>
                          </el-select>
                          <span v-if="buttonstate == 1 || buttonstate == 3">
                            <span v-if="DetailTable.sex == 0">男</span>
                            <span v-if="DetailTable.sex == 1">女</span>
                          </span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="手机号码:">
                          <el-input v-if="buttonstate == 2" v-model="DetailTable.phonenumber" style="width:80%;">
                          </el-input>
                          <span v-if="buttonstate == 1 || buttonstate == 3">{{DetailTable.phonenumber}}</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="部门:">
                          <el-input v-if="buttonstate == 2" v-model="DetailTable.deptName" style="width:80%;">
                          </el-input>
                          <span v-if="buttonstate == 1 || buttonstate == 3">{{DetailTable.deptName}}</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="职务:">
                          <el-input v-if="buttonstate == 2" v-model="DetailTable.job" style="width:80%;">
                          </el-input>
                          <span v-if="buttonstate == 1 || buttonstate == 3">{{DetailTable.job}}</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="证件类型:">
                          <el-select v-if="buttonstate == 2" v-model="DetailTable.certType" value="DetailTable.certType"
                            placeholder="请选择">
                            <el-option label="身份证" value="0"></el-option>
                          </el-select>
                          <span v-if="buttonstate == 1 || buttonstate == 3">身份证</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="证件号码:">
                          <el-input v-if="buttonstate == 2" v-model="DetailTable.certNosfz" style="width:80%;">
                          </el-input>
                          <span v-if="buttonstate == 1 || buttonstate == 3">{{DetailTable.certNosfz}}</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="身份证正反签字扫描件:">
                          <div style="display: inline-block;width: 100%" v-if="buttonstate == 2">
                            <el-upload class="imgupload" :action="$baseUrl.test+'/common/upload'" name="file"
                              list-type="picture-card" :on-preview="cert" :on-remove="certUrlRemove"
                              :on-success="certUrlsuccess" :limit="2">
                              <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-form-item label="原身份证:">
                              <div class="demo-image__preview" v-if="imgenlargecertUrl1"
                                @click="img1click(imgenlargecertUrl1)">
                                <el-image fit="contain" style="width: 100px; height: 100px;margin-left: 1rem;"
                                  :src="imgenlargecertUrl1" :preview-src-list="imgenlargecertUrl1">
                                </el-image>
                              </div>
                              <div class="demo-image__preview" v-if="imgenlargecertUrl2"
                                @click="img1click(imgenlargecertUrl2)">
                                <el-image fit="contain" style="width: 100px; height: 100px;margin-left: 1rem;"
                                  :src="imgenlargecertUrl2" :preview-src-list="imgenlargecertUrl2">
                                </el-image>
                              </div>
                            </el-form-item>
                          </div>

                          <div class="demo-image__preview" v-if="buttonstate == 1 || buttonstate == 3">
                            <!-- 反显上传的身份证 -->
                            <div class="demo-image__preview" v-if="imgenlargecertUrl1"
                              @click="img1click(imgenlargecertUrl1)">
                              <el-image fit="contain" style="width: 100px; height: 100px" :src="imgenlargecertUrl1"
                                :preview-src-list="imgenlargecertUrl1">
                              </el-image>
                            </div>
                            <div class="demo-image__preview" v-if="imgenlargecertUrl2"
                              @click="img1click(imgenlargecertUrl2)">
                              <el-image fit="contain" style="width: 100px; height: 100px" :src="imgenlargecertUrl2"
                                :preview-src-list="imgenlargecertUrl2">
                              </el-image>
                            </div>
                          </div>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="机构注册委托证明:">

                          <div style="display: inline-block;width: 100%" v-if="buttonstate == 2">
                            <el-upload class="imgupload" :action="$baseUrl.test+'/common/upload'" name="file"
                              list-type="picture-card" :on-preview="entrustment" :on-remove="entrustmentUrlRemove"
                              :on-success="entrustmentUrlsuccess" :limit="1">
                              <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-form-item label="原委托证明:">
                              <div class="demo-image__preview">
                                <el-image fit="contain" style="width: 100px; height: 100px;margin-left: 1rem;"
                                  @click="img1click(DetailTable.entrustmentUrl)" :src="DetailTable.entrustmentUrl"
                                  :preview-src-list="DetailTable.entrustmentUrl">
                                </el-image>
                              </div>
                            </el-form-item>
                          </div>

                          <div class="demo-image__preview" v-if="buttonstate == 1 || buttonstate == 3">
                            <el-image fit="contain" style="width: 100px; height: 100px;"
                              @click="img1click(DetailTable.entrustmentUrl)" :src="DetailTable.entrustmentUrl"
                              :preview-src-list="DetailTable.entrustmentUrl">
                            </el-image>
                          </div>

                        </el-form-item>
                      </el-col>

                      <div class="toptext">
                        <p>机构级别</p>
                      </div>
                      <el-col :span="12" style="text-align: left;">
                        <el-form-item label="机构级别:">
                          <el-select v-if="buttonstate == 2 || buttonstate == 3" v-model="DetailTable.orgLevel"
                            value="DetailTable.orgLevel" placeholder="请选择">
                            <el-option label="常务理事长单位" value="0"></el-option>
                            <el-option label="常务理事单位" value="1"></el-option>
                            <el-option label="理事单位" value="2"></el-option>
                          </el-select>
                          <p v-if="buttonstate == 2 || buttonstate == 3" style="color: red;font-size: 0.8rem;">
                            由审核人填写
                          </p>
                          <span v-if="buttonstate == 1">
                            <span v-if="DetailTable.orgLevel == '0'">常务理事长单位</span>
                            <span v-if="DetailTable.orgLevel == '1'">常务理事单位</span>
                            <span v-if="DetailTable.orgLevel == '2'">理事单位</span>
                          </span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="buttonstate == 3" type="primary" @click="refuse()">审 核</el-button>
        <el-button v-if="buttonstate == 2" type="primary" @click="preservation()">保 存</el-button>
        <el-button @click="dialogVisible = false">返 回</el-button>
      </span>

      <!-- 二级弹窗 -->
      <el-dialog width="60%" title="审核" style="text-align: center;" :visible.sync="innerVisible" append-to-body>
        <div style="font-weight:bold;text-align: left;margin: 1rem 0rem;">审核:</div>
        <div style="text-align: left">
          <el-radio v-model="DetailTable.status" label="2" border>通过</el-radio>
          <el-radio v-model="DetailTable.status" label="4" border>未通过</el-radio>
        </div>
        <div style="font-weight:bold;text-align: left;margin: 1rem 0rem;">理由:</div>
        <el-input type="textarea" :rows="2" placeholder="请输入审核理由" v-model="auditReason">
        </el-input>
        <div style="text-align: center;margin-top: 2rem;">
          <el-button type="primary" @click="Toexamine()">确 定</el-button>
          <el-button @click="innerVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <!-- 图片放大弹窗 -->
      <el-dialog title="图片查看详情" :visible.sync="imgdialogVisible" width="50%" append-to-body>
        <el-image fit="contain" style="width: 100%; height: 100%" :src="DetailTablecertUrl"
          :preview-src-list="DetailTablecertUrl">
        </el-image>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="imgdialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>

  </div>
</template>

<script>
  import {
    cities,
    CodeToText
  } from 'element-china-area-data'
  import $ from 'jquery';
  export default {
    data: function() {
      return {
        imgenlargecertUrl1: "",
        imgenlargecertUrl2: "",
        DetailTablecertUrl: "",
        imgdialogVisible: false,
        primary1: 'primary',
        primary2: '',
        options: cities,
        ruleForm: {
          name: '',
          value: ''
        },
        // 表格字段
        tableDataval: {
          // 机构名称
          'orgName': '',
          // 状态
          'status': '',
          // 当前页
          'pageNum': 1,
          // 每页条数
          'pageSize': 10
        },
        total: 0,
        tableData: [],
        DetailTable: {
          'address': "江苏",
          'admNickName': "",
          'admUserName': "",
          'areaId': 110105,
          'auditReason': "",
          // 营业执照路径
          'businessUrl': "",
          'businessid': "",
          'certNo': "123456345678",
          'orgCertNo': "",
          'createBy': "",
          'createTime': "2021-10-23 19:26:57",
          // 机构注册委托证明
          'entrustmentUrl': "",
          'entrustmentid': "",
          // 手机号码
          'phonenumber': '',
          // 性别
          'sex': '',
          // 部门
          'deptName': '',
          // 职务
          'job': '',
          // 身份证类型
          'certType': '',
          'orgId': 22,
          'orgLevel': "1",
          'orgName': "职业",
          'orgType': "0",
          'params': {},
          'postCode': "1234",
          'remark': "",
          'searchValue': "",
          'status': "2",
          'updateBy': "",
          'updateTime': "2021-10-31 11:14:51",
          'userId': 36,
          'certUrl': '',
          'certid': ''
        },
        dialogtitle: '查看机构详情',
        dialogVisible: false,
        buttonstate: 1,
        currentUserId: 0,
        // 当前机构id
        tableDataorgId: '',
        // 二级弹窗
        innerVisible: false,
        textarea: "",
        // 审核理由
        auditReason: "",
        // 待办
        caibanvalue: 0
      }
    },
    methods: {
      // 图片点击放大事件
      img1click(e) {
        this.DetailTablecertUrl = e;
        this.imgdialogVisible = true;
      },
      // 机构注册委托证明路径
      entrustmentUrlsuccess(response, file, fileList) {
        this.DetailTable.entrustmentid = JSON.stringify(response.data.fileId);
      },
      // 机构注册委托证明路径删除
      entrustmentUrlRemove(file, fileList) {
        this.DetailTable.entrustmentid = '';
      },
      // 上传营业执照
      businessUrlsuccess(response, file, fileList) {
        this.DetailTable.businessid = JSON.stringify(response.data.fileId);
      },
      // 营业执照删除
      businessUrlRemove(file, fileList) {
        this.DetailTable.businessid = '';
      },
      // 身份证正反签字扫描件路径
      certUrlsuccess(response, file, fileList) {
        this.DetailTable.certid = JSON.stringify(response.data.fileId);
      },
      // 身份证正反签字扫描件路径删除
      certUrlRemove(file, fileList) {
        this.DetailTable.certid = '';
        // this.ruleForm.certUrl = this.ruleForm.certUrl.substr(0, this.ruleForm.certUrl.length - 1).split(",")[0];
      },
      chooseArea(value) { //区域地址改变
        // 将数组转换成字符串
        this.DetailTable.areaId = value.join();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.tableDataval.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.tableDataval.pageNum = val;
        this.onSubmit();
      },
      // 全部
      all() {
        this.onSubmit();
        this.primary1 = 'primary';
        this.primary2 = '';
      },
      // 待办
      caiban() {
        this.primary1 = '';
        this.primary2 = 'primary';
        let tableDataval = {
          'status': 1,
          'pageNum': 1,
          'pageSize': 10
        }
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/org/getOrgList?pageNum=' + this.tableDataval.pageNum + '&pageSize=' + this
            .tableDataval.pageSize,
          data: tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
          this.caibanvalue = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      onSubmit() {
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/api/org/getOrgList?pageNum=' + this.tableDataval.pageNum + '&pageSize=' + this
            .tableDataval.pageSize,
          data: this.tableDataval,
          //请求成功的回调函数
        }).then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        })
        let data = {
          'orgName': '',
          'status': '1',
          'pageNum': 1,
          'pageSize': 1000
        }
      },
      // 获取当前机构详情
      huoqudangq() {
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/pubilc/getOrgInfo/' + this.tableDataorgId,
          params: '',
          //请求成功的回调函数
        }).then((res) => {
          for (var key in res.data.data) {
            if (res.data.data[key] == null || res.data.data[key] == "null") {
              res.data.data[key] = ""
            }
          }
          // 获取统一社会信用代码
          this.DetailTable.orgCertNo = res.data.data.certNo;
          // 不能直接赋值
          Object.assign(this.DetailTable, JSON.parse(JSON.stringify(res.data.data)))
          // 获取当前机构注册委托证明ID用于反显
          this.DetailTable.entrustmentid = this.DetailTable.entrustmentUrl;
          // 获取当前身份证正反签字扫描件路径
          if (this.DetailTable.entrustmentUrl) {
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + this.DetailTable.entrustmentUrl,
              params: '',
              //请求成功的回调函数
            }).then((res) => {
              this.DetailTable.entrustmentUrl = res.data.data.fileUrl;
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }

          // 获取当前营业执照路径ID用于反显
          this.DetailTable.businessid = this.DetailTable.businessUrl;
          // 营业执照路径
          if (this.DetailTable.businessUrl) {
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + this.DetailTable.businessUrl,
              params: '',
              //请求成功的回调函数
            }).then((res) => {
              this.DetailTable.businessUrl = res.data.data.fileUrl;
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
          // 将省市区字符串转换成数组（用于展示）
          this.DetailTable.areaId = res.data.data.areaId.split(",");
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 根据用户编号获取详细信息接口
      Administratordetails() {
        this.imgenlargecertUrl1 = "";
        this.imgenlargecertUrl2 = "";
        this.$axios({
          method: 'get',
          url: this.$baseUrl.test + '/api/user/getUserInfo/' + this.currentUserId,
          params: '',
          //请求成功的回调函数
        }).then((res) => {
          for (var key in res.data.data) {
            if (res.data.data[key] === null || res.data.data[key] === "null") {
              res.data.data[key] = ""
            }
          }

          // 不能直接赋值
          Object.assign(this.DetailTable, JSON.parse(JSON.stringify(res.data.data)))
          // 获取身份证ID
          this.DetailTable.certNosfz = res.data.data.certNo;
          // 获取当前身份证正反签字扫描件ID用于反显
          this.DetailTable.certid = this.DetailTable.certUrl;
          this.thiscertid = this.DetailTable.certUrl.split(",");
          // 如果长度等于2，就是有两张
          if (this.DetailTable.certUrl.split(",").length == 2) {
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + this.DetailTable.certUrl.split(",")[0],
              params: '',
              //请求成功的回调函数
            }).then((res) => {
              // 获取当前身份证正反签字扫描件路径
              this.imgenlargecertUrl1 = res.data.data.fileUrl;
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + this.DetailTable.certUrl.split(",")[1],
              params: '',
              //请求成功的回调函数
            }).then((res) => {
              // 获取当前身份证正反签字扫描件路径
              this.imgenlargecertUrl2 = res.data.data.fileUrl;
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            this.thiscertid.push(this.DetailTable.certUrl);
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + this.DetailTable.certUrl,
              params: '',
              //请求成功的回调函数
            }).then((res) => {
              // 获取当前身份证正反签字扫描件路径
              this.imgenlargecertUrl1 = res.data.data.fileUrl;
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 查看机构详情
      see(e) {
        this.dialogtitle = '查看机构详情';
        this.buttonstate = 1;
        this.dialogVisible = true;
        this.tableDataorgId = e.orgId;
        this.currentUserId = e.userId;
        this.huoqudangq();
        this.Administratordetails();
      },
      // 编辑
      edit(e) {
        this.dialogtitle = '编辑机构信息';
        this.tableDataorgId = e.orgId;
        this.buttonstate = 2;
        this.dialogVisible = true;
        this.currentUserId = e.userId;
        this.huoqudangq();
        this.Administratordetails();
      },
      // 审核
      examine(e) {
        this.dialogtitle = '审核机构信息';
        this.tableDataorgId = e.orgId;
        this.buttonstate = 3;
        this.dialogVisible = true;
        this.currentUserId = e.userId;
        this.huoqudangq();
        this.Administratordetails();
      },
      // 保存
      preservation(e) {
        let params = {
          "orgId": this.tableDataorgId,
          // 机构类型，0=高校及事业机构,1=企业机构,2=政府机关
          "orgType": this.DetailTable.orgType,
          // 机构名称
          "orgName": this.DetailTable.orgName,
          // 统一社会信用代码
          "orgCertNo": this.DetailTable.orgCertNo,
          // 机构所在区域ID
          "areaId": this.DetailTable.areaId ? this.DetailTable.areaId.join() : '',
          // 机构详细地址
          "address": this.DetailTable.address,
          // 邮政编码
          "postCode": this.DetailTable.postCode ? this.DetailTable.postCode : '',
          // 营业执照路径
          "businessUrl": this.DetailTable.businessid ? this.DetailTable.businessid : '',
          // 机构注册委托证明路径
          "entrustmentUrl": this.DetailTable.entrustmentid ? this.DetailTable.entrustmentid : '',
        }
        // if (!this.DetailTable.orgLevel) {
        //   this.$message({
        //     offset: 100,
        //     type: 'error',
        //     message: '请选择联盟职务！'
        //   });
        // } else {

        // }
        // 保存机构信息
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/api/org/',
          data: params,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              type: 'success',
              message: '操作成功!'
            });
            this.dialogVisible = false;
            this.huoqudangq();
            this.onSubmit();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
        let params2 = {
          "userId": this.currentUserId,
          "userName": this.DetailTable.userName,
          "nickName": this.DetailTable.nickName,
          "phonenumber": this.DetailTable.phonenumber,
          "password": this.DetailTable.password,
          "orgId": 1,
          "deptName": this.DetailTable.deptName,
          "job": this.DetailTable.job,
          "certType": this.DetailTable.certType,
          "certNo": this.DetailTable.certNosfz,
          // 身份证ID
          "certUrl": this.DetailTable.certid,
        }
        // 保存用户信息
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/api/user/',
          data: params2,
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              offset: 100,
              type: 'success',
              message: '操作成功!'
            });
            this.Administratordetails();
            location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },
      // 一级弹窗审核按钮点击事件
      refuse(e) {
        // if (!this.DetailTable.orgLevel) {
        //   this.$message({
        //     offset: 100,
        //     type: 'error',
        //     message: '请选择联盟职务！'
        //   });
        // } else {

        // }
        this.innerVisible = true;
      },
      // 机构状态修改
      changeStatus() {
        // this.$axios({
        //   method: 'put',
        //   url: this.$baseUrl.test + '/api/org/changeStatus',
        //   data: {
        //     "orgId": this.tableDataorgId,
        //     "status": this.DetailTable.status
        //   },
        // }).then((res) => {
        //   this.$message({
        //     offset: 100,
        //     type: 'success',
        //     message: '操作成功!'
        //   });
        //   this.dialogVisible = false;
        //   this.onSubmit();
        // });
      },
      // 二级弹窗审核按钮点击事件
      Toexamine() {
        if (this.DetailTable.status == "1") {
          this.$message({
            offset: 100,
            type: 'info',
            message: '请选择通过或者不通过！'
          });
        } else if (this.DetailTable.status == "4" && this.auditReason == "") {
          this.$message({
            offset: 100,
            type: 'error',
            message: '请填写未通过理由!'
          });
        } else {
          this.$confirm('是否确定审核当前机构?', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info'
          }).then(() => {
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/org/changeStatus',
              data: {
                "orgId": this.tableDataorgId,
                "status": this.DetailTable.status,
                "auditReason": this.auditReason,
                // 机构级别(0常务理事长单位 1常务理事单位 2理事单位)
                "orgLevel": this.DetailTable.orgLevel
              },
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  offset: 100,
                  type: 'success',
                  message: '操作成功!'
                });
                this.innerVisible = false;
                this.dialogVisible = false;
                this.onSubmit();
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch(error => {
              // 处理错误
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }).catch(() => {
            this.$message({
              offset: 100,
              type: 'info',
              message: '已取消～'
            });
          });
        }
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      }
    },
    // 页面加载完毕事件
    mounted() {
      let tableDataval = {
        'status': 1,
        'pageNum': 1
      }
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/org/getOrgList?pageNum=' + tableDataval.pageNum + '&pageSize=',
        data: tableDataval,
        //请求成功的回调函数
      }).then((res) => {
        this.total = res.data.total;
        this.caibanvalue = res.data.total;
      }).catch(error => {
        // 处理错误
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      this.onSubmit();
      // 把指向vue对象保存给_this
      let _this = this;
      _this.options = element_china_area_data__WEBPACK_IMPORTED_MODULE_1__.regionData
      console.log(_this.options)
    }
  }
</script>

<style src="@/style/Systemmanagement/MechanismCom.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }
</style>
