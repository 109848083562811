<template>
  <div class="subject" style="margin-top: 10%;">
    <!-- 标题 -->
    <p style="margin: -10px 0px 0px 0px;padding: 0px;font-size: 28px;">养老/家政领域教学协作共同体区块链平台</p>
    <p style="font-size: 16px;margin: 0px;padding: 0px;margin-top: 10px;font-size: 22px;">公共服务类国家职业教育双师型培训基地(长沙民政职业技术学院)
    </p>
    <div class="login">
      <!-- 登陆窗 -->
      <div class="logindiv" v-if="login==0">
        <!-- 账号登陆 -->
        <div class="logindivbutton">
          <div @click="pwdclick" :class="logindivbuttonclick1">帐户密码登录</div>
          <!-- <div @click="phoneclick" :class="logindivbuttonclick2">手机号登录</div> -->
        </div>
        <!-- 账号 -->
        <div class="pwddiv">
          <el-input style="margin-top: 1rem;" placeholder="请输入账号" v-model="zh" clearable>
          </el-input>
          <el-input style="margin-top: 1rem;" placeholder="请输入密码" v-model="pwd" show-password clearable
            @keyup.enter.native="open1()">
          </el-input>
        </div>
        <!-- 自动登陆&&忘记密码 -->
        <div class="peizhi">
          <el-link target="_blank" @click="wangjimm">忘记密码</el-link>
        </div>

        <!-- 登陆按钮 -->
        <el-button type="primary" style="width: 100%;font-size: 1.1rem;" @click="open1()">登 录</el-button>

        <!-- 机构入链&&注册账户 -->
        <div class="jigorulie">
          <div>客服 (QQ) :676754873</div>
          <div>
            <router-link :to="{path:'Register',query:{type:0}}">
              <el-link type="primary">机构入链</el-link>
            </router-link>
            ｜
            <router-link :to="{path:'Register',query:{type:1}}">
              <el-link type="primary">注册账户</el-link>
            </router-link>
          </div>
        </div>
      </div>

      <!-- 登陆窗 -->
      <div class="logindiv" v-if="login==1">
        <!-- 短信登陆 -->
        <div class="logindivbutton">
          <div @click="pwdclick" :class="logindivbuttonclick1">帐户密码登录</div>
          <div @click="phoneclick" :class="logindivbuttonclick2">手机号登录</div>
        </div>
        <!-- 账号 -->
        <div class="pwddiv">
          <el-input style="margin-top: 1rem;" placeholder="手机号" v-model="phone" clearable>
          </el-input>
          <el-input style="margin-top: 1rem;width: 70%;" placeholder="验证码" v-model="verification" show-password
            clearable>
          </el-input>
          <el-button style="width: 25%;margin-left: 5%;" type="primary" plain>获取验证码</el-button>
        </div>
        <!-- 自动登陆&&忘记密码 -->
        <div class="peizhi">
          <el-link target="_blank" @click="wangjimm">忘记密码</el-link>
        </div>

        <!-- 登陆按钮 -->
        <el-button type="primary" style="width: 100%;font-size: 1.1rem;" @click="open2()">登 录</el-button>

        <!-- 机构入链&&注册账户 -->
        <div class="jigorulie">
          <router-link :to="{path:'Register',query:{type:0}}">
            <el-link type="primary">机构入链</el-link>
          </router-link>
          ｜
          <router-link :to="{path:'Register',query:{type:1}}">
            <el-link type="primary">注册账户</el-link>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    data: function() {
      return {
        login: 0,
        logindivbuttonclick1: 'logindivbuttonclick1',
        logindivbuttonclick2: 'logindivbuttonclick',
        zh: "",
        pwd: "",
        phone: "",
        verification: "",
        // checked: false
      }
    },
    methods: {
      phoneclick: function() { //手机验证码登陆
        this.logindivbuttonclick2 = 'logindivbuttonclick1'
        this.logindivbuttonclick1 = 'logindivbuttonclick2'
        this.login = 1
      },
      pwdclick: function() { //账号密码登陆
        this.logindivbuttonclick2 = 'logindivbuttonclick2'
        this.logindivbuttonclick1 = 'logindivbuttonclick1'
        this.login = 0
      },
      // 账号登陆
      open1() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let params = {
          username: this.$Base64.encode(this.zh),
          password: this.$Base64.encode(this.pwd)
        }
        if (!this.zh || !this.pwd) {
          this.$message.error("请输入账号和密码！");
          loading.close();
        } else {
          this.$axios({
            method: 'post',
            url: this.$baseUrl.test + '/login',
            data: params,
            headers: {
              'Authorization': ''
            }
          }).then((res) => {
            if (res.data.code == 200) {
              loading.close();
              //将token保存进session
              sessionStorage.setItem('adminToken', res.data.token);
              // 获取用户id
              localStorage.setItem('userId', res.data.user.userId);
              // 获取机构ID
              localStorage.setItem('orgId', res.data.user.orgId);
              // 获取用户权限，用户类型（0平台管理员-秘书长  1机构管理员 2普通用户 ）
              localStorage.setItem('userType', res.data.user.userType);
              // 是否为专家（0否 1是）
              localStorage.setItem('isExpert', res.data.user.isExpert);
              // 获取当前登录用户等级
              localStorage.setItem('unionLevel', res.data.user.unionLevel);
              // 验证密码是否为弱密码
              let isRuomm = this.isWeakPassword(this.pwd)
              if (!isRuomm) {
                this.$router.push('/Changepassword');
                this.$message({
                  offset: 100,
                  message: '您的密码强度等级过低，请立即修改密码！',
                  type: 'warning'
                });
              } else if (res.data.user.userType == 2) { // 如果是个人用户进入师资培训专栏
                this.$router.push('/teacherTrainingcolumn');
                this.$message({
                  offset: 100,
                  message: '登陆成功，欢迎进入区块链平台！',
                  type: 'success'
                });
              } else {
                this.$router.push('/Index');
                this.$message({
                  offset: 100,
                  message: '登陆成功，欢迎进入区块链平台！',
                  type: 'success'
                });
              }
              // 获取菜单栏
              localStorage.setItem('menuList', JSON.stringify(res.data.menuList));
            } else {
              loading.close();
              this.$alert(res.data.msg, '系统提示', {
                confirmButtonText: '确定',
                callback: action => {}
              });
              this.$router.push('/');
            }
          }).catch(error => {
            loading.close();
            // 处理错误
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }

      },
      // 弱密码 长度8 大写+小写+特殊字符
      isWeakPassword(password) {
        return /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？'‘“”~ `]).*$/
          .test(password);
      },
      // 去修改密码
      topwd() {
        let params = ''
        this.$router.push({
          name: 'Changepassword',
          params: params
        });
      },
      // 忘记密码
      wangjimm() {
        this.$alert('如果忘记密码，请联系管理员找回！', '温馨提示', {
          confirmButtonText: '确定',
        });
      },
      // 手机号登陆
      open2() {
        console.log(this.phone);
        console.log(this.verification);
      },
    },
    // 页面加载完毕触发
    mounted() {
      // 判断是否需要弹窗提示重新登陆
      let Relogin = sessionStorage.getItem('Relogin')
      if (Relogin == 1) {
        this.$message({
          message: '登陆超时，请重新登陆！',
          type: 'warning'
        });
      }
    }
  }
</script>

<style src="@/style/login.css" scoped></style>
<style scoped>
  ::-webkit-scrollbar {
    display: none
  }
</style>
