<template>
  <div class="expertinformation">
    <el-row :gutter="20" style="background-color: #FFFFFF;min-height: 14.5rem; margin-left: 0px;">
      <el-col :span="24">
        <el-row>
          <!-- 面包屑 -->
          <div class="breadcrumb">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item>工作台</el-breadcrumb-item>
              <el-breadcrumb-item>{{zjandgr == 1?"个人中心":"专家管理"}}</el-breadcrumb-item>
              <el-breadcrumb-item v-if="!ruleForm.examine && zjandgr == 2 && ruleForm.seeandexamine != '2'">专家个人信息
              </el-breadcrumb-item>
              <el-breadcrumb-item v-if="zjandgr == 1">个人信息</el-breadcrumb-item>
              <el-breadcrumb-item v-if="ruleForm.seeandexamine == '2'">专家申请审核详情</el-breadcrumb-item>
              <div class="topbuttondiv" v-if="ruleForm.seeandexamine">
                <el-button type="primary" @click="toexamineclick()" v-if="ruleForm.seeandexamine=='2'"> 审 核 </el-button>
                <el-button @click="returnclick()"> 返 回 </el-button>
              </div>
            </el-breadcrumb>
          </div>
        </el-row>
      </el-col>

      <el-col :span="24" v-if="ruleForm.seeandexamine != ''">
        <!-- 基本信息 -->
        <div class="Form1">
          <el-row>
            <el-col :span="24">
              <div style="padding: 1rem;">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                  <!-- 头部文字 -->
                  <div class="toptext">
                    <p>个人信息</p>
                  </div>
                  <el-row>
                    <el-col :span="14">
                      <!-- 个人照片 -->
                      <el-form-item label="个人照片:" prop="avatar" label-width="150px !important">
                        <div v-if="ruleForm.userImageUrl != '' && !xiugai"
                          style="display: inline-block;width: 100%;text-align: left;">
                          <div class="demo-image__preview">
                            <el-image fit="contain" style="width: 100px; height: 100px" :src="ruleForm.userImageUrl"
                              @click="imgdialogVisibleclick(ruleForm.userImageUrl)">
                            </el-image>
                            <el-link type="primary" @click="modifyAvatar()" :disabled="ruleForm.examine=='1'">修改头像
                            </el-link>
                          </div>
                        </div>

                        <div v-else="ruleForm.userImageUrl == '' && xiugai"
                          style="display: inline-block;width: 100%;text-align: left;">
                          <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :limit="1"
                            :on-preview="handlePictureCardPreview" :on-success="handleSuccess"
                            :on-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                          </el-upload>
                          <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="ruleForm.userImageUrl" alt="">
                          </el-dialog>
                          <el-link type="primary" @click="quxiao()">取消</el-link>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="专家姓名:" prop="nickName" label-width="150px !important">
                        <el-input v-model="ruleForm.nickName" placeholder="请输入专家姓名" :disabled="ruleForm.examine=='1'">
                        </el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <el-form-item label="出生日期:" prop="birthday" label-width="150px !important">
                        <el-date-picker style="width: 100%;" v-model="ruleForm.birthday" type="date" placeholder="选择日期"
                          value-format="yyyy-MM-dd" format="yyyy 年 MM 月 dd 日" :disabled="ruleForm.examine=='1'">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <el-form-item label="性别:" prop="sex" label-width="150px !important">
                        <template>
                          <el-radio v-model="ruleForm.sex" label="0" :disabled="ruleForm.examine=='1'">男
                          </el-radio>
                          <el-radio v-model="ruleForm.sex" label="1" :disabled="ruleForm.examine=='1'">女
                          </el-radio>
                        </template>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <el-form-item label="手机号码:" prop="phonenumber" label-width="150px !important">
                        <el-input v-model="ruleForm.phonenumber" placeholder="请输入手机号码"
                          :disabled="ruleForm.examine=='1'"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <el-form-item label="证件类型:" prop="certType" label-width="150px !important">
                        <template>
                          <el-select style="width: 100%;" v-model="ruleForm.certType" placeholder="请选择证件类型"
                            :disabled="ruleForm.examine=='1'">
                            <el-option label="身份证" value="0">
                            </el-option>
                          </el-select>
                        </template>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <el-form-item label="证件号码:" prop="certNo" label-width="150px !important">
                        <el-input v-model="ruleForm.certNo" placeholder="请输入证件号码" :disabled="ruleForm.examine=='1'">
                        </el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <el-form-item label="通讯地址:" prop="postAddress" label-width="150px !important">
                        <el-input v-model="ruleForm.postAddress" placeholder="请输入您的通讯地址"
                          :disabled="ruleForm.examine=='1'"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="24">
                      <el-form-item label="个人简介:" prop="remark" label-width="150px !important">
                        <el-input :disabled="ruleForm.examine=='1'" type="textarea" :rows="4" placeholder="请输入个人简介"
                          v-model="ruleForm.remark">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <!-- 头部文字 -->
                  <div class="toptext">
                    <p>机构信息</p>
                  </div>

                  <el-col :span="12">
                    <el-form-item label="机构名称:" prop="inputOrg" label-width="150px !important">
                      <el-input v-model="ruleForm.inputOrg" placeholder="请输入机构名称" disabled>
                      </el-input>
                    </el-form-item>
                  </el-col>

                  <!--                  <el-col :span="12">
                    <el-form-item label="机构类型:" prop="orgType" label-width="150px !important">
                      <template>
                        <el-select style="width: 100%;" placeholder="请选择机构类型" disabled :options="orgType"
                          v-model="ruleForm.orgType" :show-all-levels="false"></el-select>
                      </template>
                    </el-form-item>
                  </el-col> -->

                  <el-col :span="12">
                    <el-form-item label="所在部门:" prop="deptName" label-width="150px !important">
                      <el-input v-model="ruleForm.deptName" placeholder="请输入所在部门" :disabled="ruleForm.examine=='1'">
                      </el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="12">
                    <el-form-item label="职务:" prop="job" label-width="150px !important">
                      <el-input v-model="ruleForm.job" placeholder="请输入职务" :disabled="ruleForm.examine=='1'">
                      </el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="12">
                    <el-form-item label="是否在职:" prop="isonjob" label-width="150px !important">
                      <template>
                        <el-radio v-model="ruleForm.isonjob" label="1" :disabled="ruleForm.examine=='1'">是
                        </el-radio>
                        <el-radio v-model="ruleForm.isonjob" label="2" :disabled="ruleForm.examine=='1'">否
                        </el-radio>
                      </template>
                    </el-form-item>
                  </el-col>


                  <el-col :span="12">
                    <el-form-item label="工作年限:" prop="workyears" label-width="150px !important">
                      <el-input v-model="ruleForm.workyears" placeholder="请输入工作年限" :disabled="ruleForm.examine=='1'">
                        <template slot="append">年</template>
                      </el-input>
                    </el-form-item>
                  </el-col>

<!--                  <el-col :span="12">
                    <el-form-item label="详细地址:" prop="address" label-width="150px !important">
                      <el-input v-model="ruleForm.address" placeholder="请输入详细地址" :disabled="ruleForm.examine=='1'">
                      </el-input>
                    </el-form-item>
                  </el-col> -->
                </el-form>

              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 专业领域 -->
        <div class="Form1">
          <el-row>
            <el-col :span="24">
              <div style="padding: 1rem;">
                <el-form :model="Expertinformation" :rules="rules2" ref="Expertinformation"
                  label-width="140px !important" class="demo-ruleForm">
                  <!-- 头部文字 -->
                  <div class="toptext">
                    <p>专业领域</p>
                  </div>

                  <el-col :span="20" style="display: flex;">
                    <div style="margin-left: 2.7rem;">
                      <span style="color: red;">*</span>
                      <span style="color: #78797d;">申报领域:</span>
                    </div>
                    <el-form-item label="" style="margin-left: -7.5rem;">
                      <template style="margin-left: 1.25rem;">
                        <el-select v-model="Professionalinformation" placeholder="请选择申报领域" disabled value-key="options2"
                          multiple :multiple-limit="3" style="float: left;margin-left: 1rem;" name="options2">
                          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                        <p style="margin: 0px;text-align: left;padding-left: 1rem;color: #7F7F7F;">
                          请先选择申报领域（最多选择3项）</p>
                      </template>
                    </el-form-item>
                  </el-col>

                  <el-col :span="14">
                    <el-form-item label="申报专家等级:" prop="reason">
                      <el-select v-model="Expertinformation.expertGrade" placeholder="请选择申报专家等级" disabled
                        style="float: left;margin-left: 1rem;">
                        <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                      <el-popover placement="top" title="专家等级说明" width="300" trigger="hover" content="本平台专家等级分为金牌专家、银牌专家、铜牌专家三个等级;金牌专家等级最高、铜牌专家等级最低。申报各专家等级条件如下:
                                1、金牌专家申报条件
                                2、银牌专家申报条件
                                3、铜牌专家申报条件:">
                        <p slot="reference" style="margin: 0px;text-align: left;padding-left: 1rem;color: #7F7F7F;">
                          查看申报专家等级条件
                        </p>
                      </el-popover>
                    </el-form-item>
                  </el-col>

                  <el-col :span="14">
                    <el-form-item label="申报专家等级理由:" prop="reason">
                      <el-input type="textarea" :rows="2" placeholder="请输入申报专家等级理由0/300" disabled
                        v-model="Expertinformation.reason">
                      </el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="24">
                    <el-form-item label="证明材料" label-width="150px !important" prop="expertCertUrl">
                      <div class="elupload">
                        <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'"
                          :on-success="uploadSuccess" :on-error="errorUpload" :show-file-list="false">
                          <el-button size="small" type="primary" style="float: left;" @click="addupload()" disabled>点击上传
                          </el-button>
                          <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg .mp4且不超过1024MB
                          </div>
                        </el-upload>
                        <el-table :data="aptitudetableData" style="width: 100%" :border="true">
                          <el-table-column type="index" label="序号" width="50">
                          </el-table-column>

                          <el-table-column prop="certNo" label="材料名称">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.certNo" placement="top">
                                <el-input v-model="scope.row.certNo" placeholder="请输入材料名称" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>

                          <el-table-column prop="fileName" label="附件" width="250">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.fileName" placement="top">
                                <span style="cursor: pointer;" @click="fileNameclick(scope.row)">{{scope.row.fileName}}</span>
                              </el-tooltip>
                              <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'"
                                :on-success="uploadSuccess" multiple :on-error="errorUpload" :show-file-list="false">
                                <el-button size="small" type="text" @click="Reupload(scope.row)" disabled>重新上传
                                </el-button>
                              </el-upload>
                            </template>
                          </el-table-column>

                          <el-table-column prop="" label="操作" scope="scope" width="90">
                            <template slot-scope="scope">
                              <el-button type="danger" icon="el-icon-delete" circle size="small" disabled
                                @click="deleteclick(scope.row)">
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-form>

              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 工作简历 -->
        <div class="Form1">
          <el-row>
            <el-col :span="24">
              <div style="padding: 1rem;">
                <el-form :model="jlruleForm" :rules="rules3" ref="jlruleForm" label-width="100px" class="demo-ruleForm">
                  <!-- 头部文字 -->
                  <div class="toptext">
                    <p>工作简历</p>
                    <el-row style="text-align: right;float: right;">
                      <el-button type="primary" @click="Resumeclick()" disabled>增加</el-button>
                    </el-row>
                  </div>
                  <el-row>
                    <el-col :span="24">
                      <template>
                        <el-table :data="jlruleForm" height="250" border style="width: 100%">
                          <el-table-column prop="date" label="起时间" width="180">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.startTime" placement="top">
                                <el-date-picker style="width: 100%;" v-model="scope.row.startTime" type="date"
                                  placeholder="选择日期" disabled value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                </el-date-picker>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="name" label="止时间" width="180">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.endTime" placement="top">
                                <el-date-picker style="width: 100%;" v-model="scope.row.endTime" type="date"
                                  placeholder="选择日期" disabled value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                </el-date-picker>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="workUnit" label="工作单位">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.workUnit" placement="top">
                                <el-input v-model="scope.row.workUnit" placeholder="请输入工作单位" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="professional" label="从事专业">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.professional" placement="top">
                                <el-input v-model="scope.row.professional" placeholder="请输入从事专业" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="position" label="担任职务">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.position" placement="top">
                                <el-input v-model="scope.row.position" placeholder="请输入担任职务" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column label="操作" width="80">
                            <template slot-scope="scope">
                              <el-button type="danger" icon="el-icon-delete" circle size="small" disabled
                                @click="Resumedeleteclick(scope.row)">
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </template>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 教育背景 -->
        <div class="Form1">
          <el-row>
            <el-col :span="24">
              <div style="padding: 1rem;">
                <el-form :model="jyruleForm" :rules="rules" ref="jyruleForm" label-width="100px" class="demo-ruleForm">
                  <!-- 头部文字 -->
                  <div class="toptext">
                    <p>教育背景</p>
                    <el-row style="text-align: right;float: right;">
                      <el-button type="primary" @click="jybjclick()" disabled>增加</el-button>
                    </el-row>
                  </div>
                  <el-row>
                    <el-col :span="24">
                      <template>
                        <el-table :data="jyruleForm" height="250" border style="width: 100%">
                          <el-table-column prop="" label="起时间" width="180">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.startTime" placement="top">
                                <el-date-picker style="width: 100%;" v-model="scope.row.startTime" type="date"
                                  placeholder="选择日期" disabled value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                </el-date-picker>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="" label="止时间" width="180">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.endTime" placement="top">
                                <el-date-picker style="width: 100%;" v-model="scope.row.endTime" type="date"
                                  placeholder="选择日期" disabled value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                </el-date-picker>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="" label="毕业院校">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.graduateSchool"
                                placement="top">
                                <el-input v-model="scope.row.graduateSchool" placeholder="请输入毕业院校" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="subject" label="所学专业">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.subject" placement="top">
                                <el-input v-model="scope.row.subject" placeholder="请输入所学专业" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="degree" label="学位">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.degree" placement="top">
                                <el-input v-model="scope.row.degree" placeholder="请输入学位" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column label="操作" width="80">
                            <template slot-scope="scope">
                              <el-button type="danger" icon="el-icon-delete" circle size="small" disabled
                                @click="jydeleteclick(scope.row)">
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </template>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 教研科研项目经验 -->
        <div class="Form1">
          <el-row>
            <el-col :span="24">
              <div style="padding: 1rem;">
                <el-form :model="experienceruleForm" :rules="rules" ref="experienceruleForm" label-width="100px"
                  class="demo-ruleForm">
                  <!-- 头部文字 -->
                  <div class="toptext">
                    <p style="width: 8rem;">教研科研项目经验</p>
                    <el-row style="text-align: right;float: right;">
                      <el-button type="primary" @click="experienceclick()" disabled>增加</el-button>
                    </el-row>
                  </div>
                  <el-row>
                    <el-col :span="24">
                      <template>
                        <el-table :data="experienceruleForm" height="250" border style="width: 100%">
                          <el-table-column prop="projectName" label="项目名称" width="180">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.projectName" placement="top">
                                <el-input v-model="scope.row.projectName" placeholder="请输入项目名称" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="projectContent" label="项目描述" width="180">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.projectContent"
                                placement="top">
                                <el-input v-model="scope.row.projectContent" placeholder="请输入项目描述" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="researchTime" label="项目研究时间">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.researchTime" placement="top">
                                <el-date-picker style="width: 100%;" v-model="scope.row.researchTime" type="date"
                                  placeholder="选择日期" disabled value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                </el-date-picker>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="responsibleContent" label="负责内容">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.responsibleContent"
                                placement="top">
                                <el-input v-model="scope.row.responsibleContent" placeholder="请输入负责内容" disabled>
                                </el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column label="操作" width="80">
                            <template slot-scope="scope">
                              <el-button type="danger" icon="el-icon-delete" circle size="small" disabled
                                @click="experiencedeleteclick(scope.row)">
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </template>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 相关领域成果/荣誉 -->
        <div class="Form1">
          <el-row>
            <el-col :span="24">
              <div style="padding: 1rem;">
                <el-form :model="achievementstableData" :rules="rules" ref="achievementstableData" label-width="100px"
                  class="demo-ruleForm">
                  <!-- 头部文字 -->
                  <div class="toptext">
                    <p style="width: 10rem;">相关领域成果/荣誉</p>
                    <el-row style="text-align: right;float: right;">
                      <el-button type="primary" @click="achievementstableDataclick()" disabled>增加</el-button>
                    </el-row>
                  </div>
                  <el-row>
                    <el-col :span="24">
                      <template>
                        <el-table :data="achievementstableData" height="250" border style="width: 100%">
                          <el-table-column prop="date" label="成果名称/荣誉">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.achievements" placement="top">
                                <el-input v-model="scope.row.achievements" placeholder="请输入成果名称/荣誉" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="name" label="成果描述">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.content" placement="top">
                                <el-input v-model="scope.row.content" placeholder="请输入成果描述" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column prop="identity" label="参与身份">
                            <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="scope.row.identity" placement="top">
                                <el-input v-model="scope.row.identity" placeholder="请输入参与身份" disabled></el-input>
                              </el-tooltip>
                            </template>
                          </el-table-column>
                          <el-table-column label="操作" width="80">
                            <template slot-scope="scope">
                              <el-button type="danger" icon="el-icon-delete" circle size="small" disabled
                                @click="achievementsdeleteclick(scope.row)">
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </template>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 信用证明与承诺 -->
        <div class="Form1">
          <el-row>
            <el-col :span="24">
              <div style="padding: 1rem;">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                  <!-- 头部文字 -->
                  <div class="toptext">
                    <p style="width: 7rem;">信用证明与承诺</p>
                  </div>
                  <el-row>
                    <el-col :span="12" style="text-align: left;margin-bottom: 2rem;">
                      信用证明(需本人签署信用证明扫描后上传):
                    </el-col>

                    <el-col :span="12" style="text-align: left;margin-bottom: 2rem;">
                      承诺书(需本人签署承诺书扫描后上传)
                    </el-col>
                    <el-col :span="12" style="margin-bottom: 2rem;">
                      <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :limit="1"
                        :on-preview="handlePictureCardPreview" :on-success="creditSuccesst" disabled
                        :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-form-item label="原信用证明:" v-if="creditimgUrl">
                        <div class="demo-image__preview">
                          <el-image fit="contain" style="width: 100px; height: 100px" :src="creditimgUrl"
                            @click="imgdialogVisibleclick(creditimgUrl)" :preview-src-list="creditimgUrl">
                          </el-image>
                        </div>
                      </el-form-item>
                    </el-col>


                    <el-col :span="12" style="margin-bottom: 2rem;">
                      <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :limit="1"
                        :on-preview="handlePictureCardPreview" :on-success="undertakingSuccesst" disabled
                        :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-form-item label="原承诺书:" v-if="undertakingmgUrl">
                        <div class="demo-image__preview">
                          <el-image fit="contain" style="width: 100px; height: 100px" :src="undertakingmgUrl"
                            @click="imgdialogVisibleclick(undertakingmgUrl)" :preview-src-list="undertakingmgUrl">
                          </el-image>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>

      </el-col>

      <el-col :span="24" v-if="ruleForm.seeandexamine == ''">
        <el-row>
          <el-col :span="4" style="position: fixed;">
            <div class="elsteps">
              <el-steps direction="vertical" :active="elstepsactive">
                <el-step class="elstepstyle" title="基本信息" @click.native="elstepsclick(0)"></el-step>
                <el-step class="elstepstyle" title="专业领域" @click.native="elstepsclick(1)" v-if="zjandgr == 2"></el-step>
                <el-step class="elstepstyle" title="工作简历" @click.native="elstepsclick(2)" v-if="zjandgr == 2"></el-step>
                <el-step class="elstepstyle" title="教育背景" @click.native="elstepsclick(3)" v-if="zjandgr == 2"></el-step>
                <el-step class="elstepstyle" title="教研科研项目经验" @click.native="elstepsclick(4)" v-if="zjandgr == 2">
                </el-step>
                <el-step class="elstepstyle" title="相关领域成果" @click.native="elstepsclick(5)" v-if="zjandgr == 2">
                </el-step>
                <el-step class="elstepstyle" title="信用证明与承诺" @click.native="elstepsclick(6)" v-if="zjandgr == 2">
                </el-step>
              </el-steps>
            </div>
          </el-col>
          <el-col :span="20" style="margin-left: 14rem;height: 80vh;overflow-y:scroll;">
            <!-- 基本信息 -->
            <div class="Form1" v-if="elstepsactive==0">
              <el-row>
                <el-col :span="24">
                  <div style="padding: 1rem;">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p>个人信息</p>
                      </div>
                      <el-row>
                        <el-col :span="14">
                          <!-- 个人照片 -->
                          <el-form-item label="个人照片:" prop="avatar" label-width="150px !important">
                            <div v-if="ruleForm.userImageUrl != '' && !xiugai"
                              style="display: inline-block;width: 100%;text-align: left;">
                              <div class="demo-image__preview">
                                <el-image fit="contain" style="width: 100px; height: 100px"
                                  :src="ruleForm.userImageUrl">
                                </el-image>
                                <el-link type="primary" @click="modifyAvatar()" :disabled="ruleForm.examine=='1'">修改头像
                                </el-link>
                              </div>
                            </div>

                            <div v-else="ruleForm.userImageUrl == '' && xiugai"
                              style="display: inline-block;width: 100%;text-align: left;">
                              <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :limit="1"
                                :on-preview="handlePictureCardPreview" :on-success="handleSuccess"
                                :on-remove="handleRemove">
                                <i class="el-icon-plus"></i>
                              </el-upload>
                              <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="ruleForm.userImageUrl" alt="">
                              </el-dialog>
                              <el-link type="primary" @click="quxiao()">取消</el-link>
                            </div>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="专家姓名:" prop="nickName" label-width="150px !important">
                            <el-input v-model="ruleForm.nickName" placeholder="请输入专家姓名"
                              :disabled="ruleForm.examine=='1'"></el-input>
                          </el-form-item>
                        </el-col>

                        <el-col :span="12">
                          <el-form-item label="出生日期:" prop="birthday" label-width="150px !important">
                            <el-date-picker style="width: 100%;" v-model="ruleForm.birthday" type="date"
                              placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy 年 MM 月 dd 日"
                              :disabled="ruleForm.examine=='1'">
                            </el-date-picker>
                          </el-form-item>
                        </el-col>

                        <el-col :span="12">
                          <el-form-item label="性别:" prop="sex" label-width="150px !important">
                            <template>
                              <el-radio v-model="ruleForm.sex" label="0" :disabled="ruleForm.examine=='1'">男
                              </el-radio>
                              <el-radio v-model="ruleForm.sex" label="1" :disabled="ruleForm.examine=='1'">女
                              </el-radio>
                            </template>
                          </el-form-item>
                        </el-col>

                        <el-col :span="12">
                          <el-form-item label="手机号码:" prop="phonenumber" label-width="150px !important">
                            <el-input v-model="ruleForm.phonenumber" placeholder="请输入手机号码"
                              :disabled="ruleForm.examine=='1'"></el-input>
                          </el-form-item>
                        </el-col>

                        <el-col :span="12">
                          <el-form-item label="证件类型:" prop="certType" label-width="150px !important">
                            <template>
                              <el-select style="width: 100%;" v-model="ruleForm.certType" placeholder="请选择证件类型"
                                :disabled="ruleForm.examine=='1'">
                                <el-option label="身份证" value="0">
                                </el-option>
                              </el-select>
                            </template>
                          </el-form-item>
                        </el-col>

                        <el-col :span="12">
                          <el-form-item label="证件号码:" prop="certNo" label-width="150px !important">
                            <el-input v-model="ruleForm.certNo" placeholder="请输入证件号码" :disabled="ruleForm.examine=='1'">
                            </el-input>
                          </el-form-item>
                        </el-col>

                        <el-col :span="12">
                          <el-form-item label="是否在职:" prop="isonjob" label-width="150px !important">
                            <template>
                              <el-radio v-model="ruleForm.isonjob" label="1" :disabled="ruleForm.examine=='1'">是
                              </el-radio>
                              <el-radio v-model="ruleForm.isonjob" label="2" :disabled="ruleForm.examine=='1'">否
                              </el-radio>
                            </template>
                          </el-form-item>
                        </el-col>


                        <el-col :span="12">
                          <el-form-item label="工作年限:" prop="workyears" label-width="150px !important">
                            <el-input v-model="ruleForm.workyears" placeholder="请输入工作年限"
                              :disabled="ruleForm.examine=='1'">
                              <template slot="append">年</template>
                            </el-input>
                          </el-form-item>
                        </el-col>

                        <el-col :span="12">
                          <el-form-item label="通讯地址:" prop="postAddress" label-width="150px !important">
                            <el-input v-model="ruleForm.postAddress" placeholder="请输入您的通讯地址"
                              :disabled="ruleForm.examine=='1'"></el-input>
                          </el-form-item>
                        </el-col>

                        <el-col :span="24">
                          <el-form-item label="个人简介:" prop="remark" label-width="150px !important">
                            <el-input :disabled="ruleForm.examine=='1'" type="textarea" :rows="4" placeholder="请输入个人简介"
                              v-model="ruleForm.remark">
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p>机构信息</p>
                      </div>

                      <el-col :span="12">
                        <el-form-item label="机构名称:" prop="inputOrg" label-width="150px !important">
                          <el-input v-model="ruleForm.inputOrg" placeholder="请输入机构名称" disabled>
                          </el-input>
                        </el-form-item>
                      </el-col>

                      <!--                      <el-col :span="12">
                        <el-form-item label="机构类型:" prop="orgType" label-width="150px !important">
                          <template>
                            <el-select style="width: 100%;" v-model="ruleForm.orgType" placeholder="请选择机构类型">
                              <el-option v-for="item in orgType" :key="item.value" :label="item.label" :value="item.value">
                              </el-option>
                            </el-select>
                          </template>
                        </el-form-item>
                      </el-col> -->

                      <el-col :span="12">
                        <el-form-item label="所在部门:" prop="deptName" label-width="150px !important">
                          <el-input v-model="ruleForm.deptName" placeholder="请输入所在部门" :disabled="ruleForm.examine=='1'">
                          </el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12">
                        <el-form-item label="职务:" prop="job" label-width="150px !important">
                          <el-input v-model="ruleForm.job" placeholder="请输入职务" :disabled="ruleForm.examine=='1'">
                          </el-input>
                        </el-form-item>
                      </el-col>

<!--                      <el-col :span="12">
                        <el-form-item label="详细地址:" prop="address" label-width="150px !important">
                          <el-input v-model="ruleForm.address" placeholder="请输入详细地址" :disabled="ruleForm.examine=='1'">
                          </el-input>
                        </el-form-item>
                      </el-col> -->

                      <el-col :span="24">
                        <el-form-item style="text-align: right;">
                          <el-button @click="Form1draft('ruleForm')" style="width: 8rem;margin-right: 2rem;"
                            v-if="ruleForm.examine!='1'">保存为草稿
                          </el-button>
                          <!-- 编辑的下一步 -->
                          <el-button type="primary" @click="Form1submit('ruleForm')" style="width: 8rem;"
                            v-if="ruleForm.examine!='1'">{{zjandgr == 1 ? '保存' : '下一步'}}
                          </el-button>
                          <!-- 审核的下一步 -->
                          <el-button type="primary" @click="elstepsclick(1)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">下一步
                          </el-button>
                        </el-form-item>
                      </el-col>
                    </el-form>

                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- 专业领域 -->
            <div class="Form1" v-if="elstepsactive==1 && zjandgr == 2">
              <el-row>
                <el-col :span="24">
                  <div style="padding: 1rem;">
                    <el-form :model="Expertinformation" :rules="rules2" ref="Expertinformation"
                      label-width="140px !important" class="demo-ruleForm">
                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p>专业领域</p>
                      </div>

                      <el-col :span="20" style="display: flex;">
                        <div style="margin-left: 2.7rem;">
                          <span style="color: red;">*</span>
                          <span style="color: #78797d;">申报领域:</span>
                        </div>
                        <el-form-item label="" style="margin-left: -7.5rem;">
                          <template style="margin-left: 1.25rem;">
                            <el-select v-model="Professionalinformation" placeholder="请选择申报领域" value-key="options2"
                              multiple :multiple-limit="3" style="float: left;margin-left: 1rem;" name="options2">
                              <el-option v-for="item in options2" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                            <p style="margin: 0px;text-align: left;padding-left: 1rem;color: #7F7F7F;">
                              请先选择申报领域（最多选择3项）</p>
                          </template>
                        </el-form-item>
                      </el-col>

                      <el-col :span="14">
                        <el-form-item label="申报专家等级:" prop="reason">
                          <el-select v-model="Expertinformation.expertGrade" placeholder="请选择申报专家等级"
                            style="float: left;margin-left: 1rem;">
                            <el-option v-for="item in options3" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                          <el-popover placement="top" title="专家等级说明" width="300" trigger="hover" content="本平台专家等级分为金牌专家、银牌专家、铜牌专家三个等级;金牌专家等级最高、铜牌专家等级最低。申报各专家等级条件如下:
                                    1、金牌专家申报条件
                                    2、银牌专家申报条件
                                    3、铜牌专家申报条件:">
                            <p slot="reference" style="margin: 0px;text-align: left;padding-left: 1rem;color: #7F7F7F;">
                              查看申报专家等级条件
                            </p>
                          </el-popover>
                        </el-form-item>
                      </el-col>

                      <el-col :span="14">
                        <el-form-item label="申报专家等级理由:" prop="reason">
                          <el-input type="textarea" :rows="2" placeholder="请输入申报专家等级理由0/300"
                            v-model="Expertinformation.reason">
                          </el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="24">
                        <el-form-item label="证明材料" label-width="150px !important" prop="expertCertUrl">
                          <div class="elupload">
                            <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'"
                              :on-success="uploadSuccess" :on-error="errorUpload" :show-file-list="false">
                              <el-button size="small" type="primary" style="float: left;" @click="addupload()">点击上传
                              </el-button>
                              <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg .mp4且不超过1024MB
                              </div>
                            </el-upload>
                            <el-table :data="aptitudetableData" style="width: 100%" :border="true">
                              <el-table-column type="index" label="序号" width="50">
                              </el-table-column>

                              <el-table-column prop="certNo" label="材料名称">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.certNo" placement="top">
                                    <el-input v-model="scope.row.certNo" placeholder="请输入材料名称"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>

                              <el-table-column prop="fileName" label="附件" width="250">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.fileName" placement="top">
                                    <span>{{scope.row.fileName}}</span>
                                  </el-tooltip>
                                  <el-upload class="upload-demo" :action="$baseUrl.test+'/common/upload'"
                                    :on-success="uploadSuccess" multiple :on-error="errorUpload"
                                    :show-file-list="false">
                                    <el-button size="small" type="text" @click="Reupload(scope.row)">重新上传</el-button>
                                  </el-upload>
                                </template>
                              </el-table-column>

                              <el-table-column prop="" label="操作" scope="scope" width="90">
                                <template slot-scope="scope">
                                  <el-button type="danger" icon="el-icon-delete" circle size="small"
                                    @click="deleteclick(scope.row)">
                                  </el-button>
                                </template>
                              </el-table-column>
                            </el-table>
                          </div>
                        </el-form-item>
                      </el-col>

                      <el-col :span="24">
                        <el-form-item style="text-align: right;">
                          <el-button @click="Form2draft('ruleForm')" style="width: 8rem;margin-right: 2rem;"
                            v-if="ruleForm.examine != '1'">保存为草稿
                          </el-button>
                          <!-- 编辑的下一步 -->
                          <el-button type="primary" @click="Form2submit('Expertinformation')" style="width: 8rem;"
                            v-if="ruleForm.examine != '1'">提交下一步
                          </el-button>
                          <!-- 审核&&查看的上一步 -->
                          <el-button type="success" @click="elstepsclick(0)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">上一步
                          </el-button>
                          <!-- 审核&&查看的下一步 -->
                          <el-button type="primary" @click="elstepsclick(2)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">下一步
                          </el-button>
                        </el-form-item>
                      </el-col>
                    </el-form>

                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- 工作简历 -->
            <div class="Form1" v-if="elstepsactive==2 && zjandgr == 2">
              <el-row>
                <el-col :span="24">
                  <div style="padding: 1rem;">
                    <el-form :model="jlruleForm" :rules="rules3" ref="jlruleForm" label-width="100px"
                      class="demo-ruleForm">
                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p>工作简历</p>
                        <el-row style="text-align: right;float: right;">
                          <el-button type="primary" @click="Resumeclick()">增加</el-button>
                        </el-row>
                      </div>
                      <el-row>
                        <el-col :span="24">
                          <template>
                            <el-table :data="jlruleForm" height="250" border style="width: 100%">
                              <el-table-column prop="date" label="起时间" width="180">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.startTime" placement="top">
                                    <el-date-picker style="width: 100%;" v-model="scope.row.startTime" type="date"
                                      placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                    </el-date-picker>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="name" label="止时间" width="180">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.endTime" placement="top">
                                    <el-date-picker style="width: 100%;" v-model="scope.row.endTime" type="date"
                                      placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                    </el-date-picker>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="workUnit" label="工作单位">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.workUnit" placement="top">
                                    <el-input v-model="scope.row.workUnit" placeholder="请输入工作单位"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="professional" label="从事专业">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.professional"
                                    placement="top">
                                    <el-input v-model="scope.row.professional" placeholder="请输入从事专业"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="position" label="担任职务">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.position" placement="top">
                                    <el-input v-model="scope.row.position" placeholder="请输入担任职务"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column label="操作" width="80">
                                <template slot-scope="scope">
                                  <el-button type="danger" icon="el-icon-delete" circle size="small"
                                    @click="Resumedeleteclick(scope.row)">
                                  </el-button>
                                </template>
                              </el-table-column>
                            </el-table>
                          </template>
                        </el-col>
                      </el-row>
                      <el-col :span="24">
                        <el-form-item style="text-align: right;">
                          <el-button @click="Form3draft('jlruleForm')" style="width: 8rem;margin-right: 2rem;"
                            v-if="ruleForm.examine != '1'">保存为草稿
                          </el-button>
                          <el-button type="primary" @click="Form3submit('jlruleForm')" style="width: 8rem;"
                            v-if="ruleForm.examine != '1'">提交下一步
                          </el-button>
                          <el-button type="success" @click="elstepsclick(1)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">上一步
                          </el-button>
                          <el-button type="primary" @click="elstepsclick(3)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">下一步
                          </el-button>
                        </el-form-item>
                      </el-col>
                    </el-form>

                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- 教育背景 -->
            <div class="Form1" v-if="elstepsactive==3 && zjandgr == 2">
              <el-row>
                <el-col :span="24">
                  <div style="padding: 1rem;">
                    <el-form :model="jyruleForm" :rules="rules" ref="jyruleForm" label-width="100px"
                      class="demo-ruleForm">
                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p>教育背景</p>
                        <el-row style="text-align: right;float: right;">
                          <el-button type="primary" @click="jybjclick()">增加</el-button>
                        </el-row>
                      </div>
                      <el-row>
                        <el-col :span="24">
                          <template>
                            <el-table :data="jyruleForm" height="250" border style="width: 100%">
                              <el-table-column prop="startTime" label="起时间" width="180">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.startTime" placement="top">
                                    <el-date-picker style="width: 100%;" v-model="scope.row.startTime" type="date"
                                      placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                    </el-date-picker>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="endTime" label="止时间" width="180">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.endTime" placement="top">
                                    <el-date-picker style="width: 100%;" v-model="scope.row.endTime" type="date"
                                      placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                    </el-date-picker>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="graduateSchool" label="毕业院校">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.graduateSchool"
                                    placement="top">
                                    <el-input v-model="scope.row.graduateSchool" placeholder="请输入毕业院校"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="subject" label="所学专业">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.subject" placement="top">
                                    <el-input v-model="scope.row.subject" placeholder="请输入所学专业"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="degree" label="学位">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.degree" placement="top">
                                    <el-input v-model="scope.row.degree" placeholder="请输入学位"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column label="操作" width="80">
                                <template slot-scope="scope">
                                  <el-button type="danger" icon="el-icon-delete" circle size="small"
                                    @click="jydeleteclick(scope.row)">
                                  </el-button>
                                </template>
                              </el-table-column>
                            </el-table>
                          </template>
                        </el-col>
                      </el-row>
                      <el-col :span="24">
                        <el-form-item style="text-align: right;">
                          <el-button @click="Form4draft('jyruleForm')" style="width: 8rem;margin-right: 2rem;"
                            v-if="ruleForm.examine != '1'">保存为草稿
                          </el-button>
                          <el-button type="primary" @click="Form4submit('jyruleForm')" style="width: 8rem;"
                            v-if="ruleForm.examine != '1'">提交下一步
                          </el-button>
                          <el-button type="success" @click="elstepsclick(2)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">上一步
                          </el-button>
                          <el-button type="primary" @click="elstepsclick(4)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">下一步
                          </el-button>
                        </el-form-item>
                      </el-col>
                    </el-form>

                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- 教研科研项目经验 -->
            <div class="Form1" v-if="elstepsactive==4 && zjandgr == 2">
              <el-row>
                <el-col :span="24">
                  <div style="padding: 1rem;">
                    <el-form :model="experienceruleForm" :rules="rules" ref="experienceruleForm" label-width="100px"
                      class="demo-ruleForm">
                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p style="width: 8rem;">教研科研项目经验</p>
                        <el-row style="text-align: right;float: right;">
                          <el-button type="primary" @click="experienceclick()">增加</el-button>
                        </el-row>
                      </div>
                      <el-row>
                        <el-col :span="24">
                          <template>
                            <el-table :data="experienceruleForm" height="250" border style="width: 100%">
                              <el-table-column prop="projectName" label="项目名称" width="180">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.projectName"
                                    placement="top">
                                    <el-input v-model="scope.row.projectName" placeholder="请输入项目名称"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="projectContent" label="项目描述" width="180">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.projectContent"
                                    placement="top">
                                    <el-input v-model="scope.row.projectContent" placeholder="请输入项目描述"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="researchTime" label="项目研究时间">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.researchTime"
                                    placement="top">
                                    <el-date-picker style="width: 100%;" v-model="scope.row.researchTime" type="date"
                                      placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy年MM月dd日">
                                    </el-date-picker>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="responsibleContent" label="负责内容">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.responsibleContent"
                                    placement="top">
                                    <el-input v-model="scope.row.responsibleContent" placeholder="请输入负责内容"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column label="操作" width="80">
                                <template slot-scope="scope">
                                  <el-button type="danger" icon="el-icon-delete" circle size="small"
                                    @click="experiencedeleteclick(scope.row)">
                                  </el-button>
                                </template>
                              </el-table-column>
                            </el-table>
                          </template>
                        </el-col>
                      </el-row>
                      <el-col :span="24">
                        <el-form-item style="text-align: right;">
                          <el-button @click="Form5draft()" style="width: 8rem;margin-right: 2rem;"
                            v-if="ruleForm.examine != '1'">保存为草稿
                          </el-button>
                          <el-button type="success" @click="elstepsclick(3)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">上一步
                          </el-button>
                          <el-button type="primary" @click="elstepsclick(5)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">下一步
                          </el-button>
                          <el-button type="primary" @click="Form5submit('experienceruleForm')" style="width: 8rem;"
                            v-if="ruleForm.examine != '1'">提交下一步
                          </el-button>
                        </el-form-item>
                      </el-col>
                    </el-form>

                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- 相关领域成果/荣誉 -->
            <div class="Form1" v-if="elstepsactive==5 && zjandgr == 2">
              <el-row>
                <el-col :span="24">
                  <div style="padding: 1rem;">
                    <el-form :model="achievementstableData" :rules="rules" ref="achievementstableData"
                      label-width="100px" class="demo-ruleForm">
                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p style="width: 10rem;">相关领域成果/荣誉</p>
                        <el-row style="text-align: right;float: right;">
                          <el-button type="primary" @click="achievementstableDataclick()">增加</el-button>
                        </el-row>
                      </div>
                      <el-row>
                        <el-col :span="24">
                          <template>
                            <el-table :data="achievementstableData" height="250" border style="width: 100%">
                              <el-table-column prop="date" label="成果名称/荣誉">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.achievements"
                                    placement="top">
                                    <el-input v-model="scope.row.achievements" placeholder="请输入成果名称/荣誉"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="name" label="成果描述">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.content" placement="top">
                                    <el-input v-model="scope.row.content" placeholder="请输入成果描述"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column prop="identity" label="参与身份">
                                <template slot-scope="scope">
                                  <el-tooltip class="item" effect="dark" :content="scope.row.identity" placement="top">
                                    <el-input v-model="scope.row.identity" placeholder="请输入参与身份"></el-input>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column label="操作" width="80">
                                <template slot-scope="scope">
                                  <el-button type="danger" icon="el-icon-delete" circle size="small"
                                    @click="achievementsdeleteclick(scope.row)">
                                  </el-button>
                                </template>
                              </el-table-column>
                            </el-table>
                          </template>
                        </el-col>
                      </el-row>
                      <el-col :span="24">
                        <el-form-item style="text-align: right;">
                          <el-button @click="Form6draft()" style="width: 8rem;margin-right: 2rem;"
                            v-if="ruleForm.examine != '1'">保存为草稿
                          </el-button>
                          <el-button type="success" @click="elstepsclick(4)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">上一步
                          </el-button>

                          <el-button type="primary" @click="elstepsclick(6)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">下一步
                          </el-button>
                          <el-button type="primary" @click="Form6submit('achievementstableData')" style="width: 8rem;"
                            v-if="ruleForm.examine != '1'">提交下一步
                          </el-button>
                        </el-form-item>
                      </el-col>
                    </el-form>

                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- 信用证明与承诺 -->
            <div class="Form1" v-if="elstepsactive==6 && zjandgr == 2">
              <el-row>
                <el-col :span="24">
                  <div style="padding: 1rem;">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                      <!-- 头部文字 -->
                      <div class="toptext">
                        <p style="width: 7rem;">信用证明与承诺</p>
                      </div>
                      <el-row>
                        <el-col :span="12" style="text-align: left;margin-bottom: 2rem;">
                          信用证明(需本人签署信用证明扫描后上传):
                        </el-col>

                        <el-col :span="12" style="text-align: left;margin-bottom: 2rem;">
                          承诺书(需本人签署承诺书扫描后上传)
                        </el-col>
                        <el-col :span="12" style="margin-bottom: 2rem;">
                          <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :limit="1"
                            :on-preview="handlePictureCardPreview" :on-success="creditSuccesst"
                            :on-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                          </el-upload>
                          <el-form-item label="原信用证明:" v-if="creditimgUrl">
                            <div class="demo-image__preview">
                              <el-image fit="contain" style="width: 100px; height: 100px" :src="creditimgUrl"
                                :preview-src-list="creditimgUrl">
                              </el-image>
                            </div>
                          </el-form-item>
                        </el-col>


                        <el-col :span="12" style="margin-bottom: 2rem;">
                          <el-upload :action="$baseUrl.test+'/common/upload'" list-type="picture-card" :limit="1"
                            :on-preview="handlePictureCardPreview" :on-success="undertakingSuccesst"
                            :on-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                          </el-upload>
                          <el-form-item label="原承诺书:" v-if="undertakingmgUrl">
                            <div class="demo-image__preview">
                              <el-image fit="contain" style="width: 100px; height: 100px" :src="undertakingmgUrl"
                                :preview-src-list="undertakingmgUrl">
                              </el-image>
                            </div>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-col :span="24">
                        <el-form-item style="text-align: right;">
                          <el-button @click="Form7draft('ruleForm')" style="width: 8rem;margin-right: 2rem;"
                            v-if="ruleForm.examine != '1'">保存为草稿
                          </el-button>
                          <el-button type="success" @click="elstepsclick(5)" style="width: 8rem;"
                            v-if="ruleForm.examine == '1'">上一步
                          </el-button>
                          <el-button type="primary" @click="Form7submit()" style="width: 8rem;"
                            v-if="ruleForm.examine != '1'">提交
                          </el-button>
                        </el-form-item>
                      </el-col>
                    </el-form>
                  </div>
                </el-col>
              </el-row>
            </div>

          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <!-- 专家审核弹窗 -->
    <el-dialog title="审核" :visible.sync="zjdialogVisible" width="40%" :before-close="handleClose">
      <el-form :model="zjruleForm" :rules="zjrules" ref="zjruleForm" label-width="100px !important"
        class="demo-ruleForm">
        <el-form-item label="审核：" prop="resource" style="text-align: left;">
          <el-radio-group v-model="zjruleForm.resource">
            <el-radio label="通过"></el-radio>
            <el-radio label="未通过"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="理由：" prop="desc">
          <el-input type="textarea" v-model="zjruleForm.desc" placeholder="请输入审核理由"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('zjruleForm')">确 定</el-button>
          <el-button @click="zjdialogVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 图片查看弹窗 -->
    <el-dialog title="提示" :visible.sync="imgdialogVisible" width="30%" :before-close="handleClose">
      <el-image style="width: 100%; height: 100%" :src="imgdialogVisibleurl" fit="fill"></el-image>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgdialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import $ from 'jquery';
  export default {
    // 这里原来是name: 'commonComponents',但是会报错。
    name: '',
    components: {
      // commonComponents
    },
    props: ['expertId', 'xiayibu', 'examine', 'zjandgr'],
    data: function() {
      return {
        // 图片放大查看弹窗
        imgdialogVisible: false,
        imgdialogVisibleurl: '',
        // 申报领域二级
        Professionalinformation: [],
        orgType: [{
          value: '0',
          label: '高校及事业机构'
        }, {
          value: '1',
          label: '企业机构'
        }, {
          value: '2',
          label: '政府机关'
        }],
        subjectType: [],
        // 专家审核弹窗
        zjdialogVisible: false,
        // 增加工作简历
        ResumeclickdialogVisible: false,
        // 通过||不通过&&理由
        zjruleForm: {
          resource: '',
          desc: ''
        },
        // 工作简历表格
        jlruleForm: [],
        // 教育背景表格
        jyruleForm: [],
        // 教研科研项目经验表格
        experienceruleForm: [],
        // 相关领域成果/荣誉
        achievementstableData: [],
        // 专家审核提示
        zjrules: {
          resource: [{
            required: true,
            message: '请选择通过或者不通过',
            trigger: 'change'
          }],
          desc: [{
            required: true,
            message: '请输入审核理由',
            trigger: 'blur'
          }]
        },
        options1: [],
        options2: [],
        // 字段名：expertGrade，0金牌专家、1银牌专家、2铜牌专家，这个没有字典
        options3: [{
          value: '0',
          label: '金牌专家'
        }, {
          value: '1',
          label: '银牌专家'
        }, {
          value: '2',
          label: '铜牌专家'
        }, ],
        titleGrade: [],
        xiugai: false,
        disabled: false,
        thisexpertId: "",
        clearImg: true,
        elstepsactive: 0,
        dialogVisible: false,
        // 用户信息
        ruleForm: {
          // 1=审核、0=查看
          seeandexamine: '',
          examine: '',
          // 用户头像id
          avatar: '',
          // 用户头像地址
          userImageUrl: '',
          // 专家姓名
          nickName: '',
          // 性别
          sex: '',
          // 出生日期
          birthday: '',
          // 手机号码
          phonenumber: '',
          // 证件类型
          certType: '',
          // 证件号码
          certNo: '',
          // 通讯地址
          postAddress: '',
          // 个人简介
          remark: '',
          // 机构名称
          inputOrg: '',
          // 机构类型
          orgType: '',
          // 所在部门
          deptName: '',
          // 职务
          job: '',
          // 是否在职
          isonjob: '',
          // 工作年限
          workyears: '',
          // 详细地址
          address: '',
        },
        // 证明材料
        aptitudetableData: [],
        // 当前重新证明材料ID
        uploadSuccessID: '',
        // 申报领域一级
        Professional: "",

        // 专家信息
        Expertinformation: {
          // 用户ID已通过进入事件获取
          userId: this.expertId.userId,
          // 专家编号已通过进入事件或者提交事件获取
          expertNo: '',
          // 专家ID
          expertId: '',
          // 专家证号
          expertCertNo: '',
          // 专家证书路径，这里为文件上传后返回的文件ID，英文逗号隔开，如：1,2,3
          expertCertUrl: '',
          // 申报领域理由
          reason: '',
          // 专业技术职称（0中级 1高级 2其他）
          titleGrade: '',
          // 专业技术职称名称
          titleName: '',
          // 信用证明路径(需本人签署信用证明扫描后上传)，这里为文件上传后返回的文件ID，英文逗号隔开，如：1,2,3
          credit: '',
          // 承诺书路径(需本人签署承诺书扫描后上传)，这里为文件上传后返回的文件ID，英文逗号隔开，如：1,2,3
          undertaking: '',
          // 所属领域（0心理学领域、1课程体系改革领域、2产品研发领域、3、创新创业领域）
          // field: [],
          // 专家等级（0不合格专家、1普通专家、2银牌专家、3金牌专家）
          expertGrade: '',
          // 是否推荐（0否，1是）
          isRecommend: '',
          // 专家积分
          expertScore: '',
          // 政治面貌
          politicalStatus: '',
          // 专家学历
          education: '',
          // 毕业院校
          graduateSchool: '',
          // 所学专业
          subject: '',
          // 入学时间
          admissionTime: '',
          // 毕业时间
          graduationTime: '',
          // 行政职务
          adminPost: '',
          // 单位电话
          workTele: '',
          // 备用电话
          standbyTele: '',
          // 单位地址
          workAddress: '',
          // 邮编
          postalCode: '',
          // 执业资格
          register: '',
          // 获取时间
          getTime: '',
          // 从事专业
          professional: '',
          // 从事专业开始时间
          proBegin: '',
          // 从事专业结束时间
          proEnd: '',
          // 奖励情况
          honers: '',
          // 职称评定时间
          titleValidTime: '',
          // 工作经验
          workExperience: '',
          // 专家所属行政区划
          areaId: '',
          // 申报专家等级
          expertGrade: '',
          // 申报领域二级
          Professionalinformation: [],
        },
        // 原来的职称证书或推荐函
        titleCertimgUrl: '',
        rules: {
          // 用户头像ID
          avatar: [{
            required: true,
            message: '请选择个人照片',
            trigger: 'blur'
          }],
          // 专家姓名
          nickName: [{
            required: true,
            message: '请输入专家姓名',
            trigger: 'blur'
          }],
          // 性别
          sex: [{
            required: true,
            message: '请选择性别',
            trigger: 'blur'
          }],
          // 出生日期
          birthday: [{
            required: true,
            message: '请选择出生日期',
            trigger: 'blur'
          }],
          // 手机号码
          phonenumber: [{
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          }],
          // 证件类型
          certType: [{
            required: true,
            message: '请选择证件类型',
            trigger: 'blur'
          }],
          // 证件号码
          certNo: [{
            required: true,
            message: '请输入证件号码',
            trigger: 'blur'
          }],
          // 通讯地址
          postAddress: [{
            required: true,
            message: '请输入通讯地址',
            trigger: 'blur'
          }],
          // 个人简历
          remark: [{
            required: true,
            message: '请输入个人简历',
            trigger: 'blur'
          }],
          // 机构名称
          inputOrg: [{
            required: true,
            message: '请输入机构名称',
            trigger: 'blur'
          }],
          // 机构类型
          orgType: [{
            required: true,
            message: '请选择机构类型',
            trigger: 'change'
          }],
          // 所在部门
          deptName: [{
            required: true,
            message: '请输入所在部门',
            trigger: 'blur'
          }],
          // 职务
          job: [{
            required: true,
            message: '请输入职务',
            trigger: 'blur'
          }],
          // 是否在职
          isonjob: [{
            required: true,
            message: '请选择是否在职',
            trigger: 'blur'
          }],
          // 工作年限
          workyears: [{
            required: true,
            message: '请输入工作年限',
            trigger: 'blur'
          }],
          // 详细地址
          address: [{
            required: true,
            message: '请输入详细地址',
            trigger: 'blur'
          }]
        },
        rules2: {
          // 申报领域理由
          reason: [{
            required: true,
            message: '请选择申报专家等级',
            trigger: 'blur'
          }],
        }
      };
    },
    methods: {
      // 审核时文件名称点击预览
      fileNameclick(item){
        window.open(item.fileUrl)
      },

      // 图片点击事件
      imgdialogVisibleclick(e) {
        this.imgdialogVisible = true;
        this.imgdialogVisibleurl = e;
      },
      // 上传点击事件
      addupload() {
        this.uploadSuccessID = ""
      },
      // 重新上传点击事件
      Reupload(e) {
        this.uploadSuccessID = e.fileId
      },
      // 删除点击事件
      deleteclick(e) {
        console.log(e.certUrl);
        // 循环二级附件
        for (let i = 0; i < this.aptitudetableData.length; i++) {
          for (var key in this.aptitudetableData[i]) {
            // 用新ID替换旧ID
            if (this.aptitudetableData[i][key] == e.certUrl) {
              this.aptitudetableData.splice(i, 1);
            }
          }
        }
      },
      // 证明材料上传成功
      uploadSuccess(response, file, fileList) {
        // 判断是否上传成功
        if (response.code == 200) {
          // 判断是否为重新上传
          if (this.uploadSuccessID != "") {
            // 循环二级附件
            for (let i = 0; i < this.aptitudetableData.length; i++) {
              for (var key in this.aptitudetableData[i]) {
                // 用新ID替换旧ID
                if (this.aptitudetableData[i][key] == this.uploadSuccessID) {
                  // 证书编号
                  this.aptitudetableData[i].certUrl = response.data.certUrl
                  // 附件名称
                  this.aptitudetableData[i].fileName = response.data.fileName
                  // 获取当前上传的文件ID
                  this.Expertinformation.expertCertUrl += response.data.fileId
                }
              }
            }
            // 清空ID
            this.uploadSuccessID == ""
          } else {
            // 获取当前上传的文件ID
            this.Expertinformation.expertCertUrl += response.data.fileId
            // 获取二级资源信息
            this.aptitudetableData.push({
              // 证明编号
              certNo: response.data.certNo,
              // 颁发单位
              // issuingUnit: response.data.issuingUnit,
              // 发证日期
              // issueDate: response.data.issueDate,
              // 证书路径，这里为文件上传后返回的文件ID，英文逗号隔开，如：1,2,3
              certUrl: response.data.fileId,
              // 附件名称
              fileName: response.data.fileName,
              // 专家ID
              expertId: this.thisexpertId,
            })
          }
        } else {
          this.$message({
            offset: 100,
            showClose: true,
            message: response.msg,
            type: 'error'
          });
        }
      },

      // 信用证明上传成功
      creditSuccesst(response, file, fileList) {
        if (response.code == 200) {
          this.Expertinformation.credit = response.data.fileId;
        } else {
          this.$message({
            offset: 100,
            showClose: true,
            message: response.msg,
            type: 'error'
          });
        }

      },

      // 承诺书上传成功
      undertakingSuccesst(response, file, fileList) {
        if (response.code == 200) {
          this.Expertinformation.undertaking = response.data.fileId;
        } else {
          this.$message({
            offset: 100,
            showClose: true,
            message: response.msg,
            type: 'error'
          });
        }

      },


      // 工作简历添加点击事件
      Resumeclick() {
        this.jlruleForm.push({
          // 专家ID
          expertId: this.thisexpertId,
          startTime: "",
          endTime: "",
          workUnit: "",
          professional: "",
          position: ""
        });
      },

      // 教育背景添加点击事件
      jybjclick() {
        this.jyruleForm.push({
          // 专家ID
          expertId: this.thisexpertId,
          startTime: "",
          endTime: "",
          graduateSchool: "",
          subject: "",
          degree: ""
        });
      },

      // 教研科研项目经验添加点击事件
      experienceclick() {
        this.experienceruleForm.push({
          // 专家ID
          expertId: this.thisexpertId,
          projectName: "",
          projectContent: "",
          researchTime: "",
          responsibleContent: "",
        });
      },

      // 相关领域成果/荣誉添加点击事件
      achievementstableDataclick() {
        this.achievementstableData.push({
          // 专家ID
          expertId: this.thisexpertId,
          achievements: "",
          content: "",
          identity: "",
        });
      },

      // 工作简历删除点击事件
      Resumedeleteclick(e) {
        // 根据工作单位删除
        for (let i = 0; i < this.jlruleForm.length; i++) {
          for (var key in this.jlruleForm[i]) {
            if (this.jlruleForm[i][key] == e.workUnit) {
              this.jlruleForm.splice(i, 1);
            }
          }
        }
      },

      // 教育背景删除事件
      jydeleteclick(e) {
        // 根据毕业院校删除
        for (let i = 0; i < this.jyruleForm.length; i++) {
          for (var key in this.jyruleForm[i]) {
            if (this.jyruleForm[i][key] == e.graduateSchool) {
              this.jyruleForm.splice(i, 1);
            }
          }
        }
      },

      // 专家教研科研项目经验删除事件
      experiencedeleteclick(e) {
        // 根据毕业院校删除
        for (let i = 0; i < this.experienceruleForm.length; i++) {
          for (var key in this.experienceruleForm[i]) {
            if (this.experienceruleForm[i][key] == e.projectName) {
              this.experienceruleForm.splice(i, 1);
            }
          }
        }
      },

      // 专家教研科研项目经验删除事件
      achievementsdeleteclick(e) {
        // 根据毕业院校删除
        for (let i = 0; i < this.achievementstableData.length; i++) {
          for (var key in this.achievementstableData[i]) {
            if (this.achievementstableData[i][key] == e.achievements) {
              this.achievementstableData.splice(i, 1);
            }
          }
        }
      },

      // 审核弹窗
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      // 专家审核点击事件
      toexamineclick() {
        this.zjdialogVisible = true;
      },
      // 提交审核
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 提交审核
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/expert',
              data: {
                // 专家ID
                "expertId": this.thisexpertId,
                // 2有效 3退回
                "status": this.zjruleForm.resource == "通过" ? 2 : 3,
                "auditReason": this.zjruleForm.desc,
              },
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  offset: 100,
                  message: res.data.msg,
                  type: 'success'
                });
                this.zjdialogVisible = false;
                // 返回专家申请审核列表
                this.returnclick();
              } else {
                this.$message({
                  offset: 100,
                  message: res.data.msg,
                  type: 'error'
                });
                this.zjdialogVisible = false;
              }
            }).catch(error => {

              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      // 返回专家申请审核列表
      returnclick() {
        this.$router.push('/Expertdata');
      },
      // 修改头像点击事件
      modifyAvatar() {
        this.xiugai = true;
      },
      // 取消修改
      quxiao() {
        this.xiugai = false;
      },
      elstepsclick(e) {
        this.elstepsactive = e;
        if (e == 1) {
          this.Form1submit();
        }
      },
      // 第一表提交事件
      Form1submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let Userparams = {
              // 用户头像id
              "avatar": this.ruleForm.avatar,
              // 用户账号
              "userName": this.ruleForm.userName,
              //  用户id
              "userId": this.expertId.userId ? this.expertId.userId : localStorage.userId,
              // 专家姓名
              "nickName": this.ruleForm.nickName,
              // 出生日期
              "birthday": this.ruleForm.birthday,
              // 性别
              "sex": this.ruleForm.sex,
              // 手机号码
              "phonenumber": this.ruleForm.phonenumber,
              // 所在机构ID
              "orgId": this.ruleForm.orgId,
              // 机构类型，0=高校及事业机构,1=企业机构,2=政府机关
              "orgType": this.ruleForm.orgType,
              // 机构名称
              "inputOrg": this.ruleForm.inputOrg,
              // 详细地址
              "address": this.ruleForm.address,
              // 部门名称
              "deptName": this.ruleForm.deptName,
              // 职务
              "job": this.ruleForm.job,
              // 是否在职
              "isonjob": this.ruleForm.isonjob,
              // 工作年限
              "workyears": this.ruleForm.workyears,
              // 证件类型
              "certType": this.ruleForm.certType,
              // 证件号码
              "certNo": this.ruleForm.certNo,
              // 通讯地址
              "postAddress": this.ruleForm.postAddress,
              // 个人简历
              "remark": this.ruleForm.remark,
              // 身份证照片路径
              "certUrl": this.ruleForm.certUrl,
              // 机构名称
              "inputOrg": this.ruleForm.inputOrg,
            }

            let userIdxx = {
              "expertId": this.thisexpertId,
              "status": "1"
            }
            this.$axios({ // 将专家状态改为待审核
              method: 'put',
              url: this.$baseUrl.test + '/api/expert',
              data: userIdxx,
            }).then((res) => {
              //
            }).catch(error => {

              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });

            // 修改用户信息
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/user/',
              data: Userparams,
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: 'success',
                  offset: 100,
                });
                if (this.zjandgr == 2) { // 提交成功之后进入下一步
                  this.elstepsactive = 1;
                }
              }
            }).catch(error => {

              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 第一表单存草稿
      Form1draft(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let Userparams = {
              // 用户头像id
              "avatar": this.ruleForm.avatar,
              // 用户账号
              "userName": this.ruleForm.userName,
              //  用户id
              "userId": this.expertId.userId ? this.expertId.userId : localStorage.userId,
              // 专家姓名
              "nickName": this.ruleForm.nickName,
              // 出生日期
              "birthday": this.ruleForm.birthday,
              // 性别
              "sex": this.ruleForm.sex,
              // 手机号码
              "phonenumber": this.ruleForm.phonenumber,
              // 所在机构ID
              "orgId": this.ruleForm.orgId,
              // 部门名称
              "deptName": this.ruleForm.deptName,
              // 职务
              "job": this.ruleForm.job,
              // 是否在职
              "isonjob": this.ruleForm.isonjob,
              // 工作年限
              "workyears": this.ruleForm.workyears,
              // 证件类型
              "certType": this.ruleForm.certType,
              // 证件号码
              "certNo": this.ruleForm.certNo,
              // 通讯地址
              "postAddress": this.ruleForm.postAddress,
              // 个人简历
              "remark": this.ruleForm.remark,
              // 身份证照片路径
              "certUrl": this.ruleForm.certUrl
            }

            // 修改用户信息
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/user/',
              data: Userparams,

            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: 'success',
                  offset: 100,
                });
              } else {
                this.$message({
                  offset: 100,
                  message: res.data.msg,
                  type: 'error'
                });
              }
              if (this.zjandgr != 1) {
                this.elstepsactive = 1;
              }
            }).catch(error => {

              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      // 第二表提交事件
      Form2submit(formName) {
        // 申报领域二级
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.Professionalinformation.length == 0) {
              this.$message({
                offset: 100,
                message: "请选择申报领域!",
                type: 'error'
              });
            } else {
              let addExpertSubject = [];
              this.Expertinformation.Professionalinformation = this.Professionalinformation;
              for (let i in this.Expertinformation.Professionalinformation) {
                addExpertSubject.push({
                  // 专家ID
                  "expertId": this.thisexpertId ? this.thisexpertId : localStorage.userId,
                  // 专业二级ID
                  "subjectTypeId": this.Expertinformation.Professionalinformation[i],
                });
              }
              // 提交专家申报领域
              this.$axios({
                method: 'post',
                url: this.$baseUrl.test + "/api/expertSubject/addExpertSubject",
                data: addExpertSubject

              }).then((res) => {
                console.log(res.data);
              }).catch(error => {

                this.$message({
                  offset: 100,
                  message: error.msg,
                  type: 'error'
                });
              });
              // 保存证明材料
              if (this.aptitudetableData.length > 0) {
                this.$axios({
                  method: 'put',
                  url: this.$baseUrl.test + '/api/aptitude',
                  data: this.aptitudetableData,

                }).then((res) => {
                  if (res.data.code == 200) {
                    this.elstepsactive = 2;
                    this.$message({
                      message: res.data.msg,
                      type: 'success',
                      offset: 100
                    });
                  } else {
                    this.$message({
                      offset: 100,
                      message: res.data.msg,
                      type: 'error'
                    });
                  }
                }).catch(error => {

                  this.$message({
                    offset: 100,
                    message: error.msg,
                    type: 'error'
                  });
                });
              } else {
                this.$message({
                  offset: 100,
                  message: "请上传材料证明！",
                  type: 'error'
                });
              }
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      // 第三步存草稿
      Form2draft() {
        this.Form2submit();
      },

      // 第三表单提交事件
      Form3submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && this.jlruleForm.length > 0) {
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/resume',
              data: this.jlruleForm,

            }).then((res) => {
              if (res.data.code == 200) {
                // 提交成功之后进入下一步
                this.elstepsactive = 3;
                this.$message({
                  message: res.data.msg,
                  type: 'success',
                  offset: 100,
                });
              } else {
                this.$message({
                  offset: 100,
                  message: res.data.msg,
                  type: 'error'
                });
              }
            }).catch(error => {

              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            this.$message({
              offset: 100,
              message: "请添加工作简历！",
              type: 'error'
            });
            return false;
          }
        });
      },

      // 第三步存草稿
      Form3draft() {
        this.Form3submit();
      },

      // 第四步 专家教育背景接口
      Form4submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && this.jyruleForm.length > 0) {
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/education',
              data: this.jyruleForm,

            }).then((res) => {
              if (res.data.code == 200) {
                this.elstepsactive = 4;
                this.$message({
                  message: res.data.msg,
                  type: 'success',
                  offset: 100,
                });
              } else {
                this.$message({
                  offset: 100,
                  message: res.data.msg,
                  type: 'error'
                });
              }
            }).catch(error => {

              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            this.$message({
              offset: 100,
              message: "请添加教育背景！",
              type: 'error'
            });
            return false;
          }
        });
      },

      // 第四步存草稿
      Form4draft() {
        this.Form4submit();
      },


      // 第五步 保存专家教研科研项目经验接口
      Form5submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && this.experienceruleForm.length > 0) {
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/researchProject',
              data: this.experienceruleForm,

            }).then((res) => {
              if (res.data.code == 200) {
                this.elstepsactive = 5;
                this.$message({
                  message: res.data.msg,
                  type: 'success',
                  offset: 100,
                });
              } else {
                this.$message({
                  offset: 100,
                  message: res.data.msg,
                  type: 'error'
                });
              }
            }).catch(error => {

              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            this.$message({
              offset: 100,
              message: "请添加教研科研项目经验！",
              type: 'error'
            });
            return false;
          }
        });
      },
      // 第四步存草稿
      Form5draft() {
        this.Form5submit();
      },

      // 第六步 保存专家相关领域成果接口
      Form6submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && this.achievementstableData.length > 0) {
            this.$axios({
              method: 'put',
              url: this.$baseUrl.test + '/api/relatedFields',
              data: this.achievementstableData,

            }).then((res) => {
              if (res.data.code == 200) {
                this.elstepsactive = 6;
                this.$message({
                  message: res.data.msg,
                  type: 'success',
                  offset: 100,
                });
              } else {
                this.$message({
                  offset: 100,
                  message: res.data.msg,
                  type: 'error'
                });
              }
            }).catch(error => {

              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          } else {
            this.$message({
              offset: 100,
              message: "请添加相关领域成果！",
              type: 'error'
            });
            return false;
          }
        });
      },
      // 第六步存草稿
      Form6draft() {
        this.Form6submit();
      },
      // 第七步提交信用证明与承诺
      Form7submit() {
        this.Expertinformation.status = 1;
        // 保存信用证明与承诺
        this.Form7draft();
        let status = {
          "expertId": this.thisexpertId,
          "status": "1"
        }
        // 专家送审
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/api/expert',
          data: status,

        }).then((res) => {
          if (res.data.code == 200) {
            this.$alert(
              '<h4>您的联盟专家申请 已提交审核</h4><br><h5>恭喜，申报提交成功，我们将会在3个工作日内审核完成，请您及时关注平台消息提醒</h5>',
              '', {
                type: 'success',
                center: true,
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定'
              });
          } else {
            this.$message({
              offset: 100,
              message: "提交失败！" + res.data.msg,
              type: 'error'
            });
          }
        }).catch(error => {

          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 第七步保存信用证明与承诺
      Form7draft() {
        this.$axios({
          method: 'put',
          url: this.$baseUrl.test + '/api/expert',
          data: this.Expertinformation,

        }).then((res) => {
          if (res.data.code == 200) {
            // 提交成功
            this.$message({
              message: res.data.msg,
              type: 'success',
              offset: 100
            });
          } else {
            this.$message({
              offset: 100,
              message: res.data.msg,
              type: 'error'
            });
          }
        }).catch(error => {

          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 文件删除事件
      handleRemove(file) {
        console.log(file);
      },
      // 文件上传时的钩子
      handleProgress(file) {
        console.log("1" + file.url);
      },
      // 照片放大事件
      handlePictureCardPreview(file) {
        this.ruleForm.userImageUrl = file.url;
        this.dialogVisible = true;
      },
      // 文件上传成功时触发
      handleSuccess(response, file, fileList) {
        // 获取用户头像ID
        this.ruleForm.avatar = response.data.fileId;
      },
      // 文件下载
      handleDownload(file) {
        console.log(file);
      },


    },
    // 页面加载完毕触发
    mounted() {
      // userId是专家列表跳转过来的专家用户ID
      this.examine = this.expertId.userId ? 1 : 0;
      // this.ruleForm.examine = this.expertId.userId ? 1 : 0;
      // 如果是从专家个人信息、用户个人信息点击进入的
      if (this.xiayibu == 1) {
        this.ruleForm.seeandexamine = '';
      } else {
        this.ruleForm.seeandexamine = 1;
      }
      if (this.examine == "no") {
        this.ruleForm.seeandexamine = '';
      } else {
        this.ruleForm.seeandexamine = this.examine;
      }
      // 审核过来的
      if (this.expertId.seeandexamine == 2) {
        this.ruleForm.seeandexamine = '2'
        // 指定左侧导航栏
        localStorage.setItem('BaractiveIndex', "/Expertdata");
        // 定义当前头部导航栏
        localStorage.setItem('activeIndex', "1");
      }
      // 指定头部下标
      localStorage.setItem('activeIndex', 4);
      //如果跳转过来的用户id没有值就去缓存里面的用户ID
      let userId = this.expertId.userId ? this.expertId.userId : localStorage.userId;
      // 根据用户ID获取专家详细信息接口
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/expert/getExpertByUserId/' + userId,
        params: '',
      }).then((res) => {
        if (res.data.data) {
          this.thisexpertId = res.data.data.expertId ? res.data.data.expertId : 0; // 获取专家ID,如果有专家ID的话
          this.Expertinformation.expertNo = res.data.data.expertNo ? res.data.data.expertNo : 0; // 获取专家编号
          if (this.thisexpertId != "") { // 根据专家ID查询专家证明材料接口
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/aptitude/list?expertId=' + this.thisexpertId,
              params: '',

            }).then((e) => {
              this.aptitudetableData = [];
              for (let i in e.data.rows) {
                // 获取二级资源信息
                this.aptitudetableData.push({
                  certNo: e.data.rows[i].certNo,
                  certUrl: e.data.rows[i].certUrl,
                  fileName: e.data.rows[i].fileName,
                  expertId: this.thisexpertId,
                  fileUrl: e.data.rows[i].fileUrl, // 用于审核是查看
                })
              }
            }).catch(error => {
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }

          if (res.data.data.titleCertUrl) { // 职称证书或推荐函路径
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + res.data.data.titleCertUrl,
              params: '',
            }).then((e) => {
              this.titleCertimgUrl = e.data.data.fileUrl
            }).catch(error => {
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
          if (res.data.data.credit) { // 信用证明路径
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + res.data.data.credit,
              params: '',
            }).then((e) => {
              this.creditimgUrl = e.data.data.fileUrl
            }).catch(error => {
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }

          if (res.data.data.undertaking) { // 承诺书路径
            this.$axios({
              method: 'get',
              url: this.$baseUrl.test + '/api/files/' + res.data.data.undertaking,
              params: '',
            }).then((e) => {
              this.undertakingmgUrl = e.data.data.fileUrl
            }).catch(error => {
              this.$message({
                offset: 100,
                message: error.msg,
                type: 'error'
              });
            });
          }
          // 获取专家详细信息
          res.data.data.status = '1'
          this.Expertinformation = res.data.data;
          // 根据专家ID获取简历
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/resume/list?expertId=' + res.data.data.expertId,
            params: '',
          }).then((e) => {
            this.jlruleForm = e.data.rows;
          }).catch(error => {
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
          // 根据专家ID查询专家教育背景列表接口
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/education/list?expertId=' + res.data.data.expertId,
            params: '',
          }).then((e) => {
            this.jyruleForm = e.data.rows;
          }).catch(error => {
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });

          // 根据专家ID查询专家教研科研项目经验接口
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/researchProject/list?expertId=' + res.data.data.expertId,
            params: '',
          }).then((e) => {
            this.experienceruleForm = e.data.rows;
          }).catch(error => {
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });

          // 根据专家ID查询专家相关领域成果/荣誉
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/relatedFields/list?expertId=' + res.data.data.expertId,
            params: '',
          }).then((e) => {
            this.achievementstableData = e.data.rows;
          }).catch(error => {

            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
          // 根据专家ID查询所选的申报领域列表
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/expertSubject/getSubjectListByExpertId/' + res.data.data.expertId,
            params: '',
          }).then((e) => {
            if (e.data.code == 200) {
              this.Expertinformation.Professionalinformation = [];
              // 申报领域一级,转字符串
              this.Professional = e.data.rows.length > 0 ? '' + e.data.rows[0].subjectId : []
              for (let i in e.data.rows) {
                // 申报领域二级
                this.Expertinformation.Professionalinformation.push(
                  e.data.rows[i].subjectTypeId.toString())
              }
              this.Professionalinformation = this.Expertinformation.Professionalinformation;
            }
          }).catch(error => {
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        } else {
          console.log("没有专家ID")
        }
      }).catch(error => {

        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 根据用户编号获用户取详细信息接口
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/user/getUserInfo/' + userId,
        params: '',
      }).then((res) => {
        // 空值转“”
        for (var key in res.data.data) {
          if (res.data.data[key] == null || res.data.data[key] == "null") {
            res.data.data[key] = ""
          }
        }
        // 根据用户头像ID获取用户头像地址
        if (res.data.data.avatar) {
          this.$axios({
            method: 'get',
            url: this.$baseUrl.test + '/api/files/' + res.data.data.avatar,
            data: '',
          }).then((e) => {
            if (e.data.code == 200) {
              // 获取头像地址
              this.ruleForm.userImageUrl = e.data.data.fileUrl
            }
          }).catch(error => {
            this.$message({
              offset: 100,
              message: error.msg,
              type: 'error'
            });
          });
        }
        Object.assign(this.ruleForm, JSON.parse(JSON.stringify(res.data.data)));
        this.ruleForm.remark = res.data.data.remark;
      }).catch(error => {
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });
      // 获取专业类别一级
      let dictType = ['subject_type', 'title_grade']
      this.$axios({
        method: 'post',
        url: this.$baseUrl.test + '/api/pubilc/getDictLists',
        data: dictType

      }).then((res) => {
        // 获取一级
        for (let i in res.data.data.subject_type) {
          this.options1.push({
            'value': res.data.data.subject_type[i].dictValue,
            'label': res.data.data.subject_type[i].dictLabel,
          })
        }

        // 获取专业技术职称
        for (let i in res.data.data.title_grade) {
          this.titleGrade.push({
            'value': res.data.data.title_grade[i].dictValue,
            'label': res.data.data.title_grade[i].dictLabel,
          })
        }
      }).catch(error => {
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

      // 获取专业类别二级
      this.$axios({
        method: 'get',
        url: this.$baseUrl.test + '/api/subject/list?parentId=0',
        params: '',

      }).then((res) => {
        for (let i in res.data.data) {
          this.options2.push({
            'value': res.data.data[i].subjectId.toString(),
            'label': res.data.data[i].subjectName,
            'disabled': false
          })
        }
      }).catch(error => {
        this.$message({
          offset: 100,
          message: error.msg,
          type: 'error'
        });
      });

    }
  }
</script>

<style src="@/style/Expert/Expertinformation.css" scoped></style>
<style src="@/style/main.css" scoped></style>
<style scoped>
  * {
    padding: 0rem;
  }

  .el-progress__text {
    display: none !important;
  }

  .el-progress-bar {
    padding-right: 0px !important;
  }

  .libaoimgitem .el-badge__content.is-fixed {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: #cacaca;
    transform: translateY(-50%) translateX(100%);
  }

  /* 以完成的字 */
  .el-step__title.is-finish {
    color: #c0c4cb !important;
  }

  /* 以完成的图标和步骤 */
  .el-step__head.is-finish {
    color: #c0c4cb !important;
    border-color: #c0c4cb !important;
  }

  .el-step__main {
    white-space: normal;
    /* text-align: left; */
    display: flex;
    justify-content: flex-start;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .el-form-item__label {
    text-align: right;
  }

  /deep/ .demo-ruleForm {
    height: auto;
    overflow-y: auto;
    margin-top: 0;
    border-radius: 10px;
  }
</style>
