<template>
  <div class="hall" style="padding: 0rem;">
    <el-row>
      <el-col :span="24">
        <div class="HirehallCom">
          <el-row style="margin-top: 10px;">
            <el-col :span="24">
              <div class="HirehallCom2">
                <div class="HirehallComtitle">校企资源</div>
                <div class="HirehallComss">
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <div class="zhaopingxxlist">
                <el-row :gutter="0" v-loading="loading" element-loading-text="拼命加载中"
                  element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                  <el-col :span="24" class="zplist">
                    <div style="background-color: #f1f2f5;">
                      <div v-for="item in tableData">
                        <div class="zplistdiv" @click="xiangq(item)">
                          <div style="width: 300px;height: 140px;">
                            <img style="width: 80%;height: 100%;object-fit: contain;" :src="item.articleCoverUrl" />
                          </div>
                          <div style="width: calc(80% - 300px);text-align: left;">
                            <div class="zuoxianrtitle">{{item.articleTitle}}</div>
                            <div>{{item.articleIntroduce}}</div>
                          </div>
                          <div class="shij">
                            {{item.createTime}}
                          </div>
                        </div>
                      </div>
                      <el-empty v-if='tableData.length == 0' description="暂无资源"></el-empty>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!-- 分页 -->
              <div class="block" style="margin-bottom: 3rem;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pagination.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
              </div>
            </el-col>
          </el-row>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        loading: false,
        tableData: [],
        total: '',
        // 分页
        pagination: {
          'pageNum': 1,
          'pageSize': 10,
          'type': 1,
        },
      }
    },
    // 页面加载完毕事件
    mounted() {
      this.onSubmit();
      localStorage.setItem('activeIndexnew', '2');
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pagination.pageSize = val;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pagination.pageNum = val;
        this.onSubmit();
      },
      onSubmit() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$baseUrl.test + '/system/doorArticle/list?pageNum=' +
            this.pagination.pageNum + '&pageSize=' + this.pagination.pageSize + '&type=' + this.type +
            '&articleStatus=1',
          data: this.pagination,
          headers: {
            'Authorization': ''
          }
          //请求成功的回调函数
        }).then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            this.tableData = res.data.rows;
            this.total = res.data.total;
          } else {
            this.tableData = [];
            this.loading = false;
          }
        }).catch(error => {
          // 处理错误
          this.$message({
            offset: 100,
            message: error.msg,
            type: 'error'
          });
        });
      },

      // 跳转
      xiangq(e) {
        // 跳转到文章详情页面
        this.$router.push({
          name: 'Portaldetails'
        });
        localStorage.setItem("xxarticleId", e.articleId)
      }
    }
  }
</script>

<style src="@/style/HirehallCom.css"></style>
<style scoped>
  .el-select {
    margin-right: 2rem;
    display: inline-block;
    position: relative;
  }

  .el-radio--mini.is-bordered {
    padding: 6px 2px 0px 2px !important;
    border-radius: 3px;
    height: 28px;
  }

  ::-webkit-scrollbar {
    display: none
  }

  .iconstyle {
    float: left;
    margin-right: 1rem;
  }

  .gzuodid {
    text-align: left;
    padding: 0.5rem 0.5rem;
  }

  .gzuodid .el-select {
    width: 8rem;
    margin-right: 1rem;
  }

  .gzuodid .el-radio {
    margin-right: 0px;
  }

  .zplistdiv {
    display: flex;
    padding: 1rem;
    border-bottom: solid #eeeeee 1px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .HirehallCom2 {
    height: 12.9375rem;
    background-image: url(../assets/u50.png) !important;
    background-repeat: round;
    background-size: 100% 100%;
  }

  .zuoxianrtitle {
    font-family: 'Arial Normal', 'Arial';
    font-weight: bolder;
    font-style: normal;
    font-size: 22px;
    text-align: left;
    display: flex;
    padding-bottom: 10px;
  }
</style>
